body > ng-component {
    display: block;
    height: 100%;
}

.data-centre__page {
    height: 100%;
    .site__content {
        height: 100%;
    }

    .site__header--logged-in,.site__header--logged-out{
        .container{
            max-width: none;
            width: 100%;
            padding: 0;
        }    
    }
    .site__header--logged-in, .site__header--logged-out {
        .main__navigation {
            &.open {
                top: rems(52);
                .button_-group {
                    padding-top: rems(20);
                }
            }
            .button {
                line-height: 1;
                margin-bottom: 0;
                &.button--small{    
                    &.button--icon-small{
                        .icon{
                            height: rems(18);
                            width: rems(18);
            
                            svg{
                                height: rems(18);
                                width: rems(18);
                            }
            
                        }
                    }
                
                }
                &.button--ghost {
                    background-color: var(--color-grey-light);
                    border: 1px solid var(--color-grey);
                    
                    &:hover{
                        background-color: #bbb;
                        border-color: var(--color-grey-dark);
                    }
                }
            }
        }
    }
    .container {
        width: 100%;
    }

    .data-centre__report--wrapper {
        overflow: scroll;
        height: calc(100% - 52px);

        &::-webkit-scrollbar {
            width: rems(12);
        }

        &::-webkit-scrollbar-track {
            background: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-grey-dark);
            border-radius: rems(20);
            border: rems(3) solid white;
        }
    }
    .data-centre__report {
        font-weight: 400;
        margin: auto;
        padding: rems(20);
        font-size: rems(14);
        line-height: rems(24);
        font-family: "Mulish", "Muli", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
        color: var(--color-black);
        background-color: var(--color-white);
        line-height: 1.4;
        max-width: rems(800);
        min-width: rems(600);

        &--wide {
            max-width: rems(1200);
            min-width: rems(800);
        }
        &--widest {
            max-width: 96vw;
            min-width: rems(800);
        }
    }
}


@include breakpoint(for-tablet-landscape-up) {

    .data-centre__report {
        padding: rems(30);
    }
}


@media (min-width: 800px) {
    body > ng-component {
        display: block;
    }
    .data-centre__page {
        height: 100%;
        .site__content {
            height: 100%;
        }
        .data-centre__report--wrapper {
            background: var(--color-grey-xlight);
            padding: rems(24) 0;

            &::-webkit-scrollbar-track {
                background: var(--color-grey-xlight);
            }
    
            &::-webkit-scrollbar-thumb {
                border: rems(3) solid var(--color-grey-xlight);
            }

            &::-webkit-scrollbar-corner {
                background: var(--color-grey-xlight);
            }

            .data-centre__report {
                background: white;
                //min-height: 96vh;
                padding: rems(30) rems(30);
                -webkit-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
                -moz-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
                box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            }
        }
    }
}

@media print {
    .data-centre__report--wrapper {
        background: none;
        padding-top: 0;

        .data-centre__report {
            background: none;
            min-height: 0;
            padding: 0 rems(10) 0 rems(10);
            box-shadow: none;
        }
    }
}
