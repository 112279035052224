.coho__dark {
    .site__header--logged-in, .site__header--logged-out {

        .main__navigation {
            background-color: var(--color-blue-dark);

            li {
                border-left: rems(4) solid var(--color-blue-dark);

                &.active {
                    background-color: var(--color-blue-dark);
                    border-left: rems(4) solid var(--color-blue-dark);
                }
            }
        }

        .header__profile-image {
            border: rems(2) solid var(--color-blue);
        }

        .header__options {

            .dropdown {

                &.open {
                    .header__options-button {
                        background-color: var(--color-magenta-xdark);
                    }
                }

                &.dropdown__feedback {
                    &.open {
                        .header__options-button {
                            background-color: var(--color-pumpkin-xdark);
                        }
                    }
                }

                .dropdown__menu {
                    background-color: var(--color-black);
                    border: 2px solid var(--color-magenta-xdark);

                    li {
                        border-bottom: 1px solid var(--color-grey-dark);

                        &.dropdown__menu-section {
                            border-top: 1px solid var(--color-grey-dark);
                        }
                    }

                    .dropdown__menu-title {
                        background-color: var(--color-magenta-xdark);
                    }
                }

                &.dropdown__announcements, &.dropdown__feedback, &.dropdown__messages {
                    .header__options-button {
                        .profile__image, .header__profile-image.profile__image {
                            background-color: transparent;
                            border-color: transparent;
                        }
                    }
                }
            }
        }




        .header__options-button {
            background-color: transparent;

            &::after {
                content: url("data:image/svg+xml; utf8, <svg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'><path class='path' fill='%23ffffff' d='m4.70710678 5.29289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l4 4.00000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.00000002-4.00000002c.3905243-.39052429.3905243-1.02368927 0-1.41421356s-1.0236893-.39052429-1.4142136 0l-3.2928932 3.29289322z'/></svg>");
            }

            .badge {
                box-shadow: 0 2px 0px rgba(0,0,0,0.5);
            }

            &:focus {
                .header__profile-image {
                    border: 2px solid var(--color-grey-xlight);
                }
            }
        }

        .dropdown__feedback{
            .feedback__menu {
                .feedback__menu-main{
                    >li{
                        background: var(--color-black);
                    }
                }
                .dropdown__menu-item {
                    .icon {
                        fill: var(--color-black);
                    }
                    &:hover {
                        background: transparent;
                        color: var(--color-grey-xlight);

                    }
                    .feedback--icons .feedback--icons-holder {
                        background-color: var(--color-grey-border);
                        p { 
                            color: var(--color-grey-xlight); 
                        }
                    }
                }
            }
        }
                    
    }

    @include breakpoint(for-tablet-landscape-up) {
        .site__header--logged-in, .site__header--logged-out {
            .main__navigation {
                li {
                    background-color: transparent;

                    a {
                        color: var(--color-white);

                        &:hover {
                            background-color: transparent;

                            &::after {
                                background-color: var(--color-white);
                            }
                        }
                    }

                    &.active {
                        background-color: transparent;

                        a {
                            &::after {
                                background-color: var(--color-pink);
                            }

                            &:hover {
                                &::after {
                                    background-color: var(--color-pink);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
