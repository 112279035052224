.upsell__container {
    background-color: var(--color-green-light);
    margin-bottom: rems(20);
    margin-top: rems(10);
    border: 1px solid var(--color-green);
    border-radius: radius(medium);
    padding: rems(10) rems(15);

    h4 {
        color: var(--color-green-xdark);
        font-size: rems(24);
    }

    h4 + p {
        margin-top: rems(16);
    }

    p {
        line-height: 1.4;
        //font-size: rems(15);
        &.last {
            margin-bottom: 0;
        }
    }
}
