.button{

    &.button--link{
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        color: var(--color-text);
        cursor: pointer;
        display: unset;
        font-family: $type__family;
        font-size: rems(16);
        font-weight: 600;
        line-height: unset;
        margin-bottom: 0;
        min-width: 0;
        padding: 0 rems(2);
        text-align: left;
        text-decoration: none;
        //transition: all 0.2s ease-out;
        border-bottom: 1px dotted var(--color-black);

        &:hover{
            border-color: var(--color-cyan);
            background-color: transparent;
            color: var(--color-cyan);
            
        }

        &:focus{
            border: 0;
            //box-shadow: 0 0 0 rems(3) var(--color-text);
            outline: none;
        }

        &.button--small {
            font-size: rems(14);
        }

        &.button--link-blue {
            border-bottom: 0;
            text-decoration: underline;
            color: var(--color-cyan);
            &:hover {
                text-decoration: none;
            }
        }

        &.button--link-tag {
            display: block;
            font-size: rems(12);
            padding-top: rems(8);
            margin-right: rems(8);
        }
    }
}



