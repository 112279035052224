.conversation__page {
    .conversation__grid-wrapper {
        .conversation__grid {
            .conversation__grid-right {
                .conversation__grid-header {
                    &.maintenance {

                        manager-section {

                            .status__holder {
                                &:not(.status__holder--severity) {
                                    ul {
                                        li {
                                            margin: 0 auto;
                                        }
                                    }
                                }
                            }
                            .col {
                                &.col__md--6 {
                                    width: 100%;
                                }
                            }

                        }

                        user-section {
                            .accordion {
                                text-align: left;
                            }
                        }

                        p {
                            &.u-text--caption {
                                line-height: 1.4;
                            }
                        }

                        .item__holder {
                            text-align: left;

                            &.item__holder--status {
                                .col {
                                    &.col__xs--6.col__sm--4.col__md--3.col__lg--4 {
                                        width: 50%;
                                    }
                                }
                                .item__holder--status-wrapper {
                                   flex-direction: column;
                                   .item__holder--actions {
                                        .button__group {
                                            justify-content: left;
                                        }
                                   }
                                }
                            }
                        }


                    }
                }
            } 
        }
    }
}