.form__range{
    -webkit-appearance: none;
    background: transparent;
    display: block;
    height: rems(32);
    margin-left: 0;
    margin-top: 0;
    overflow: visible;
    position: relative;
    width: 100%;

    &:active, &:focus{
        outline: none;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &::-moz-focus-outer {
        border:0;
    }
  
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        height: rems(32);
        width: rems(32);
        border-radius: 100px;
        background-color: var(--color-white);
        border: rems(1) solid var(--color-grey);
        box-shadow: $shadow__up;
        cursor: pointer;
        margin-top: rems(-16);
        margin-left: rems(0);
        transition: all 0.2s ease-out; 

        &:hover{
            border-color: rems(1) solid var(--color-blue);
            box-shadow: $shadow__outline;
        }

        &:active{
            box-shadow: $shadow__outline;
        }

      }
      

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: rems(4);
        cursor: pointer;
        background-color: var(--color-grey-light);
        border-radius: rems(2);
        border: 1px solid var(--color-grey-light);
        }

        &:focus{
            &::-webkit-slider-thumb{
                box-shadow: $shadow__outline;
            }
        }

        &:disabled{
            &::-webkit-slider-thumb{
                background-color: var(--color-grey-xlight);
                border-color: var(--color-grey-light);

                &:hover{
                    box-shadow: none;
                }

            }
        }


      // Firefox
      &::-moz-range-thumb{
        -webkit-appearance: none;
        height: rems(32);
        width: rems(32);
        border-radius: 100px;
        background-color: white;
        border: 2px solid var(--color-primary);
        cursor: pointer;
        margin-top: rems(-16);
        margin-left: rems(0);
        transition: all 0.2s ease-out; 
        cursor: pointer;
    
    
        &:hover{
            box-shadow: $shadow__outline;
        }

        &:active{
            background-color: var(--color-form);
            box-shadow: $shadow__outline;
        }
        
        }

        &:disabled{
            &::-moz-range-thumb{
                background-color: var(--color-grey-xlight);
                border-color: var(--color-grey-light);

                &:hover{
                    box-shadow: none;
                }

            }
        }
    
        &::-moz-range-track{
            width: 100%;
            height: rems(4);
            cursor: pointer;
            background-color: var(--color-grey-light);
            border-radius: rems(2);
            border: 1px solid var(--color-grey-light);
        }
    
    
        &::-ms-thumb{
            -webkit-appearance: none;
            height: rems(32);
            width: rems(32);
            border-radius: 100px;
            background-color: white;
            border: 2px solid var(--color-primary);
            cursor: pointer;
            margin-top: rems(-16);
            margin-left: rems(0);
            transition: all 0.2s ease-out; 
            cursor: pointer;
        
        
            &:hover{
                box-shadow: $shadow__outline;
            }
    
            &:active{
                background-color: var(--color-form);
                box-shadow: $shadow__outline;
            }
        }

        &:disabled{
            &::-ms-thumb{
                background-color: var(--color-grey-xlight);
                border-color: var(--color-grey-light);

                &:hover{
                    box-shadow: none;
                }

            }
        }
  
        &::-ms-track{

            /* Resets so custom styles vcan be added */
            background: transparent;
            border-color: transparent;
            color: transparent;
            /* */
            width: 100%;
            height: rems(4);
            cursor: pointer;
            background-color: var(--color-grey-light);
            border-radius: rems(2);
            border: 1px solid var(--color-grey-light);
        }

}

.form__range-group{
    margin-bottom: rems(20);
}

// Scale

.form__range-scale{
    display: flex;
    flex: 1 0 100%;
    flex-direction: row;
    justify-content: space-between;
}

.form__range-scale-item{
    color: var(--color-grey);
}