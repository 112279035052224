.page__section {
    padding-bottom: rems(20);
    padding-top: rems(20);
    transition: padding 0.25s linear;

    &.page__section--focus {
        padding-top: rems(20)
    }

    &.page__section--alt {
        background-color: var(--color-grey-xxlight);
    }

    &.page__section--alt {
        background-color: var(--color-grey-xxlight);
    }

    &.page__section--highlight {
        background-color: var(--color-teal-light);
    }


    &.page__section--advert {
        background-color: transparent;

        background-image: url("https://d19qeljo1i8r7y.cloudfront.net/images/advert/advert_bg-400.png");
        background-image: url("https://coho-design.s3.eu-west-2.amazonaws.com/front-end/latest/assets/images/design/advert/advert_bg-400.png");

        background-repeat: repeat;
        padding-top: rems(30);
        padding-bottom: rems(30);
    }

    &.page__section--slim {
        padding-bottom: rems(10);
        padding-top: rems(28);
    }

    &.page__section--supplier {
        padding-bottom: rems(20);
        padding-top: rems(28);
    }

    &--narrow {
        padding-top: rems(8);
        padding-bottom: rems(8);
    }
    &--narrowest {
        padding-top: 0;
        padding-bottom: rems(8);
    }

    &.page__section--border {
        border-bottom: 1px solid var(--color-grey-light);
    }

    .page__section-content {
        .row {
            max-height: 9999vh;
            transition: max-height 1s linear;
        }
    }

    &.collapsed {
        .page__section-content {
            padding-bottom: 0;
            height: 0;
            overflow: hidden;

            .row {
                max-height: 0;
            }
        }
    }

    .room__content-section-content {
        max-height: 9999vh;
        transition: max-height 1s linear;
    }

    .room__content-section.collapsed {
        .room__content-section-content {
            padding-bottom: 0;
            height: 0;
            max-height: 0;
            overflow: hidden;
        }
    }

    .heading--section {
        transition: margin 0.25s linear;
    }

    .heading--settings {
        .button--open {
            display: none;
        }
    }

    .accordion.accordion--flat {
        border-bottom: none;
    }

    &.page__section--alt {
        .accordion.accordion--flat {
            border-bottom: none;
        }
    }
}

@include breakpoint(for-tablet-portrait-up){
    .page__section{
        padding-bottom: rems(60);
        padding-top: rems(60);

        &.collapsed {
            padding-bottom: rems(24);
            padding-top: rems(28);
        }

        &--narrow {
            padding-top: rems(8);
            //padding-bottom: rems(8);
        }
        &--narrowest {
            padding-top: 0;
            padding-bottom: rems(8);
        }

        &.collapsed {
            .heading--section {
                margin-bottom: rems(16);
            }
            h2 + p {
                margin-top: rems(16);
                margin-bottom: rems(16);
            }
        }



    }
}

