.card__rent {
    padding-top: rems(6);
    min-height: rems(94);
    position: relative;

    &:focus {
        outline: none;
    }

    &--action {
        background-color: var(--color-magenta-xdark);
    }

    &--active {
        background-color: var(--color-green-xxdark);
    }

    &--active-2 {
        background-color: var(--color-green-xdark);
    }

    &--active-3 {
        background-color: var(--color-green-dark);
    }

    &--progress {
        background-color: var(--color-orange-xdark);
    }

    &--inactive {
        background-color: var(--color-grey-border);
        cursor: default;

        &:focus {
            outline: 0;
        }
    }

    .card__amount {
        padding-top: rems(16);
        font-size: rems(40);
        line-height: 1;
        color: var(--color-white);
        text-align: center;

        &:hover {
            color: white;
        }
    }

    &.card__rent--total {
        .card__amount {
            padding-top: rems(14);
            font-size: rems(32);
        }
        .card__total {
            padding-top: rems(2);
            font-size: rems(14);
        }
    }

    &--smaller {
        .card__amount {
            font-size: rems(28);
            padding-top: rems(26);
        }

        &.card__rent--total {
            .card__amount {
                padding-top: rems(16);
                font-size: rems(28);
            }
            .card__total {
                padding-top: rems(2);
                font-size: rems(14);
            }
        }
    }

    &--smallest {
        .card__amount {
            font-size: rems(24);
            padding-top: rems(30);
        }

        &.card__rent--total {
            .card__amount {
                padding-top: rems(20);
                font-size: rems(24);
            }
            .card__total {
                padding-top: rems(2);
                font-size: rems(14);
            }
        }
    }

    .card__total {
        padding-top: rems(4);
        font-size: rems(16);
        line-height: 1;
        color: var(--color-white);
        text-align: center;

        &:hover {
            color: white;
        }
    }

    .card__title {
        color: var(--color-white);
        font-size: rems(12);
        text-align: center;
        //text-transform: uppercase;
        line-height: 1.5;
        position: absolute;
        bottom: rems(4);
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);

        &.card__due-date {
            width: 100%;
        }

        &:hover {
            color: white;
        }
    }

    &.active {
        position: relative;

        &::after {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23e9e9e9' d='M17.000,23.000 L17.000,21.000 L27.000,21.000 L27.000,23.000 L17.000,23.000 zM17.000,16.000 L27.000,16.000 L27.000,18.000 L17.000,18.000 L17.000,16.000 zM17.000,11.000 L27.000,11.000 L27.000,13.000 L17.000,13.000 L17.000,11.000 zM17.000,6.000 L27.000,6.000 L27.000,8.000 L17.000,8.000 L17.000,6.000 zM3.000,21.000 L13.000,21.000 L13.000,23.000 L3.000,23.000 L3.000,21.000 zM3.000,16.000 L13.000,16.000 L13.000,18.000 L3.000,18.000 L3.000,16.000 zM3.000,11.000 L13.000,11.000 L13.000,13.000 L3.000,13.000 L3.000,11.000 zM3.000,6.000 L13.000,6.000 L13.000,8.000 L3.000,8.000 L3.000,6.000 z'/></svg>");
            content: "";
            display: block;
            position: absolute;
            height: rems(20);
            right: rems(4);
            top: rems(4);
            transition: transform 0.2s ease-out;
            width: rems(20);
        }
    }

}
