.style__element{
    background-color: white;
    border: 1px solid #cccccc;
    padding: rems(20);
}

.style__section{
    background-color: #f7f7f7;
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin-bottom: rems(30);
    padding: rems(20);
}

.style__code{
    .prettyprint{
        overflow: auto;
    }
}