
.card{
    &.card__partners {
        &.card--image-left{

            .card__image-content {
                flex-direction: column;

                .card__image{
                    width: rems(180);
                    max-width: rems(180);
                    margin-bottom: rems(20);
                }
            }


            .card__content {
                display: flex;
                flex-direction: column;
                .card__content--left {
                    h4 + p {
                        margin-top: rems(10);
                    }
    
                }
                .card__content--right {

                }

            }

    
        }
    }
    &.card__partner {
        .card__content--left {
            h4 + p {
                margin-top: 0;
                margin-bottom: rems(10);
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .card{
        &.card__partners {
            &.card--image-left{
                .card__image-content {
                    flex-direction: row;
                    
                }    
    
            }
        }
    }   
}

@include breakpoint(for-tablet-landscape-up){
    .card{
        &.card__partners {
            &.card--image-left{ 
                .card__content {
                    flex-direction: row;
                    .card__content--left {

                        flex-basis: 60%;
                    }
                    .card__content--right {
                        margin-left: rems(30);
                        flex-basis: 40%;
                    }

                    &.card__content--fullwidth {
                        .card__content--left {

                            flex-basis: 80%;
                        }
                    }
    
                }
    
        
            }
        }
    }    
}