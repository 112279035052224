.demo__header {
    display: none;

    .demo-manager, .demo-housemate {
        display: none;
    }

    &.visible {
        display: block;
        width: 100%;
        background-color: var(--color-magenta);
        min-height: rems(152);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;

        &.expanded {
            min-height: rems(336);
        }

        .container {
            padding: rems(10) rems(20);

            p {
                margin-bottom: 0;
                color: var(--color-white);

                a {
                    color: var(--color-white);
                    padding-left: rems(8);
                    font-size: rems(14);

                    &.hidden {
                        display: none;
                    }
                }
            }

            .demo__header--content {
                display: none;


                &.visible {
                    display: flex;
                    flex-direction: column;
                    padding: rems(8) 0;
                }
            }

            .demo__header--header {
                display: flex;
                flex-direction: column;
                padding: rems(8) 0;
            }

            .demo__header--content-description {
                flex: 1;

                p {
                    font-size: rems(14);
                }
            }

            .demo__header--content-action-wrapper {
                display: flex;
                flex-direction: column;
            }

            .demo__header--content-actions {
                padding-top: rems(12);

                select {
                    margin-bottom: 0;
                }

                .button {
                    white-space: nowrap;
                    margin-bottom: 0;

                    &.button--ghost {
                        color: var(--color-magenta);
                    }
                }
            }
        }
    }

    &.manager {
        .demo-manager {
            display: inline-block;
        }
    }

    &.housemate {
        .demo-housemate {
            display: inline-block;
        }
    }
}

.conversation__page {
    .demo__header {
        &.visible {
            display: none;
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .conversation__page {
        .demo__header {
            &.visible {
                display: block;
            }
        }
    }

    .demo__header {

        &.visible {
            min-height: rems(106);
            &.expanded {
                min-height: rems(218);
            }

            .container {
                .demo__header--content-action-wrapper {
                    flex-direction: row;
                }

                .demo__header--content-actions {
                    padding-left: rems(20);

                    &:first-of-type {
                        padding-left: 0;
                    }
                }


            }
        }
    }
}
@include breakpoint(for-tablet-portrait-up) {
    .demo__header {
        &.visible {
            min-height: rems(70);
            &.expanded {
                min-height: rems(158);
            }
            .container {
                .demo__header--content {
                    &.visible {
                        flex-direction: row;

                        .demo__header--content-actions {
                            padding-top: rems(8);
                            padding-left: rems(30);
                        }
                    }
                }

                .demo__header--header {
                    flex-direction: row;

                    .demo__header--content-actions {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
@include breakpoint(for-small-desktop-up) {
    .demo__header {
        &.visible {
            &.expanded {
                min-height: rems(134);
            }
        }
    }
}

