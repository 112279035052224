
autocomplete {
    p-autocomplete {
        &.p-element {

            &.hasAppend {
                span {
                    input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-right: none;
                    
                        &:focus {
                            z-index: 100;
                        }
                    }
                }
            }
            &.form__input--small {
                span {
                    input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
                        font-size: rems(14);
                        padding: rems(6) rems(10);
                        //margin-bottom: rems(8);
                        height: rems(35);
                    }
                }
            }
            &.form__input--tiny {
                span {
                    input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
                        font-size: rems(14);
                        padding: rems(2) rems(4);
                        //margin-bottom: rems(8);
                        height: rems(28);
                        border-top-left-radius: radius(small);
                        border-bottom-left-radius: radius(small);
                    }
                }
            }            
        }
        span.p-autocomplete {
            width: 100%;
        }
    }
    .form__input-group-append {
        &.form__input--small {
            width: rems(35);
            height: rems(35);
            .button.button--icon.button--icon-only {
                min-width: rems(35);
                min-height: rems(35);
                .icon {
                    top: rems(7);
                    left: rems(7);
                }
            }
        }
        &.form__input--tiny {
            width: rems(25);
            height: rems(25);
            background: transparent;
            .form__input-group-button {
                height: rems(32);
            }
            .button.button--icon.button--icon-only {
                min-width: rems(28);
                min-height: rems(28);
                border-top-right-radius: radius(small);
                border-bottom-right-radius: radius(small);
                .icon {
                    top: rems(3);
                    left: rems(4);
                }
            }
        }
    }
}


// .form__select {
//     &.hasAppend {
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//         border-right: none;
//         margin-bottom: 0;

//         &:focus {
//             z-index: 100;
//         }
//     }
// }

