multi-dropdown {
    .mat-form-field {
        width: 100%;
    }

    .mat-form-field-infix {
        padding: 0 !important;
        border-top: 0 !important;
    }


    .mat-form-field-underline, .mat-form-field-subscript-wrapper {
        display: none;
    }

    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        display: none;
    }

    .mat-select {
        &.form__multiselect {
            @include input__up;
            appearance: none;
            background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
            background-repeat: no-repeat;
            background-position: right 10px center;
            height: rems(42);
            margin-bottom: rems(4);
            overflow: hidden;
            padding: rems(10) rems(36) rems(10) rems(8);
            min-width: rems(144);
            width: 100%;

            &:focus {
                background-color: white;
                border: rems(1) solid var(--color-form);
                box-shadow: $shadow__up, $shadow__outline;
                outline: none;
            }

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                background: cvar(--color-grey-xlight);
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_disabled}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                background-repeat: no-repeat;
                background-position: right 10px center;
                color: var(--color-text);
            }

            &.required {
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_required}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                background-repeat: no-repeat;
                background-position: right 10px center;
                border-color: var(--color-magenta);
                box-shadow: $shadow__required;

                &:focus {
                    background-color: white;
                    background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                    border: rems(1) solid var(--color-form);
                    box-shadow: $shadow__up, $shadow__outline;
                    outline: none;
                }
            }

            &.read-only {
                background-color: var(--color-white);
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_readonly}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                border-color: var(--color-grey-xlight);
                box-shadow: none;
            }
        }

        .mat-select-arrow-wrapper {
            .mat-select-arrow {
                border: none;
            }
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        top: rems(26);
        left: rems(16);
        color: var(--color-black);
        font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
        font-weight: 600;
    }


}
@include breakpoint(for-tablet-portrait-up) {
    .form__multiselect {
        &.form__input--width-quarter {
            max-width: 25%;
        }

        &.form__input--width-third {
            max-width: 33.333%;
        }

        &.form__input--width-half {
            max-width: 50%;
        }

        &.form__input--width-two-thirds {
            max-width: 66.666%;
        }

        &.form__input--width-three-quarters {
            max-width: 75%;
        }
    }
}

.cdk-overlay-pane {
    .mat-option {
        color: var(--color-black);
        font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 1rem;
    }

    .mat-option.mat-active {
        background: none;
    }

    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: var(--color-black);
    }

    .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
        background: var(--color-teal);
    }

    .mat-select-panel {
        background: var(--color-white);
        &:not([class*=mat-elevation-z]) {
            box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f
        }
    }
}

