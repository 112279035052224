.form__input-group textbox, .form__input-group numberbox, .form__input-group dropdown, .form__input-group .date-picker-wrapper, .form__input-group autocomplete {
    display: flex;
    flex-direction: row;
    margin-bottom: rems(4);
    position: relative;
    width: 100%;

    input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
        margin-bottom: 0;
        transition: box-shadow 0.2s ease-out, border-radius 0.2s ease-out;
        //height: rems(42);

        &:focus {
            position: relative;
            z-index: 100;
        }


        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;

            &:focus {
                z-index: 100;
            }
        }

        &.hasAppend {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;

            &:focus {
                z-index: 100;
            }
        }
    }

    .form__select {
        &.hasAppend {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            margin-bottom: 0;

            &:focus {
                z-index: 100;
            }
        }
    }

    textbox, numberbox {
        width: inherit;

        &:not(:first-child) {
            input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;

                &:focus {
                    z-index: 100;
                }
            }
        }

        &:not(:last-child) {
            input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;

                &:focus {
                    z-index: 100;
                }
            }
        }
    }

    dropdown {
        width: inherit;

        &:not(:first-child) {
            .form__select {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;

                &:focus {
                    z-index: 100;
                }
            }
        }

        &:not(:last-child) {
            .form__select {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;

                &:focus {
                    z-index: 100;
                }
            }
        }
    }

    .form__input-group-prepend {
        align-items: center;
        background-color: var(--color-form-light);
        border: rems(1) solid var(--color-form);
        border-top-left-radius: radius(medium);
        border-bottom-left-radius: radius(medium);
        color: var(--color-blue);
        display: flex;
        flex-shrink: 0;
        font-size: rems(14);
        font-weight: 800;
        line-height: rems(24);
        justify-items: center;
        padding: 0 rems(12);

        .icon {
            height: rems(20);
            width: rems(20);

            svg {
                height: rems(20);
                width: rems(20);
            }
        }

        &.readonly, &.disabled {
            background-color: var(--color-grey-light);
            color: var(--color-grey);
            border-color: var(--color-grey-border);
        }
    }


    .form__input-group-append {
        align-items: center;
        background-color: var(--color-form-light);
        border: rems(1) solid var(--color-form);
        border-top-right-radius: radius(medium);
        border-bottom-right-radius: radius(medium);
        color: var(--color-blue);
        display: flex;
        flex-shrink: 0;
        font-size: rems(14);
        font-weight: 800;
        justify-items: center;
        line-height: rems(24);
        max-width: rems(120);
        height: rems(42);
        //padding: rems(8) rems(16);

        .icon {
            height: rems(20);
            width: rems(20);

            svg {
                height: rems(20);
                width: rems(20);
            }
        }

        &.hidden {
            display: none;
        }

        &.readonly, &.disabled {
            background-color: var(--color-grey-light);
            color: var(--color-grey);
            border-color: var(--color-grey-border);
        }
    }



    .form__input-group-text, .form__input-group-number {
        padding: 0 rems(16);
        //height: rems(40);
        .form__input-group-text, .form__input-group-number {
            padding: 0;
        }
    }

    .form__input-group-select {
        padding: 0;
        overflow: visible;

        .form__select {
            background-color: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
            border: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: radius(medium);
            border-bottom-right-radius: radius(medium);
            box-shadow: none;
            color: var(--color-blue);
            font-size: rems(14);
            font-weight: 800;
            margin: 0;
            padding-right: rems(30);
            height: auto;
            min-width: 0;
            white-space: nowrap;
            //overflow: hidden;
            text-overflow: ellipsis;

            &:focus {
                //@include shadow__input_active;
            }
        }
    }

    .form__input-group-select-dates {
        height: rems(40);

        .form__select {
            width: 5rem;
            min-width: 0;
            background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='10px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");

            &:focus {
                box-shadow: none;
            }
        }
    }

    .form__input-group-button {
        background-color: transparent;
        border: none;
        padding: 0;


        .button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: radius(medium);
            border-bottom-right-radius: radius(medium);
            margin-bottom: 0;
            padding-top: rems(10);
            padding-bottom: rems(10);

        }
    }
    // DISABLED
    &.disabled {
        input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
            background: var(--color-grey-xlight);
            color: var(--color-text);
        }

        .form__input-group-prepend, .form__input-group-append {
            background-color: var(--color-grey-light);
            border-color: var(--color-grey-border);
            color: var(--color-grey);
        }

        .form__input-group-select {
            padding: 0;
            overflow: visible;

            .form__select {
                background-color: transparent;
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_disabled}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                color: var(--color-grey);
            }
        }
    }
    //REQUIRED
    .form__input-group-prepend.required, .form__input-group-append.required {
        background-color: rgba(var(--color-pink-rgb),0.2);
        border-color: var(--color-pink);
        box-shadow: $shadow__required;
        color: var(--color-magenta-dark);
        border-left: 0;

        input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
            border-color: var(--color-pink);
            box-shadow: $shadow__required;

            @include input__down_focus;
        }

        .form__input-group-prepend {
            border-right-width: rems(2);
        }

        .form__input-group-select {
            padding: 0;
            overflow: visible;

            .form__select {
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_required}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                background-repeat: no-repeat;
                background-position: right 10px center;
                border-color: var(--color-pink);
                border-left-width: rems(1);
                //box-shadow: $shadow__required;
                color: var(--color-magenta-dark);

                &:focus {
                    background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_required}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
                    background-repeat: no-repeat;
                    background-position: right 10px center;
                    border-color: var(--color-pink);
                    border-left-width: rems(1);
                    box-shadow: none;
                    color: var(--color-magenta-dark);
                }
            }
        }
    }


    &.read-only {
        .form__input-group-prepend, .form__input-group-append {
            background-color: var(--color-white);
            border-color: var(--color-grey-xlight);
        }

        .form__input-group-select {
            padding: 0;
            overflow: visible;

            .form__select {
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_readonly}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
            }
        }
    }
}
.form__input-group {
    &.form__input-group--has-button {
        textbox, numberbox, autocomplete {
            .form__input-group-append {
                border: 0;
            }
        }
        autocomplete {
            .button.button--icon.button--icon-only {
                min-width: rems(41);
                padding-left: 0;
                min-height: rems(41);
                &:focus, &:active {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }
    }
}

.form__input-group--small.form__input-group textbox,.form__input-group--small.form__input-group numberbox,.form__input-group--small.form__input-group dropdown,.form__input-group--small.form__input-group .date-picker-wrapper {

    input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
        padding: rems(6) rems(10);
        min-height: 0;
        height: rems(34);
        font-size: rems(14);
    }

    .form__input-group-prepend, .form__input-group-append {
        padding: rems(6) rems(10);
        height: rems(34);

        .icon {
            height: rems(16);
            width: rems(16);

            svg {
                height: rems(16);
                width: rems(16);
            }
        }
    }
}

//Numberbox fix
.p-inputnumber,p-inputnumber {
    display: block;
    width: 100%;
}

.form__input-group-prepend + p-inputnumber span input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]).p-inputnumber-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    &:focus {
        z-index: 100;
    }
}

// Really unsure about the wider effects of this
// input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]):not(.p-inputnumber-input:disabled) {
//     background-color: transparent;
// }


@include breakpoint(for-tablet-portrait-up) {
    .form__input-group {
        &.form__input--width-quarter {
            max-width: 25%;
        }

        &.form__input--width-third {
            max-width: 33.333%;
        }

        &.form__input--width-half {
            max-width: 50%;
        }

        &.form__input--width-two-thirds {
            max-width: 66.666%;
        }

        &.form__input--width-three-quarters {
            max-width: 75%;
        }
    }

    .form__input-group textbox, .form__input-group numberbox, .form__input-group dropdown {
        .form__input-group-append {
            max-width: unset;
        }
    }
}
