.button__accordion{
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: rems(16);
    line-height: rems(24);
    padding: rems(0) rems(10) rems(0) rems(0);
    position: relative;
    text-decoration: none;

    .icon{
        fill: var(--color-blue);
        height: rems(24);
        margin-right: rems(5);
        width: rems(24);

        svg{
            height: rems(24);
            width: rems(24);
        }

    }

    &:hover{
        text-decoration: underline;
    }

    &.open{
        .icon{
            transform: rotate(180deg);
        }
    }


}