.form__message--confirmation{
    background-color: var(--color-cyan-light);
    margin-bottom: 0;
    .form__message-icon{
        .icon{
            fill: var(--color-cyan-dark);
        }
    }

    &.form__message--notification-white{
        background-color: white;
    }

    &.form__message--border{
        border: 1px solid var(--color-cyan);
    }
}
