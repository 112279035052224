.coho__dark {
    .icon {
        fill: var(--color-grey-xlight);

        &.icon--grey {
            fill: var(--color-grey-light);
        }

        &.icon--orange {
            fill: var(--color-orange-dark);
        }

        &.icon--green {
            fill: var(--color-green-dark);
        }
    }

    .icon-settings {
        &.icon--grey {
            fill: var(--color-grey-border);
        }
    }


    .feedback__holder {
        .feedback--icons {
            .feedback--icons-holder {
                background-color: #666666;
                .icon {
                    fill: #cccccc;
                }
            }
        }
        &.reduced {
            .feedback--icons {
                .feedback--icons-holder {
                    background-color: var(--color-black);
                    .icon {
                        fill: var(--color-black);
                    }
                }
            }
        }
    }


    


}
