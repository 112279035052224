.message__contextual-options{
    //border-bottom: 1px solid var(--color-grey-light);
    align-items: flex-start;
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    //height: rems(40);
    //margin-bottom: rems(0);
    overflow: auto;
    //padding: rems(8) rems(0) rems(0) rems(5);
    width: 100%;

    //li{
    //    display: flex;
    //    flex: 0 1 auto;
    //    margin-right: rems(5);
    //    width: auto;
    //}

    button{
        background-color: var(--color-teal-xlight);
        border: 1px solid var(--color-teal);
        border-radius: 100px;
        color: var(--color-teal);
        cursor: pointer;
        display: inline-block;
        font-family: $type__family;
        font-size: rems(12);
        font-weight: 400;
        height: rems(24);
        line-height: rems(16);
        margin-bottom: rems(5);
        margin-top: rems(5);
        margin-right: rems(8);
        min-width: rems(120);
        padding: rems(0) rems(6); 
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-out;
        white-space: nowrap;
        word-wrap: none;

        &:last-child {
            margin-right: 0;
        }
        
        &:hover{
            background-color: var(--color-teal);
            color: white;
        }
        
        &:focus {
            outline: 0;
        }
        
        &.low{
            background-color: var(--color-green-dark);
            border-color: var(--color-green-dark);
            color: white;
        }

        &.medium{
            background-color: var(--color-orange-dark);
            border-color: var(--color-orange-dark);
            color: white;
        }

        &.high{
            background-color: var(--color-magenta);
            border-color: var(--color-magenta);
            color: white;

        }        

    }

}

.message__contextual-action{
    background-color: var(--color-teal-xlight);
    border: 1px solid var(--color-teal);
    border-radius: radius(medium);
    bottom: rems(5);
    @include shadow__high;
    display: none;
    font-size: rems(14);
    line-height: rems(20);
    left: rems(5);
    right: rems(5);
    max-height: 36rem;
    overflow: auto;
    position: relative;
    padding: rems(20) rems(20) rems(10) rems(20);
    z-index: 200;

    &.open{
        display: block;
        position: absolute;
    }

    .file_documents_container{
        margin-bottom: 0;
    }

    .button__group{
        margin-bottom: 0;
    }

}

@include breakpoint(for-tablet-portrait-up) {
    .message__contextual-action{
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
        min-width: rems(768);
    }
    
}