my-viewings-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  
  .unclaimed_account_banner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding: 8px;
      gap: 8px;
      background-color: var(--color-magenta-light);
    
      loader-button {
        flex-shrink: 0;
      }
  }
  
  conversations {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
}

viewing-view {
  width: 100%;
  
  viewing-view-applicant, viewing-view-housemate, viewing-view-manager {
    width: 100%
  }
  
  .width_100 {
    width: 100%
  }
  
  worklow-applicant-viewing, workflow-manager-viewing {
    width: 100%
  }
}