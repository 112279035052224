.calendar {
    .fc {
        .fc-popover {
            z-index: 3;
        }

        .fc-event {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin-bottom: 0;
            margin-top: rems(5);
            padding: 0;

            .caption {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                flex-wrap: wrap;
                font-size: rems(14);
                line-height: rems(20);
                text-align: center;
            }

            li {
                cursor: pointer;
                display: flex;
                flex: 0 0 auto;
                flex-direction: column;
                padding: rems(2);
                position: relative;
                width: rems(28);

                &:hover {
                    .tooltip {
                        display: block;
                    }
                }

            }

            img {
                height: rems(24);
                width: rems(24);
            }


            .icon {
                fill: var(--color-grey);
                height: rems(24);
                width: rems(24);
            }

            &--large {
                margin-bottom: rems(20);

                li {
                    padding: rems(10);
                    width: rems(92);
                }

                img {
                    height: rems(72);
                    width: rems(72);
                }
            }
        }

        .fc-header-toolbar {
            flex-direction: column;
        }

        h2.fc-toolbar-title {
            font-weight: 400;
            font-size: rems(18);
        }

        .fc-button {
            font-size: rems(14);
            padding: rems(4) rems(6);

            &:disabled {
                opacity: 1;
            }
        }

        .fc-button-primary {
            background-color: var(--color-blue);
            border-color: var(--color-blue);

            &:disabled {
                background-color: var(--color-grey-dark);
                border-color: var(--color-grey-dark);
            }

            &:not(:disabled) {

                &.fc-button-active,
                &:hover {
                    background-color: var(--color-blue-xdark);
                    border-color: var(--color-blue-xdark);
                }
            }

        }

        table {
            font-size: rems(14);

            &.fc-list-table {
                font-size: rems(13);
            }
        }

        .fc-list-event,
        .fc-daygrid-event {
            &:hover {
                cursor: pointer;
            }
        }

        .fc-list-event-graphic,
        .fc-daygrid-event-dot {
            visibility: hidden;
            display: none;
        }

        a {
            &.fc-col-header-cell-cushion {
                color: var(--color-grey);
                font-weight: 600;
                font-size: rems(14);
            }
        }

        .fc-daygrid-day,
        .fc-timegrid-col {
            &.fc-day-today {
                background-color: var(--color-cyan-light);
            }
        }

        .fc-non-business {
            background-color: rgba(var(--color-grey-rgb), 0.1);
        }

        .fc-event,
        .fc-list-event-title a {
            &:focus {
                outline: 0;
            }
        }

        .fc-list-table {
            td {
                padding: rems(4) rems(10);
                border: 0 !important;
                &.fc-list-event-time {
                  width: auto;
                }
            }

        }

        .fc-event-title {
            padding: 0 rems(3);
            white-space: normal;
            font-weight: 400;
            font-size: rems(11);
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .fc-event-time {
            white-space: normal;
            font-weight: 400;
            font-size: rems(11);
            line-height: 1.3;
            padding: 0 rems(3) 0 rems(6);
        }

        .calendar__entry--viewing {
            background-color: var(--color-cyan-xdark);
            border: 2px solid var(--color-cyan-xdark);
            color: var(--color-white);

            &-cancelled {
                background-color: var(--color-grey-dark);

                &:hover {
                    background-color: var(--color-grey-border);
                    td {
                        background-color: var(--color-grey-border);
                    }
                }
            }

            &-confirmed {
                background-color: var(--color-green-xdark);

                &:hover {
                    background-color: var(--color-green-dark);
                    td {
                        background-color: var(--color-green-dark);
                    }
                }
            }

            &-requested {
                background-color: var(--color-pink-xdark);

                &:hover {
                    background-color: var(--color-pink-dark);
                    td {
                        background-color: var(--color-pink-dark);
                    }
                }
            }

            &-proposed {
                background-color: var(--color-cyan-dark);

                &:hover {
                    background-color: var(--color-cyan);
                    td {
                        background-color: var(--color-cyan);
                    }
                }
            }
        }

        .calendar__entry--action,
        .fc-list-event.calendar__entry--action {
            background-color: var(--color-brown-xdark);
            border: 2px solid var(--color-brown-xdark);
            color: var(--color-white);

            &.fc-h-event .fc-event-main {
                color: var(--color-white);
            }

            &:hover {
                background-color: var(--color-brown-dark);
                td {
                    background-color: var(--color-brown-dark);
                }
            }

            &-snoozed {
                background-color: var(--color-brown-light);
                color: var(--color-black);

                &.fc-h-event .fc-event-main {
                    color: var(--color-black);
                }

                &:hover {
                    background-color: var(--color-brown-light);
                    td {
                        background-color: var(--color-brown-light);
                    }
                }
            }

        }

        .calendar__entry--maintenance,
        .fc-list-event.calendar__entry--maintenance {
            background-color: var(--color-blue-dark);
            border: 2px solid var(--color-blue-dark);
            color: var(--color-white);

            &:hover {
                background-color: var(--color-blue);
                td {
                    background-color: var(--color-blue);
                }
            }

            &-completed {
                background-color: var(--color-green-xdark);

                &:hover {
                    background-color: var(--color-green-dark);
                    td {
                        background-color: var(--color-green-dark);
                    }
                }
            }

        }

        .calendar__entry--tenancy {
            background-color: var(--color-teal-xdark);
            border: 2px solid var(--color-teal-xdark);
            color: var(--color-white);

            &-move-in {
                background-color: var(--color-pink-xdark);

                &:hover {
                    background-color: var(--color-pink-dark);
                    td {
                        background-color: var(--color-pink-dark);
                    }
                }
            }

            &-move-out {
                background-color: var(--color-grey-dark);

                &:hover {
                    background-color: var(--color-grey-border);
                    td {
                        background-color: var(--color-grey-border);
                    }
                }
            }

            &-fixed-term-ending {
                background-color: var(--color-teal-dark);

                &:hover {
                    background-color: var(--color-teal);
                    td {
                        background-color: var(--color-teal);
                    }
                }
            }

        }

    }

}

@include breakpoint (for-tablet-portrait-up) {
    .calendar {
        .fc {
            .fc-header-toolbar {
                flex-direction: row;
            }
        }    
    }
}