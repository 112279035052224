@import "labels";
@import "inputs";
@import "input-groups";
@import "select";
@import "checkboxes";
@import "radio-buttons";
@import "radio";
@import "checkbox-buttons";
@import "form-grid";
@import "button-content";
@import "tags";
@import "fieldset";
@import "header";
@import "sections";
@import "range";
@import "switch";
@import "messages";
@import "form-group";
@import "numbers";
@import "file-upload";
@import "multi-switch";
@import "multi-select";
@import "help";
@import "button-options";
@import "datepicker";
@import "options-group";
@import "edit-group";
@import "records";
@import "payment";
@import "signature-pad";
@import "timepicker";
@import "options";
@import "csv-preview";
@import "autocomplete";
@import "form-options";
@import "progress";
@import "steps";
@import "date-selector";

.form {
    max-width: rems(660);
    padding-left: rems(20);
    padding-right: rems(20);
    width: 100%;

    &.form--centered {
        margin-left: auto;
        margin-right: auto;
    }

    &.form--inline, &.form--subform {
        background-color: var(--color-grey-xxlight);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        margin-bottom: rems(20);
        padding: rems(20) rems(20) rems(10) rems(20);

        &.u-margin-bottom--10 {
            margin-bottom: rems(10);
        }
        &.u-margin-bottom--none {
            margin-bottom: 0;
        }
        &.form--subform-subtle  {
            background-color: transparent;
            border: 0;
            margin-bottom: 0;
            padding: 0;
        }
        &.form--subform--active,&.form--subform-active {
            position: relative;
            z-index:999;
            border: 1px solid var(--color-grey-border);
            margin-left: rems(-15);
            padding: rems(10) rems(15);
            margin-right: rems(-15);
            box-shadow: 0 rems(6) rems(48) 0 rgba(0,0,0,0.20),
            0 rems(3) rems(6) 0 rgba(0,0,0,0.15);
            &.form--subform-workflow {
                margin-left: 0;
                .form--subflow-workflow {
                    margin-left: rems(15);
                }
                error-response-summary {
                    display: block;
                }
            }
        }
        &.form--subform--active,&.form--subform-active {
            &.form--subform-inside-modal {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 95%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        &.form--bg-light {
            background-color: var(--color-white);
        }

    }

    &.form--variable {
        max-width: none;

        .form__header {
            max-width: rems(660);
            margin-left: auto;
            margin-right: auto;
        }

        fieldset {
            max-width: rems(660);
            margin-left: auto;
            margin-right: auto;

            &.fieldset--variable {
                max-width: none;

                .form__section-header, .form__section {
                    max-width: rems(660);
                    margin-left: auto;
                    margin-right: auto;

                    &.full-width {
                        max-width: rems(960);
                    }
                }
            }
        }
    }

    &.form--pricing {
        // max-width: none;  -- Removing Rent Automation

        .form__header {
            max-width: rems(660);
        }

        fieldset {
            max-width: rems(660);

            &.fieldset--variable {
                max-width: none;

                .form__section-header, .form__section {
                    max-width: rems(660);

                    &.full-width {
                        max-width: rems(960);
                    }
                }
            }
        }
    }

    &.form--full-width {
        max-width: none;
        &.form--pricing {
            fieldset {
                &.fieldset--variable {


                    .form__section-header  {
                        max-width: rems(960);
                    }
                    .form__section {
                        &.full-width {
                            max-width: rems(960);
                        }
                    }
                }
            }
        }

        h3,p {
            max-width: rems(720);
        }


    }

    &.form--notpadded {
        padding-left: 0;
        padding-right: 0;
    }

    &.form--nomargin {
        margin-bottom: 0;
    }

    &.form--noborder {
        border: 0;
    }



    &.form--slim {
        padding-left: rems(10);
        padding-right: rems(10);
    }

    &.form--slimmer {
        padding: rems(10);
        .button {
            margin-bottom: 0;
        }
    }

    &.form--inset {
        background-color: var(--color-grey-xxlight);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        margin-bottom: rems(8);
        padding: rems(8) rems(8) rems(8) rems(8);

        &.form--subform--active {
            position: relative;
            z-index:999;
            border: 1px solid var(--color-grey-border);
            //margin-left: rems(-15);
            padding: rems(10) rems(15);
            margin-right: rems(-15);
            box-shadow: 0 rems(6) rems(48) 0 rgba(0,0,0,0.20),
            0 rems(3) rems(6) 0 rgba(0,0,0,0.15);
        }
    }

    &.form--border {
        border-top: 1px solid var(--color-grey-light);
        padding-top: rems(20);
    }


    p {
        &.as__label {
            margin-bottom: rems(8);
            margin-top: rems(20);
        }
    }
}

.form--padded {
    padding-bottom: rems(30);
    fieldset {
        margin-bottom: rems(30);
    }
}

.page__section {
    .form {
        &.form--subform {
            background-color: var(--color-white);
            &.form--subform-subtle {
                background-color: transparent;
            }
            &.form--subform-workflow {
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}

.page__section--alt{
    .form{
        &.form--inline{
            background-color: var(--color-white);
        }
        &.form--subform{
            background-color: var(--color-grey-xxlight);
        }
    }
    //since the service panel is white, we need to re-inverse the colors
    .service__offer-panel {
        .form {
            &.form--inline {
                background-color: var(--color-grey-xxlight);
            }
            &.form--subform {
                background-color: var(--color-white);
            }
        }
    }
}

.form__question-list {
    list-style: none;
    margin: 0 0 rems(20) 0;
    padding: 0;

    li {
        font-size: 14px;

        .button.button--small.button--cancel {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            min-width: rems(24);
            min-height: rems(24);
            .icon {
                top: rems(-4);
                left: 0;
            }
            &:focus {
                border: 0;
                box-shadow: none;
            }
        }
    }
}

.form__grid.hidden#moreOptions {
    display: none;
}
