body, html {
    height: -webkit-fill-available;
}

.conversation__page {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include chrome-29-up {
        height: 100vh;
    }

    @include safari-11-up {
        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
            height: -webkit-fill-available;
        }
    }

    .site__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        ng-component {
            display: contents;

            conversations {
                display: contents;
            }
        }
    }

    .site__header {
        .container {
            width: 100%;
            padding: 0;
            display: flex;
            .main__menu-icon {
                display: none;
            }
            .header__logo {
                margin-left: rems(16);
            }
            #backButton1,#backButton2 {
                max-height: rems(36); 
                margin-top: rems(8); 
                margin-left: rems(20);
            }
        }

        &.site__header--fixed {
            position:unset;
        }
    }

    .site__header--logged-in {
        .main__navigation {
            top: rems(52);
        }
        .dropdown__messages {
            .header__profile-image {
                margin-top: rems(16);
            }
        }
    }

    .container {
        width: 100%;
    }

    .nav__secondary {
        .container {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            //padding-right: rems(10);

            .messages__close {
                background-color: transparent;
                border: none;
                color: var(--color-magenta);
                cursor: pointer;
                position: absolute;
                left: rems(6);
                top: 0;
                z-index: 2;
                padding: rems(8);
                padding-top: rems(0);
                padding-left: rems(0);
                margin: 0;

                .icon {
                    fill: var(--color-magenta);
                }

                &:hover {
                    color: var(--color-text);

                    .icon {
                        fill: var(--color-text);
                    }
                }

                &:focus {
                    outline: 0;
                }
            }
        }

        .button {
            margin-bottom: 0;
            position: relative;

            &.button--small {
                min-width: rems(64);
            }
        }

        .nav__secondary-title {
            //width: 100%;
            flex: 1;

            h2 + * {
                margin-top: 0;
            }

            h2 {
                padding-left: rems(20);
            }
        }

        .nav__secondary-filter {
            padding-top: rems(15);

            .filter__options {
                li {
                    width: 100%;
                    .filter__options-text {
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }

            .property-filter {
                box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
                width: 100%;
            }

            .ng-select {
                &.ng-select-single, &.ng-select-multiple {
                    .ng-select-container {
                        height: rems(34);
                        min-height: rems(34);
                        .ng-value-container {
                            .ng-input {
                                top: unset;
                            }
                            .ng-placeholder {
                                top: rems(4);
                            }
                        }
                    }
                }
            }

            .form__select {
                box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
                height: rems(34);
                margin-bottom: 0;
                padding: rems(0) rems(36) rems(0) rems(8);
                font-size: rems(14);
                font-weight: 600;
            }

            .filter__options li .filter__options-icon {
                top: rems(5);
            }
        }

        .nav__secondary-actions {
            flex-shrink: 0;
            margin-top: 0;

            .button {
                margin: rems(10) 0;
            }
        }
    }

    .conversation__grid-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;

        .conversation__grid {
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: row;
            border-radius: rems(5);
            overflow: auto;

            .conversation__grid-list {
                display: flex;
                flex-direction: column;
                flex: 1;
                min-width: 300px;
            }

            .zero__state:not(.notes__container, .notes_zero) {
                display: none;
            }

            .conversation__grid-right {
                display: none;

                .conversation__grid-header {
                    display: none;
                    padding: rems(12) 0;
                    border-bottom: 1px solid #ccc;
                }

                .conversation__grid-messages {
                    display: none;

                    .conversation__grid-messages-footer {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        background-color: var(--color-grey-xlight);

                        .conversation__grid-messages-footer-warning {
                            padding: rems(4) rems(8) 0 rems(4);
                            p {
                                margin-bottom: 0;
                            }
                        }

                        .conversation__grid-messages-footer-actions {
                            padding: rems(4) rems(8);

                            .message__contextual-options {
                                border-bottom: 0;
                            }
                        }

                        .conversation__grid-messages-footer-options {
                            padding-right: rems(6);
                            display: flex;
                            flex-direction: row;

                            .messages__option-button {
                                min-width: 0;
                                margin: 0;
                                background-color: var(--color-cyan-dark);
                                border-color: var(--color-cyan-xdark);
                                max-height: rems(34);
                                width: rems(30);
                                padding: rems(4);
                                padding-bottom: 0;

                                .icon, .icon svg {
                                    width: rems(16);
                                    height: rems(16);
                                    fill: white;
                                }

                                &:last-child {
                                    margin-left: rems(6);
                                }

                                &:focus {
                                    border-color: var(--color-cyan-xdark);
                                    box-shadow: none;
                                }
                            }
                        }

                        .conversation__grid-messages-footer-send {
                            padding: 0 rems(8);
                            display: flex;
                            flex-direction: row;

                            textarea {
                                flex: 1;
                                margin-bottom: 0;
                                box-shadow: none;
                                padding: rems(4) rems(8);
                                font-size: rems(14);
                                transition: max-height 0.1s ease-out;
                            }

                            button.button.messages__reply-button {
                                min-width: 0;
                                margin: 0;
                                margin-left: rems(8);
                                max-height: rems(34);
                                height: rems(34);
                                padding: rems(2);
                                padding-bottom: 0;
                                width: rems(34);
                                position: relative;

                                .icon, .icon svg {
                                    fill: white;
                                    width: rems(24);
                                    height: rems(24);
                                }

                                &:focus {
                                    border-color: var(--color-cyan-xdark);
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.no__thread-list {
            .conversation__grid {
                .conversation__grid-list {
                    overflow-y: hidden;

                    ul.empty {
                        display: none;
                    }

                    .zero__state {
                        display: block;
                        padding: rems(16) rems(8);
                        color: var(--color-grey);
                        .form__message {
                            margin-bottom: 0;
                            width: 100%;
                            max-width: rems(400);
                        }
                    }
                }
            }
        }

        &.no__thread {
            .conversation__grid {
                .conversation__grid-list {
                    background: white;
                }
            }
        }
    }

    &.is-phone {
        &.is-app {
            .conversation__grid-wrapper {
                padding-bottom: 0;
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .conversation__page {

        .conversation__grid-wrapper {
            flex: 1;

            .conversation__grid {

                .conversation__grid-list {
                    flex: 0.3;
                    display: flex;
                    flex-direction: column;
                    border-right: 1px solid var(--color-grey-light);
                }

                .conversation__grid-right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .conversation__grid-header {
                        border-bottom: 1px solid #ccc;
                        display: block;
                    }

                    .conversation__grid-messages {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        overflow: hidden;

                        .conversation__grid-messages-content {
                            padding: rems(8);
                            flex: 1;
                            width: 100%;
                            overflow-y: auto;
                            display: flex;
                        }

                        .conversation__grid-messages-footer {
                            width: 100%;

                            .conversation__grid-messages-footer-actions {
                            }


                            .conversation__grid-messages-footer-options {
                                flex-direction: column;

                                .messages__option-button {

                                    &:last-child {
                                        margin-left: 0;
                                        margin-top: rems(4);
                                    }
                                }
                            }

                            .conversation__grid-messages-footer-send {
                                padding-bottom: rems(8);

                                textarea {
                                    font-size: rems(15);
                                    min-height: rems(56);
                                }

                                button.button.messages__reply-button {
                                    max-height: rems(56);
                                    height: rems(56);
                                    width: rems(44);

                                    .icon, .icon svg {
                                        fill: white;
                                        width: rems(30);
                                        height: rems(30);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.no__thread-list {
                .conversation__grid {
                    .conversation__grid-list {
                        border-right: 1px solid var(--color-grey-light);
                    }
                }
            }

            &.no__thread {
                .conversation__grid {
                    .conversation__grid-list {
                        flex: 0.45;
                    }

                    .zero__state {
                        display: flex;
                        flex: 0.55;
                        padding: rems(16);
                        color: var(--color-grey);
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .conversation__page {
        .nav__secondary {
            .container {
                flex-direction: row;
                max-width: none;

                .messages__close {
                }

                .button {
                    margin: 0;
                    position: relative;

                    &.button--small {
                        min-width: rems(64);

                        &.button--icon.button--compact {
                            width: rems(36);
                            min-width: 0;
                            text-indent: -5000px;
                            padding-left: 0;

                            .icon {
                                text-indent: 0;
                            }
                        }
                    }
                }
            }

            .nav__secondary-filter {
                padding-top: 0;
                padding-right: rems(10);
                min-width: fit-content;

                ng-select {
                    margin-right: rems(8);
                }

                .form__select {
                    width: auto;
                    margin-right: rems(8);
                }

                .property-filter {
                    width: rems(400);
                }

                .filter__options li .filter__options-text {
                    margin-right: 0;
                }
            }

            .nav__secondary-actions {
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .conversation__page {
        .site__header--logged-in {
            .main__navigation {
                top: 0;
            }
        }

        .nav__secondary {
            .container {
                .messages__close {
                    padding-top: rems(2);
                }

                .button {
                    margin-bottom: 0;
                    position: relative;

                    &.button--small {
                        min-width: rems(80);

                        &.button--icon.button--compact {
                            width: auto;
                            min-width: rems(80);
                            text-indent: 0;
                            padding-left: rems(32);

                            .icon {
                                text-indent: 0;
                            }
                        }
                    }
                }
            }

            .nav__secondary-filter {
                padding-right: rems(30);
                .property-filter {
                    width: rems(500);
                }
            }

            .nav__secondary-actions {
            }
        }

        .container {
            max-width: none;
        }

        .conversation__grid-wrapper {
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .conversation__page {
        .conversation__grid-wrapper {
            margin: rems(10);
            border: 1px solid var(--color-grey-light);
            border-radius: radius(medium);

            .conversation__grid {
                .conversation__grid-list {
                    flex: 0.3;
                }

                .conversation__grid-right {
                    flex-direction: row-reverse;

                    .conversation__grid-header {
                        height: 100%;
                        flex: 0.7;
                        border-bottom: 0;
                        border-left: 1px solid #ccc;
                    }

                    .conversation__grid-messages {
                        flex: 1;
                    }
                }
            }


            // WIDTH WHEN NO CONVERSATION SELECTED
          &.no__thread {
            .conversation__grid {
              .conversation__grid-list {
                flex: 0.3;
              }

              .zero__state {
                flex: 0.7;
              }
            }
          }
        }
        .nav__secondary {
            .nav__secondary-filter {
                .property-filter {
                    width: rems(600);
                }
            }


        }
    }
}

@include breakpoint(for-large-desktop-up) {
    .conversation__page {
        .conversation__grid-wrapper {
            margin: rems(12);
            border: 1px solid var(--color-grey-light);
            border-radius: radius(medium);

            .conversation__grid {
                .conversation__grid-list {
                    flex: 0.35;
                }

                .conversation__grid-right {
                    flex-direction: row-reverse;

                    .conversation__grid-header {
                        height: 100%;
                        flex: 0.65;
                        border-bottom: 0;
                        border-left: 1px solid #ccc;
                    }

                    .conversation__grid-messages {
                        flex: 1;
                        @-moz-document url-prefix() {
                            max-height: calc(100vh - 150px); //Fix for firefox
                        }
                    }
                }
            }

            // WIDTH WHEN NO CONVERSATION SELECTED
            &.no__thread {
                .conversation__grid {
                    .conversation__grid-list {
                        flex: 0.35;
                    }

                    .zero__state {
                        flex: 0.65;
                    }
                }
            }
        }
    }
}
