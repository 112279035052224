
.payments__details {
    border-top: rems(1) solid var(--color-grey-light);
    font-size: rems(14);
    list-style: none;
    margin-bottom: rems(20);
    margin-top: rems(20);
    padding: 0;
    width: 100%;


    .payments__details-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: rems(2);
        //padding-top: rems(10);
        width: 100%;
        flex-direction: column;



        .payments__details-description {
        }

        .payments__details-property {
        }

        .payments__details-amount {
        }
    }

    li {
        //border-bottom: rems(1) solid var(--color-grey-light);

        &.payment__divider {
            margin-top: rems(4);
            border-top: rems(1) solid var(--color-grey-light);
            padding-top: rems(4);
        }
        &:first-of-type {
            padding-top: rems(10);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.payment__card-holder {
    display: flex;
    flex-direction: column;
    background: var(--color-grey-xxlight);
    border-radius: radius(medium);
    border: 1px solid var(--color-grey-light);
    padding: rems(4) rems(8);
    margin-bottom: rems(30);

    > div {
        padding: rems(4) 0;
    }

    .payment__card-holder--image {
        height: rems(38);
    }

    &.payment__card-holder--warning {
        background-color: var(--color-pink-light);

        .payment__card-holder--expiry {
            color: var(--color-pink-xdark)
        }
    }

    & + form-message .form__message {
        //margin-top: rems(-20);
        //margin-bottom: rems(30);
    }
}

#organisation-accounting-organisation-panel form-message {
    &:last-of-type {
        & + p {
            margin-top: rems(30);
        }
    }
}

.page__section--alt {
    .payment__card-holder {
        background: var(--color-white);
    }
}

.invoice__holder {
    border-radius: radius(medium);
    border: 1px solid var(--color-grey-light);
    padding: rems(12) rems(12) rems(4) rems(12);
    box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
    margin-bottom: rems(20);

    .invoice__holder--header {
        display: flex;
        flex-direction: row;
        .button__group {
            margin-left: rems(20);
            flex-basis: content;
        }
    }

    .payments__details {
        margin-bottom: 0;
        margin-top: 0;
        li:last-child {
            border-bottom: 0;
        }
    }
}
.page__section, .page__section--alt {
    .accordion.accordion--flat.payments {
        .file_documents_container {
            margin-bottom: 0.5rem;

            .file_document_group {
                background: none;
                border: 0;
                padding: 0;
                padding-top: rems(8);
                border-radius: 0;
                border-top: 1px solid var(--color-grey-light);
            }

            &:first-child {
                .file_document_group {
                    border-top: 0;
                }
            }
        }

        border-bottom: 0;
    }
}

.form--payment {
    position: relative;

    .payment__auth {
        background: rgba(var(--color-green-light-rgb),0.9);
        border: 1px solid var(--color-green);
        border-radius: radius(medium);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 50% rems(30) 0 rems(30);
        &.payment__auth-step-4 {
            padding-top: 40%;
        }

    }
}

@include breakpoint(for-large-phone-landscape-up) {

    .payment__card-holder {
        flex-direction: row;
        align-items: center;
        max-width: rems(600);
        .payment__card-holder--description {
            margin-left: rems(8);
        }
        .payment__card-holder--expiry {
            margin-left: rems(40);
        }
        .payment__card-holder--actions {
            margin-left: auto;
        }
    }
}
@include breakpoint(for-tablet-portrait-up) {
    .payments__details {
        .payments__details-item {
            flex-direction: row;

            .payments__details-description {
                flex: 0.5;
                /*padding-right: rems(32);*/
                text-align: right;
            }

            .payments__details-property {
                flex: 1;
            }

            .payments__details-amount {
                min-width: 5rem;
                text-align: right;
            }

            .payments__details-invoice {
                min-width: 5rem;
                text-align: right;
            }
        }
    }
}
