.features__header{
    display: flex;
    flex: 1 0 auto;
    height: rems(80);
}

.features__header-content{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    max-width: none;
    padding-bottom: rems(10);
    padding-left: rems(20);
    padding-right: rems(20);
    padding-top: rems(10);
    position: relative;
    width: 100%;
    justify-content: space-between;
}

.features__header-content-left {
    display: flex;
    flex-direction: column;
    padding-top: rems(20);
    .features__header-logo{
        display: block;
        width: rems(140);
        img{
            display: block;
            height: auto;
            width: 100%;
        }
        
        &:focus { outline: none; }
    }
    p {
        margin-top: rems(-14);
        padding-left: rems(56);
        font-size: rems(18);
        color: var(--color-blue); 
    }
}

.features__header-actions{
    display: flex;
    .button {
        margin-bottom: 0;
    }
}
@include breakpoint(for-large-phone-up) {
    .features__header-content-left {
        flex-direction: row;
        align-items: center;  
        padding-top: 0;
        p {
            margin-top: 0;
            margin-bottom: rems(3);
            padding-left: rems(12);
            font-size: rems(28);
        }
    }  
}
@include breakpoint(for-tablet-landscape-up){
    .features__header-content{
        
        margin-left: auto;
        margin-right: auto;
        max-width: rems(1120);
        width: rems(1120);
    }    
}


