.button--dismiss {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    text-transform: uppercase;
    color: var(--color-white);
    font-size: rems(12);
    font-weight: 400;
    padding: rems(4);
    line-height: 1;
    strong {
        padding-left: rems(4);
        font-weight: 800;
    }
    &:hover {
        background-color: transparent;
        cursor: pointer;
        outline: 1px dotted var(--color-white);
    }

    &.button--icon{
        padding-right: rems(48);
        position: relative;
        text-align: left;
        .icon{
            display: block;
            fill: #ffffff;
            height: rems(30);
            position: absolute;
            left: unset;
            right: rems(9);
            top: rems(8);
            width: rems(30); 
            svg{
                height: rems(30);
                width: rems(30); 
            }
        }
        &.button--tiniest {
            padding-right: rems(22);
            height: rems(22);
            .icon{
                height: rems(16);
                right: rems(2);
                top: rems(3);
                width: rems(16); 
        
                svg{
                    height: rems(16);
                    width: rems(16); 
                }
        
            }            
        }
    }
}