.statement-box.statement-report {
    max-width: 800px;
    min-width: 600px;

    table {
        tr {
            td {
                padding: rems(2) rems(5);
                font-size: rems(14);

                &:nth-child(1) {
                    width: 12.5%;
                }

                &:nth-child(2) {
                    width: auto;
                }

                &:nth-child(3), &:nth-child(5) {
                    text-align: right;
                    width: 12.5%;
                }

                &:nth-child(4) {
                    text-align: right;
                    width: 10%;
                }
            }
            &.top {
                td {
                    &.heading-title {
                        img {
                            width: rems(120);
                            margin-bottom: rems(8);
                        }

                        span {
                            font-size: rems(18);
                        }
                    }
                }
            }

            &.heading {
                td {
                    color: var(--color-grey-border);

                    &:nth-child(1) {
                        padding-left: rems(30);
                    }
                }
            }
            &.category-heading {
                td {
                    font-weight: 700;
                    color: var(--color-grey);
                    border-top: 1px solid var(--color-grey);
                    border-bottom: 1px solid var(--color-grey);

                    &:nth-child(1) {
                        padding-left: rems(15);
                    }

                    &:nth-child(2) {
                        text-align: right;
                        width: 12.5%;
                    }

                    &:nth-child(3) {
                        width: 10%;
                    }
                }

                &.expense {
                    td {
                        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                            color: crimson;
                            font-weight: 700;
                        }
                    }
                }
            }

            &.property-heading {
                td {
                    //border-bottom: 1px solid var(--color-black);

                    &:nth-child(2) {
                        text-align: right;
                        width: 12.5%;
                    }

                    &:nth-child(3) {
                        width: 10%;
                    }
                }
            }

            &.item {
                td {
                    border-top: 1px solid var(--color-grey-light);
                    border-bottom: 0;

                    &:nth-child(1) {
                        padding-left: rems(25);
                    }
                }

/*                &:first-of-type {
                    td {
                        border-top: 0px;
                    }
                }*/

                &.expense {
                    td {
                        &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                            color: crimson;
                        }
                    }
                }
            }

            &.properties-total {
                td {
                    border-bottom: 1px solid var(--color-black);
                    font-weight: 700;
                    padding: rems(5);

                    &:nth-child(2) {
                        text-align: right;
                        width: 12.5%;
                    }

                    &:nth-child(3) {
                        width: 10%;
                    }
                }
            }
        }
    }
}


/*@include breakpoint(for-tablet-landscape-up) {
    .statement-box.statement-report {
        table {
            tr {
                td {
                    font-size: rems(16);
                }
                &.top {
                    td {
                        &.heading-title {
                            span {
                                font-size: rems(18);
                            }
                        }
                    }
                }
            }
        }
    }
}*/



@media (min-width: 800px) {
    .statement__wrapper {
        background: var(--color-grey-xlight);
        height: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .statement-report {
            background: white;
            min-height: 96vh;
            padding: rems(30) rems(30);
            -webkit-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            -moz-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
        }
    }
}

@media print {
    .statement__wrapper {
        background: none;
        padding-top: 0;

        .statement-report {
            background: none;
            min-height: 0;
            padding: 0 rems(10) 0 rems(10);
            box-shadow: none;
        }
    }
}
