.news__page {
    .news__meta {
        span {
            color: var(--color-grey-light);
        }
    }

    .page__header {
        
        &.page__header-blend {
            background-color: var(--color-green);
            background-blend-mode: multiply;
        }

        &::after {
            //background: linear-gradient(rgba(36,88,34,0),rgba(36,88,34,0.8));
            background: linear-gradient(rgba(28,51,24,0),rgba(28,51,24,0.8));
        }
    }

    .page__content {
        .news__article-summary {
            border-top: 1px solid var(--color-grey-light);
            padding-top: rems(32);
            margin-top: rems(32);

            .news__article-meta {
                color: var(--color-grey);
                margin: 0;
                padding: rems(8) 0 rems(16) 0;
                font-size: 0.875rem;
            }

            h2 {
                a {
                    color: var(--color-text);
                    text-decoration: none;

                    &:hover {
                        color: var(--color-blue-dark);
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .news__article-content {
                display: flex;
                flex-direction: row;

                .news__article-image {
                    padding-right: rems(24);

                    img {
                        width: 100%;
                    }
                }

                .news__article-text {
                }
            }
        }

        & > div.news__article-summary:first-child {
            border-top: 0;
            margin-top: 0;
            //padding-top: 0;
            p {
                font-size: 1.125rem;
                line-height: 1.6rem;
            }

            h2 {
                font-size: 2rem;
                line-height: 2.4rem;

                & + p {
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                }
            }
        }

        & > div.news__article-summary:last-child {
        }

        article {
            @include breakpoint(for-tablet-landscape-up) {
                padding-right: 3rem;
            }

            p {
                &:first-child {
                    font-size: 1.25rem;
                    line-height: 1.7rem;
                }
            }

            .news__image-holder {
                margin-bottom: rems(18);
                padding-right: rems(48);

                img {
                    width: 100%;
                }
            }

            .guest__credit {
                padding-right: rems(48);
                color: var(--color-grey);
                border-top: 1px solid var(--color-grey);
            }

            .guest__profile {
                display: flex;
                flex: 1 0 auto;
                margin-bottom: rems(10);
                background-color: #ffffff;
                box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
                border-radius: 0.25rem;
                color: #262626;
                padding: rems(16);
                padding-bottom: rems(8);

                p {
                    color: var(--color-grey);
                    font-size: rems(14);
                    line-height: rems(20);
                    margin-bottom: rems(10);

                    + p {
                        margin-top: rems(10);
                    }

                    a:focus {
                        outline: 0;
                    }
                }

                h4 {
                    + p {
                        margin-top: rems(5);
                    }
                }

                &.guest__profile--small {
                    img {
                        height: auto;
                        width: rems(50);
                    }

                    h5 {
                        font-weight: normal;
                    }
                }
            }

            .guest__profile-image {
                display: block;
                min-width: rems(96);
                width: rems(96);


                img {
                    height: auto;
                    width: 100%;
                    border-radius: rems(4);
                }
            }

            .guest__profile-text {
                padding-left: rems(32);
                padding-top: 0;
            }
        }

        .article__footer {
            padding-top: rems(32);
        }
    }
}
