.supplier__heading {
    display: flex;
    .supplier__heading--text {
        flex-basis: 100%;
    }
    &.supplier__heading--has-button {
        .supplier__heading--button {
            margin-top: rems(-8);
            min-width: fit-content;
            .button {
                margin-bottom: 0;
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .supplier__heading {
        .supplier__heading--text {
            min-height: rems(25);
        }
    }
}
