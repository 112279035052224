.coho__dark, .coho__dark .page__section--alt {

    .card__filters {
        &--action {
            background-color: var(--color-magenta-xdark);

            &:hover {
                background-color: var(--color-magenta);
            }
        }

        &--active {
            background-color: var(--color-green-xxdark);

            &:hover {
                background-color: var(--color-green-xdark);
            }
        }

        &--active-2 {
            background-color: var(--color-green-xdark);

            &:hover {
                background-color: var(--color-green-dark);
            }
        }

        &--active-3 {
            background-color: var(--color-green-dark);

            &:hover {
                background-color: var(--color-green);
            }
        }

        &--progress {
            background-color: var(--color-orange-xdark);

            &:hover {
                background-color: var(--color-orange-dark);
            }
        }

        &--inactive {
            background-color: var(--color-grey);

            &:hover {
                background-color: var(--color-grey-border);
            }

            .card__count {
                color: var(--color-grey-xxlight);

                &:hover {
                    color: var(--color-grey-xxlight);
                }
            }

            .card__title {
                color: var(--color-grey-xxlight);

                &:hover {
                    color: var(--color-grey-xxlight);
                }
            }
        }

        &--filter {
            .card__count {
                color: var(--color-grey-xxlight);

                &:hover {
                    color: var(--color-grey-xxlight);
                }
            }

            .card__title {
                color: var(--color-grey-xxlight);

                &:hover {
                    color: var(--color-grey-xxlight);
                }
            }
        }

        &--filter-2 {
            background-color: var(--color-grey-border);

            &:hover {
                background-color: var(--color-grey-border);
            }

            .card__count {
                color: var(--color-grey-xxlight);

                &:hover {
                    color: var(--color-grey-xxlight);
                }
            }

            .card__title {
                color: var(--color-grey-xxlight);

                &:hover {
                    color: var(--color-grey-xxlight);
                }
            }
        }
    }
}

.coho__dark .page__section--alt {
    .card__filters {
        &--inactive {
            background-color: var(--color-black);

            &:hover {
                background-color: var(--color-black);
            }
        }
    }
}
