.coho__dark {
    .match__holder--container {
        .match__holder {
            &.match__holder--match {
                .compact__table {
                    &.compact__table--narrow {
                        tr {
                            td {
                                &.value {
                                    &.partial {
                                        color:var(--color-green-dark);
                                        text-decoration: underline dotted rems(1) var(--color-green);
                                    }
                                }
                            }
                        }
                    }
                }
                .section__tabs {
                    .tab-container {
                        .tabs {
                            .tab {
                                &.active {
                                    background-color: var(--color-grey-dark);
                                    color: var(--color-grey-xlight);
                                }
                                &.highlight {
                                    border-color: var(--color-green-light);
                                    background-color: var(--color-green-xxdark);
    
                                }
                                &.lowlight {
                                    border-color: var(--color-pumpkin-light);
                                    background-color: var(--color-pumpkin-xdark);
                                }
                            }
                        }
                    }
                    .tab-panes {
                        .tab-pane {
                            &.highlight {
                                border: 1px solid var(--color-green-light);
                                background-color: var(--color-green-xxdark);
                            }
                            &.lowlight {
                                border: 1px solid var(--color-pumpkin-light);
                                background-color: var(--color-pumpkin-xdark);
                                .compact__table {
                                    &.compact__table--narrow {
                                        tr {
                                            td {
                                                &.value {
                                                    &.partial {
                                                        color:var(--color-pumpkin);
                                                        text-decoration: underline dotted rems(1) var(--color-pumpkin);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            p {
                                &.helper {
                                    color: var(--color-grey-light);
                                }
                            }
                        }
                    }
    
                }
            }
        }
    }
    
    .compact__table {
        &.compact__table--narrow {
            &.compact__table--transactions {
                &.compact__table--transactions-border {
                tr {
                    th {
                        color: var(--color-grey-xlight);
                        }
                        &.deleted {
                            background-color: var(--color-grey-xdark);
                        }
                        &.matched {
                            background-color: var(--color-green-xdark);
                        }
                    }
                }
            }
        }
    }
}
