.marketing__service{
    display: flex;
    flex: 1 0 auto;
    margin-bottom: rems(10);

    p{
        color: var(--color-grey);
        font-size: rems(14);
        line-height: rems(20);
        margin-bottom: rems(10);
        
        + p{
            margin-top: rems(10);
        }

    }

    h4{
        + p{
            margin-top: rems(5);
        }
    }

    &.marketing__service--small{
        img{
            height: auto;
            width: rems(50); 
        }

        h5{
            font-weight: normal;
        }
    }
}

.marketing__service-image{
    display: flex;
    min-width: rems(80);
    width: rems(80);
    
    img{
        height: auto;
        width: 100%;
    }
}

.marketing__service-text{
    padding-left: rems(10);
    padding-top: rems(16);

}

.marketing__service-badge{
    background-color: white;
    border: 1px solid var(--color-grey-border);
    border-radius: 100px;
    color: var(--color-grey);
    display: inline-block;
    font-size: rems(16);
    padding: rems(5) rems(15);

    &.marketing__service-badge--automatic{
        background-color: var(--color-green-dark);
        border-color: var(--color-green-dark);
        color: white;
    }


    &.marketing__service-badge--manual{
        background-color: var(--color-pink-dark);
        border-color: var(--color-pink-dark);
        color: white;
    }

    + p{
        margin-top: rems(10);
    }

}

// MODAL

.marketing__service-modal{
    .modal__body{
        align-items: flex-start;
    }
}

.marketing__service-modal-img{
    display: block;
    height: rems(80);
    width: rems(80);
}