.form__tags{
    list-style: none;
    padding: 0;
}

.form__tag{
    background: white;
    border: 2px solid var(--color-primary);
    border-radius: rems(20);
    display: inline-block;
    font-size: rems(16);
    font-weight: 700;
    line-height: rems(20);
    margin-bottom: rems(10);
    margin-right: rems(10);
    min-width: rems(120);
    padding: rems(8) rems(48) rems(8) rems(16);
    position: relative;

    &:hover{
        background: var(--color-primary-light-3);
        color: var(--color-primary-dark);
    }

    .form__tag-close{
        background: none;
        border: none;
        color: var(--color-primary);
        font-size: rems(32);
        height: rems(40);
        line-height: rems(32);
        padding: 0;
        padding-bottom: 8px;
        position: absolute;
        right: 0;
        top: 0;
        width: rems(40);

        &:hover{
            color: var(--color-primary-dark);
            cursor: pointer;
        }

        &:focus{
            outline: none;
        }

    }

}

.form__tag{
    &.form__tag--parent{
        border: none;
        border-bottom: 1px solid var(--color-grey-light);
        border-radius: 0;
        font-size: rems(20);
        font-weight: 500;
        line-height: rems(24);
        margin-bottom: rems(20);
        padding: 0;
        padding-bottom: rems(10);
        width: 100%;

        &:hover{
            background-color: #ffffff;
        }

    }
}