#tenancies-organisation-panel {
    .compact__table.compact__table--narrow {
        thead {
            th {

                .icon {
                    padding-left: 2px;
                }

                .icon, svg {
                    width: rems(10);
                    height: rems(10);
                }

                &.sortable {
                    cursor: pointer;
                }
            }
        }

        tr.summary {
            td.tenant-status {
                display: flex;

                div {
                    line-height: 1.3;
                    padding: 0 rems(2);
                    height: rems(18);
                    width: rems(18);
                    border-radius: rems(10);
                    border: rems(1) solid var(--color-white);
                    box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.2);
                    margin: rems(4) 0 0 0;

                    .icon, svg {
                        width: rems(12);
                        height: rems(12);
                    }


                    &.tenancy__summary--unplugged {
                        background-color: var(--color-grey-light);
                    }

                    &.tenancy__summary--invited {
                        background-color: var(--color-orange-dark);
                    }

                    &.tenancy__summary--joined {
                        background-color: var(--color-blue);
                    }
                }
            }

            td.expired {
                color: var(--color-magenta-dark);
                font-weight: 800;
            }

            td.periodic {
                color: var(--color-grey-light);
            }
        }
    }
}

@include breakpoint (for-tablet-landscape-up) {
    #tenancies-organisation-panel {
        .compact__table.compact__table--narrow {
            tr.summary {
                td.tenant-status {
                    div {
                        margin: 0;
                    }
                }
            }
        }
    }
}
