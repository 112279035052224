.landing__block {
    display: flex;
    flex-direction: column;
    padding-bottom: rems(30);
    width: 100%;

    hr {
        border-bottom: 1px solid var(--color-grey-light);
        display: block;
        height: 1px;
        width: 100%;
    }

    ul {
        padding-left: 1rem;
    }

    &.landing__block--management {
        border-bottom: 1px solid var(--color-grey-xlight);
    }
}

.landing__block-content, .landing__block-action{
    width: 80%;
    max-width: rems(660);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
   
}

.landing__block-action{
    max-width: rems(500);
    .col {
        padding-left: rems(4);
        padding-right: rems(4);
    }
    .button {
        width: 100%;
    }
}

.landing__block-image {
    padding-bottom: rems(20);
    width: 80%;
    max-width: rems(660);
    margin-left: auto;
    margin-right: auto;



    img {
        height: auto;
        max-width: 100%;
        
    }
}

.landing__block--customers {
    display: flex;
    justify-content: space-between;
    padding-top: rems(30);

    a {
        width: 25%;
        max-width: rems(160);

        img {
            width: 100%;
            align-self: flex-start;
        }

        &:focus { outline: none; }
    }
}

.landing__block{
    &.landing__block--header{
        border-bottom: rems(1) dotted var(--color-grey-light);
        padding-bottom: rems(30);
        padding-top: rems(0);
        text-align: center;

        h2{
            margin-bottom: rems(10);

        }
        
        &.landing__block--padding {
            padding-top: rems(60);
        }

        &.landing__block--no-border {
            border-bottom: 0;
        }

    }
    &.landing__block--level2 {
        border-bottom: none;
        //padding-bottom: 0;
        &.landing__block--padding {
            padding-top: rems(0);
        }        
        
    }

}

.landing__page {
    .marketing__block-image {
        padding-top: rems(20);

        img {
            max-width: 80%;
        }
    }

    .marketing__block--image-full {
        .marketing__block-image {
            img {
                max-width: 100%;
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .landing__page {
        .marketing__block-image {
            padding-top: 0;

            img {
                max-width: 100%;
            }
        }

        .landing__block--customers {
            img {
                max-width: rems(160);
            }
            max-width: rems(640);
            margin: 0 auto;
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .landing__page {
        .landing__block-content, .landing__block-image {
            width: 100%;
        }

        .landing__block--customers {
            img {
                max-width: rems(160);
            }

            max-width: rems(640);
            margin: 0 auto;
            padding-top: rems(50);
        }
    }
}
