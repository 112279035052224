.button{
    &.button--focus{
        background-color: var(--color-orange-light);
        border-color: var(--color-orange);
        .icon{
            fill: var(--color-orange-xxdark);
        }

        &:hover{
            //background-color: var(--color-orange);
            border-color: var(--color-orange-xxdark); 
        }
        
        &:focus{
            border: 1px solid var(--color-orange-dark);
            box-shadow: 0 0 0 rems(3) var(--color-orange-xdark);
            outline: none;
        }
    }
}