.partners__header{
    display: flex;
    flex: 1 0 auto;
    height: rems(80);
}

.partners__header-content{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    max-width: none;
    padding-bottom: rems(10);
    padding-left: rems(20);
    padding-right: rems(20);
    padding-top: rems(10);
    position: relative;
    width: 100%;
}

.partners__header-logo{
    display: block;
    width: rems(116);

    img{
        display: block;
        height: auto;
        width: 100%;
    }
}

.partners__header-actions{
    display: none;
}

@include breakpoint(for-tablet-landscape-up){
    .partners__header-content{
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        max-width: rems(960);
        width: rems(960);
        .partners__header-actions{
            display: flex;
            .button--login {
                margin-right: 0.5rem;
            }
        }
    }
}

@include breakpoint(for-small-desktop-up){
    .partners__header-content{
            max-width: none;
            width: rems(1200);
    }
}

// Add login button to header
@include breakpoint(for-tablet-portrait-up) {
  .partners__header-content {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: none;
    width: 48rem;

    .partners__header-actions {
      display: flex;
      .button--login {
          margin-right: 0.5rem;
      }
      .button--register {
        display: none;
      }

      .button--login {
        //margin-right: 0;
      }
    }
  }
}