.card {

    &.card--inner {
        height: auto;
        margin-top: rems(10);
        border: 1px solid var(--color-grey-light);
        box-shadow: none;

        .card__header {
            border: none;
            align-items: center;
            padding: rems(4) rems(4) rems(4) rems(8);

            p {
                margin-bottom: 0;
            }

            .button.button--icon.button--icon-only.button--small {
                min-width: rems(26);
                height: rems(26);
                min-height: 0;
                margin-bottom: 0;
                margin-left: rems(10);

                .icon {
                    left: rems(5);
                    top: rems(4);

                    svg {
                        height: rems(16);
                        width: rems(16);
                    }
                }
            }
        }

        .card__content {
            padding: rems(10);
            .button {
                margin-bottom: 0;
            }
        }

        + form-message {
            padding-top: rems(20);
            display: block;
        }
    }
}
