.button{
    &.button--tiny{    
        font-size: rems(12);
        line-height: rems(14);
        min-width: rems(60);
        padding: rems(4) rems(8); 
        font-weight: 600;
        margin-bottom: rems(4);
    
        &.button--icon{
            padding-left: rems(24);
            position: relative;
            text-align: left;
            .icon{
                height: rems(12);
                position: absolute;
                left: rems(5);
                top: rems(5);
                width: rems(12);

                svg{
                    height: rems(12);
                    width: rems(12);
                }

            }
        }

        &.button--icon-only {
            .icon{
                height: rems(16);
                position: absolute;
                left: rems(3);
                top: rems(3);
                width: rems(16);

                svg{
                    height: rems(16);
                    width: rems(16);
                }

            }
        }

        &.button--nomargin {
            margin-bottom: 0;
        }
    
    }
}
.button{
    &.button--tiniest{    
        font-size: rems(12);
        line-height: rems(14);
        min-width: rems(28);
        padding: rems(4) rems(8); 
        font-weight: 600;
        margin-bottom: rems(4);
        max-height: rems(24);
        height:max-content;

        &.button--icon{
            padding-left: rems(24);
            position: relative;
            text-align: left;
            .icon{
                height: rems(12);
                position: absolute;
                left: rems(5);
                top: rems(5);
                width: rems(12);

                svg{
                    height: rems(12);
                    width: rems(12);
                }

            }
        }
        &.button--icon-only {
            .icon{
                height: rems(16);
                position: absolute;
                left: rems(3);
                top: rems(3);
                width: rems(16);

                svg{
                    height: rems(16);
                    width: rems(16);
                }

            }
        }

        &.button--nomargin {
            margin-bottom: 0;
        }
    }
}

.button.button--tiniest + .button.button--tiniest {    
    margin-left: rems(2);
}

td {
    &.actions {
        .button{
            &.button--tiny{    
                padding: rems(2) rems(8); 
                min-width: rems(52);
            
                &.button--icon{
                    padding-left: rems(20);
                    .icon{
                        top: rems(3);
                    }
                }

                &.button--icon-only {
                    padding-left: 0;
                }
        
                &.button--nomargin {
                    margin-bottom: 0;
                }
            
            }
        }
    }
}