.coho__dark {
    .card, .page__section--alt .card {
        &.card__rent {

            &--action {
                background-color: var(--color-magenta-xdark);
                &:hover {
                    background-color: var(--color-magenta);
                }
            }

            &--active {
                background-color: var(--color-green-xxdark);
                &:hover {
                    background-color: var(--color-green-xdark);
                }
            }

            &--active-2 {
                background-color: var(--color-green-xdark);
                &:hover {
                    background-color: var(--color-green-dark);
                }
            }

            &--active-3 {
                background-color: var(--color-green-dark);
                &:hover {
                    background-color: var(--color-green);
                }
            }

            &--progress {
                background-color: var(--color-orange-xdark);
                &:hover {
                    background-color: var(--color-orange-dark);
                }
            }

            &--inactive {
                background-color: var(--color-grey-border);
                cursor: default;
            }
        }
    }

    rent-records {
        .compact__table--narrow {
            rent-record-row {
                tr.paid_late {
                    td.status {
                        span:not(.label) {
                            background-color: var(--color-grey-border);
                            border: 1px solid var(--color-grey);
                        }
                    }
                }
            }
        }
    }

    rent-records {
        .compact__table--narrow {
            tr.helper {
                td {
                    .button.button--small.button--icon {
                        color: var(--color-grey-light);
                    }
                }
            }
        }
    }

    .rent__total-holder {

        .rent__total-holder--label {
            color: var(--color-grey-light);
        }

        .rent__total-holder--amount {
            color: var(--color-grey-xlight);
        }

        &.due {
            background-color: var(--color-magenta-xdark);
        }

        &.overdue {
            background-color: var(--color-warning-dark);

            .rent__total-holder--label {
                color: var(--color-grey-xlight);
            }
        }

        &.paid {
            background-color: var(--color-green-xdark);
        }
    }

    onboardings {
        tr.action td.step {
            color: var(--color-white);
        }

        tr td.step {
            color: var(--color-grey-light);
        }
    }

    .compact__table {
        tr {
            &.summary {
                &.overdue {
                    background: var(--color-black-light);

                    td:not(.actions) {
                        color: var(--color-pink);
                    }
                }
            }
        }
    }

    .view__detail, .view__compact {
        .display--settings .display--settings-icon.view__compact-icon {
            .icon {
                fill: var(--color-grey-light);
            }
        }
    }

    financial-items, tenancies-recurring-transactions-card, financial-item-repeatings {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    td, th {
                        &.amount {
                            &.negative {
                                color: var(--color-warning-dark);
                            }
                        }
                    }

                    &.settled {
                        td, th {
                            color: var(--color-grey-light);
                        }
                    }

                    &.client-money {
                        background-color: var(--color-green-xxdark);
                        color: var(--color-grey-xlight);
                        td.actions a {
                            color: var(--color-cyan-light);
                        }
                    }

                    &.client-expenses {
                        background-color:  #777;
                        color: var(--color-white);
                        td.actions a {
                            color: var(--color-cyan-light);
                        }
                    }

                    &.tenant-funds {
                        background-color: var(--color-cyan-xdark);
                        color: var(--color-grey-xlight);
                        td.actions a {
                            color: var(--color-cyan-light);
                        }
                    }

                }

                .status-badge {
                    background-color: var(--color-grey-light);
                    border: 1px solid var(--color-grey-border);
                    color: var(--color-black);

                    &.status-badge--overdue {
                        border-color: var(--color-pink-dark);
                        background-color: var(--color-pink-xdark);
                        color: var(--color-white);
                    }

                    &.status-badge--due {
                        border-color: var(--color-orange-dark);
                        background-color: var(--color-orange-xdark);
                        color: var(--color-white);
                    }

                    &.status-badge--paid {
                        border-color: var(--color-green-xdark);
                        background-color: var(--color-green-xxdark);
                        color: var(--color-grey-xlight);
                    }

                    &.status-badge--client-expense {
                        border-color: var(--color-grey-dark);
                        background-color: #777;
                        color: var(--color-white);
                    }

                    &.status-badge--client-money {
                        border-color: var(--color-green-dark);
                        background-color: var(--color-green-xxdark);
                        color: var(--color-grey-xlight);
                    }

                    &.status-badge--tenant-funds {
                        border-color: var(--color-cyan-dark);
                        background-color: var(--color-cyan-xdark);
                        color: var(--color-grey-xlight);
                    }

                    &.status-badge--income {
                        border-color: var(--color-green-xdark);
                        background-color: var(--color-green-xxdark);
                        color: var(--color-grey-xlight);
                    }

                    &.status-badge--expense {
                        border-color: #993534;
                        background-color: var(--color-warning-dark);
                        color: var(--color-white);
                    }
                }
            }
        }

        .finance__total-holder {
            .finance__total-holder--label {
                color: var(--color-grey-light);
            }
            .finance__total-holder--amount {
                color: var(--color-grey-xlight);
            }
            border: rems(1) solid var(--color-grey-light);

            &.client-money {
                background-color: var(--color-green-xxdark);
            }

            &.tenant-funds {
                background-color: var(--color-cyan-xdark);
            }

            &.client-expenses {
                background-color: #777;
                .finance__total-holder--amount {
                    color: var(--color-white);
                }
            }
        }


        .settlement__info-item--label {
            color: var(--color-grey-border);
        }
    }

    .status__container {
        .status__holder{
            background-color: transparent;;
        }
    }

    .item__holder {
        background-color: var(--color-black-light);
    }
    .item__holder--white {
        background-color: var(--color-black);
    }

    maintenance-issues, maintenance-issues-tenant, maintenance-issues-owner, maintenance-issue-page {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    &.details {
                        background-color: var(--color-black);
                        td {
                            background-color: var(--color-black);
                        }
                    }
                }
            }
        }
    }

}
@include breakpoint(for-tablet-portrait-up) {
    .coho__dark {
        financial-items, tenancies-recurring-transactions-card, financial-item-repeatings {
            .compact__table {
                &.compact__table--narrow {
                    tr {
                        td, th {
                            .icon {
                                &.property-ownership-owned {
                                    svg {
                                        fill: var(--color-cyan);
                                    }
                                }

                                &.property-ownership-master-lease {
                                    svg {
                                        fill: var(--color-brown);
                                    }
                                }

                                &.property-ownership-managed {
                                    svg {
                                        fill: #cb418c;
                                    }
                                }
                            }

                            &.property, &.room, &.tenancy, &.category, &.transaction, &.settlement, &.payment {
                                span {
                                    .tooltip {
                                        background-color: var(--color-blue-dark);
                                        box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
                                        color: white;
                                        &.property-ownership-owned {
                                            background-color: var(--color-cyan-dark);
                                        }

                                        &.property-ownership-master-lease {
                                            background-color: var(--color-brown-dark);
                                        }

                                        &.property-ownership-managed {
                                            background-color: var(--color-magenta-dark);
                                        }
                                    }

                                }
                            }

                            &.category, &.transaction, &.settlement, &.payment {
                                span {
                                    .tooltip {
                                        background-color: var(--color-grey);
                                    }
                                }
                            }
                        }
                    }
                }
            }


            .finance__detail--holder {
                .finance__detail--item-holder {
                    .finance__detail--label {
                        color: var(--color-grey-border);
                    }

                }
            }
        }
        onboarding-row {
            onboarding-card {
                background: var(--color-black);
            }
        }




        .compact__table, .compact__table.compact__table--narrow {

            &-highlight {
                tr.summary {
                    &:hover {
                        cursor:pointer;
                        background: var(--color-black-light);
                    }
                }
            }
        }


    }
}

@include breakpoint(for-tablet-landscape-up) {
    .coho__dark {
        .compact__table, .compact__table.compact__table--narrow {
            border-bottom: 1px solid rgba(3,3,3,0.2);

            tr {
                border-top: 1px solid rgba(3,3,3,0.2);

                &.details {
                    box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
                    //background: var(--color-grey-dark);

                    td {
                        background: var(--color-grey-dark);
                    }
                }
            }
        }

        .page__section--alt {
            .compact__table, .compact__table.compact__table--narrow{

                tr {

                    &.details {

                        td {
                            background: var(--color-black);
                        }
                    }
                }
            }
        }
    }
}
