.button__help{
    background-color: white;
    border: 1px solid var(--color-grey);
    border-radius: 100%;
    color: var(--color-grey);
    cursor: pointer;
    display: inline-block;
    font-size: rems(12);
    height: rems(16);
    margin-bottom: rems(0);
    margin-left: rems(5);
    margin-right: rems(5);
    margin-top: rems(0);
    padding: 0;
    vertical-align: middle;
    text-align: center;
    width: rems(16);

    &:hover{
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: white;
    }

}