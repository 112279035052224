.coho__dark {
    .notes {

        .notes__container {

            &::-webkit-scrollbar-track {
                background: var(--color-grey);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-grey-light);
                border: rems(3) solid (grey);
            }

            history-item {
                .note {
                    .note__header {
                        &.note__header--actions {

                            .note__actions {
                                color: var(--color-grey-border);

                                a {
                                    color: var(--color-grey-border);
                                }
                            }
                        }
                    }

                    &__contextual {
                        .note__content {
                            p {
                                color: var(--color-teal-xlight);
                            }
                        }
                    }
                }
            }
        }
    }

    .compact__table {
        .notes {
            .notes__container {
                &::-webkit-scrollbar-track {
                    background: var(--color-grey-xxlight);
                }

                &::-webkit-scrollbar-thumb {
                    border: rems(3) solid var(--color-grey-xxlight);
                }
            }
        }
    }
}
