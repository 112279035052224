.form__steps {
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    padding: rems(10) rems(10) rems(6) rems(10);
    background-color: var(--color-grey-xlight);

    h4 + * {
        margin-top: rems(12);
    }

    &--step {
        display: flex;
        flex-direction: column;
        padding-bottom: rems(10);
        border-bottom: rems(1) dotted var(--color-grey-border);

        &-index {
            p {
                margin-bottom: rems(8);
            }
        }
        &-action {
            margin-top: rems(8);
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {

    .form__steps {
        justify-content: center;

        &--step {
            flex-direction: row;
            border-bottom: 0;
            padding-bottom: rems(4);

            &:last-of-type {
                padding-bottom: 0;
            }

            &-index {
                p {
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: rems(22);
                    min-width: rems(32);
                    line-height: 1;
                    padding-top: rems(6);
                    padding-right: rems(6);
                    color:var(--color-teal-dark);
                }
            }
            &-name {
                input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]).form__input--small, textarea.form__input--small {
                    margin-bottom: 0;
                    min-height: rems(35);
                    width: calc(rems(597) - rems(233));
                }
            }
            &-assign {
                min-width: 80px;
                padding-left: rems(6);
                .form__select {
                    min-width: rems(155);
                }
            }
            &-action {
                margin-top: 0;
                padding-left: rems(6);
            }
        }
    }

}