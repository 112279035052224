.landing__page {
    .landing__subnav {
        padding: rems(8) 0;
        border-top: rems(1) dotted var(--color-grey-light);
        border-bottom: rems(1) dotted var(--color-grey-light);

        ul {
            list-style: none outside;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                font-size: rems(13);
                color: var(--color-grey);
                padding: rems(4) 0;
                a {
                    font-size: inherit;
                    color: inherit;
                    text-decoration: none;


                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        border-bottom: 1px solid var(--color-grey-light);
                    }
                }

                &.parent {
                    a {
                        color: var(--color-grey-dark);

                        &:hover {
                            border-bottom: 1px solid var(--color-grey);
                        }
                    }
                }
            }
        }
    }
}



@include breakpoint(for-tablet-portrait-up) {
    .landing__page {
        .landing__subnav {
            padding: rems(16) 0 rems(16) 0;

            ul {

                li {
                    padding: 0 rems(10) 0 rems(12);
                    display: inline-block;
                    border-left: 1px solid var(--color-grey-light);

                    a {
                        padding-bottom: rems(2);
                    }

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }
    }

}
