@import "cover";
@import "header";
@import "card";
@import "profile";
@import "holder";


.partners__image-holder {
    margin-bottom: rems(18);
    padding-right: rems(48);

    img {
        width: 100%;
    }
}
