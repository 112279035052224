.maintenance-job-report-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    justify-content: center;

    h1 {
        font-size: 1.875rem;
        line-height: 2.813rem; //times 1.5 like the body
    }
    h2 {
        font-size: 1.5rem;
        line-height: 2.25rem; //times 1.5 like the body
    }
    h3 {
        font-size: 1.25rem;
        line-height: 1.875rem; //times 1.5 like the body
    }

    img.coho-logo {
        height: rems(32);
        margin-top: 0;
    }

  img.organisation-logo {
    width: 120px;
    margin-top: 0;
  }

  img.property-image {
    width: 340px;
    margin-top: 0;
  }

    .title_and_logo {  //JON TODO
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;
    }

    .issue_title_and_full_address { //JON_TODO
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        padding-bottom: 1rem;

        .issue_title {
            flex:3;
        }

        .full_address {
            flex:1;
            text-align: right;
            margin: 0;
        }
    }

    .maintenance-report-time-and-severity {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
    }

    .maintenance-report-statuses {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 2rem;
    }

    .maintenance-report-request-raised-and-assigned {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
    }

    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    .section_with_border {
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: rems(20);
        padding-bottom: rems(20);
    }

    .maintenance-report-images img {
        width: 100%;
        max-width: 230px;
        object-fit: cover;
    }

  .maintenance-report-images img.full-size {
    max-width: 100%;
  }

    .img_grid {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;
        max-width: 100%;
    }

    table {
        width: 100%;

        thead {
            tr {
                border-bottom: 1px solid #AAA;
                margin-bottom: 3px;
            }

            th {
                padding: 5px;
            }
        }

        tbody {
            th {
                font-weight:bold;
                padding: 5px 20px 5px 5px;
            }

            td {
                padding:5px;
            }
        }
    }
}






