.page__container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;

    .page__section--full {
        flex: 1 1 auto;
    }

}

.page__section--supplier {
    input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
        &.form__input--search {
            height: rems(48);
            margin-bottom: rems(16);
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .page__section--supplier {
        input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
            &.form__input--search {
                margin-bottom: 0;
            }
        }
    }
}