.whats-new { 
    .slick-prev, .slick-next {
        text-transform: uppercase;
        color: var(--color-violet-dark);
        font-size: rems(12);
        font-weight: 400;
        padding: 0 rems(4);
        cursor: pointer;
        &:focus { outline: none;}
        &:hover {
            color: var(--color-violet-xdark);
            outline: 1px dotted var(--color-violet-xdark);
            border: 0;
        }
        &.slick-disabled {
            opacity: 0.25;
            cursor: default;
            &:hover {
                outline: 0;
            }
        }
    }
    .slick-prev { 
        position: absolute;
        right: rems(85);
        top: rems(18);
        z-index: 1
    }
    .slick-next {
        border-left: rems(1) solid rgba(var(--color-violet-xdark-rgb),0.25);
        position: absolute;
        right: rems(26);
        top: rems(18);
    }
}

