label.form__checkbox {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: rems(14);
    font-weight: 400;
    margin-bottom: rems(20);
    margin-top: 0;
    min-height: rems($size__checkbox-size);
    position: relative;
    display: inline-flex;
    padding-left: rems($size__checkbox-size);
    width: 100%;

    &.compact {
        margin-bottom: rems(10);
    }

    svg-icon {
        margin-left: rems(10);
    }

    .form__checkbox-label {
        margin-left: rems(10);
        line-height: 1.2;

        span {
            font-size: rems(12);
            color: var(--color-grey);
        }

        &--small {
            font-size: rems(14);
            line-height: 1.4;
            margin-top: rems(-4);
        }

        &:hover {
            cursor: pointer;
        }

        &::before {
            background-color: white;
            border: 1px solid var(--color-grey-border);
            border-radius: radius(small);
            box-shadow: $shadow__up;
            content: "";
            display: block;
            left: 0;
            height: rems($size__checkbox-size);
            position: absolute;
            top: 0;
            transition: 0.2s all ease-out;
            width: rems($size__checkbox-size);
        }

        &::after {
            border-bottom: rems(4) solid var(--color-grey-light);
            border-left: rems(4) solid var(--color-grey-light);
            content: "";
            display: inline-block;
            height: rems(10);
            left: rems(4);
            opacity: 0.25;
            position: absolute;
            top: rems(5);
            transform: rotate(-45deg);
            transition: 0.2s all ease-out;
            width: rems(16);
        }
    }

    input[type=checkbox] {
        height: rems($size__checkbox-size);
        left: 0;
        margin: 0 rems(10) 0 0;
        outline: none;
        padding: 0;
        position: absolute;
        top: 0;
        width: rems($size__checkbox-size);

        &:focus {
            + .form__checkbox-label {
                &::before {
                    background-color: #ffffff;
                    border: rems(1) solid var(--color-form);
                    box-shadow: $shadow__outline;
                }
            }
        }

        &:checked {
            + .form__checkbox-label {
                &::before {
                    background-color: var(--color-form);
                    border: 1px solid var(--color-form-dark);
                }
                &::after {
                    background-color: var(--color-form);
                    border-bottom: rems(4) solid var(--color-white);
                    border-left: rems(4) solid var(--color-white);
                    opacity: 1;
                }
            }

            &:focus {
                + .form__checkbox-label {
                    &::before {
                        box-shadow: $shadow__outline;
                    }
                }
            }
        }

        &:disabled {
            + .form__checkbox-label {
                &::before {
                    background-color: var(--color-grey-xlight);
                }

                &:hover {
                    cursor: initial;
                }
            }

            &:checked {
                + .form__checkbox-label {
                    &::before {
                        background-color: var(--color-form-light);
                        border: 1px solid var(--color-form-dark);
                    }
                    &::after {
                        background-color: var(--color-form-light);
                        border-bottom: rems(4) solid white;
                        border-left: rems(4) solid white;
                        opacity: 1;
                    }

                    &:hover {
                        cursor: initial;
                    }
                }
            }
        }

        &.required {
            + .form__checkbox-label {
                &::before {
                    border-color: var(--color-pink);
                    box-shadow: $shadow__required;

                    @include input__down_focus;
                }
            }
        }
    }

    &.read-only {
        input[type=checkbox] {
            &:disabled {
                + .form__checkbox-label {
                    &::before {
                        background-color: white;
                        border-color: var(--color-grey-xlight);
                        box-shadow: none;
                    }
                }
            }
        }
    }

    &.form__input--small {
        input[type=checkbox] {
            height: rems(18);
            width: rems(18);
            top: rems(3);
        }
        &.form__checkbox {
            margin-bottom: rems(8);
            padding-left: rems(16);
        }

        .form__checkbox-label {
            font-size: rems(14);
            line-height: 1.4;
            //margin-top: rems(-4);

            &::before {
                height: rems(18);
                width: rems(18);
                top: rems(3);
            }

            &::after {
                height: rems(8);
                top: rems(7);
                left: rems(3);
                width: rems(13);
                border-bottom-width: rems(3) !important;
                border-left-width: rems(3) !important;
            }
        }
    }
}

checkbox + .form__input-help {
    margin-top: rems(-10);
}