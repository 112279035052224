.card {
    &.card--manager {
        height: auto;
    }
}

@include breakpoint(for-tablet-portrait-up){
    .card {
        &.card--manager {
            .card__content {
                .profile__preview {
                    .profile__image {
                        flex-basis: 4rem;
                        height: 4rem;
                        max-width: 4rem;
                        width: 4rem;
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .card {
        &.card--manager {
            .card__content {
                .profile__preview {
                    .profile__image {
                        flex-basis: 5rem;
                        height: 5rem;
                        max-width: 5rem;
                        width: 5rem;
                    }
                }
            }
        }
    }
}

