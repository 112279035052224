.button{
    background-color: var(--color-magenta);
    border: 1px solid var(--color-magenta-dark);
    border-radius: radius(medium);
    box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: $type__family;
    font-size: rems(16);
    font-weight: 700;
    line-height: rems(20);
    margin-bottom: rems(10);
    min-width: rems(120);
    padding: rems(13) rems(12);
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover{
        background-color: var(--color-magenta-dark);
        border-color: var(--color-magenta-xdark);
        color: white;
    }

    &:focus{
        border: 1px solid var(--color-magenta);
        box-shadow: 0 0 0 rems(3) var(--color-magenta-xdark);
        outline: none;
    }

    &:disabled, &.disabled {
        background-color: var(--color-grey);
        border-color: var(--color-grey);
        color: white;

        svg-icon {
            pointer-events: none;
        }

        &:hover {
            background-color: var(--color-grey);
            border-color: var(--color-grey);
            cursor: default;
        }

    }

    &.button--hidden {
        display: none;
    }

}

.button {
    &.button--app {
        img {
            width: rems(163);
        }
    }
}



.button--full{
    display: block;
    width: 100%;
}

.button--right{
    display: inline-block;
    float: right;
}

.button--nomargin {
    margin-bottom: 0;
}

@include breakpoint(for-large-phone-landscape-up){
    .button{
        display: inline-block;
        width: auto;
    }

    .button--full{
        display: block;
        width: 100%;
    }
}

.button--center{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

loader-button + loader-button {
    .button {
        margin-left: rems(10);
    }
}


a.close_x {
    background-color: var(--color-grey-border);
    cursor: pointer;
    font-size: rems(12);
    padding: 0 rems(5) rems(2) rems(5);
    font-weight: 600;
    color: white;
    border-radius: rems(10);
    vertical-align: top;
    margin-left: rems(2);
    text-decoration: none;

    &:hover {
        color: white;
        background-color: var(--color-pink-dark);
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}