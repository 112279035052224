//SEE USAGE IN manage-room-card.component.html
//THIS HAS NOTHING TO DO WITH A MODAL, IT IS A WAY TO STYLE SOME CARDS

.inactive__modal{
    display:none;
}

.inactive__modal-wrapper{
    position: relative;
    .inactive__modal{
        background-color: rgba(var(--color-white-rgb), 0.7);
        @include shadow__low;
        //border: rems(1) solid var(--color-grey-light);
        //border-radius: radius(medium);
        bottom: rems(0);
        display: flex;
        flex-direction: column;
        left: rems(0);
        overflow: auto;
        padding: rems(20);
        position: absolute;
        right: rems(0);
        top: rems(0);
        .inactive__modal-content{
            align-items: center;
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            justify-content: flex-end;
            margin-left: auto;
            margin-right: auto;
            max-width: rems(600);
            text-align: center;
            button {
                margin-bottom: 0;
                &.button--icon.button--ghost .icon{
                    fill: var(--color-grey);
                }

            }
        }
    }
    .card__actions {
        .button {
            visibility: hidden;
        }
    }
}

.room{
    .inactive__modal-content{
        padding-top: rems(50);
        justify-content: flex-start;
    }
}

