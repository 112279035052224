.form__radio-buttons{
    display: block;
}

.form__radio-button {
    display: block;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    .form__radio-button-label {
        @include input__up;
        margin-bottom: rems(10);
        padding: rems(8) rems(10);
        text-align: center;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            &.form__radio-button-label-title {
                font-size: rems(16);
            }
        }

        .form__radio-button-label-title {
            font-weight: 600;
            line-height: 1.2;
        }

        p {
            margin: 4px 0;
            color: var(--color-grey-dark);

            &.u-text--small {
                font-size: rems(12);
                line-height: 1.4;
            }
        }
    }

    input[type=radio] {
        left: -3000px;
        opacity: 0;
        position: absolute;

        &:checked {
            + .form__radio-button-label {
                background-color: var(--color-form-light);
                border: rems(1) solid var(--color-form);
                box-shadow: $shadow__radio-buttons;
                color: var(--color-blue);
                font-weight: 700;

                .form__radio-button-label-title {
                    font-weight: 700;
                }

                p {
                    font-weight: 600;
                }
            }
        }

        &:focus {
            + .form__radio-button-label {
                border-color: var(--color-form);
                box-shadow: $shadow__radio-buttons--focus, $shadow__outline;
            }
        }

        &:disabled,.disabled {
            + .form__radio-button-label {
                background-color: var(--color-grey-xxlight);
                border-color: var(--color-grey-xxlight);
                color: var(--color-grey-light) !important;
                cursor: default;
            }

            &:hover {
                cursor: default;
                border-color: var(--color-grey-xxlight);
            }

            &:checked {
                + .form__radio-button-label {
                    background-color: var(--color-form-light);
                    color: var(--color-blue);
                }
            }
        }
    }
    // REQUIRED

    &.required {
        input[type=radio] {
            + .form__radio-button-label {
                // background-color: rgba(var(--color-pink-rgb), 0.2);
                border-color: var(--color-pink);
                box-shadow: $shadow__required;
                // color: var(--color-magenta-dark);
            }
        }
    }
    // READ ONLY

    &.read-only {
        input[type=radio] {
            + .form__radio-button-label {
                background-color: var(--color-white);
                border-color: var(--color-grey-xlight);
                box-shadow: none;
            }

            &:checked {
                + .form__radio-button-label {
                    border-color: var(--color-grey);
                    box-shadow: 0 0 0 rems(2) var(--color-grey)
                }
            }
        }
    }
}


.form--inline {
    .form__radio-button {
        input[type=radio] {
            &:disabled,.disabled {
                + .form__radio-button-label {
                    border-color: var(--color-grey-light);
                }
            }
        }
    }
}

// GRID

.form__grid-element{

    .form__radio-button{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 0;

        .form__radio-button-label{
            background-color: white;
            height: 100%;
        }
    }
}

