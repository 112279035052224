.site__header--slim,
.site__header--slim.site__header--logged-in
.site__header--slim.site__header--logged-out {

    height: rems(52);

    .header__logo {
        padding-bottom: rems(12);
        padding-top: rems(12);
        width: rems(80);
    }

    .main__menu-icon {
        height: rems(48);
    }

    .header__options {
        height: rems(52);

        .dropdown {
            .header__options-button {
                height: rems(52);
                //right: 0;

                .badge {
                    top: rems(6);
                }

                &::after {
                    top: rems(20);
                }
            }

            .dropdown__menu {
                top: rems(51);
                z-index: 901;

                &.dropdown__menu--right {
                    right: 0;
                }
            }

            &.open {
                .header__options-button {
                    //right: 0;
                }
            }

            &.dropdown__announcements {
                .header__options-button {
                    //right: rems(80);

                    .badge {
                        right: rems(8);
                    }
                }
            }

            &.dropdown__feedback {
                .header__options-button {
                    //right: rems(80);

                    .badge {
                        right: rems(8);
                    }
                }   
                .feedback__menu {
                    top: rems(51);
                    z-index: 901;
    
                }             
            }
        }
    }

    .header__profile-image {
        margin-top: 0rem;
        height: 2rem;
        width: 2rem;
    }

    .dropdown__announcements {
        .header__profile-image {
            margin-top: rems(12);

            .icon-bell, .icon-bell svg {
                width: rems(24);
                height: rems(24);
            }
        }
    }

    .dropdown__feedback {
        .header__profile-image {
            margin-top: rems(12);

            .icon-happy-outline, .icon-happy-outline svg {
                width: rems(24);
                height: rems(24);
            }
        }
    }


    .main__navigation {
        li {
            a {
                padding-top: rems(8);
                padding-bottom: rems(8);
            }
        }
    }


    @include breakpoint(for-tablet-portrait-up) {
        .header__options {
            //right: 0;
        }

        .main__navigation {
            padding-right: rems(80);
            transition: padding-right 0.5s linear;
        }

        &.site__header--announcements {
            .main__navigation {
                padding-right: rems(137);
            }
        }
    }

    @include breakpoint(for-tablet-landscape-up) {
        .main__navigation li.manage {
            //margin-right: 1rem;
        }

        .header__options {
            .header__options-button {
                //padding-right: rems(16);
                .badge {
                    //right: 0;
                }
            }
        }

        .main__navigation {
            height: rems(52);
        }
    }
}
