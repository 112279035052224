.form__progress {
    .form__progress--step {
        background-color: var(--color-teal-dark);
        border: rems(1) solid var(--color-teal-xdark);
        border-radius: radius(medium);
        color: var(--color-white);

        padding: rems(4) rems(8);

        position: relative;

        p {

            font-weight: 400;
            font-size: rems(18);
            margin-bottom: 0;
            line-height: 1.4; 

            &:first-child {
                text-transform: uppercase;
                font-size: rems(12);
                margin-bottom: 0;
                font-weight: 700;
            }
            
        }

        &:hover {
            box-shadow: 0 0 rems(3) rems(3) var(--color-form-light);
        }

        &-past {
            background-color: var(--color-green-light);
            border-color: var(--color-green-dark);
            color: var(--color-green-xxdark);
            &:hover {
                box-shadow: 0 0 rems(3) rems(3) rgba(var(--color-green-rgb),0.15);
            }
        }
        &-future {
            background-color: var(--color-grey-border);
            border-color: var(--color-grey);
            &:hover {
                box-shadow: none;
            }
        }

        > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:focus {
                outline: none;
            }
        }
    }
}