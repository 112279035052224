.profile__team{
    display: flex;
    flex: 1 0 auto;
    margin-bottom: rems(16);
    /*flex-direction: column;*/
    position: relative;
    //border-bottom: 1px solid var(--color-grey-light);
    padding-bottom: rems(8);
    padding-top: rems(16);
    .profile__image{
        flex-basis: rems(50);
        height: rems(50);
        max-width: rems(50);
        width: rems(50);
        margin-right: rems(10);
    }
    .profile__team-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        margin-right: rems(8);
        .profile__team-text{
            display: flex;
            flex-direction: column;
            margin-left: 0;
            > h1, > h2, > h3, > h4, > h5, > h6{
                padding-top: 0;
            }
            > p {
                /*margin-top: rems(4);*/
                margin-bottom: 0;
                font-size: rems(14);
                line-height: 1.25rem;
                a {
                    &:focus {
                        outline: none;
                    }
                }
                span {
                    margin-left: rems(8);
                    margin-right: rems(8);
                }
            }
        } 

/*        .profile__team-role {
            padding-top: rems(10);
            p{
                font-size: rems(14);
                color: var(--color-pink-dark);
                margin-bottom: rems(16);
            }
        }*/
    }
    .profile__team-actions {
        display: flex;
        flex-direction: column;
        a{
            font-size: rems(14);
            color: var(--color-grey);
            &:focus {
                outline: none;
            }
            &:last-child {
                /*margin-bottom: rems(16);*/
            }
        }
    }
        

}


@include breakpoint(for-large-phone-landscape-up) {
    .profile__team{
        /*flex-direction: row;*/
        margin-bottom: rems(8);
        padding-bottom: rems(8);
        .profile__image {
            /*margin-right: rems(20);*/
        }
        .profile__team-content {
            flex-direction: row;
            padding-right: 0;
            .profile__team-text{
                margin: rems(16) 0;
                margin: 0;
                /*flex-basis: 80%;*/
            }   
/*            .profile__team-role {
                flex-basis: 20%;
                padding-top: 0;
            }*/
        }
        .profile__team-actions{
            flex-direction: column;
            justify-items: flex-end;
            margin: 0 0 0 rems(20);
            min-width: rems(80);
            a {
                /*padding-left: rems(8);*/
            }
        }
    }    
}




