.investment__embedded-table {
    table {
        width: 100%;
        border-collapse: collapse;

        tr {
            td {
                border: 4px solid #FFFFFF;

                &.label {
                }

                &.symbol {
                    text-align: center;
                    font-size: 1.125rem;
                    width: 1.5rem;
                }

                &.amount {
                    background-color: #E7F8E4;
                    width: 20%;
                    text-align: right;
                    padding-right: 0.25rem;
                }
            }

            &.total {
                border-top: 2px solid #2d2d2d;

                td {
                    font-weight: bold;
                }
            }

            &.highlight {
                td.amount {
                    font-weight: bold;
                    background-color: var(--color-green);
                }
            }
        }
    }
}

p.notes {
    color: var(--color-grey);
    font-size: rems(14);
    line-height: 1.4;
}
