.gallery { 
    position: relative;

    img { 
        display: block;
        margin-bottom: 0;
    }

    .slick-arrow {
        background-color: transparent;
        border: none;
        height: rems(48);
        margin-top: rems(-48);
        overflow: hidden;
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        width: rems(48);
        background: rgba(0,0,0,0.1);

        &:hover {
            background-color: rgba(0,0,0,0.2);
        }

        &.slick-prev {
            background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cpath%20id%3D%22b%22%20d%3D%22m15.9898475%206.41841888c.5578918-.55789184%201.4624132-.55789184%202.020305%200%20.5578919.55789185.5578919%201.46241325%200%202.02030509l-6.1330096%206.13270463h10.8371428c.7889782%200%201.4285714.6395932%201.4285714%201.4285714s-.6395932%201.4285714-1.4285714%201.4285714h-10.8371428l6.1330096%206.1327046c.5285292.5285291.5563465%201.3681607.083452%201.9294374l-.083452.0908677c-.5578918.5578919-1.4624132.5578919-2.020305%200l-8.57142862-8.5714286c-.55789184-.5578918-.55789184-1.4624132%200-2.020305z%22%2F%3E%0A%20%20%20%20%3Cfilter%20id%3D%22a%22%20width%3D%22286.7%25%22%20height%3D%22260%25%22%20x%3D%22-93.3%25%22%20y%3D%22-70%25%22%3E%0A%20%20%20%20%20%20%3CfeOffset%20dy%3D%222%22%20in%3D%22SourceAlpha%22%20result%3D%22shadowOffsetOuter1%22%2F%3E%0A%20%20%20%20%20%20%3CfeGaussianBlur%20in%3D%22shadowOffsetOuter1%22%20result%3D%22shadowBlurOuter1%22%20stdDeviation%3D%225%22%2F%3E%0A%20%20%20%20%20%20%3CfeColorMatrix%20in%3D%22shadowBlurOuter1%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.2%200%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20fill%3D%22none%22%3E%0A%20%20%20%20%3Cuse%20fill%3D%22%23000%22%20filter%3D%22url%28%23a%29%22%20xlink%3Ahref%3D%22%23b%22%2F%3E%0A%20%20%20%20%3Cuse%20fill%3D%22%23fff%22%20xlink%3Ahref%3D%22%23b%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            left: 0;
            z-index: 100;
            border-top-right-radius: radius(medium);
            border-bottom-right-radius: radius(medium);

            &:focus {
                background-color: rgba(0,0,0,0.2);
                box-shadow: 0 0 0 rems(4) var(--color-pink);
                outline: none;
            }
        }

        &.slick-next {
            background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cpath%20id%3D%22b%22%20d%3D%22m15.1530097%206.41841888%208.5714286%208.57142862c.5578918.5578918.5578918%201.4624132%200%202.020305l-8.5714286%208.5714286c-.5578919.5578919-1.4624133.5578919-2.0203051%200l-.083452-.0908677c-.4728944-.5612767-.4450771-1.4009083.083452-1.9294374l6.1330097-6.1327046h-10.83714287c-.78897822%200-1.42857143-.6395932-1.42857143-1.4285714s.63959321-1.4285714%201.42857143-1.4285714h10.83714287l-6.1330097-6.13270463c-.5578918-.55789184-.5578918-1.46241324%200-2.02030509.5578918-.55789184%201.4624132-.55789184%202.0203051%200z%22%2F%3E%0A%20%20%20%20%3Cfilter%20id%3D%22a%22%20width%3D%22286.7%25%22%20height%3D%22260%25%22%20x%3D%22-93.3%25%22%20y%3D%22-70%25%22%3E%0A%20%20%20%20%20%20%3CfeOffset%20dy%3D%222%22%20in%3D%22SourceAlpha%22%20result%3D%22shadowOffsetOuter1%22%2F%3E%0A%20%20%20%20%20%20%3CfeGaussianBlur%20in%3D%22shadowOffsetOuter1%22%20result%3D%22shadowBlurOuter1%22%20stdDeviation%3D%225%22%2F%3E%0A%20%20%20%20%20%20%3CfeColorMatrix%20in%3D%22shadowBlurOuter1%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.2%200%22%2F%3E%0A%20%20%20%20%3C%2Ffilter%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20fill%3D%22none%22%3E%0A%20%20%20%20%3Cuse%20fill%3D%22%23000%22%20filter%3D%22url%28%23a%29%22%20xlink%3Ahref%3D%22%23b%22%2F%3E%0A%20%20%20%20%3Cuse%20fill%3D%22%23fff%22%20xlink%3Ahref%3D%22%23b%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            right: 0;
            z-index: 100;
            border-top-left-radius: radius(medium);
            border-bottom-left-radius: radius(medium);

            &:focus {
                background-color: rgba(0,0,0,0.2);
                box-shadow: 0 0 0 rems(4) var(--color-pink);
                outline: none;
            }
        }
    }

    .slick-slide {
        border-radius: radius(medium);
        overflow: hidden;

        &:focus {
            outline: none;
        }
    }

    .slick-dots {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        position: absolute;

        li {
            display: inline-block;

            button {
                background-color: var(--color-grey-light);
                border: none;
                border-radius: radius(small);
                height: rems(10);
                margin-left: rems(5);
                margin-right: rems(5);
                overflow: hidden;
                padding: 0;
                text-indent: -9999px;
                width: rems(10);

                &:focus {
                    box-shadow: inset 0 0 0 1px var(--color-primary-light), 0 0 0 rems(2) rgba(var(--color-primary-light-rgb),0.5);
                    outline: none;
                }
            }

            &.slick-active {
                button {
                    background-color: var(--color-magenta);
                }
            }
        }
    }
}
