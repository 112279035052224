// Skin design by Veaceslav Grimalschi
// https://github.com/grimalschi

.irs--houseshare {
    $top: 36px;
    $bottom: 21px;
    $line_height: 4px;
    $handle_width: 24px;
    $handle_height: 24px;

    $line_color: #959595;
    $bar_color: #1BB3AF;
    $handle_color_1: #1BB3AF;
    $handle_color_2: #D1F0EF;
    $handle_color_3: #1BB3AF;
    $minmax_text_color: #333;
    $minmax_bg_color: rgba(0, 0, 0, 0.1);
    $label_color_1: transparent;
    $label_color_2: #262626;
    $grid_color_1: #dedede;
    $grid_color_2: silver;


    height: 50px;

    &.irs-with-grid {
        height: 65px;
    }

    .irs-line {
        top: $top;
        height: $line_height;
        background-color: $line_color;
        border-radius: $line_height;
    }

    .irs-bar {
        top: $top;
        height: $line_height;
        background-color: $bar_color;

        &--single {
            border-radius: $line_height 0 0 $line_height;
            background-color: transparent;
        }
    }

    .irs-shadow {
        height: 4px;
        bottom: $bottom;
        background-color: fade($line_color, 50%);
    }

    .irs-handle {
        top: 26px;
        width: $handle_width;
        height: $handle_height;
        border: 4px solid $handle_color_1;
        background-color: $handle_color_2;
        border-radius: $handle_width;
        box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);

        &.state_hover,
        &:hover {
            background-color: $handle_color_3;
        }
    }

    .irs-min,
    .irs-max {
        display: none;
        color: $minmax_text_color;
        font-size: 14px;
        line-height: 1;
        top: 0;
        padding: 3px 5px;
        background-color: $minmax_bg_color;
        border-radius: $line_height;
    }

    .irs-single,
    .irs-from,
    .irs-to {
        font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 1rem;
        font-weight: 650;
        line-height: 1;
        text-shadow: none;
        padding: 3px 5px;
        background-color: $label_color_1;
        color: $label_color_2;
        border-radius: $line_height;

        &:before {
            position: absolute;
            display: block;
            content: "";
            bottom: -6px;
            left: 50%;
            width: 0;
            height: 0;
            margin-left: -3px;
            overflow: hidden;
            border: 3px solid transparent;
            border-top-color: $label_color_1;
        }
    }

    .irs-grid {
        height: 25px;

        &-pol {
            background-color: $grid_color_1;
        }

        &-text {
            color: $grid_color_2;
            font-size: 13px;
        }
    }

    .range-slider-label {
        bottom: -25px;
        position: absolute;
        color: #262626;
        font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 1rem;
    }

    .range-slider-area {
        position: absolute;
        width: 3px;
        height: 13px;
        background: #959595;
        bottom: 14px;
    }

    .range-slider-pip {
        position: absolute;
        width: 2px;
        height: 8px;
        background: #dedede;
        bottom: 14px;
    }
}
