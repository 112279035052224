.card__image--score-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(var(--color-white-rgb),0.75);
    width: 100%;
    height: 100%;
    padding: 0;
}

.card__image--score {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    property-completion-score {
        display: block;
        margin-top: 7.5%;
        position: relative;
    }

    &-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 300;
        font-size: rems(21);
        color: var(--color-grey-dark);
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .card__image--score {
        &-label {
            font-weight: 600;
            font-size: rems(24);
        }
    }   
}

.page__section-content--compact {

    .card__image--score {
        padding: rems(2);
        width: rems(80);

        property-completion-score {
            margin-top: 0;

            .property--score-inner {
                height: rems(56);
            }
        }

        &-label {
            font-size: rems(10);
            font-weight: 800;
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .page__section-content--compact {

        .card__image--score {
            width: rems(92);

            property-completion-score {

                .property--score-inner {
                    height: rems(67);
                }
            }

            &-label {
                font-size: rems(12);
                font-weight: 400;
            }
        }
    }
}
