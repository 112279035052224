.row--display{
    .col {
        display: flex;
        flex-direction: row;
            .display--settings {
                display: inline-flex;
                margin-left: auto;
                .display--settings-icon {
                    display: inline-block;
                    height: rems(30);
                }
            }
    }
}