.inner__container {
    border: rems(2) solid var(--color-grey-border);
    border-radius: radius(large);

    &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: rems(10) rems(8);
        border-bottom: rems(1) solid var(--color-grey-light);
        h2 {
            color: var(--color-white);
        }
        .icon {
            margin-right: rems(12);
        }
        svg {
            fill: var(--color-white);
        }
    }

    &--content {
        padding: rems(20);
        padding-bottom: 0;
    }

    &--footer {
        a {
            background-color: var(--color-grey-xlight);
            display: block;
            width: 100%;
            text-align: center;
            color: var(--color-grey-dark);
            font-size: rems(18);
            text-decoration: none;
            padding: rems(10) 0;
            border-bottom-left-radius: radius(medium);
            border-bottom-right-radius: radius(medium);
            &:focus {
                outline: none;
            }
            &:hover {
                background-color: var(--color-grey);
                color: var(--color-white);
            }
        }
    }

    &--actions {
        border-color: var(--color-magenta-dark);

        .inner__container--header {
            h2 {color: var(--color-magenta-dark);}
            svg { fill: var(--color-magenta-dark);}
            border-bottom-color: var(--color-magenta);
        }
        .inner__container--footer {
            a { 
                color: var(--color-magenta-dark);
                background-color: var(--color-magenta-light);
                &:hover {
                    background-color: var(--color-magenta-dark);
                    color: var(--color-white);
                }
            }
        }
    }
    &--clear {
        
        .inner__container--header {
            h2 {color: var(--color-black);}
            svg { fill: var(--color-black);}
        }
        .inner__container--footer {
        }
    }
}
