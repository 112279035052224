.form__select {
    @include input__up;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: rems(16);
    height: rems(42);
    margin-bottom: rems(4);
    overflow: hidden;
    padding: rems(10) rems(36) rems(10) rems(8);
    min-width: rems(144);
    width: 100%;

    &:focus {
        background-color: white;
        border: rems(1) solid var(--color-form);
        box-shadow: $shadow__up, $shadow__outline;
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background: var(--color-grey-xlight);
        background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_disabled}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
        background-repeat: no-repeat;
        background-position: right 10px center;
        color: var(--color-text);
    }

    &.required {
        background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_required}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
        background-repeat: no-repeat;
        background-position: right 10px center;
        border-color: var(--color-magenta);
        box-shadow: $shadow__required;

        &:focus {
            background-color: white;
            background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
            border: rems(1) solid var(--color-form);
            box-shadow: $shadow__up, $shadow__outline;
            outline: none;
        }
    }

    &.read-only {
        background-color: var(--color-white);
        background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg_readonly}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
        border-color: var(--color-grey-xlight);
        box-shadow: none;
    }

    &.form__select--small {
        font-size: rems(14);
        padding: rems(6) rems(10);
        margin-bottom: rems(8);
        height: rems(34);
    }

    &.form__input--small {
        font-size: rems(14);
        padding: rems(6) rems(10);
        margin-bottom: rems(8);
        height: rems(35);
    }

    &.form__input--filter {
        margin-bottom: 0;
    }

    &.form__select--nomarginbottom{
        margin-bottom: 0;
    }
}

@include breakpoint(for-tablet-portrait-up){
    .form__select{
        &.form__input--width-quarter{
            max-width: 25%;
        }

        &.form__input--width-third{
            max-width: 33.333%;
        }

        &.form__input--width-half{
            max-width: 50%;
        }

        &.form__input--width-two-thirds{
            max-width: 66.666%;
        }

        &.form__input--width-three-quarters{
            max-width: 75%;
        }
    }
}


