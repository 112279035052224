.section__tabs {
    margin-top: rems(32);
    margin-bottom: rems(32);

    .tab-container {
        .tab-panes {
            background-color: var(--color-white);
            border: 1px solid var(--color-grey-light);
            border-radius: rems(5);

            .tab-pane {
                display: none;

                &.tab-widescreen {
                    padding: 9.5% 0;
                    background-color: var(--color-teal-light);
                    border-radius: radius(small);
                    outline: 1px solid var(--color-grey-xlight)
                }

                img {
                    display: block;
                    width: 100%;
                }

                .embed-container {
                    position: relative;
                    padding-bottom: 56.25%;

                    @media (min-width:72rems) {
                        padding-bottom: 57.25%;
                    }

                    height: 0;
                    overflow: hidden;
                    max-width: 100%;

                    iframe, object, embed {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .notes {
                    .notes__container {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        max-height: none;
                        min-height: 0;
                    }
                }
            }

            .active {
                display: block;
            }
        }

        .tabs {
            margin: 0;
            border: 0;

            .tab {
                display: inline-block;
                padding: rems(8);
                font-size: rems(15);
                /*font-weight: 800;*/
                color: var(--color-text);
                border: 1px solid var(--color-grey-light);
                border-top-left-radius: rems(5);
                border-top-right-radius: rems(5);
                border-top: 1px solid var(--color-grey-light);
                border-bottom: 0;
                position: relative;
                background-color: var(--color-grey-xxlight);
                margin: 0 rems(2) 0 0;
                text-decoration: none;

                .icon svg {
                    display: none;
                }

                &:focus {
                    outline: 0;
                }

                &:hover {
                    cursor: pointer;
                }

                &.has--icon {
                    padding-left: rems(26);
                }

                &.inactive {
                    color: var(--color-grey);
                }

                svg {
                    position: absolute;
                    top: 55%;
                    transform: translateY(-50%);
                    left: rems(5);
                    width: rems(15);
                    height: rems(15);
                    fill: var(--color-grey);
                }

                &.active {
                    background-color: var(--color-white);
                    border-bottom: 1px solid white;
                    margin-bottom: -1px;

                    &:hover {
                        cursor: default;
                    }

                    svg {
                        fill: var(--color-black);
                    }
                }
            }
        }

        .tab-panes {
            .tab-pane {
                padding: 10px;
            }
        }
    }

    &.inverted {
        margin-top: 0;
        margin-bottom: 0;

        .tabs {
            border-bottom: 0;
            margin-bottom: 0;
            .tab {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: rems(5);
                border-bottom-right-radius: rems(5);

                &.active {
                    border: 2px solid var(--color-cyan-dark);
                    border-top: 0;
                }
            }
        }

        .slick-dotted.slick-slider {
            margin-bottom: 0;
        }
    }

    &.main {
        margin-top: 0;
        margin-bottom: 1.25rem;

        .gallery {
            .slick-dots {
                display: none !important;
            }
        }
    }
}

maintenance-issue,.compact__table tr.details {
    .section__tabs {
        margin: 0;
        .tab-container {
            height: 100%;
            .tabs {
                border: 0;
                margin: 0;
                .tab {
                    font-size: rems(12);
                    padding: 0 rems(8);
                    padding-top: rems(4);
                    margin: 0;
                    border-top: rems(1) solid var(--color-grey-light);
                    border-bottom: 0;
                    background-color: var(--color-grey-xxlight);
                    height: rems(24);
                    &.active {
                        background-color: var(--color-white);
                        height: rems(25);
                    }
                    &.has--icon {
                        padding-left: rems(26);
                    }
                }
            }
        }
        .tab-panes {
            border: 0;
            margin-top: -1px;
            height: 100%;
            .tab-pane {
                border: 1px solid var(--color-grey-light);
                border-radius: radius(medium);
                padding: rems(6) rems(10);
                height: 100%;
                &.not-full-height {
                    height: auto;
                }
            }
        }

    }
}

body:not(.coho__dark) {
    action-card-progressions {
        .tab-pane.active {
            background-color: var(--color-white);
        }
    }
}

conversation-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
}

history-panel {
    .section__tabs {
        margin-top: 0;
        margin-bottom: 0;
        .tab-container {
            .tab-panes {
                .tab-pane {
                    padding: 0;
                    .notes {
                        .form {
                            background-color: var(--color-grey-xxlight);
                            border-bottom: rems(1) solid var(--color-grey-light);
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }
        }
    }
}

.card__content--right {
    .section__tabs {
        .tab-container {
            .tab-panes {
                .tab-pane {
                    .notes {
                        .form {
                            margin-top: rems(-6);
                            margin-left: rems(-10);
                            margin-right: rems(-10);
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            width: calc(100% + rems(20));
                            background-color: transparent;
                            border-bottom: rems(1) solid var(--color-grey-light);
                        }
                    }
                }
            }
        }
    }
}


@include breakpoint(for-large-phone-up) {

    .section__tabs {
        .tab-container {
            .tabs {
                .tab {
                    &.has--icon {
                        padding: rems(8) rems(8) rems(8) rems(26);
                    }
                    padding: rems(8);

                    .icon svg {
                        display: flex;
                    }
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {

    .section__tabs {
        .tab-container {
            .tabs {
                padding: 0 rems(8);

                .tab {
                    &.has--icon {
                        padding: rems(8) rems(12) rems(8) rems(26);
                    }
                    padding: rems(8) rems(12);
                    margin: 0 rems(2);
                }
            }
        }

        &.main {

            .gallery {
                .slick-dots {
                    display:block!important;
                    bottom: rems(10);

                    li {
                        margin: 0 2px;

                        button {
                            background-color: var(--color-grey-xlight);

                            &:hover {
                                background-color: var(--color-grey-light);
                            }
                        }

                        &.slick-active {
                            button {
                                background-color: var(--color-magenta);
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .section__tabs {
        &.main {
            margin-bottom: 0;
        }
    }
    history-panel, .card__content--right {
        .section__tabs {
            .tab-container {
                .tab-panes {
                    .tab-pane {
                        .notes {
                            .form {
                                .history__input-form--buttons {
                                    width: 190px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include breakpoint(for-tablet-landscape-up) {
    maintenance-issue,.compact__table tr.details {
        .section__tabs {
            margin: 0;
            height: calc(100% - rems(78));
            max-height: rems(735);
            .tab-panes {
                height: calc(100% - rems(25));
                .tab-pane {
                    .notes {
                        height: calc(100% - rems(50));
                        .notes__container {
                            display: flex;
                            flex-direction: column;
                            min-height: 0;
                            max-height: none;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .compact__table.compact__table--actions action-card tr.details  {
        .card__content--right {
            min-height: rems(480);
        }
        action-card-progressions .section__tabs {
            margin: 0;
            height: 100%;
            max-height: none;
            .tab-panes {
                height: auto;
                 .tab-pane {
                    min-height: rems(400);
                }
            }
        }
    }

.card__content--right {
    .section__tabs {
        .tab-container {
            .tab-panes {
                .tab-pane {
                    .notes {
                        .form {
                            .history__input-form--buttons {
                                width: 112px;
                                .hide-for-small {
                                    display: none !important;
                                }
                                .show-for-small {
                                    display: block !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
}

//DARK MODE

.coho__dark {
    .tab-panes {
        background-color: var(--color-black) !important;
        border: 1px solid var(--color-grey-light) !important;
    }

    .tabs {
        .tab {
            color: var(--color-white) !important;
            border: 1px solid var(--color-grey-light) !important;
            border-top: 1px solid var(--color-grey-light) !important;
            background-color: var(--color-black) !important;

            svg {
                fill: var(--color-white) !important;
            }
        }
        &.active {
            background-color: var(--color-black) !important;
        }
    }
}
