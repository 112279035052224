.button__group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rems(0);
    margin-left: rems(-5);
    margin-right: rems(-5);
    width: calc(100% + 0.625rem);
    align-items: center;
    row-gap: 10px;
}

.button__group-item{
    padding-left: rems(5);
    padding-right: rems(5);
    width: auto;
    &.hidden,&--hidden {
        display: none;
    }
    &--full {
        width: 100%;
    }
}

.button__group--centered{
    justify-content: center;
}

.button__group--right{
    justify-content: right;
}

.button__group--stacked {
    .button__group-item {
        margin-bottom: rems(8);
    }
}

.button__group--no-padding {
    .button__group-item {
        padding-top: 0 !important;
    }
}

.button__group--nomargin {
    margin-left: 0;
    margin-right: 0;
}

.button__group--spaced {
    .button__group-item {
        margin-right: rems(2);

        &:last-child {
            margin-right: 0;
        }
    }
}

.button__group--slim {
    margin-left: rems(-2);
    margin-right: rems(-2);
    width: calc(100% + 0.25rem);
    .button__group-item{
        padding-left: rems(2);
        padding-right: rems(2);
    }

}

.button__group--bottom {
    align-items: flex-end;
}

.button__group--nowrap {
    flex-wrap: nowrap;
}
.button__group--inline {
    width: max-content;
}
