.accordion {
    &.accordion--nav {
        border: 0;
        border-top: rems(2) solid var(--color-grey-border);
        box-shadow: none;
        border-radius: 0;
        background-color: var(--color-white);
        padding: 0;
        margin: 0;

        &#organisation {
            border-top: 0;
        }

        &#room {
            border-bottom: rems(2) solid var(--color-grey-border);
        }

        .accordion__title {
            border-bottom: none;

            .accordion__title-button {
                font-size: rems(18);
                background-color: transparent;
                border-radius: rems(0);
                font-weight: 800;
                padding: rems(16);

                span {
                    font-weight: 400;
                    font-size: rems(14);
                    color: var(--color-grey);
                }

                &:hover {
                    color: var(--color-text);
                }

                &:focus {
                    text-decoration: none;
                }

                &::after {
                    display: none;
                }
            }
        }

        .accordion__content {
            padding: rems(16);
            padding-bottom: rems(8);

            .u-padding-top--20 {
                padding-top: 0;
            }
        }

        &.closed {
            .accordion__title {
                .accordion__title-button {
                    &:hover {
                        color: var(--color-text);
                        background-color: var(--color-teal-xlight);
                    }
                }
            }
        }
    }

    + .accordion.accordion--panel {
        margin-top: 0;
    }
}
