.description__preview{
    h1, h2, h3, h4, h5, h6, p, ul, ol{
        font-size: rems(14);
        line-height: rems(24);
        margin-bottom: rems(14);
        margin-top: 0;
        padding-top: 0;
    }

    ul, ol{
        padding-left: 1rem;
        list-style: disc;

        li{
            display: list-item;
            list-style: disc;
            min-height: initial;
        }
    }
}

.property__description{
    
    h4{
        font-size: rems(18);
        line-height: rems(23);
    }

    h5{
        font-size: rems(16);
        line-height: rems(20);
    }
}