viewing-row {
    .card__header {
        &.card__header--actions {
            padding-right: 0;

            .card__action-items {
                min-width: rems(78);
                padding-right: 0;
            }

            .button__group-item {
                &:last-of-type {
                    padding-right: 0;
                }
            }

            .button.button--small.button--icon:not(.button--icon-only) {
                text-indent: rems(999);
                padding-left: rems(24);
                max-width: 0;
                min-width: 0;

                @include breakpoint(for-tablet-portrait-up) {
                    text-indent: 0;
                    padding-left: rems(32);
                    min-width: rems(80);
                    max-width: unset;
                }

                .icon {
                    text-indent: 0;
                }
            }
        }
    }
}
viewings, leads {
    .compact__table {
        tr {
            &.summary {
                td.tenant-status {
                    display: flex;

                    div {
                        line-height: 1.3;
                        padding: 0 rems(2);
                        height: rems(18);
                        width: rems(18);
                        border-radius: rems(10);
                        border: rems(1) solid var(--color-white);
                        box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.2);
                        //margin: rems(4) 0 0 0;

                        .icon, svg {
                            width: rems(12);
                            height: rems(12);
                        }


                        &.viewing--unplugged {
                            background-color: var(--color-grey-light);
                        }

                        &.viewing--invited {
                            background-color: var(--color-orange-dark);
                        }

                        &.viewing--joined {
                            background-color: var(--color-blue);
                        }
                    }
                }
            }
        }
    }
}

viewing-issue-page {
  .viewing_archived_banner{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    background-color: var(--color-grey);
    color: var(--color-white);
    border-radius: radius(medium);
  }
}

my-viewings-directory {
    display: flex;
    flex-direction: column;
    padding: rems(20);
}
