
.grid__layout{
    width: 100%;
}

.row{
    @if($dev == true){
        background-color: rgba(0,0,0,0.1);
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rems(0);
    margin-left: rems(-10);
    margin-right: rems(-10);
    width: calc(100% + 1.25rem);

    &.row--hidden {
        display: none;
    }

    &.dev{
        position: relative;

        &::after{
            background-color: rgba(0,0,0,0.1);
            bottom: 0;
            content: "";
            height: 1px;
            left: rems(10);
            right: rems(10);
            position: absolute;
            z-index: 10;
        }

        &::before{
            background-color: rgba(0,0,0,0.1);
            content: "";
            height: 1px;
            left: rems(10);
            right: rems(10);
            position: absolute;
            top: 0;
            z-index: 10;
        }
    }

}


.col {
    @if($dev == true) {
        position: relative;

        &::before {
            background-color: rgba(0,0,0,0.1);
            bottom: 0;
            content: "";
            left: rems(10);
            position: absolute;
            right: rems(10);
            top: 0;
            z-index: -10;
        }
    }

    &.dev {
        position: relative;

        &::after {
            background-color: rgba(0,0,0,0.1);
            bottom: 0;
            content: "";
            right: rems(10);
            position: absolute;
            top: 0;
            width: 1px;
            z-index: 10;
        }

        &::before {
            background-color: rgba(0,0,0,0.1);
            bottom: 0;
            content: "";
            left: rems(10);
            position: absolute;
            top: 0;
            width: 1px;
            z-index: 10;
        }
    }

    margin-bottom: 0;
    padding-left: rems(10);
    padding-right: rems(10);
    width: 100%;

    &.col-2 {
        width: 50%;
    }

    &.col-4 {
        width: 25%;
    }

    &.col__centered {
        margin-left: auto;
        margin-right: auto;
    }

    &.col--hidden {
        display: none;
    }
}

.hide-for-sm-down {
    display: none;
}

@include breakpoint(for-tablet-portrait-up) {
    .hide-for-sm-down {
        display: block;
    }

    .col {
        margin-bottom: rems(20);
    }
}

@mixin columns($columns: 12, $prefix: ""){
    // First loop through the start column
    @for $i from 1 through $columns{
    // Loop through the closing column
        .col.#{$prefix}-#{$i}{
            width: (math.div($i,$columns) * 100%);
        }
    }
}

@mixin columns_pre($columns: 12, $prefix: ""){
    // First loop through the start column
    .col.#{$prefix}pre--0{
        margin-left: 0;
    }

    @for $i from 1 through $columns{
    // Loop through the closing column
        .col.#{$prefix}pre--#{$i}{
            margin-left: (math.div($i,$columns) * 100%);
        }
    }
}
@include breakpoint(for-large-phone-up){
    @include columns(12, "col__xs-");
    @include columns_pre(12, "col__xs-");
}

@include breakpoint(for-large-phone-landscape-up){
    @include columns(12, "col__sm-");
    @include columns_pre(12, "col__sm-");
}

@include breakpoint(for-tablet-portrait-up){
    @include columns(12, "col__md-");
    @include columns_pre(12, "col__md-");

    .col{
        margin-bottom: rems(0);
    }
}

@include breakpoint(for-tablet-landscape-up){
    @include columns(12, "col__lg-");
    @include columns_pre(12, "col__lg-");

    // Column width override

    .col {
        &.col__or--8c {
            width: 14.28%;
        }
    }
}

// @include breakpoint(for-wide-desktop-up){
//     @include columns(12, "col__xl-");
//     @include columns_pre(12, "col__xl-");
// }



.row {
    &.row--space {
        .col {
            margin-bottom: rems(20);
        }
    }
    &.row--narrow {
        margin-left: rems(-4);
        margin-right: rems(-4);
        width: calc(100% + 0.5rem);
        .col {
            padding-left: rems(4);
            padding-right: rems(4);
            margin-bottom: rems(8);
        }
    }


    &.row--space-half {
        .col {
            margin-bottom: rems(10);
        }
    }

    &.row--divider {
        .col {

            border-bottom: rems(1) solid var(--color-grey-light);
            margin-bottom: rems(20);
            &:last-of-type {
                border-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    &.row--border {
        border-bottom: 1px solid var(--color-grey-light);
    }
}

// Center align Vertically

.col {
    &.col__align--v-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__right {
        justify-content: flex-end;
    }

    &.col__inline {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }
}

