.form_options_container {
    display: flex;
    flex-direction: column;
    margin-bottom: rems(8);

    .form_option-item {
        align-items: flex-start;
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        //overflow: hidden; -- commented it out. see why below
    }

    .form_option_name {
        font-size: rems(14);
        display: block;
        min-width: 0;
        //overflow: hidden; -- commented it out overwise the searchable-dropdown of organisation-supplier-selector-independent-crud.component is hidden
        text-overflow: ellipsis;
        width: 100%;

        input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
            padding: rems(6);
            font-size: rems(14);
            margin-bottom: 0;
        }
        .form__select.form__input--small {
            margin-bottom: rems(2);
        }
    }

    .form_option_actions {
        margin-top: rems(10);

        .button {
            margin-right: rems(5);

            + .button {
                margin-left: rems(0);
            }

            &:last-child {
                //margin-bottom: 0;
                margin-right: 0;
            }
        }
    }

    &.small {
        .form_options_container {
            .form_option_group {
                padding: rems(8);
            }
            .form_option_icon {
                margin-right: 0;
            }
        }
    }
}



@include breakpoint(for-large-phone-up) {
    .form_options_container {
        .form_option {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }


        .form_option-item {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .form_option_name {
            //display: flex;
            flex: 1 1 35%;
            margin-right: rems(16);
            .form__select.form__input--small {
                margin-bottom: 0;
            }
        }

        .form_option_actions {
            margin-top: rems(-5);
            margin-bottom: 0;

            .button__group-item {
                padding-left: rems(4);
                padding-right: rems(4);
                &:last-of-type {
                    padding-right: rems(5);
                }
            }

            .button {
                margin-bottom: 0;
            }
        }
        &.small {
            //margin-bottom: rems(8);
            .form_option_group {
                padding: rems(8);
            }
            .form_option_icon {
                margin-right: 0;
                img {
                    width: rems(36);
                    height: rems(36);
                }
            }
            .form_option_name {
                input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
                    margin-bottom: 0;
                }
            }
        }
    }

}




