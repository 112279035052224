.supplier__header{
    background-color: var(--color-violet-xdark);
    display: block;
    padding: rems(10) 0 rems(12) 0;
    width: 100%;

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;

        h4, p {
            color: var(--color-white);
        }

        .header__logo {
            padding: 0;
            margin: 0;
            width: rems(80);
        }

        .header__navigation {
            flex-basis: 30%;
            .button__back {
                color: var(--color-violet-light);
                &:focus {
                    outline: none;
                }
            }
        }
        .header__options {
            display: flex;
            flex: 0 0 70%;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;

            .dropdown {
                display: block;
                margin-top: 0;

                .header__options-button {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    border-radius: rems(4);

                    .profile__image {
                        height: 3rem;
                        width: 3rem;
                    }
                }

                .dropdown__menu,.feedback__menu {
                    border-top-right-radius: 0;
                }

                &.open {
                    .header__options-button {
                        background-color: var(--color-magenta);
                        z-index: 999;
                    }
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .supplier__header{
        .container {
            .header__logo {
                width: rems(116);
            }

        }

    }
}
