
/*.isApp {
    .footer {
        .social-links {
            display: none;
        }
    }
}*/


.footer {
    background-color: var(--color-grey-xlight);
    color: var(--color-black);
    padding-bottom: rems(50);
    padding-top: rems(50);

    .col {
        margin-bottom: 0;
    }

    p.copyright {
        padding: 0;
        margin: 0 0 rems(4) 0;
        color: var(--color-black);
        font-size: rems(12);
    }
}

.footer__links {
    font-size: rems(14);
    list-style: none;
    padding-left: 0;
    padding-right: 0;

    a {
        color: var(--color-black);
        text-decoration: none;

        &:hover {
            color: var(--color-black);
            text-decoration: underline;
        }
    }
}

.footer {
    ul.social-links {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: left;

        li {
            display: block;
            float: left;
            margin: 0 rems(8) 0 0;

            a {
                display: block;
                width: 30px;
                height: 30px;
                background-color: var(--color-black-light);
                border-radius: radius(medium);
                padding: rems(2);
                background-image: none !important;

                .icon, svg {
                    width: rems(26);
                    height: rems(26);
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.only-focus {
    display: none;
}

.coho__focus {
    .footer {
        padding-top: rems(30);
        padding-bottom: rems(30);

        .footer__links, .social-links {
        display: none;
        }
    }
    .only-focus {
        display: flex;
        //justify-content: flex-end;
        ul.focus-links {
            font-size: rems(12);
            list-style: none;
            padding-left: 0;
            padding-right: 0;

            li {
                display: inline;
                padding-left: rems(6);
                margin-left: rems(6);
                border-left: 1px solid var(--color-grey-border);
                &:first-of-type {
                    border-left: 0;
                    padding-left: 0;
                    margin-left: 0;
                }
            }
        
            a {
                color: var(--color-black);
                text-decoration: none;
        
                &:hover {
                    color: var(--color-black);
                    text-decoration: underline;
                }

                &:focus {
                    outline: 0;
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .coho__focus {
        .only-focus {
            justify-content: flex-end;
        }
    }
}