
.new {
    background: var(--color-grey-xxlight);
    border-radius: radius(medium);
    box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-violet-xdark-rgb),0.25);
    padding: rems(40) rems(10) rems(30) rems(10);
    position: relative;
    margin: rems(12) rems(20) rems(12) rems(20);

    &__upper {
        display: flex;
        flex-direction: column; 
        

        &--heading {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &--icon {
            svg {
                fill: var(--color-violet-dark);
                width: rems(36);
                height: rems(36);
            }
        }
        &--heading {
            padding-left: rems(10);
            h3 {
                font-size: rems(20);
                color: var(--color-violet-dark);
                line-height: 1;
            }
        }
        &--content {
            padding-left: rems(12);
            padding-right: rems(12);
            color: var(--color-violet-xdark);

            a {
                white-space: nowrap;
                font-weight: 800;
                color: var(--color-violet-xdark);
                &:focus { outline: none;}
                &:hover {
                    color: var(--color-violet-xdark);
                }
            }
        }
    }

    p {
        color: var(--color-violet-xdark);
        font-size: rems(15);
        font-weight: 400;
        line-height: 1.4;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .intro {
        position: absolute;
        left: rems(10);
        top: rems(4);
        text-transform: uppercase;
        color: var(--color-violet-dark);
        font-size: rems(12);
        font-weight: 400;
    }

    .dismiss {
        position: absolute;
        right: rems(10);
        bottom: rems(6);
        text-transform: uppercase;
        color: var(--color-violet-dark);
        font-size: rems(12);
        font-weight: 400;
        //padding-bottom: 0;
        //margin-bottom: rems(4);
        padding: rems(4);
        line-height: 1;
        strong {
            padding-left: rems(4);
            font-weight: 800;
        }
        &:hover {
            cursor: pointer;
            outline: 1px dotted var(--color-violet-xdark);
        }
        &:focus { outline: none;}
    }
}
