.coho__dark {
    .context-item--hidden-content {
        color: var(--color-grey-xlight);
    }


    .context-title .context-title--button {
        color: var(--color-grey-light);
    }

    .context-item--complete {

        .context-item {
            border-color: var(--color-green);
        }

        .context-title {
            .context-title--button {
                background-color: var(--color-green-xdark);
                color: var(--color-white);

                &:hover {
                    color: var(--color-green-light);
                }

                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23FFFFFF' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
                }
            }
        }

        .context-item--hidden-content {
            border-top: 1px solid var(--color-green);
        }

        &.collapsed {
            .context-title {
                .context-title--button {
                    &::after {
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23FFFFFF' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");
                    }
                }
            }
        }
    }

    .context-item--current {

        .context-item {
            border-color: var(--color-orange);
        }

        .context-title {
            .context-title--button {
                background-color: var(--color-orange-xdark);
                color: var(--color-white);

                &:hover {
                    color: var(--color-orange-light);
                }

                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23ffffff' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
                }
            }
        }

        .context-item--hidden-content {
            border-top: 1px solid var(--color-orange);
        }

        &.collapsed {
            .context-title {
                .context-title--button {
                    &::after {
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23ffffff' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");
                    }
                }
            }
        }
    }

    .context-item--overdue, .context-item--action {

        .context-item {
            border-color: var(--color-pink);
        }

        .context-title {
            .context-title--button {
                background-color: var(--color-pink-xdark);

                &:hover {
                    color: var(--color-pink-light);
                }

                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23ffffff' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
                }
            }
        }

        .context-item--hidden-content {
            border-top: 1px solid var(--color-pink);
        }

        &.collapsed {
            .context-title {
                .context-title--button {
                    &::after {
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23ffffff' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");
                    }
                }
            }
        }
    }

    .context-item--future .context-title .context-title--button {
    }

    .context-item--skip {
        .context-status {
            background-color: var(--color-grey-dark);
            border-color: var(--color-grey-dark);
        }

        .context-item {
            border-color: var(--color-grey-dark);
        }

        .context-title {
            .context-title--button {
                color: var(--color-grey-light);
            }
        }

    }
}
