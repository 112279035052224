profitability-reports, property-owner-settlement-summary {
    display: block;
    width: 100%;
}

.card--report {
    &.card--actions-right {
        align-content: flex-start;

        .card__action-content {
            display: flex;
            flex: 1 0 auto;
            flex-direction: row;

            .card__content {
                padding: rems(10) rems(15);
            }

            .card__actions {
                padding: rems(10) rems(15);
                margin: auto 0;
                width: auto;

                .button {
                    margin-bottom: 0;

                    &:hover {
                        background-color: var(--color-grey-xxlight);
                    }
                }
            }
        }
    }
}


.card--settlement {
    .card__header {
        padding: rems(10);
        border-bottom: none;
        &:hover {
            cursor: pointer;
        }
    }

    .card__content, .card__actions {
        padding: rems(10) rems(15);
    }

    .card__actions {
        padding-bottom: rems(5);
    }
    &.closed {
        .card__content,.card__actions {
            display:none;
        }
        height: auto;
    }
}


.card--inner {
    table {
        &.settlement__items {
            width: 100%;
            border-bottom: 1px solid rgba(3,3,3,0.2);

            tr {
                align-items: center;

                td {
                    color: var(--color-grey);
                    flex-basis: 66%;

                    .button--tiny {
                        margin-right: rems(10);
                    }

                    &.settlement__items-amount {
                        flex-basis: 33%;
                        text-align: end;

                        &.settlement__items-amount--negative {
                            color: var(--color-warning);
                        }
                    }

                    &:nth-child(1) {
                        flex-basis: rems(20);
                        min-width: 0;

                        .icon, .icon svg {
                            width: rems(16);
                            height: rems(14);
                            margin-top: rems(2);
                            fill: var(--color-grey);
                        }
                    }
                }

                &.expanded {
                    td:not(.settlement-party-financial-item) {
                        color: black;
                        font-weight: 700;

                        &.settlement__items-amount--negative {
                            color: var(--color-warning);
                        }
                    }
                }

                &.settlement-party-financial-item {
                    border-top-color: var(--color-grey-xlight);
                }
            }
        }
    }
}

financial-item-view {
    .card__intro {
        padding: 1.25rem 1.25rem 1rem 1rem;
    }

}