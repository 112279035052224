.accordion{
    &.accordion--form{
        border-top: rems(1) solid  var(--color-grey-xlight);
        box-shadow: none;
        border-radius: rems(0);
        background-color: transparent;
        &.closed {
            border-bottom: rems(1) solid  var(--color-grey-xlight);
        }

        .accordion__title{
            border-bottom: none;
            .accordion__title-button {
                font-size: 1rem;
                background-color: transparent;
            }

            .accordion__title-button{
                font-size: rems(16) !important;
                border-radius: rems(0);
                font-weight: 800;
                padding-left: rems(32);
                padding-right: rems(0) !important;

                &::after{
                    left: 0;
                    background-size: 24px 15px;
                    top: rems(16);
                }

            }
        }

        .accordion__content{
            padding: 0;
            .u-margin-top--20 {
                margin-top: 0;
            }
        }

        &-subtle {
            .accordion__title{
                .accordion__title-button{
                    font-weight: 400;
                    font-size: rems(14) !important;
                    padding-left: rems(24);
                    padding-top: rems(8);
                    padding-bottom: rems(8);
    
                    &::after{
                        top: rems(7);
                    }
    
                }
            }
            border-bottom: rems(1) solid  var(--color-grey-xlight);
        }

    }

    + .accordion.accordion--form {
        margin-top: 0;
    }
}

.page__section--alt{
    .accordion{
        &.accordion--form{
            background-color: transparent;
            border-top: 1px solid var(--color-grey-light);
            &.closed {
                border-bottom: rems(1) solid var(--color-grey-light);
            }

            .accordion__title, .accordion__title-button{
                background-color: transparent;
            }
        }
    }
}
