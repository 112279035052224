.account__logo {
    display: block;
    margin-bottom: rems(30);
    width: rems(166);

    img {
        height: auto;
        width: 100%;
    }
}
.account__page {
    background-color: var(--color-teal);
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.account__page ng-component {
    background-color: var(--color-teal);
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.account__full {
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30%;
}

.account__left {
    background-color: white;
}

.account__right {
    background-color: var(--color-teal);
    background-position: center;
    background-size: cover;
    display: flex;
    flex: 1 0 auto;
    h2 {
        &.heading--1 {
            font-size: rems(48);
            font-weight: 400;
            line-height: 1.1;
        }
    }
    p {
        font-size: rems(24);
        line-height: 1.3;
    }
    ul {
        padding-left: rems(18);
    }
    li {
        font-size: rems(18);
    }
}

.account__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: rems(560);
    overflow: auto;
    padding: rems(20);
    width: 100%;

    .form {
        padding-left: 0;
        padding-right: 0;
    }


}

.account__right {
    .account__content {
        color: white;
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .account__page {
        flex-direction: row;
    }

    .account__page ng-component {
        flex-direction: row-reverse;
    }

    .account__left {
        width: 62.5%;

        .account__content {
            padding-left: rems(50);
            padding-right: rems(50);
            margin-left: 0;
        }
    }

    .account__right {
        width: 37.5%;

        .account__content {
            max-width: rems(800);
            padding: 0 rems(50);
        }
    }
}
