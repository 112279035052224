.form__grid{
    &.dev{
        .form__grid-element{
            position: relative;

            &::after{
                background-color: rgba(255,0,0,0.1);
                bottom: 0;
                content: "";
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }

            &:nth-child(odd){
                &::after{
                    background-color: rgba(255,0,0,0.05);
                }
            }

        }
    }
}

.form__grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rems(0);
    margin-left: rems(-5);
    margin-right: rems(-5);
    width: calc(100% + 0.625rem);
    &--inline {
        align-items: center;
    }
    & > .form__label {
        padding-left: rems(5);
    }
}

.form__slimmer {
    .form__grid{
        margin-left: 0;
        margin-right: 0;
    }
}

.form__grid-element {
    flex: 1 0 auto;
    flex-direction: column;
    min-width: auto;
    padding: 0 rems(5);
    width: 100%;

    &.form__grid--info-inline {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: rems(2);

        .form__label {
            margin-bottom: 0;
            width: unset;
        }

        info-button {
            display: inline-block;
            .button {
                margin-bottom: rems(8);
            }
        }
    }
}

.form__grid-2{
    .form__grid-element{
        flex-basis: 50%;
        max-width: 50%;
        width: 50%;            
    }
}

.form__grid-3{            
    .form__grid-element{
        flex-basis: 33.33%;
        max-width: 33.33%;
        width: 33.33%;
    }
}

.form__grid-4{            
    .form__grid-element{
        flex-basis: 25%;
        max-width: 25%;
        width: 25%;
    }
}

.form__grid-5{            
    .form__grid-element{
        flex-basis: 20%;
        max-width: 20%;
        width: 20%;
    }
}


.form__grid-8{
    .form__grid-element{
        flex-basis: 16.6667%;
        max-width: 16.6667%;
        width: 16.6667%;
    }
}

.form__grid-10{
    .form__grid-element{
        flex-basis: 10%;
        max-width: 10%;
        width: 10%;
    }
}

@include breakpoint(for-large-phone-landscape-up){
    .form__grid-2--small{
        .form__grid-element{
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;       
        }
    }

    .form__grid-3--small{
        .form__grid-element{
            flex-basis: 33.33%;
            max-width: 33.33%;
            width: 33.33%;
        }
        .form__grid-element--double{
            flex-basis: 66.66%;
            max-width: 66.66%;
            width: 66.66%;
        }
    }

    .form__grid-4--small{
        .form__grid-element{
            flex-basis: 25%;
            max-width: 25%;
            width: 25%;
        }
        .form__grid-element--double{
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;    
        }
        .form__grid-element--triple{
            flex-basis: 66.66%;
            max-width: 66.66%;
            width: 66.66%;
        }

    }

    .form__grid-5--small{
        .form__grid-element{
            flex-basis: 20%;
            max-width: 20%;
            width: 20%;
        }
        .form__grid-element--double{
            flex-basis: 40%;
            max-width: 40%;
            width: 40%;    
        }
        .form__grid-element--triple{
            flex-basis: 60%;
            max-width: 60%;
            width: 60%;
        }

    }

    .form__grid-8{
        .form__grid-element{
            flex-basis: 12.5%;
            max-width: 12.5%;
            width: 12.5%;
        }
    }
    .form__grid-10{
        .form__grid-element{
            flex-basis: 10%;
            max-width: 10%;
            width: 10%;
        }
    }
}


@include breakpoint(for-tablet-portrait-up) {

    .form__grid-2--medium {
        .form__grid-element {
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;   
        }
    }

    .form__grid-3--medium {
        .form__grid-element {
            flex-basis: 33.33%;
            max-width: 33.33%;
            width: 33.33%;

            &--double {
                flex-basis: 66.66%;
                max-width: 66.66%;
                width: 66.66%;
            }
        }
    }

    .form__grid-4--medium {
        .form__grid-element {
            flex-basis: 25%;
            max-width: 25%;
            width: 25%;

            &--double {
                flex-basis: 50%;
                max-width: 50%;
                width: 50%;   
            }

          &--triple {
            flex-basis: 75%;
            max-width: 75%;
            width: 75%;
          }
        }
    }
    
    .form__grid-5--medium {
        .form__grid-element {
            flex-basis: 20%;
            max-width: 20%;
            width: 20%;

            &--double {
                flex-basis: 40%;
                max-width: 40%;
                width: 40%;   
            }
        }
    }    

    .form__grid-10--medium {
        .form__grid-element {
            flex-basis: 10%;
            max-width: 10%;
            width: 10%;

            &--double {
                flex-basis: 20%;
                max-width: 20%;
                width: 20%;   
            }
        }
    }

    .form__grid--special {
        &.form__grid-4--medium {
            .form__grid-element {
                max-width: none;
                width: auto;
                display: block;
                flex-basis: auto;
                flex-grow: unset;
                padding-right: rems(25);
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .form__grid-3--large {            
        .form__grid-element{
            flex-basis: 33.33%;
            max-width: 33.33%;
            width: 33.33%;
        }
    }
}


// CSS Columns

.form__columns{
    column-gap: rems(20);
}

.form__columns-2{
    columns: 2;
}

.form__columns-3{
    columns: 3;
}

.form__columns-4{
    columns: 4;
}


@include breakpoint(for-large-phone-landscape-up){
    .form__columns-2--small{
        columns: 2;
    }

    .form__columns-3--small{
        columns: 3;
    }

    .form__columns-4--small{
        columns: 4;
    }
}

@include breakpoint(for-tablet-portrait-up){
    .form__columns-2--medium{
        columns: 2;
    }

    .form__columns-3--medium{
        columns: 3;
    }

    .form__columns-4--medium{
        columns: 4;
    }
}


