.form__label-subtext {
    color: var(--color-grey);
    font-weight: 400;
    padding-left: 0.25rem;
    font-size: rems(14);
}


.form__label{
    display: block;
    font-size: rems(16);
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: rems(4);
    padding-top: rems(4);
    width: fit-content;

    + .form__label-subtext{
        margin-top: rems(-10);
    }

    &--regular{
        font-weight: 400;
    }


    &--with-icon {
        display: flex;
        align-items: center;
        .button {
            margin-left: rems(8) !important;
        }
    }

    &--padded {
        padding-bottom: rems(7);
    }

    &--limit {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &--wrap {
        white-space: normal;
    }

}

.form__label--large{
    font-size: rems(24);
    line-height: rems(30);
    margin-bottom: rems(20);

    + .form__label-subtext{
        margin-top: rems(-20);
    }

}

.form__label--medium{
    font-size: rems(18);
    line-height: rems(30);
    margin-bottom: rems(20);

    + .form__label-subtext{
        margin-top: rems(-20);
    }

    &.form__label--medium-nomargin {
        margin-bottom: 0;
    }

}

.form__label--small{
    font-size: rems(14);
    line-height: rems(20);
    margin-bottom: rems(10);

    + .form__label-subtext{
        margin-top: rems(-10);
    }
}

.form__label--tiny{
    font-size: rems(12);
    line-height: rems(14);
    margin-bottom: rems(4);

    + .form__label-subtext{
        margin-top: rems(-10);
    }

}

h4 {
    &.form__label-title {
        font-size: rems(16);
        line-height: 1.4;
        margin-bottom: 0;
        margin-top: rems(10);
        padding-bottom: rems(5);
        width: 100%;
        border-bottom: 1px solid var(--color-grey-light);

        + * {
            margin-top: rems(8);
        }

        + checkbox {
            label {
                margin-top: rems(10);
            }
        }

        + label.form__checkbox {
            margin-top: rems(10);
        }

        + file-uploader {
            > div {
                margin-top: rems(10);
            }
        }
    }
}
