.marketing__page{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.marketing__email-form {
  background-color: #fafafa;
  border: 0.0625rem solid #e9e9e9;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.75rem -0.25rem rgba(0, 0, 0, 0.3);
  padding: 1.25rem;

  form {
    .col {
      margin-bottom: 0;
    }
  }
}