.card__confirmation {
    background-color: var(--color-green-dark);
    &.card__confirmation--error {
        background-color: var(--color-warning-dark);
    }
    color: white;
    .card__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0;
        .card__icon {
            padding-top: rems(8);
            padding-left: 0;
            max-width: rems(48);
            overflow: visible;
            margin-right: rems(8);
        }
        .card__title {
        }
    }    
}