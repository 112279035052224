.tip__trigger {
   &:focus {
       outline: 0;
   }
}

.tip {
    display: none;
    padding: rems(8) rems(12);
    margin-bottom: rems(16);
    background-color: var(--color-brown-light);
    border-radius: radius(medium);

    p {
        font-size: rems(14);
        margin-bottom: rems(10);
        &:last-child {
            margin-bottom: 0;
        }
    }


    &.tip__show {
        display: block;
    }
}
