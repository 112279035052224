
.report {
    &.report__agent-income {

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        padding-bottom: rems(4);
                        font-weight: 700;

                        &.col1,&.col2, &.col3, &.col4  {
                            text-align: left;
                        }
                        &.col2 {
                            padding-left: rems(4);
                        }
                        &.col3, &.col4  {
                            span {display: none;}
                        }
                        &.col5,&.col6,&.col7 {
                            text-align: right;
                            padding-left: rems(4);
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: top;
                        &.col1,&.col2, &.col3, &.col4  {
                            text-align: left;
                        }
                        &.col1 {
                            width: 12%;
                        }
                        &.col2 {
                            //width: 100%;
                            padding-left: rems(4);
                            span {
                                color: var(--color-grey);
                            }
                        }
                        &.col3 {
                            //width: 15%;
                        }
                        &.col4 {
                           //width: 15%;
                        }
                        &.col3, &.col4  {
                            span { display: none; }
                        }
                        &.col5,&.col6,&.col7 {
                            width: 8%;
                            text-align: right;
                            padding-left: rems(4);
                        }
                    }

                    &:last-child {
                        td {
                            padding-bottom: rems(4);
                        }
                    }
                    &.expense {
                        td {
                            &.col5,&.col6,&.col7 {
                                color: red;
                            }
                        }
                    }
                }
            }

            tfoot {
                tr {

                    th {
                        padding-top: rems(4);
                        padding-bottom: rems(12);

                        &.col1,&.col2, &.col3, &.col4  {
                            text-align: left;
                        }
                        &.col1 {
                            //width: 12%; 
                        }
                        &.col2 {
                            padding-left: rems(4);
                        }
                        &.col3, &.col4  {
                            //display: none;
                        }
                        &.col5,&.col6,&.col7 {
                            text-align: right;
                            padding-left: rems(4);
                        }

                    }


                    &.report__main-total {
                        border-top: 1px solid var(--color-black);
                        border-bottom: 1px solid var(--color-black);
                        th {
                            padding-top: rems(12);
                            font-weight: 800;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 740px) {
    .report {
        &.report__agent-income {

            table {

                thead {
                    tr {
                        th {

                            &.col3, &.col4 {
                                span { display: block; }
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            &.col2 {
                                width: 20%;

                                span {
                                    display: none;
                                }
                            }

                            &.col3, &.col4 {
                                display: table-cell;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            &.col3, &.col4 {
                                span {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
