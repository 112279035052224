.image__add {
    background-image: url(https://d19qeljo1i8r7y.cloudfront.net/images/placeholder/property-add-image.png);
    background-size: contain;
    display: block;

    .image__size {

        img {
            opacity: 0;
        }
    }
    &:focus {outline: 0;}
}

.section__header-org-title {
    .image__add {
        background-image: url(https://d19qeljo1i8r7y.cloudfront.net/images/placeholder/organisation-add-image.png);
    }
}

.room__heading {
    .image__add {
        background-image: url(https://d19qeljo1i8r7y.cloudfront.net/images/placeholder/room-add-image.png);
    }
}

@include breakpoint(for-small-desktop-up) {

    .image__add {
        .image__size {

            img {
                opacity: 1;
            }
        }

        &:hover {
            .image__size {
                img {
                    opacity: 0;
                }
            }
        }
    }
}
