fieldset{
    border: none;
    border-bottom: 1px solid var(--color-grey-light);
    display: block;
    margin: 0;
    margin-bottom: rems(10);
    padding: 0 0 rems(10) 0;

    &.fieldset--last{
        border-bottom: none;
    }

    &fieldset {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    h4 + label {
        margin-top: rems(20);
    }

}

// .form{
//     fieldset{
//         &:last-child{
//             border-bottom: none;
//         }
//     }
// }
