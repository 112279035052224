.view__detail {
    .display--settings .display--settings-icon.view__compact-icon,.display--settings .display--settings-icon.view__list-icon {
        .icon {
            fill: var(--color-grey);
            opacity: 0.3;
        }

        &:hover {
            .icon {
                opacity: 0.5;
            }
        }
    }

    .page__section-content--compact,.page__section-content--list {
        display: none;
    }

    .page__section-content--compact#buildings {
        display: flex;
    }


}

.view__compact {
    .display--settings .display--settings-icon.view__detail-icon,.display--settings .display--settings-icon.view__list-icon {

        .icon {
            fill: var(--color-grey);
            opacity: 0.3;
        }

        &:hover {
            .icon {
                opacity: 0.5;
            }
        }
    }

    .page__section-content--detail,.page__section-content--list {
        display: none;
    }
}

.view__list {
    .display--settings .display--settings-icon.view__detail-icon,.display--settings .display--settings-icon.view__compact-icon {

        .icon {
            fill: var(--color-grey);
            opacity: 0.3;
        }

        &:hover {
            .icon {
                opacity: 0.5;
            }
        }
    }

    .page__section-content--detail,.page__section-content--compact {
        display: none;
    }
}

.view__detail-icon, .view__compact-icon, .view__list-icon {
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: 0;
    }
}

.view__detail-icon {
    border-left: 1px solid var(--color-grey-light);
    margin-left: rems(8);
    padding-left: rems(8);
    padding-top: rems(2);
}
.view__compact-icon {
    //border-left: 1px solid var(--color-grey-light);
    //margin-left: rems(6);
    //padding-left: rems(8);
    padding-top: rems(2);
}
.view__detail-icon, .view__compact-icon {
    .icon-card,.icon-list {
        height: rems(28);
        width: rems(28);
        svg {
            height: rems(28);
            width: rems(28);
        }
    }
}


maintenance-issues {
    display: block;
    padding-top: rems(10);
}

.compact__table, .rent__schedule {
    width: 100%;
    display: flex;
    flex-direction: column;

    thead {
        display: none;
    }

    tbody {
        width: 100%;
    }

    tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: rems(4) 0;
        padding: rems(8);
        padding-bottom: rems(12);
        border: 0.5px solid rgba(3,3,3,0.2);
        border-radius: radius(small);

        td, th {
            flex: 1 1 100%;
            text-align: left;
            font-size: rems(14);
            width: 100%;

            span {
                &.label {
                    font-weight: bold;
                    display: block;
                }
            }

            a {
                &:focus {
                    outline: 0;
                }
            }
        }

        &.hidden {
            display: none;
        }

        &.match{
          &--perfect{
            background: var(--color-green-light);
          }

          &--multiple-emails{
            background: var(--color-primary-light-3);
          }

          &--weak{
          }
        }

        &.missing_gc_mandate_or_customer {
            background: var(--color-orange-light);
            td,th {
                &:last-of-type {
                    padding-right: rems(8) !important;
                }
            }
            td {
                &:first-of-type {
                    padding-left: rems(4) !important;
                }
            }
        }

        &.mandate_id_match_subscription {
            background: var(--color-pink-light);
            td,th {
                &:last-of-type {
                    padding-right: rems(8) !important;
                }
            }
            td {
                &:first-of-type {
                    padding-left: rems(4) !important;
                }
            }
        }

        &.summary {
            margin-bottom: 0;

            td {
                &.actions {
                    &.hidden {
                        padding: 0;

                        a {
                            display: none;
                        }
                    }
                }

                &.action-actions {
                    .u-margin-left--10 {
                        margin-left: 0;
                    }
                }

                &.title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .context__list-actions.context__list-actions-single {
                    display: inline;

                    ul {
                        display: inline-block;
                        margin: 0;
                        margin-left: rems(2);
                        width: auto;

                        li {
                            text-transform: capitalize;
                            margin: 0;
                            padding: rems(2) rems(6);
                            min-width: rems(76);
                        }
                    }
                }
            }

            &.open {
                border-bottom: 0;

                td {
                    display: none;

                    &.date, &.property {
                        display: table-cell;
                    }
                }
            }

            &.assigned {
                background-color: var(--color-teal-xlight);

                td {
                    &:not(.actions) {
                        color: var(--color-teal-xxdark);
                    }
                }
            }

            &.overdue {
                background-color: var(--color-magenta-light);
                td:not(.actions, .action-assigned) {
                    color: var(--color-magenta-xdark);
                    font-weight: 800;
                }
            }

            &.assigned.overdue {
                background-color: var(--color-teal-xlight);
            }

            &.snoozed {
                background-color: var(--color-grey-xlight);

                td {
                    &:not(.actions) {
                        color: var(--color-grey-dark);
                    }
                }
            }

            &.disabled {
                td {
                    &:not(.actions) {
                        color: var(--color-grey);
                    }
                }
            }

            &.excluded {
                background-color: var(--color-grey-xlight);
            }

        }

        &.details {
            margin-top: 0;

            td {
                .card__header {
                    padding: rems(4) 0 rems(8) 0;
                }

                .card__content {
                    display: flex;
                    flex-direction: column;
                    padding: rems(16) 0;

                    &--left {
                        padding-bottom: rems(20);

                        .context__list-actions {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 0;

                            .context__list-actions-group {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: rems(8) 0;

                                p {
                                    margin: 0;
                                    padding: 0;
                                    border: 0;
                                    text-align: left;
                                    min-width: rems(60);
                                }

                                ul {
                                    margin-top: 0;
                                    display: block;

                                    li {
                                        display: inline-block;
                                        margin-bottom: 0;
                                    }
                                }

                                &:last-of-type {
                                    margin-bottom: 0;
                                    border: 0;
                                }
                            }
                        }

                        viewing-view {
                            form-message {
                                display: block;
                                margin-top: rems(4);

                                &:first-of-type {
                                    margin-top: 0;
                                }
                            }

                            accordion {
                                display: block;
                                margin-bottom: rems(20);
                            }


                            lead-view {
                                display: block;
                                padding-bottom: rems(20);
                            }
                        }

                        lead-view {
                            display: flex;
                            flex-direction: column;

                            .lead__message--container {
                                margin-top: 0;
                                max-height: rems(160);
                                overflow: hidden;
                                overflow-y: scroll;
                                width: 100%;
                                padding-right: rems(20);

                                &::-webkit-scrollbar {
                                    width: rems(12);
                                }

                                &::-webkit-scrollbar-track {
                                    background: white;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: var(--color-grey-dark);
                                    border-radius: rems(20);
                                    border: rems(3) solid white;
                                }

                                .lead__message {
                                    margin-top: rems(8);

                                    p.u-text--small {
                                        margin-bottom: 0;
                                    }

                                    expand-text {
                                        p {
                                            font-size: rems(14);
                                        }
                                    }
                                }
                            }

                            .button__group {
                                flex: 0 1 100%;
                                align-items: flex-end;
                            }
                        }
                    }

                    &--right {
                        padding-top: rems(8);
                        border-top: 1px solid var(--color-grey-xlight);
                    }
                }

                .card__actions {
                    padding: 0;
                }

                .card--inner {
                    .card__header, .card__content {
                        padding: rems(10) rems(14);
                    }
                }
            }
        }

        &.child {
            background-color: var(--color-grey-xlight);
            td.action-title {
                svg { display:none; }
            }
        }
    }
    /*    .button {
        margin: 0;
    }*/

    &.compact__table--narrow {
        tr {
            td {
                .context__list-actions.context__list-actions-single {
                    display: inline;
                    &.with-margin {
                        margin-left: rems(2);
                    }
                    ul {
                        display: inline-block;
                        margin: 0;
                        margin-left: rems(-2);
                        width: auto;

                        li {
                            text-transform: capitalize;
                            margin: 0;
                            padding: rems(2) rems(6);
                            min-width: rems(64);
                        }
                    }
                }
                .context__list-actions.context__list-actions-single.context__list-actions-assign {
                    ul {
                        li {
                          min-width: rems(30);
                          &.assigned {
                              border-color: var(--color-grey-xlight);
                              background-color: var(--color-grey-xxlight);
                              border-radius: radius(medium);
                              color: var(--color-grey-dark);
                              display: flex;
                              gap: 5px;
                              align-items: center;

                              img {
                                  height: 25px;
                                  width: 25px;
                                  border-radius: 50%;
                                  object-fit: cover;
                              }
                          }
                          &.assigned:hover {
                            border-color: var(--color-grey-border);
                          }
                          &.self {
                            border-color: transparent;
                            background-color: var(--color-cyan-light);
                            border-radius: radius(medium);
                            color: var(--color-cyan-xdark);
                            justify-content: center;
                            display: flex;
                            gap: 5px;
                            align-items: center;

                            img {
                              height: 25px;
                              width: 25px;
                              border-radius: 50%;
                              object-fit: cover;
                            }
                          }

                          &.self:hover {
                            border-color: var(--color-cyan);
                          }
                        }
                    }
                }
            }
        }
    }
}

.rent__schedule {
    border-bottom: rems(1) solid rgba(var(--color-black-rgb),0.2);
    //max-width: rems(320);
    margin-bottom: 0;
    thead {
        display: table-cell;
        tr {
            border-top: 0;
        }
    }
    tr {
        border: 0;
        flex-wrap: nowrap;
        border-top: rems(1) solid rgba(var(--color-black-rgb),0.2);
        margin: 0;
        padding: rems(2) 0;


        &.details:hover {
            background-color: transparent;
        }

        th, td {

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }

            border: 0;

            &.amount {
                text-align: right;
            }
        }
    }

}

property-summary {
    .compact__table {
        tr {
            &.summary {
                td {
                    .context__list-actions {
                        &.context__list-actions-single {
                            &.context__list-actions-wider {
                                    ul {
                                        li {
                                            min-width: rems(100);
                                        }
                                    }
                            }
                        }
                    }
                }
            }
        }
    }
}


.compact__table--mobile {
    &.compact__table.compact__table--narrow {
        border-bottom: 1px solid rgba(3,3,3,0.2);
        thead {
            display: table-cell;

            tr {
                margin-bottom: 0;
                border-top: 0;
            }
        }

        tr {
            border: 0;
            flex-wrap: nowrap;
            border-top: rems(1) solid rgba(var(--color-black-rgb),0.1);
            margin: 0;
            padding: rems(2) 0;

            &.details:hover {
                background-color: transparent;
            }

            th, td {
                display: none;

                &.mobile {
                    display: table-cell
                }

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    padding-right: 0;
                }

                flex: 1 1 194px;
                border: 0;

                span.label {
                    display: none;
                }

                &.property {
                    flex: 1 1 350px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.actions {
                    flex: 1 1 150px;
                    text-align: right;

                    a:focus {
                        outline: 0;
                    }

                    span {
                        &.badge {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.compact__table {

    tr {

        &.details {

            td {

                .card__header {
                    &.card__header--category {
                        padding: rems(4) 0;

                        .card__header--category-wrapper {
                            //max-width: 63%;

                            p {
                                margin: 0;
                                margin-bottom: rems(2);
                                font-size: rems(12);
                                color: var(--color-grey-border);
                                line-height: 1;
                            }

                            h4 {
                                font-weight: 400;
                                font-size: rems(22);
                                max-width: none;
                                line-height: 1.2;
                            }

                            .button__menu {
                                p {
                                    margin-bottom: 0;
                                    padding: rems(4) 0 rems(4) rems(10);
                                    font-size: rems(14);
                                    font-weight: 400;
                                    color: var(--color-black);
                                    line-height: 1.5rem;
                                    &:hover {
                                        cursor: pointer;
                                        background: var(--color-teal-xlight);
                                    }
                                }
                            }
                        }
                    }


                    &.card__header--actions {
                        .card__action-items {
                            padding: rems(6) rems(4);
                            padding-left: 0;
                            //min-width: rems(82);
                        }
                    }
                }

                .card__content {
                    .card__content--right {
                        min-height: rems(330);
                        &.card__content--right-half {
                            h4 {
                                padding-bottom: rems(12);
                            }
                            .form__grid-element--double {
                                date-time-picker {
                                    flex-direction: row;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

maintenance-issues {
    .compact__table {
        tr {
            &.details {
                td {
                    .card__content {
                        .card__content--right {
                            min-height: rems(530);
                        }
                    }
                }
            }
        }
    }
}


@include breakpoint(for-large-phone-up) {
    .compact__table {
        tr {
            td, th {
                span {
                    &.label {
                        padding-right: 8px;
                        display: inline-block;
                        width: 35%;
                    }
                }

                div {
                    &.forced-container {
                        display: inline-block;
                        width: 58%;
                    }
                    &.forced-line {
                        line-height: 1.3;
                    }

                }

                &.title {
                    -webkit-line-clamp: 1;
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .compact__table {
        .card__content {
            .context__list-actions {
                flex-direction: row;

                .context__list-actions-group {
                    p {
                        min-width: 0;
                        padding-right: rems(16);
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .compact__table td span.label {
        width: 25%;
    }

    .compact__table.compact__table--narrow {
        border-bottom: 1px solid var(--color-grey-light);
        border-spacing: 0;

        thead {
            display: table-cell;

            tr {
                margin-bottom: 0;
                border-top: 0;
                &:hover{
                    background-color: transparent;
                }

                th.sortable {
                    cursor: pointer;

                    .icon {
                        padding-left: 2px;
                    }

                    .icon, svg {
                        width: rems(10);
                        height: rems(10);
                    }
                }
            }
        }

        tr {
            border: 0;
            flex-wrap: nowrap;
            border-top: rems(1) solid rgba(var(--color-black-rgb),0.1);
            margin: 0;
            padding: rems(2) 0;
            border-radius: 0;

            &.details:hover {
                background-color: transparent;
            }


            th, td {

                div {
                    &.forced-container {
                        display: block;
                        width: auto;
                    }
                    &.forced-line {
                        line-height: unset;
                    }

                }

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    padding-right: 0;
                }

                flex: 1 1 194px;
                border: 0;
                line-height: 1.3;

                span.label {
                    display: none;
                }

                &.options {
                    display: flex;
                    flex: 1 1 160px;
                    min-width: rems(90);
                }
                &.date {
                    flex: 1 1 180px;
                }
                &.rent-date {
                    flex: 1 1 100px;
                }

                &.short-date {
                    flex: 0 1 100px;
                }
                &.due-date {
                    flex: 1 1 140px;
                }
                &.tenant {
                    flex: 1 1 400px;
                    &.padded {
                        padding-left: rems(16);
                    }
                }
                &.date-wide {
                    flex: 1 1 220px;
                }
                &.date-wider {
                    flex: 1 1 280px;
                }

                &.when {
                    flex: 1 1 330px;
                }
                &.status {
                    flex: 1 1 150px;
                    &-wide {
                        flex: 1 1 500px;
                        margin-right: 2px;
                    }
                    &-short {
                        flex: 0 1 85px;
                    }
                    &-125 {
                        flex: 0 1 125px;
                    }
                    &-wider {
                        flex: 0 1 200px;
                    }
                    &-higher {
                        margin-left: 1px;
                        max-height: rems(24);
                    }
                }
                &.rent-status, .action-status {
                    flex: 1 1 130px;
                }

                &.location {
                    flex: 1 1 40%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.property {
                    flex: 0 1 350px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    &-short {
                        flex: 0 1 300px;
                    }
                    &-wide {
                        flex: 0 1 400px;
                    }
                    &-widest {
                        flex: 0 1 500px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
                &.restrict-height {
                    max-height: rems(22);
                }
                &.restrict-width {
                    max-width: rems(350);
                }
                &.tenancy {
                    flex: 1 1 500px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.room-property {
                    flex: 1 1 600px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.email {
                    flex: 1 1 300px;
                    &-wide {
                        flex: 1 1 500px;
                    }
                }

                &.activity {
                    flex: 1 1 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    &.padded {
                        padding-left: rems(10);
                    }
                }
                &.account {
                    flex: 1 1 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.bank, &.supplier {
                    flex: 1 1 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }


                &.reference {
                    flex: 1 1 500px;
                }

                &.item-type {
                    flex: 1 1 500px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.payee {
                    flex: 1 1 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.property-room {
                    flex: 1 1 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.tenancy-status {
                    flex: 1 1 100%;
                }

                &.tenant-status {
                    flex: 0 0 24px;
                    min-width: 0;
                }

                &.property-availability {
                    flex: 1 1 60%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.property-room-step, &.step {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    height: fit-content;
                }

                &.description {
                    flex: 1 1 60%;
                    &-padded {
                        padding-right: rems(12);
                    }
                }

                &.property-room-step {
                    flex: 1 1 40%;
                }

                &.step {
                    flex: 1 1 50%;
                }

                &.name, &.room-name, &.tag {
                    flex: 1 1 200px;

                    &.wide {
                        flex: 1 1 280px;
                    }

                    &.widest {
                        flex: 1 1 440px;
                    }

                    &.narrow {
                        flex: 1 1 100px;
                    }

                    pre {
                        margin: 5px 0;
                    }
                }

                &.tenancy-status {
                    flex: 1 1 300px;
                }

                &.specialism {
                    flex: 1 1 240px;
                }

                &.action-assigned {
                    flex: 0 1 95px;
                }
                &.action-auto {
                    flex: 0 1 75px;
                    text-align: center;
                }

                &.action-property {
                    flex: 0 1 240px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    height: fit-content;
                    max-height: rems(28);
                }

                &.action-room {
                    flex: 0 1 240px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    height: fit-content;
                    max-height: rems(28);
                }

                &.action-title {
                    flex: 1 1 480px;
                }

                &.action-desc {
                    flex: 1 1 500px;
                }

                &.action-stepqty {
                    flex: 0 1 75px;
                    text-align: right;
                    padding-right: rems(8);
                }

                &.room-status {
                    flex: 1 1 350px;
                }

                &.amount, .rent-amount {
                    flex: 1 1 100px;
                    &.wider {
                        flex: 1 1 180px;
                    }
                }

                &.tenancy-rent, &.tenant {
                    flex: 1 1 200px;
                }

                &.amount-desc {
                    flex: 1 1 220px;
                }

                &.tenant {
                    flex: 1 1 250px;

                    &.joined {
                        font-weight: 700;
                        color: var(--color-blue-dark);
                    }

                    &.not-invited {
                        color: var(--color-grey);
                    }

                    &.invited {
                        color: var(--color-orange-xdark);
                    }

                    &-long {
                        flex: 1 1 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }

                &.qty, &.severity, &.days {
                    flex: 1 1 100px;
                }

                &.type {
                    flex: 1 1 110px;
                }

                &.checkbox {
                    flex: 1 1 30px;
                    min-width:0;
                    &.wider {
                        flex: 1 1 45px;
                    }
                }

                &.rent-type {
                    flex: 1 1 350px;
                }
                &.ownership {
                    flex: 1 1 250px;
                }

                &.title {
                    flex: 1 1 100%;
                    &-short {
                        flex: 1 1 70%;
                        max-width: rems(350);
                        line-height: 1.3;
                    }
                }

                &.amount, &.rent, &.overdue, &.due-today, &.paid, &.upcoming, &.total, &.soon, &.right {
                    text-align: right;
                }

                &.rent, &.overdue, &.soon, &.due-today, &.paid, &.upcoming, &.total {
                    flex: 1 1 150px;
                    &.narrow {
                        flex: 1 1 100px;
                    }
                }

                &.actions {
                    flex: 0 1 100px;
                    text-align: right;
                    align-items: flex-start;

                    a:focus {
                        outline: 0;
                    }

                    &.wide {
                        text-align: right;
                        flex: 1 1 200px;
                    }

                    &.widest {
                        text-align: right;
                        flex: 1 1 340px;

                        &.column {
                          flex-direction: column;
                        }
                    }

                    &.narrow {
                        min-width: 0;
                    }

                    &.narrowest {
                        min-width: 0;
                        flex: 0 1 65px;
                    }

                    &-steps {
                        flex: 1 1 160px;
                        min-width: rems(160);
                    }
                }

                &.action-actions {
                    padding-right: rems(4);
                    flex: 0 1 168px;

                    .u-margin-left--10 {
                        margin-left: rems(6);
                    }

                    a {
                        margin-left: rems(6);

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                &.user-assigned {
                    flex: 0 1 150px;
                    &.premium-preview {
                        font-weight: 800;
                        color: var(--color-rating-gold);
                    }
                    span {
                        background-color: var(--color-grey-border);
                        color: var(--color-magenta);
                        cursor: pointer;
                        font-size: rems(12);
                        padding: 0 rems(5) rems(2) rems(5);
                        font-weight: 600;
                        color: white;
                        border-radius: rems(10);
                        vertical-align: top;
                        margin-left: rems(2);

                        &:hover {
                            background-color: var(--color-grey);
                        }

                        &:focus {
                            outline: 0;
                        }
                    }
                }

                &.status {
                    &.wide {
                        flex: 1 1 260px;

                        ul {
                            margin-top: 0;
                            justify-content: flex-start;

                            li {
                                margin: 0;
                            }
                        }
                    }
                }

                &.url {
                    flex: 1 1 260px;
                }
            }

            td {
                &.options {
                    justify-content: center;
                }
                &.amount {
                    &.negative {
                        color: var(--color-warning-dark);
                    }
                }

            }

            &.child {
                td.action-title {
                    display:flex;
                    align-content: center;
                    svg {
                        display: inline;
                        &.icon-child,&.icon-last-child {
                            width: rems(18);
                            height: rems(18);
                            margin-right: rems(4);
                        }
                    }
                }
            }


        }

        tfoot {
            tr {
                border-color: rgba(3,3,3,0.5);
            }
        }

        &-padded {
            tr {
                padding: rems(4) 0;

                th, td {
                    &:first-of-type {
                        padding-left: rems(4);
                    }

                    &:last-of-type {
                        padding-right: rems(4);
                    }
                }
            }
        }

        &-highlight {
            tbody {
                tr {
                    &.summary {
                        &:hover {
                            cursor:pointer;
                            background: var(--color-grey-xxlight);
                        }
                    }

                    &.stale {
                        background: var(--color-grey-xlight);
                        &:hover {
                            background: #e5e5e5;
                        }
                        td {
                            color: var(--color-grey-dark);
                        }
                    }
                    &.highlight {
                        background: var(--color-magenta-light);
                        td {
                            &.balance {
                                color: var(--color-warning-dark);
                                font-weight: 700;
                            }
                        }
                        &:hover {
                            background: #ebe4e7;
                        }

                    }
                }
            }
        }
    }

    .page__section--alt {
        .compact__table.compact__table--narrow {
            &-highlight {
                tbody {
                    tr {
                        &.summary {
                            &:hover {
                                cursor:pointer;
                                background: var(--color-grey-xlight);
                            }
                        }
                    }
                }
            }
        }
    }

    .compact__table.compact__table--narrow.compact__table--actions {
        tr {
            //max-height: rems(36);
            td,th {
                &.has-button {
                    flex: 0 0 28px;
                    min-width: rems(28);
                    line-height: 1;
                    &.padded {
                        padding-right: rems(34);
                    }
                }
            }
            th {
                &:first-child,&.actions-assigned {
                    padding-left: rems(4);
                }
                &:last-child,&.actions {
                    padding-right: rems(4);
                }
            }
            &.summary {
                td {
                    &:first-child {
                        padding-left: rems(4);
                    }
                    &:last-child {
                        padding-right: rems(4);
                    }
                    .button--icon.button--ghost .icon.icon--green {
                        fill: var(--color-green-xdark);
                    }
                    &.status {
                        .context__list-actions.context__list-actions-single ul li {
                            min-width: rems(78);
                        }
                    }
                }
            }
        }
    }

    .compact__table {

        tr {

            &.details {

                td {

                    .card__header {
                        &.card__header--category {
                            //padding: rems(4) rems(14);
                            .card__header--category-wrapper {
                                flex-basis: 100%;


                            }
                            .card__action-items {
                                min-width: fit-content;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .compact__table {
        border-bottom: 1px solid rgba(3,3,3,0.2);

        thead {
            display: table-cell;

            tr {
                margin-bottom: 0;
                border-top: 0;

                th.sortable {
                    cursor: pointer;

                    .icon {
                        padding-left: 2px;
                    }

                    .icon, svg {
                        width: rems(10);
                        height: rems(10);
                    }
                }
            }
        }

        tr {
            border: 0;
            flex-wrap: nowrap;
            border-top: rems(1) solid rgba(var(--color-black-rgb),0.1);
            margin: 0;
            padding: rems(2) 0;

            &.details:hover {
                background-color: transparent;
            }

            th, td {
                flex: 1 1 194px;
                border: 0;
                min-width: rems(85);
                line-height: 1.3;

                span.label {
                    display: none;
                }

                &.date {
                    flex: 1 1 180px;
                }

                &.when {
                    flex: 1 1 300px;
                }

                &.property {
                    flex: 1 1 350px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &.title {
                    flex: 1 1 100%;
                }

                &.amount {
                    text-align: right;
                }

                &.actions {
                    flex: 1 1 150px;
                    text-align: center;

                    a:focus {
                        outline: 0;
                    }
                }
            }

            &.summary {
                &.open {
                    td {
                        display: table-cell;

                        &.title {
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                        }
                    }
                }
            }

            &.details {
                box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
                border-radius: radius(medium);
                margin: rems(8) 0 rems(16) 0;

                td {
                    padding: 0;

                    .card__header {
                        padding: rems(10) rems(14);

                        &.card__header--actions {
                            h4 {
                                max-width: 75%;
                            }
                        }
                    }
                    .card__intro {
                        padding: rems(10) rems(14);
                    }
                    .card__content {
                        padding: rems(10) rems(14);
                        flex-direction: row;

                        &--left {
                            flex: 0 1 60%;
                            padding-bottom: 0;
                            max-height: fit-content;

                            .form__message {
                                width: 100%;
                            }

                            .context__list-actions {
                                flex-direction: row;

                                .context__list-actions-group {
                                    ul {
                                        li {
                                            padding: rems(2) rems(6);
                                            min-width: 0;
                                        }
                                    }

                                    p {
                                        min-width: rems(52);
                                    }

                                    &:first-of-type {
                                        margin-right: rems(16);
                                    }
                                }
                            }

                            lead-view {
                                .lead__message--container {
                                    &::-webkit-scrollbar-track {
                                        background: var(--color-grey-xxlight);
                                    }

                                    &::-webkit-scrollbar-thumb {
                                        border: rems(3) solid var(--color-grey-xxlight);
                                    }
                                }
                            }
                        }
                        &--left-half {
                            flex: 0 1 50%;
                        }
                        &--right {
                            flex: 0 1 40%;
                            //padding: 0 rems(8) 0 rems(16);  -- changed for onboarding
                            padding: 0 0 0 rems(16);
                            margin-left: rems(16);
                            border-top: 0;
                            border-left: 1px solid var(--color-grey-xlight);
                        }
                        &--right-half {
                            flex: 0 1 50%;
                            border-left: 0;
                            padding-left: 0;

                            h4 {
                                padding-top: rems(6);
                                padding-bottom: rems(18);
                            }
                        }
                        &--left-40 {
                            flex: 0 1 40%;
                        }
                        &--right-60 {
                            flex: 0 1 60%;
                            border-left: 0;
                            padding-left: 0;

                            h4 {
                                padding-top: rems(6);
                                padding-bottom: rems(18);
                            }
                        }
                        &--full {
                            flex: 0 1 100%;
                            padding-bottom: 0;
                            max-height: fit-content;
                            .card__detail--holder {
                                max-width: 60%
                            }
                            .notes.notes--no-height {
                                .notes__container {
                                    min-height: unset;
                                }

                            }
                        }
                    }

                    .card__actions {
                        padding: rems(10) rems(14);
                    }
                }
            }

            &.open + .details {
                border-top: 0;
            }
        }
    }

    form #rent-due-record-generator .compact__table tr td {
        background: none;
    }

    .compact__table {

        tr {

            &.details {

                td {

                    .card__header {
                        &.card__header--category {
                            padding: rems(4) rems(14);
                            .card__header--category-wrapper {
                                flex-basis: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .compact__table {
        tr {
            &.details {
                //margin: rems(10) 0 rems(30) 0;
                td {
                    .card__content {
                        .context__list-actions {
                            flex-direction: row;

                            .context__list-actions-group {
                                ul {
                                    li {
                                        min-width: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            td, th {
                //flex: 1 1 158px;
                .card__header {
                    h4 {
                        max-width: rems(800);
                    }
                }
            }
        }
    }
}
