.card, a.card {
    &.card__panel{
        padding: rems(12) rems(12) rems(12) rems(12);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: var(--color-grey-xxlight);
            cursor: pointer;
        }
        &--actions {
            background-color: var(--color-magenta-xdark);
            &:hover {
                background-color: var(--color-magenta-dark);
            }
        }
        &--secondary {
            background-color: var(--color-cyan-xdark);
            &:hover {
                background-color: var(--color-cyan-dark);
            }
        }
        &--clear {
            background-color: var(--color-green-xxdark);
            &:hover {
                background-color: var(--color-green-xdark);
                box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
            }
        }
        .card__icon {
            .icon, .icon svg {
                height: rems(36);
                width: rems(36);
            }
        }
        .card__title-wrapper{
            .card__title {
                line-height: 1;
                color: var(--color-black);
                font-size: rems(16);
                text-align: center;
                &:hover {
                    color: var(--color-black);
                }
            }
            .card__sub-title {
                color: var(--color-black);
                font-size: rems(10);
                font-weight: 400;
                line-height: 1;
                text-align: center;
                padding-bottom: rems(4);
            }
            .card__title + .card__sub-title {
                margin-top: rems(4);
            }
        }
        &--actions {
            background-color: var(--color-magenta-xdark);
            &:hover {
                background-color: var(--color-magenta-dark);
            }
            .card__icon {
                .icon {
                    fill: var(--color-white);
                }
            }
            .card__title-wrapper {
                .card__title,.card__sub-title {
                    color: var(--color-white);
                    &:hover {
                        color: var(--color-white);
                    }
                }
            }
        }
        &--clear {
            background-color: var(--color-green-xxdark);
            &:hover {
                background-color: var(--color-green-xdark);

            }
            .card__icon {
                .icon {
                    fill: var(--color-white);
                }
            }
            .card__title-wrapper {
                .card__title,.card__sub-title {
                    color: var(--color-white);
                    &:hover {
                        color: var(--color-white);
                    }
                }
            }
        }
        &--secondary {
            background-color: var(--color-cyan-xdark);
            &:hover {
                background-color: var(--color-cyan-dark);
            }
            .card__icon {
                .icon {
                    fill: var(--color-white);
                }
            }
            .card__title-wrapper {
                .card__title,.card__sub-title {
                    color: var(--color-white);
                    &:hover {
                        color: var(--color-white);
                    }
                }
            }
        }

        &--other {
            background-color: var(--color-white);
            .card__icon {
                .icon {
                    fill: var(--color-black);
                }
            }
            .card__title-wrapper{
                .card__title, .card__sub-title {
                    color: var(--color-black);
                    &:hover {
                        color: var(--color-black);
                    }
                }
            }
            &:hover {
                box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-black-rgb),0.15);
            }
        }

        &--inactive {
            background-color: var(--color-grey-xxlight);
            color: var(--color-grey-light);
            box-shadow: none;
            pointer-events: none;
          
            .card__icon {
              svg-icon {
                pointer-events: none;
              }
              
              .icon {
                  fill: var(--color-grey-light);
              }
            }
            .card__title-wrapper{
                .card__title, .card__sub-title {
                    color: var(--color-grey-light);
                    &:hover {
                        color: var(--color-grey-light);
                    }
                }
            }
            &:hover {
                cursor:auto;
                box-shadow: none;
                background-color: var(--color-grey-xxlight);
                //box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
            }
        }

        &.active {
            position: relative;

            &::after {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23e9e9e9' d='M17.000,23.000 L17.000,21.000 L27.000,21.000 L27.000,23.000 L17.000,23.000 zM17.000,16.000 L27.000,16.000 L27.000,18.000 L17.000,18.000 L17.000,16.000 zM17.000,11.000 L27.000,11.000 L27.000,13.000 L17.000,13.000 L17.000,11.000 zM17.000,6.000 L27.000,6.000 L27.000,8.000 L17.000,8.000 L17.000,6.000 zM3.000,21.000 L13.000,21.000 L13.000,23.000 L3.000,23.000 L3.000,21.000 zM3.000,16.000 L13.000,16.000 L13.000,18.000 L3.000,18.000 L3.000,16.000 zM3.000,11.000 L13.000,11.000 L13.000,13.000 L3.000,13.000 L3.000,11.000 zM3.000,6.000 L13.000,6.000 L13.000,8.000 L3.000,8.000 L3.000,6.000 z'/></svg>");
                content: "";
                display: block;
                position: absolute;
                height: rems(24);
                right: rems(4);
                top: rems(4);
                transition: transform 0.2s ease-out;
                width: rems(24);
            }
        }
        &--compact {
            flex-direction: row;
            padding: rems(8) rems(8);
            justify-content: flex-start;
            .card__icon {
                .icon {
                    height: rems(30);
                    width: rems(30);
                }
            }
            .card__title-wrapper{
                padding-left: rems(10);
                .card__title {
                    text-align: left;
                }
                .card__sub-title {
                    text-align: left;
                    line-height: 1.2;
                }
            }
        }
    }
}


@include breakpoint(for-tablet-portrait-up) {
    .card, a.card {
        &.card__panel:not(.card__panel--compact){
            min-height: rems(120);
            .card__icon {
                .icon {
                    height: rems(48);
                    width: rems(48);
                }
            }
        }
    }


}
