.loader__wrapper {
    width: 100px;
}

.loader__overlay {
    background-color: rgba(var(--color-blue-dark-rgb), 0.95);
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// SVG

.loader__style--0 {
    fill: #ffffff;
}

.loader__style--1 {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    stroke-width: 12;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.loader__style--2 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 12;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
.showOnDelayed {
    visibility:hidden;
    color: var(--color-white);
    text-align: center;
    padding-top: 0;
    font-weight: 400;
    height: 0;
}
.loaderDelayed {
    .showOnDelayed {
        visibility: visible;
        height: auto;
        padding-top: rems(40);
    }
}
// Anim

.hex__a {
    animation: hex__a-animation 1.5s ease-out forwards;
    animation-iteration-count: infinite;
    opacity: 0;
}

.hex__b {
    animation: hex__b-animation 1.5s ease-out forwards;
    animation-iteration-count: infinite;
    opacity: 0;
}

.line__1 {
    animation: line__1-animation 1.5s ease-out forwards;
    animation-iteration-count: infinite;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    stroke: rgba(255, 255, 255, 0);
    transform-origin: center;
}

.line__2 {
    animation: line__2-animation 1.5s ease-out forwards;
    animation-iteration-count: infinite;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    stroke: rgba(255, 255, 255, 0);
    transform-origin: center;
}

@keyframes hex__a-animation {
    40% {
        opacity: 0;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hex__b-animation {
    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}


@keyframes line__1-animation {
    10% {
        stroke-dasharray: 400;
        stroke-dashoffset: 400;
        stroke: rgba(255, 255, 255, 1);
    }

    50% {
        stroke-dashoffset: 0;
        stroke: rgba(255, 255, 255, 1);
    }

    100% {
        stroke-dashoffset: 0;
        stroke: rgba(255, 255, 255, 1);
    }
}

@keyframes line__2-animation {
    30% {
        stroke-dasharray: 400;
        stroke-dashoffset: 400;
        stroke: rgba(255, 255, 255, 1);
    }

    80% {
        stroke-dashoffset: 0;
        stroke: rgba(255, 255, 255, 1);
    }

    100% {
        stroke-dashoffset: 0;
        stroke: rgba(255, 255, 255, 1);
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .loader__wrapper {
        width: 200px;
        svg {
            width: rems(200);
            height: rems(200);
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .showOnDelayed {
        p {
            font-size: rems(18);
            line-height: 1.7;
        }
    }
}