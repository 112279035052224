.card__property-actions{
    display: flex;
    padding-top: rems(20);
    min-height: rems(45);

    .badge{
        margin-right: rems(10);
    }

}

.card {
    &.card--property {

        .card__content--wrapper{
            width: 100%;
            height: auto;
            display: grid;
            .card__content {
                align-self: center;
            }
        }

        outline: rems(1) solid var(--color-grey-xlight);
        &:focus {
            outline: none;
        }

        &.card--property-has-completion {
            outline: rems(1) solid var(--color-grey);
        }

        &.card--property-has-completion {
            outline: rems(1) solid var(--color-grey);
            .card__content--wrapper{
                .card__content {
                    align-self: unset;
                }
            }
        }

        .card__completion { 
            position: relative;
            width: 100%;
            height: rems(8);
            border-top-right-radius: radius(medium);
            background-color: var(--color-grey-xlight);
            > div {
                height: 100%;
                position: absolute;
                border-top-right-radius: 0;
            }
            .property-completion--30 {  
                background-color: var(--color-orange-dark);
            }
            .property-completion--50 {  
                background-color: var(--color-orange);
            }
            .property-completion--70 {  
                background-color: var(--color-green);
            }
            .property-completion--80 {  
                background-color: var(--color-green-dark);
            }
            .property-completion--90 {  
                background-color: var(--color-green-xdark);
            }
         }

    }

    &.card--property-has-actions {
        outline: rems(1) solid var(--color-pink); 
    }


}


.card {
    &.card--property {
        .card__image--indicators {
            position:absolute;
            left: 0;
            bottom: rems(-2);
        }
        .card__image--property-type {
            position: absolute;
            bottom: rems(8);
            left: rems(5);
        }
        .card__image--owner-type {
            position: absolute;
            bottom: rems(8);
            left: rems(42);

        }
        .card__image--owner-type, .card__image--property-type {
            svg {
                width: rems(20);
                height: rems(20);
                //fill: var(--color-white);
            }

            //align-items: center;
            //background-color: var(--color-black);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            height: rems(32);
            padding-top: rems(6);
            padding-bottom: rems(2);
            text-align: center;
            width: rems(32);
            outline: solid rems(0.5) var(--color-grey-dark);


            .tooltip {
                background-color: var(--color-blue-dark);
                border-radius: radius(small);
                box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
                color: white;
                display: none;
                font-size: rems(12);
                line-height: rems(12);
                left: 0;
                padding: rems(5);
                position: absolute;
                top: rems(-28);
                white-space: nowrap;
            } 

            &:hover {
                .tooltip {
                    display: block;
                    z-index: 100;

                    &:hover {
                        display: none;
                    }
                }
            } 
        } 

        .card__image {
            border-top-left-radius: radius(medium);
            border-top-right-radius: radius(medium);
            border-bottom-left-radius: 0;

        }

        .card__icon {
            padding: rems(8) rems(10) 0 rems(10);
            svg {
                width: rems(40);
                height: rems(40);
            }
        }

        .card__image--property-type {
            background-color: var(--color-blue-light);
            svg {
                fill: var(--color-blue-dark);
            }
            .tooltip {
                background-color: var(--color-blue-dark);
            }
        }

        .card__image--owner-type {
            .tooltip {
                left: rems(-36);
            }

            &.card__image--owner-type-owned {
                background-color: var(--color-cyan-light);
                svg {
                    fill: var(--color-cyan-dark);
                }
                .tooltip {
                    background-color: var(--color-cyan-dark);
                }
            }

            &.card__image--owner-type-rent {
                background-color: var(--color-brown-light);
                svg {
                    fill: var(--color-brown-dark);
                }
                .tooltip {
                    background-color: var(--color-brown-dark);
                }
            }

            &.card__image--owner-type-managed {
                background-color: var(--color-magenta-light);
                svg {
                    fill: var(--color-magenta-dark);
                }
                .tooltip {
                    background-color: var(--color-magenta-dark);
                }
            }
        }
    }
}

.page__section-content--compact {
    .card {
        &.card--property {
            .card__content--wrapper{
                width: 100%;
                height: 100%;
                display: grid;
            }
            .card__image {
                .image__size {
                    &.image__size--4x3 {
                        border-top-left-radius: radius(medium);
                        border-bottom-left-radius: radius(medium);
                    }
                }
            }
            &.card--property-has-completion {
                .card__image {
                    .image__size {
                        &.image__size--4x3 {
                            border-top-left-radius: 0;
                        }
                    }
                }
            }
            .card__image--indicators {
                bottom: rems(0);
                top: unset;
            }
            .card__image--property-type {
                bottom: rems(4);
                left: rems(3);
            }
            .card__image--owner-type {
                bottom: rems(4);
                left: rems(30);
            }
            .card__image--owner-type, .card__image--property-type {
                .tooltip {
                    left: 0;
                    top: rems(-44);
                }
                svg {
                    width: rems(16);
                    height: rems(16);
                }
                height: rems(24);
                padding-top: rems(2);
                padding-bottom: rems(0);
                width: rems(24);
            }
            .card__image--owner-type {
                .tooltip {
                    left: rems(-28);
                }
            }

        }
    }
}

.card {
    &.card--property {
        &.card--property-horizontal {
            flex-direction: column;
            .card__image {
                .card__image-caption {
                    left: rems(16);
                    bottom: rems(16);
                }
            }
            .card__content--right {
                padding: rems(12) rems(20);
                .card__content--upper {
                    display: flex;
                    flex-direction: column;
                    .card__content {
                        padding: 0;
                    }   
                    .card__content--housemates {
                        display: flex;
                        flex-direction: column;
                        margin-top: rems(8);
                        align-items: flex-start;
                        .user__group {
                            margin-top: 0;
                            left: unset;
                            position: relative;
                        }
                    }
                }
            }
            &-v2 {
                a.card__content--main {
                    color: var(--color-text);
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                .card__content--main {
                    display: flex;
                    flex-direction: column;
                }
                .card__image {
                    .card__image--room-holder {
                        padding: rems(12) rems(20) 0 rems(20);
                    }
                }
                .card__content--right { 
                    .card__content--upper {
                        flex-direction: column-reverse;
                        .text__price {
                            padding: rems(20) 0 rems(8) 0;
                        }
                    }
                    .card__content--lower {
                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                        .card__content--lower-profile {
                            padding-top: rems(10);
                            display: flex;
                            flex-direction: column;
                            .card__content--housemates {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                .user__group {
                                    margin-top: 0;
                                    left: unset;
                                    position: relative;
                                    li {
                                        width: rems(36);
                                        height: rems(36);
                                        &.user__group-more {
                                            min-width: rems(36);
                                            line-height: rems(36);
                                        }
                                        .profile__mini-image{
                                            width: rems(36);
                                            border: 0;
                                        }
                                    }
                                }
                            }
                            .card__content--interests {
                                padding-top: rems(10);
                                p {
                                    margin-bottom: 0;
                                }
                                .profile__preview-interests {
                                    li {
                                        width: rems(38);
                                    }
                                    img {
                                        width: rems(34);
                                        height: rems(34);
                                    }
                                }
                            }
                            .card__content--by {
                                padding-top: rems(10);
                                p {
                                    margin-bottom: rems(10);
                                }
                                .org-holder {
                                    display: flex;
                                    align-items: center;
                                    .org-image {
                                        width: rems(36);
                                        height: rems(36);
                                        border-radius: $size__radius--sm;
                                        box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.25);
                                        img {
                                            width: rems(36);
                                        }
                                    }
                                    .org-name {
                                        margin-left: rems(10);
                                        max-width: rems(190);
                                        p {
                                            margin-bottom: 0;
                                            font-size: 14px;
                                            line-height: 1.2;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-v2-multi {
                .card__image {
                    .card__image--room-holder {
                        padding: rems(20) rems(20) rems(12) rems(20);
                        .text__price {
                            margin-top: 0;
                        }
                        .card--room {
                            outline: rems(1) solid var(--color-grey-xlight);
                            margin-bottom: rems(10);
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                            &.card--wrapper {
                                &:hover {
                                    box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.4);
                                    outline: rems(1) solid rgba(var(--color-violet-rgb),0.5);
                                }
                            }
                            .heading--4 {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
            &-v2-opens {
                // a.card__content--main {
                //     color: var(--color-text);
                //     text-decoration: none;
                //     &:hover {
                //         text-decoration: none;
                //     }
                //     &:focus {
                //         outline: none;
                //     }
                // }
                .card__content--footer {
                    display: none;
                    padding: rems(25) rems(20) rems(5) rems(20);
                    .card.card--room {
                        .card__image {
                            flex-basis: unset;
                        }
                    }
                    border-top: rems(1) solid var(--color-grey-xlight);
                }
                &.open {
                    .card__content--footer {
                        display: flex;
                    }
                }
            }
        }
    }
}
@include breakpoint (for-large-phone-landscape-up) {
    .card {
        &.card--property {
            &.card--property-horizontal {
                &-v2 {
                    .card__content--right { 
                        .card__content--lower {
                            .card__content--lower-profile {
                                flex-direction: column;
                                .card__content--housemate-wrapper {
                                    display: flex;
                                    flex-direction: row;
                                }
                                .card__content--interests {
                                    margin-left: rems(40);
                                    padding-top: 0;
                                    p {
                                        margin-bottom: rems(10);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint (for-tablet-portrait-up) {
    .card {
        &.card--property {
            &.card--property-horizontal {
                flex-direction: row;
                .card__image {
                    flex-basis: 35%;
                    .card__image-caption {}
                }
                .card__content--right {
                    flex-basis: 65%;
                    padding: rems(12) rems(20);
                    .card__content--upper {
                        flex-direction: row;
                        .card__content {
                            flex-basis: 65%;
                        }   
                        .card__content--housemates {
                            flex-basis: 35%;
                            align-items: flex-end;
                            .user__group {
                                margin-top: 0;
                                left: unset;
                                position: relative;
                                text-align: right;
                            }
                        }
                    }
                }
                &-v2 {
                    flex-direction: column;
                    .card__content--main {
                        flex-direction: row;
                    }
                    .card__content--right {
                        .card__content--upper {
                            padding-top: rems(10);
                            .text__price {
                                padding: 0;
                                min-width: rems(200);
                                text-align: right;                                
                            }
                            .card__content {
                                flex-basis: 80%;

                            }

                        }
                        .card__content--lower {
                            p {
                                -webkit-line-clamp: 2;
                            }
                        }
                    }
                }
                &-v2-multi {
                    .card__content--right {
                        .card__content--upper {
                            padding-top: 0;
                        }
                    }
                    .card__image {
                        .card__image--room-holder {
                            padding: rems(12);
                            .card {
                                &.card--room {
                                    .card__content {
                                        .heading--4 {
                                            font-size: rems(14);
                                        }
                                    }
                                }
                            }
                            .text__price {
                                margin-top: 0;
                                p {
                                    font-size: rems(11);
                                    strong {
                                        font-size: rems(16);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
}

@media (min-width: 1024px) { 
    .card {
        &.card--property {
            &.card--property-horizontal {
                &-v2 {
                    .card__content--right {
                        .card__content--lower {
                            .card__content--lower-profile {
                                flex-direction: row;
                                .card__content--by {
                                    padding-top: 0;
                                    margin-left: rems(40);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@include breakpoint (for-tablet-landscape-up) {
    .card {
        &.card--property {
            &.card--property-horizontal {
                .card__image {
                    flex-basis: 32%;
                }
                .card__content--right {
                    flex-basis: 68%;

                    .card__content--upper {
                        .card__content {
                            flex-basis: 60%;
                        }

                        .card__content--housemates {
                            flex-basis: 40%;

                            .user__group {
                            }

                            .profile__preview-interests {
                                li {
                                    width: rems(36);
                                }

                                img {
                                    width: rems(32);
                                    height: rems(32);
                                }
                            }
                        }
                    }
                }
                &-v2 {
                    .card__content--right {
                        .card__content--upper {
                            .card__content {
                                flex-basis: 100%;
                            }
                            .text__price {
                                min-width: rems(200);
                                text-align: right;
                            }   
                        }
                        .card__content--lower {
                            display: flex;
                            flex-direction: column;
                            height: calc(100% - rems(34));
                            // p {
                            //     -webkit-line-clamp: 2;
                            // }
                            .card__content--lower-profile {
                                margin-top: auto;
                                padding-top: 0;
                                .card__content--housemates, 
                                .card__content--interests {
                                    min-width: rems(178);
                                }
                                .card__content--by {
                                    margin-left: auto;
                                    width: rems(236);
                                }
                            }
                        }
                    }
                }
                &-v2-multi {
                    .card__content--right {
                        .card__content--lower {
                            height: calc(100% - rems(24));
                            p {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 99;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                    .card__image {
                        .card__image--room-holder {
                            .card {
                                &.card--room {
                                    .card__content {
                                        padding-top: rems(4);
                                        .heading--4 {
                                            font-size: rems(16);
                                        }
                                    }
                                }
                            }
                            .text__price {
                                margin-top: 0;
                                p {
                                    font-size: rems(12);
                                    strong {
                                        font-size: rems(18);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include breakpoint (for-small-desktop-up) {
    .card {
        &.card--property {
            &.card--property-horizontal {
                .card__image {
                    flex-basis: 32%;
                }
                .card__content--right {
                    flex-basis: 68%;
                    .card__content--upper {
                        .card__content {
                            flex-basis: 60%;
                        }   
                        .card__content--housemates {
                            flex-basis: 40%;
                            .user__group {}
                            .profile__preview-interests {
                            }
                        }
                    }
                }
                &-v2 {
                    .card__content--right {
                        .card__content--upper {
                            .card__content {
                                flex-basis: 100%;                            
                            }
                            .text__price {
                                min-width: rems(200);
                                text-align: right;
                            }   
                        }
                        .card__content--lower {
                            .heading--3 {
                                max-width: calc(100% - rems(200))
                            }
                            // p {
                            //     -webkit-line-clamp: 4;
                            // }
                        }
                    }
                }
                &-v2-multi {
                    .card__content--right {
                        .card__content--lower {
                            p {
                                -webkit-line-clamp: 99;
                            }
                        }
                    }
                }
                &-v2-opens {
                    .card__content--right {
                        .card__content--upper {
                            .text__price {
                                min-width: rems(230);
                                text-align: right;
                            }   
                        }
                    }
                }
            }
        }
    }       
}