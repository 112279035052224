.card.investment {
    height: calc(100% - 1.9rem);

    h6 {
        margin-bottom: 0.3125rem;
        border-bottom: 1px solid #ededed;
        margin-top: rems(8);

        &:first-child {
            margin-top: 0;
        }

        & + p {
            margin-top: rems(8);
        }
    }

    p {
        margin-bottom: rems(16);
        font-size: 0.875rem;
        line-height: 1.4;
    }

    .card__content {
        *:last-child {
            margin-bottom: rems(16);
        }

        .text__price {
            margin-top: rems(12);
        }
    }
}
