.profile__compliance{
    border-top: rems(1) solid var(--color-grey-xlight);
    font-size: rems(14);
    list-style: none;
    margin-bottom: rems(20);
    margin-top: rems(20);
    padding: 0;
    width: 100%;

    li{
        border-bottom: rems(1) solid var(--color-grey-xlight);

        .profile__compliance-item{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: rems(10);
            padding-top: rems(10);
            width: 100%;
            font-size: 0.75rem;
            line-height: 1.5;
            .loader{
                padding: 0;
            }

            .profile__compliance-details--holder {
                display: flex;
                flex-direction: column;
                border-top: rems(1) solid var(--color-grey-xxlight);
                padding-top: rems(8);
                margin-top: rems(8);
                &:first-of-type {
                    border-top: 0;
                    padding-top: 0;
                    margin-top: 0;
                }
            }

            .profile__compliance-details {
                strong {
                    font-size: 1rem;
                }

                .profile__compliance-details--item {

                    display: flex;
                    align-items: center;
                    padding-top: rems(2);

                    // svg-icon {
                    //     display: none;
                    // }
                    .icon-user, .icon-thumbs-up, .icon-document,.icon-warning {
                        &.icon--tiny {
                            width: rems(16);
                            height: rems(16);
                            margin-right: rems(4);
                        }
                    }
                }
            }

            .profile__compliance-actions{
                a{
                    color: var(--color-teal);
                    margin-right: rems(4);
                    &:last-child {
                        margin-right: 0;
                    }
                    &:focus {
                        outline: 0;
                    }
                }
            }

            .profile__compliance-status{
                background-color: var(--color-green-light);
                border: 2px solid var(--color-green);
                border-radius: rems(16);
                color: var(--color-green-xdark);
                display: flex;
                //flex: 0 0 1rem;
                margin-top: rems(5);
                margin-bottom: rems(5);
                margin-right: rems(5);
                padding: rems(2) rems(8);
                font-size: 0.75rem;
                font-weight: 700;
                width: fit-content;
                max-height: rems(26);
                white-space: nowrap;
                &.awaiting{
                    background-color: var(--color-magenta-light);
                    border-color: var(--color-magenta);
                    color: var(--color-magenta-dark);
                }
                &.manual{
                    background-color: var(--color-cyan-light);
                    border-color: var(--color-cyan);
                    color: var(--color-cyan-dark);

                }
                &.existing{
                    background-color: var(--color-grey-light);
                    border-color: var(--color-grey-border);
                    color: var(--color-grey-dark);

                }
                &.error{
                    background-color: var(--color-magenta-dark);
                    border-color: var(--color-magenta-xdark);
                    color: var(--color-white);

                }

            }

        }

        &:last-child{
            margin-bottom: 0;
        }
    }



}

@include breakpoint(for-large-phone-landscape-up) {
    .profile__compliance{
        li{
            .profile__compliance-item{
                flex-direction: row;
                align-items: center;
                .profile__compliance-details {
                }
                .profile__compliance-actions{
                    //flex-basis: 6.5rem;
                }
                .profile__compliance-status{
                }
            }
        }
    }
}
@include breakpoint(for-tablet-landscape-up) {
    .profile__compliance{
        li{
            .profile__compliance-item{
                .profile__compliance-details {
                    .profile__compliance-details--item {
                        svg-icon {
                            display: block;
                        }
                    }
                }
                .profile__compliance-actions{
                    //flex-basis: 10.5rem;
                }
            }
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .profile__compliance{ 
        li .profile__compliance-item {
            .profile__compliance-details--holder {
                flex-direction: row;
                .profile__compliance-details {
                    flex-basis: 66%;
                    .profile__compliance-details--item {
                        .icon-user, .icon-thumbs-up, .icon-document,.icon-warning {
                            &.icon--tiny {
                                margin-right: rems(12);
                            }
                        }
                    }
                }
            }
        }
    }
}