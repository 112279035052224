.room {
    background-color: white;
    border-radius: radius(medium);
    box-shadow: 0 0 rems(20) rgba(0,0,0,0.15);
    margin-bottom: rems(20);
    border-left: rems(5) solid var(--color-teal-light);
    border-right: rems(5) solid var(--color-teal-light);
}

.room__nav{
    border-bottom: 1px solid var(--color-grey-xlight);
    padding: rems(10) rems(20);
    display: flex;
    flex-direction: column;
    a {
        flex: 1 1 100%;
        &.button__forward {
            justify-content: flex-end;
        }

        &:focus {
            outline: 0;
        }
    }
}

.room__content-section{
    border-bottom: 1px solid var(--color-grey-xlight);
    padding: rems(20) rems(20) rems(20) rems(20);
}

.room__content-sub-section{
    border-bottom: 1px solid var(--color-grey-xlight);
    margin-bottom: rems(20);
}


.room {
    .room__content-section {
        h3, h4 {
            &.heading--section {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }

        &#room_details {
            border-bottom: none;
        }

        .room__content-sub-section {
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
            }

            .room__content-profile-holder {
                background: var(--color-grey-xxlight);
                border: 1px solid var(--color-grey-xlight);
                padding: rems(8);
                border-radius: radius(medium);

                .profile__preview {
                    margin-bottom: 0;
                }
            }
        }

        .context__list {
            margin: 0 0 rems(30) 0;
            width: 100%;

            li {
                margin: rems(8) 0;

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .context-item {
                align-items: flex-start;

                .context-title {
                    .context-title--button {
                        &::after {
                            left: rems(4);
                        }
                    }
                }

                .context-item--hidden-content {
                    font-size: rems(16);
                    padding: rems(12);
                }
            }
        }

        onboarding-view {
            border-bottom: 1px solid var(--color-grey-light);
            display: block;
            padding-top: rems(30);

            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

.room__heading {
    border-bottom: 1px solid var(--color-grey-xlight);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: rems(20);
    padding-bottom: 0;
    width: 100%;

    &-title {
        display: flex;
        flex: 1 0 50%;
        flex-direction: column;
        padding-right: rems(30);

        h2 {
            margin-bottom: 0;

            + p {
                color: var(--color-grey);
                margin-top: 0;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
    }

    &-image {
        border-radius: radius(small);
        margin-right: rems(20);
        overflow: hidden;
        width: rems(100);
        min-width: rems(100);
        padding-bottom: rems(20);

        img {
            display: block;
            height: auto;
            width: rems(100);
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        &-upper {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &-price {
        //width: 100%;
        padding-bottom: rems(20);
        min-width: rems(160);
    }
}
@include breakpoint(for-large-phone-landscape-up) {
    .room {
        .room__content-section{
            .context__list{
                .context-item {
                    min-height: 0;
                    padding-bottom: 0;
                }
            }             

        }
    }  
    .room__nav{
        flex-direction: row;
        a {
            flex: 1 1 50%;
        }
    }
}

@include breakpoint(for-tablet-portrait-up){
    .room {
        .room__content-section{
            //padding: rems(20) 18% rems(20) rems(20);
            .context__list {
                .context-item {
                    .context-item--visible-content {
                        .context-content {
                            .context-title {
                                font-size: rems(16);
                            }
                            .context-text { 
                                font-size: rems(16);
                            }
                        }
                    }
                }
            }        
        }
        .room__heading{
            flex-wrap: nowrap;

            &-content {
                flex-direction: row;
            }

            &-title{
                //flex: 1 0 auto;
            }

            &-price{
                margin-left: auto;
                width: auto;
            }

            &-right {
                &-upper {
                    flex-direction: row;
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up){
    .room__content-section{
       // padding: rems(20) rems(20) rems(20) rems(20);
    }
	.room__content-section--wide{
        padding: rems(20);
    }
}

// Previous tenants

.room__previous-tenants{
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    width: 100%;

    .profile__preview{
        display: flex;
        flex: 1 0 auto;
        padding-right: rems(20);
    }
}

@include breakpoint(for-tablet-landscape-up){
    .room__previous-tenants{
        .profile__preview{
            flex-basis: 20%;
            width: 20%;
        }
    }
}

// ACTIONS

.profile__preview-actions{
    // background-color: green;
    display: flex;
}


.home__description {
    font-size: rems(18);
    line-height: 1.4;
    padding-bottom: rems(8);
}
