.site__header{
    background-color: var(--color-blue);
    display: block;
    height: $size__header--sm;
    width: 100%;
}

.header__logo{
    display: none;
    @media (min-width: 370px) {
        display: block;
    }

    margin-left: rems(48);
    padding-top: rems(16);
    width: rems(100);

    img{
        display: block;
        height: auto;
        width: 100%;
    }

    &:focus {
        outline: none;
    }
}
.header__logo.menu_hidden {
    margin-left: rems(16);
}

.main__navigation{
    display: block;
}

.site__header--fixed {
    position:fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    &.site__header--demo {
        top: rems(152);
    }
    &.site__header--demo-expanded {
        top: rems(336);
    }
}

#firstDemoContainer {
    padding-top: rems(152);
    &.expanded {
        padding-top: rems(336);
    }
}


#firstContainer {
    padding-top: rems(48);
}
.demo__page {
    #firstContainer {
        padding-top: 0;
    }
}

@include breakpoint(for-large-phone-up) {
    .site__header--fixed {
        &.site__header--demo {
            top: rems(152);
        }
        &.site__header--demo-expanded {
            top: rems(288);
        }
    }

    #firstDemoContainer {
        &.expanded {
            padding-top: rems(288);
        }
    }
}
@include breakpoint(for-large-phone-landscape-up) {
    #firstContainer {
        padding-top: rems(80);
    }
    .site__header{
        height: $size__header--md;
    }
    .header__logo {
        width: rems(116);
        padding-top: rems(20);
        margin-left: rems(56);
    }
    .header__logo.menu_hidden {
        margin-left: rems(24);
    }
    .site__header--fixed {
        &.site__header--demo {
            top: rems(106);
        }
        &.site__header--demo-expanded {
            top: rems(218);
        }
    }
    #firstDemoContainer {
        padding-top: rems(106);
        &.expanded {
            padding-top: rems(218);
        }
    }
}
@include breakpoint(for-tablet-portrait-up) {
    .site__header--fixed {
        &.site__header--demo {
            top: rems(70);
        }
        &.site__header--demo-expanded {
            top: rems(158);
        }
    }
    #firstDemoContainer {
        padding-top: rems(70);
        &.expanded {
            padding-top: rems(158);
        }
    }
}
@include breakpoint(for-small-desktop-up) {
    .site__header--fixed {
        &.site__header--demo-expanded {
            top: rems(134);
        }
    }
    #firstDemoContainer {
        &.expanded {
            padding-top: rems(134);
        }
    }
}

@include breakpoint(for-large-desktop-up) {
    .header__logo {
        margin-left: auto;
        margin-right: auto;
        width: rems(116);
        padding-top: rems(20);
    }

}
