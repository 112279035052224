.coho__dark {
    .wide-nav__wrapper {
        background: var(--color-black);
        .wide-nav__container {
            .wide-nav__content {
                .wide-nav__content-contextual {
                    .accordion {
                        &.accordion--help {
                            margin-bottom: rems(30);
                            background: linear-gradient(90deg, var(--color-orange-dark) 0%, var(--color-black) 1%, var(--color-black) 99%, var(--color-orange-dark) 100%);
                            &.closed {
                                .accordion__title {
                                    .accordion__title-button {
                                        &:hover {
                                            color: var(--color-grey-xlight);
                                            background-color: var(--color-orange-light);
                                            background: linear-gradient(90deg, var(--color-orange-dark) 0%, var(--color-black-light) 1%, var(--color-black-light) 99%, var(--color-orange-dark) 100%);
                                        }
                                    }
                                }
                            }
                        } 
                        &.accordion--panel.closed {
                            .accordion__title {
                                .accordion__title-button {
                                    &:hover {
                                        color: var(--color-grey-xlight);
                                        background-color: var(--color-black-light);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}