.record_container {
    display: flex;
    margin-bottom: rems(20);

    .record_title {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        width: 100%;
        
        p { margin: 0;}
    }

    .record_actions {
        margin-top: rems(10);

        .button {
            margin-right: rems(5);

            + .button {
                margin-left: rems(0);
            }

            &:last-child {
                margin-bottom: 0;
                margin-right: 0;
            }
        }
    }
}

.record_wrapper {
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: radius(large);
    padding: rems(16) rems(16) rems(16) rems(16);
    margin-bottom: rems(20);
    &:last-child {
        margin-bottom: 0;
    }
    .file_documents_container {
        margin-bottom: 0;
    }
}

@include breakpoint(for-large-phone-up) {
    .record_container {
        .record_title {

            

        }


    }
}
@include breakpoint(for-tablet-portrait-up) {
    .record_container {
        .record_title {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;  
            p {
                flex: 1;
                padding-right: rems(40);
            }
        }
        .record_actions {
            margin-top: rems(0);
            margin-bottom: 0;

            .button {
                margin-bottom: 0;
            }
        }        
    }
}
