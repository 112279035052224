.page__form-embed {
    background-color: var(--color-grey-xxlight);
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    padding: rems(20);

    &--cta {
        background-color: var(--color-orange-light);
        border-color: var(--color-orange);
        p:last-child {
            margin-bottom: rems(4);
        }
    }
}
