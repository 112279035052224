@import "base";
@import "typography";
@import "icons";
@import "navigation";
@import "cards";
@import "buttons";
@import "badges";
@import "modal";
@import "infobox";
@import "files";
@import "forms";
@import "accordion";
@import "header";
@import "timeline";
@import "rent";
@import "switch";
@import "conversations";
@import "profile";
@import "news";
@import "helpers";
@import "quick-access";
@import "notification";
@import "pca";
@import "payment";
@import "dropdown";
@import "users";
@import "sections";
@import "headings";
@import "report";
@import "filters";
@import "compact";
@import "context";
@import "notes";
@import "steps";
@import "finance";
@import "feedback";
@import "transactions";
@import "advert";
@import "tags";
@import "dashboard";
@import "widescreen";
@import "tables";
@import "placeholder";

.coho__dark {
    .upsell__container {
        background-color: var(--color-green-xdark);
        border-color: var(--color-green-dark);
        .button.button--payment {
            background-color: var(--color-white);
            color: var(--color-green-xdark);
        }
    }
    .loader {
        .loading__text {
            color: var(--color-grey-xlight);
        }
    }
    .payment__auth {
        .loader {
            .loading__text {
                color: var(--color-grey-xlight);
            }
        }
    }
}
