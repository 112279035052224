.conversation__page {
    .conversation__grid-wrapper {
        .conversation__grid {
            .conversation__grid-right {
                .conversation__grid-header {
                    .conversation__grid-header-context {
                        .tags__container {
                            padding-top: rems(10);
                            padding-bottom: rems(10);
                            flex-direction: column;
                            .tags__list {
                                justify-content: center;
                            }
                            .tags__form {
                                flex-basis:auto;
                                padding-top: rems(10);

                                autocomplete {
                                    input {
                                        max-width: 125px;
                                        &:not(:focus){
                                            border-color: var(--color-grey-xlight) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

