.form__input-numbers{
    display: flex;

    input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]){
        &.form__input-number-only{
            border-radius: 0;
            font-size: rems(24);
            position: relative;
            -moz-appearance: textfield;
            text-align: center;
            width: 20%;      
            z-index: 0;  

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            
            &:first-child{
                border-bottom-left-radius: radius(medium);
                border-top-left-radius: radius(medium);
            }
            
            &:last-child{
                border-bottom-right-radius: radius(medium);
                border-top-right-radius: radius(medium);
            }

            &:not(:last-child){
                border-right: none;
            }

            &:focus{
                z-index: 10;
            }

        }

		&.form__input-verification{
            border-bottom: rems(3) solid var(--color-grey-light);
			border-left: none;
			border-right: none;
			border-top: none;
            border-radius: 0;
            box-shadow: none;
            font-size: rems(24);
			margin: 0 rems(8) 0 0;
            position: relative;
            -moz-appearance: textfield;
            text-align: center;
            width: 20%;      
            z-index: 0;

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            
            &:focus{
                background-color: var(--color-cyan-light);
                border-bottom-color: var(--color-cyan-dark);
                z-index: 10;
            }

        }
    }
}

.form__input-numbers--verify-5{
    max-width: rems(280);
}
