.card--organisation.card--image-left {
    .card__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        .card__image {
            padding-top: rems(8);
            padding-left: 0;
            max-width: rems(48);
            overflow: visible;
            margin-right: rems(12);
            .image__size {
                box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);          
            }
        }
        .card__title {
            h5 {
                font-size: rems(16);
                line-height: 1.3;
            }
        }
    }
    .card__content {
        padding: 0.5rem 1rem 1.5rem 1rem;
    }
}
