.report {
    &.report__rental-statement-v1 {

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        padding-bottom: rems(4);

                        &.col1 {
                            text-align: left;
                            font-weight: 400;
                        }

                        &.col2, &.col4, &.col5 {
                            color: var(--color-grey-border);
                            text-align: right;
                        }

                        &.col3 {
                            color: var(--color-grey-border);
                            text-align: center;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.col1 {
                        }

                        &.col2 {
                            width: 12%;
                            text-align: right;
                        }

                        &.col3 {
                            width: 10%;
                            text-align: left;
                            padding-left: rems(12);
                        }

                        &.col4 {
                            width: 12%;
                            text-align: right;
                        }

                        &.col5 {
                            width: 10%;
                            text-align: right;
                        }
                    }

                    &:last-child {
                        td {
                            padding-bottom: rems(4);
                        }
                    }
                }
            }

            tfoot {
                tr {

                    th {
                        padding-top: rems(4);
                        padding-bottom: rems(12);

                        &.col1 {
                            text-align: left;
                            font-weight: 400;
                        }

                        &.col2 {
                            width: 12%;
                            text-align: right;
                        }

                        &.col3 {
                            width: 10%;
                            text-align: left;
                            padding-left: rems(12);
                        }

                        &.col4 {
                            width: 12%;
                            text-align: right;
                        }

                        &.col5 {
                            width: 10%;
                            text-align: right;
                        }

                        &.col4, &.col5 {
                            border-top: 1px solid var(--color-grey);
                            text-align: right;
                        }
                    }

                    &.report__totals {
                        border-bottom: 1px solid var(--color-grey-light);

                        th {
                            padding-top: rems(12);
                        }

                        &.report__main-total {
                            border-bottom: 1px solid var(--color-black);

                            th {

                                &.col4, &.col5 {
                                    border-top: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.report__rental-statement {

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        padding-bottom: rems(12);

                        &.col1 {
                            text-align: left;
                            font-weight: 400;
                        }

                        &.col2, &.col3, &.col4, &.col5 {
                            color: var(--color-grey-border);
                            text-align: left;
                        }

                        &.col6, &.col7, &.col8 {
                            color: var(--color-grey-border);
                            text-align: right;
                            border-bottom: 1px solid var(--color-grey-border);
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.col1 {
                        }

                        &.col2 {
                            width: 12%;
                            text-align: left;
                        }

                        &.col3 {
                            width: 10%;
                            text-align: left;
                        }

                        &.col4 {
                            width: 12%;
                            text-align: left;
                        }

                        &.col5 {
                            width: 10%;
                            text-align: left;
                        }

                        &.col6 {
                            width: 10%;
                            text-align: right;
                        }

                        &.col7 {
                            width: 10%;
                            text-align: right;
                        }

                        &.col8 {
                            width: 10%;
                            text-align: right;
                        }
                    }

                    &:last-child {
                        td {
                            padding-bottom: rems(4);
                        }
                    }

                    &.balance {
                        td {
                            padding-top: rems(4);
                            padding-bottom: rems(12);
                        }
                    }
                }
            }

            tfoot {
                tr {

                    th {
                        padding-top: rems(12);
                        padding-bottom: rems(12);

                        &.col1 {
                            text-align: left;
                            font-weight: 400;
                            width: 60%;
                        }

                        &.col2 {
                            width: 12%;
                            text-align: left;
                        }

                        &.col3 {
                            width: 10%;
                            text-align: left;
                        }

                        &.col4 {
                            width: 12%;
                            text-align: left;
                        }

                        &.col5 {
                            width: 10%;
                            text-align: left;
                        }

                        &.col6, &.col7, &.col8 {
                            border-top: 1px solid var(--color-grey);
                            text-align: right;
                            width: 10%;
                        }
                    }

                    &.report__totals {
                        //border-bottom: 1px solid var(--color-grey-light);

                        th {
                            padding-top: rems(12);

                            &.col1 {
                                text-align: left;
                                font-weight: 400;
                                width: 44%;
                            }
                        }

                        &.report__main-total {
                            //border-bottom: 1px solid var(--color-black);

                            th {

                                &.col6, &.col7, &.col8 {
                                    border-bottom: 1px solid var(--color-black);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.report__actions {

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        padding-bottom: rems(4);

                        &.col1 {
                            text-align: left;
                            font-weight: 400;
                        }

                        &.col2 {
                            text-align: left;
                            font-weight: 400;
                        }

                        &.col3 {
                            color: var(--color-grey-border);
                            text-align: center;
                        }

                        &.col4 {
                            color: var(--color-grey-border);
                            text-align: right;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.col1 {
                        }

                        &.col2 {
                            text-align: left;
                        }

                        &.col3 {
                            width: 10%;
                            text-align: left;
                            padding-left: rems(12);
                        }

                        &.col4 {
                            width: 12%;
                            text-align: right;
                        }
                    }

                    &:last-child {
                        td {
                            padding-bottom: rems(4);
                        }
                    }
                }
            }
        }
    }
}
