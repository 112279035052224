.coho__dark {
    .rent__payment-content {
        background-color: var(--color-grey-dark);
        //border: 1px solid var(--color-grey-border);
        .rent__payment-profile {
            background-color: var(--color-black);
            box-shadow: 0 0.1875rem 0.375rem 0 rgba(204,204,204, 0.1);
            border: 1px solid var(--color-grey-border);

            .profile__preview-text {
                h6 {
                    color: var(--color-grey-xlight);
                }
            }
        }

        .rent__payment-details-form {
            input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]), textarea {
                background: var(--color-grey-border);
                border: 0.0625rem solid var(--color-grey);
                border-radius: 0.25rem;
                box-shadow: inset 0 0.1875rem 0 0 rgba(204,204,204, 0.07);
                color: var(--color-black);

                &::placeholder {
                    color: var(--color-grey-dark);
                }
            }
        }
    }

    .rent__payments {
        .rent__payment {
            .rent__payment-wrapper {
                background-color: var(--color-grey-dark);
            }
        }
    }

    .ra__message--waiting {
        background-color: var(--color-green-xxdark);
        border-color: var(--color-green-light);
    
        .ra__message-icon {
            .icon {
                fill: var(--color-grey-xlight);
            }
        }
    
        &.ra__message--border {
            border: 1px solid var(--color-green-light);
        }
    
        &.ra__message--no-border {
            border: none;
        }

        .ra__message-text {
            h5,p {
                color: var(--color-grey-xlight);
            }
        }
    }
    
    .ra__message--queued {
        background-color: var(--color-orange-xdark);
        border-color: var(--color-orange-light);
    
        .ra__message-icon {
            .icon {
                fill: none;
                stroke: var(--color-grey-xlight);
            }
        }
    
        &.ra__message--border {
            border: 1px solid var(--color-orange-light);
        }

        .ra__message-text {
            h5,p {
                color: var(--color-grey-xlight);
            }
        }
    }

}
