
.gallery__card-wrapper {
    padding: rems(10) rems(15) rems(20) rems(10);
}
a.card--tenancy,.card--tenancy {

    //padding: rems(10);
    max-width: rems(660);

    .card__header {

        border-top-left-radius: radius(medium);
        border-top-right-radius: radius(medium);
        h5 {
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .card__content {
        .profile__preview--room .profile__preview-text {
            margin-left: rems(40);
        }
    }

    &:focus {
        outline: none;
    }

    &-orange {
        .card__header {
            background-color: var(--color-orange-xdark);
            color: white;
        }
        box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-orange-xdark-rgb),0.25);
        &:hover {
            box-shadow: 0 rems(8) rems(16) 0 rgba(var(--color-orange-xdark-rgb),0.25);
        }
    }

    &-green {
        .card__header {
            background-color: var(--color-green-xxdark);
            color: white;
        }
        box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-green-xxdark-rgb),0.25);
        &:hover {
            box-shadow: 0 rems(8) rems(16) 0 rgba(var(--color-green-xxdark-rgb),0.25);
        }
    }

    &-grey {
        .card__header {
            background-color: var(--color-grey);
            color: white;
        }
        box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-black-rgb),0.25);
        &:hover {
            box-shadow: 0 rems(8) rems(16) 0 rgba(var(--color-black-rgb),0.25);
        }
    }

}
