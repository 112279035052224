.form {
    &.form--payment {
        background: var(--color-green-light);
        border-radius: radius(medium);
        padding-top: rems(10);

        .form__header {
            display: flex;

            h2 {
                flex-basis: 100%;
            }

            .payment__logos {
                display: flex;
                flex-direction: column;
                margin-top: 0;

                .icon {
                    //margin-bottom: rems(4);
                    svg {
                        height: rems(24);
                    }
                }
            }
        }

        .form__label {
            span {
                color: var(--color-grey);
                font-weight: 400;
            }
        }
    }
}

@include breakpoint(for-large-phone-up) {
    .form {
        &.form--payment {

            .form__header {

                .payment__logos {
                    flex-direction: row;

                    .icon {
                        margin-left: rems(8);
                        //margin-bottom: 0;
                        svg {
                            height: rems(30);
                        }
                    }
                }
            }
        }
    }
}
