.list{
    list-style: disc;
    padding-left: rems(16);

    li{
        list-style-position: outside;
        padding-left: rems(0);
        margin-bottom: rems(10);
    }
}


.list__icons{
    list-style: none;
    padding: 0;

    li{
        clear: both;
        display: block;
        margin-bottom: rems(10);
        min-height: rems(30);
        position: relative;
        width: 100%;
    }

    .list__icons-title{
        border-bottom: 1px solid var(--color-grey-xlight);
    }

}

.list__icons-icon{
    position: absolute;
    width: rems(30);

    .icon{
        display: block;
        fill: var(--color-blue);
        height: rems(30);
        width: rems(30);
    }




}

.list__icons-text{
    display: inline-block;
    margin-left: rems(40);
    margin-top: rems(3);
    min-height: rems(30);
}

.list__icons-photos{
    display: flex;
    flex-wrap: wrap;
    margin-left: rems(40);
    margin-top: rems(3);
    padding: 0;

    li{
        align-items: center;
        border: 1px solid var(--color-grey-xlight);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: rems(10);
        padding: rems(5);
        width: rems(90);

        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
}


.list__icons{
    &.list__icons--small{
        font-size: rems(14);

        li{
            margin-bottom: rems(5);
        }

        .list__icons-icon{
            
            .icon{
                height: rems(24);
                width: rems(24);

                svg{
                    height: rems(24);
                    width: rems(24);
                }

            }
        }

        .list__icons-text{
            margin-top: 0;
            min-height: rems(20);
        }

    }
}