.marketing__block{
    display: flex;
    flex-direction: column;
    padding-bottom: rems(30);
    width: 100%;

    hr{
        border-bottom: 1px solid var(--color-grey-light);
        display: block;
        height: 1px;
        width: 100%;
    }
      ul {
        padding-left: 1rem;
      }    
}

.marketing__block-content{
    width: 100%;

}

.marketing__block-image{
    padding-bottom: rems(20);
    width: 100%;

    img{
        height: auto;
        max-width: 100%;
    }
}

.marketing__icon-holder {
    min-height: rems(154);
    padding-bottom: rems(20);
}

.marketing__block{
    &.marketing__block--header{
        border-bottom: rems(1) dotted var(--color-grey-light);
        padding-bottom: rems(0);
        padding-top: rems(0);

        h2{
            margin-bottom: rems(10);

            + p{
                margin-top: rems(0);
            }
        }

        p{
            color: var(--color-grey);
            font-size: rems(18);
            line-height: rems(22);
        }

        + .layout__grid{
            padding-top: rems(30);
        }

    }

    &.marketing__block--action{
        padding-bottom: 0;
        padding-top: 0;
        
        .marketing__block-content{
            align-items: center;
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            width: 100%;
        }
    }
    &.marketing__block--browse{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: rems(800);
        margin: 0 auto;
        flex-wrap: wrap;
        padding-top: 0;
    }
}

@include breakpoint(for-tablet-portrait-up){
    .marketing__block{
        flex-direction: row;
        padding-bottom: rems(30);
        padding-top: rems(30);
    }

    .marketing__block-content{
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        justify-content: center;
        width: 38.2%;
    
    }
    
    .marketing__block-image{
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        justify-content: center;
        padding-bottom: rems(0);
        padding-right: rems(50);
        width: 61.8%;
    }

    .marketing__block{
        &.marketing__block--reverse{
            flex-direction: row-reverse;
            .marketing__block-image{
                padding-left: rems(50);
                padding-right: rems(0);            
            }
        }

        &.marketing__block--full-width{
            flex-direction: row;

            .marketing__block-content{
                padding-left: rems(0);
                padding-right: rems(0);
                width: 100%;          
            }
        }


        &.marketing__block--header{
            padding-bottom: rems(0);
            padding-top: rems(0);

            .marketing__block-content{
                padding-left: rems(0);
                padding-right: rems(0);

                p{
                    max-width: rems(700);
                }
            }

            + .layout__grid{
                padding-top: rems(50);
            }
        }

    }

}
