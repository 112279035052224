.coho__dark {

    .advert {
        background-color: var(--color-violet-xdark);

        .intro, .dismiss {
            color: volor(violet,light);
        }
    }

    .button--advert {
        background-color: var(--color-violet);
        border-color: var(--color-violet-dark);
        &:hover {
            background-color: var(--color-violet-dark);
        }
    }

    .advert {
        .button--ghost {
            background-color: var(--color-violet-dark);
            color: var(--color-grey-xxlight);
        }
    }

}