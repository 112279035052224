@keyframes dot__anim {
    0%{
        transform: scale(0.2);
    }

    50%{
        transform: scale(1);
    }

    100%{
        transform: scale(0.2);
    }
}


.loader {
    text-align: center;
    padding: rems(10) rems(20);
    height: auto;
    width: 100%;

    .loading__text {
        color: var(--color-grey);
    }

    .loader__dot {
        animation: dot__anim 2s infinite;
        background-color: var(--color-grey);
        border-radius: 100%;
        display: inline-block;
        margin-left: rems(4);
        margin-right: rems(4);
        height: rems(12);
        transform: scale(0.2);
        width: rems(12);

        &:nth-child(1) {
            background-color: var(--color-blue);
            animation-delay: 0.2s;
        }

        &:nth-child(2) {
            background-color: var(--color-cyan);
            animation-delay: 0.4s;
        }

        &:nth-child(3) {
            background-color: var(--color-teal);
            animation-delay: 0.8s;
        }

        &:nth-child(4) {
            background-color: var(--color-pink);
            animation-delay: 1s;
        }

        &:nth-child(5) {
            background-color: var(--color-magenta);
            animation-delay: 1.2s;
        }
    }

    &.loader--small {
        .loading__text {
            font-size: rems(14);
        }

        .loader__dot {
            &:nth-child(1) {
                background-color: var(--color-blue);
                animation-delay: 0.2s;
            }

            &:nth-child(2) {
                background-color: var(--color-cyan);
                animation-delay: 0.4s;
            }

            &:nth-child(3) {
                background-color: var(--color-magenta);
                animation-delay: 0.8s;
            }
        }
    }
    &.loader--tiny {
        max-width: rems(48);
        width: rems(48);
        padding: 0;

        .loading__text {
            display:none;
        }

        .loader__dot {

            margin-left: rems(2);
            margin-right: rems(2);
            height: rems(8);
            transform: scale(0.2);
            width: rems(8);

            &:nth-child(1) {
                background-color: var(--color-blue);
                animation-delay: 0.2s;
            }

            &:nth-child(2) {
                background-color: var(--color-magenta);
                animation-delay: 0.4s;
            }
        }
    }
}

.payment__auth {
    .loader {
        .loading__text {
            color: var(--color-grey);
        }

        .loader__dot {
            &:nth-child(1) {
                background-color: var(--color-green-xdark);
            }

            &:nth-child(2) {
                background-color: var(--color-green);
            }

            &:nth-child(3) {
                background-color: var(--color-green-dark);
            }

            &:nth-child(4) {
                background-color: var(--color-green);
            }

            &:nth-child(5) {
                background-color: var(--color-green-xdark);
            }
        }
    }
}

.loader__inline {
    display: flex;
    align-items: center;
  
    .loader__icon {
      width: rems(30);
      height: rems(30);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rems(10);
    }
  
    .loader__text {
      display: flex;
      align-items: center;
      color: var(--color-grey-border);
    }
  }
  