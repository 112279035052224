.form__group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: rems(20);
    min-height: rems(48);

    .form__select{
        margin-bottom: 0;
        &.form__select--margin {
            margin-bottom: rems(20);
        }
    }

    input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]), textarea{
        //margin-bottom: 0;
    }

    .form__message{
        margin-top: rems(20);
    }

    &.form__group--divider{
        border-bottom: 1px solid var(--color-grey-light);
    }

    &.form__group--margin {
        padding-bottom: rems(20);
    }

    &.form__group--auto-width{
        align-items: flex-start;
    }


    &.form__group--collapse{
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.form--inline {
    .form__group {
        &.form__group--divider {
            border-color: (grey, border);
        }
    }
}

@include breakpoint(for-tablet-portrait-up){
    .form__group{

        &.form__group--inline{
            align-items: center;
            display: flex;
            flex-direction: row;

            label{
                display: flex;
                flex: 1 0 auto;
                margin-bottom: 0;
                margin-right: rems(20);
                width: auto;
            }

            &.form__group--inline-aligned-right{
                label{
                    align-self: center;
                    justify-content: flex-end;
                    text-align: right;
                    width: 25%;
                }
            }
            
        }
    }  
}
