.coho__dark {
    .conversation__page {
        .conversation__grid-wrapper, .conversation__grid-wrapper.no__thread {
            .conversation__grid {
                border-color: var(--color-grey-dark);

                .conversation__grid-list {
                    background-color: var(--color-black);
                    border-color: var(--color-grey-dark);

                    &::-webkit-scrollbar-track {
                        background: var(--color-grey);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--color-grey-light);
                        border: rems(3) solid (grey);
                    }

                    ul {
                        li {
                            border-bottom-color: var(--color-grey-dark);
                            &.load-more {
                                background-color: var(--color-cyan-xdark);
                                &:hover {
                                    background-color: var(--color-cyan-dark);
                                }
                            }

                            &:hover {
                                background-color: var(--color-grey-dark);
                            }

                            a {
                                color: var(--color-grey-xlight);

                                .thread__list-header {
                                    .thread__list-header-datetime {
                                        color: var(--color-grey-border);
                                    }
                                }
                            }

                            &.active {
                                background-color: var(--color-grey-dark);
                            }
                        }
                    }
                }

                .conversation__grid-right {
                    .conversation__grid-header {
                        border-color: var(--color-grey-dark);

                        .header__show {
                            color: var(--color-grey);

                            .icon {
                                fill: var(--color-grey-light);
                            }

                            &:hover {

                                .icon {
                                    fill: var(--color-grey);
                                }
                            }
                        }
                    }

                    .conversation__grid-messages {
                        .conversation__grid-messages-content {
                            &::-webkit-scrollbar-track {
                                background: var(--color-grey);
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: var(--color-grey-light);
                                border: rems(3) solid (grey);
                            }

                            .messages__details-wrapper {
                                ul {
                                    li {
                                        &.messages__details {

                                            &.message__thread--contextual {

                                                .messages__details-content {
                                                    display: flex;
                                                    justify-content: center;
                                                    background-color: var(--color-grey-xxlight);

                                                    .messages__details-participant,
                                                    .messages__details-text {
                                                        color: var(--color-grey-light);

                                                        span.messages__details-when {
                                                            color: var(--color-grey-light);
                                                        }
                                                    }
                                                }
                                            }

                                            .messages__details-content {
                                                .messages__details-participant,
                                                .messages__details-text {
                                                    color: var(--color-grey-xlight);

                                                    span.messages__details-when {
                                                        color: var(--color-grey-xlight);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .conversation__grid-messages-footer {
                            .conversation__grid-messages-footer-send {
                                textarea {
                                    background-color: var(--color-black);
                                }
                            }
                        }
                    }
                }
            }

            &.no__thread-list {
                .conversation__grid {
                    background: var(--color-grey-dark);

                    .conversation__grid-list {
                        background: var(--color-grey-dark);

                        .zero__state {
                            color: var(--color-grey-xlight);
                        }
                    }
                }
            }
        }

        .nav__secondary {
            .container {
                .messages__close {
                    &:hover {
                        .icon {
                            fill: var(--color-grey-xlight);
                        }
                    }
                }
            }
        }
    }


    .message__contextual-action {
        background-color: var(--color-teal-dark);
        border: var(--color-teal);
    }

    conversation-tab {
        .conversation__grid-messages {
            .conversation__grid-messages-content {
                &::-webkit-scrollbar-track {
                    background: var(--color-grey);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--color-grey-light);
                    border: rems(3) solid (grey);
                }

                .messages__details-wrapper {
                    ul {
                        li {
                            &.messages__details {

                                &.message__thread--contextual {

                                    .messages__details-content {
                                        display: flex;
                                        justify-content: center;
                                        background-color: var(--color-grey-xxlight);

                                        .messages__details-participant,
                                        .messages__details-text {
                                            color: var(--color-grey-light);

                                            span.messages__details-when {
                                                color: var(--color-grey-light);
                                            }
                                        }
                                    }
                                }

                                .messages__details-content {
                                    .messages__details-participant,
                                    .messages__details-text {
                                        color: var(--color-grey-xlight);

                                        span.messages__details-when {
                                            color: var(--color-grey-xlight);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .conversation__grid-messages-footer {
                .conversation__grid-messages-footer-send {
                    textarea {
                        background-color: var(--color-black);
                    }
                }
            }
        }
    }

    .service__offer {
        background-color: var(--color-cyan-dark);
    }
    .service__offer-panel {
        background: var(--color-grey-dark);
    }
    .debug {
        background-color: #8e7622;
    }

    //NEW STUFF
    //light-3 > dark
    //light-2 > grey
    //light > grey
    //xlight > grey
    //text > white
    //white > text
    //grey > light

    //LEFT SIDE (CONVERSATION LIST)

    .conversation__grid-wrapper {
      border: 1px solid var(--color-grey);
    }

    filter-and-new-conversation {
        border-bottom: 1px solid var(--color-grey);
    }

    conversation-list-item {
        border-bottom: 1px solid var(--color-grey);

        .u-text--caption {
          color: var(--color-grey-xxlight)
        }

        .text_and_icon { //JON_TODO
          svg {
            fill: var(--color-grey-light);
          }
        }
    }

    conversation-list-item.active {
        background-color: var(--color-grey);
    }

    conversation-list-item:hover {
        background-color: var(--color-grey-dark);
    }

    .load-more:hover {
        color: var(--color-primary);
        background-color: var(--color-grey-dark);
    }

    //MIDDLE (CONVERSATION)

    .no_active_conversation {
        background-color: var(--color-grey-dark);
        color: var(--color-grey);

        svg {
            fill: var(--color-grey);
        }
    }

    conversation-header {
        border-bottom: 1px solid var(--color-grey);

        a {
            color: var(--color-white);
        }
    }

    conversation-details {
        border-left: 1px solid var(--color-grey);
    }

    message-list-item {
        .other_users {
            .details_content {
                background-color: var(--color-grey-dark);
            }
        }

        .current_user {
            .details_content {
                background-color: var(--color-primary-dark);
            }
        }
    }

    message-sender {
        background-color: var(--color-grey);
        .message_form {
            background-color: var(--color-grey);
        }
    }
}


