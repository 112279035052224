.button--cancel{
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    color: var(--color-blue);
    font-weight: 600;
    min-width: auto;
    text-decoration: underline;

    &:hover{
        background-color: transparent;
        border-color: transparent;
        color: var(--color-blue);
    text-decoration: none;
    }

    &:focus{
        border: 0;
        box-shadow: none;
        outline: none;
    }

    &:disabled, &.disabled{
        background-color: transparent;
        border: 0;
        box-shadow: none;
        color: var(--color-grey-light);
        text-decoration: none;

        &:hover{
            background-color: transparent;
            border: 0;
            box-shadow: none;
            color: var(--color-grey-light);
            text-decoration: none;
        }
    }

}

.button {
    &.button--cancel {
        &.button--small {
            padding-left: rems(2);
            padding-right: rems(2);
            min-width: auto;
        }
        &.button--tiny {
            &:focus{
                border: 0;
                box-shadow: none;
                outline: none;
            }
        }
    }
}
