
.card{
    &.card__listing {
        &.card--image-left{
            .card__image-content{
                .card__image{
                    padding-top: rems(8);
                    max-width: rems(40);
                    margin-bottom: 0;
                    padding-left: rems(8);
                    img {
                        height: auto;
                        width: 100%;
                    }
                }
                .card__content {
                    padding: 0;
                    padding-left: rems(8);
                    display: flex;
                    //align-items: center;
                    font-weight: 400;
                }
            }
        }
    }
}

.listing {
    padding: rems(8) 0;
    height: 100%;
    border: rems(1) solid var(--color-grey-xlight);
    border-radius: radius(small);
    padding: rems(8);
    padding-bottom: 0;

    .listing__header {
        display: flex;
        flex-direction: row;
        .listing__header--icon {
            svg {
                width: rems(40);
                height: rems(40);
            }
            padding: rems(2) rems(12) 0 rems(4);
        }
        h4 + p {
            margin-top: rems(2);
        }
    }

    .listing__content {

        .card__image, .form__message .form__message-icon {
            svg, .icon {
                display: block;
                width: rems(30);
                height: rems(30);
            }
        } 

        a.card {
            &:focus {
                outline: 0;
            }
        }
        .card__listing {

            .card__image-content {
                border-radius: radius(small);
                outline: 1px solid var(--color-grey-border);
                background-color: var(--color-grey-xlight);
                width: 100%;
            }

            .card__content {

                p {
                    line-height: 1.1;
                    margin: rems(6) 0;
                    position: relative;

                    span.listing__status,&.listing__status {

                        text-transform: uppercase;
                        color: var(--color-white);
                        background-color: var(--color-grey-border);
                        font-size: rems(12);
                        border-radius: radius(small);
                        padding: rems(1) rems(4);
                    }
                }
            }

            &.active {
                .card__image-content {
                    background-color: var(--color-white);
                }
                
                .card__content {
                    p {
                        &.listing__status {
                            background-color:var(--color-grey);
                            position: absolute;
                            top: 0;
                            right: rems(8);
                            padding: rems(2) rems(4);
                        }
                    }
                }
            }
            &.settings {
                .card__image-content {
                    background-color: var(--color-grey-xlight);
                    background-color: rgba(var(--color-grey-xlight-rgb),0.5);
                }
            }

            &.updating, &.uploading {
                .card__content {
                    p {
                        &.listing__status {
                            background-color:var(--color-orange-dark);
                            position: absolute;
                            top: 0;
                            right: rems(8);
                            padding: rems(2) rems(4);
                        }
                    }
                }
            }

            &.error {
                .card__content {
                    p {
                        &.listing__status {
                            background-color:var(--color-pink-dark);
                            position: absolute;
                            top: 0;
                            right: rems(8);
                            padding: rems(2) rems(4);
                        }
                    }
                }
            }
        }
    }

    &.listing--border {
        border: rems(2) solid var(--color-teal-light);

        .listing__content {
            .card__listing {

    
                &.info {
                    .card__image-content {
                        outline: 1px solid var(--color-teal);
                        outline: 1px solid rgba(var(--color-teal-rgb),0.5);
                        background-color: var(--color-teal-xlight);
                        background-color: rgba(var(--color-teal-xlight-rgb),0.6);
                    }
                }
    
                &.active {
                    .card__image-content {
                        outline: 1px solid var(--color-green);
                        background-color: var(--color-green-light);
                        background-color: rgba(var(--color-green-light-rgb),0.5);
                    }
                    .card__content {
                        p {
                            &.listing__status {
                                background-color:var(--color-green-xdark);
                            }
                        }
                    }
                }
    
            }
        }
    }

}



