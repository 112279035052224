.accordion{
    &.accordion--flat{
        //border-bottom: 1px solid var(--color-grey-xlight);
        box-shadow: none;
        margin-bottom: 0;
    
        .accordion__title{
            border-bottom: none;
            
            .accordion__title-button{
                border-radius: rems(0);
                font-weight: 800;
                padding-left: rems(40);
                padding-right: rems(0);
                text-decoration: none;
            
                &::after{
                    left: 0;
                }
    
            }
        }

        .accordion__content{

            padding: 0 0 rems(20) 0;
        }
    
    }
    
    + .accordion.accordion--flat{
        margin-top: 0;
    }
}

.page__section--alt{
    .accordion{
        &.accordion--flat{
            background-color: transparent;
            border-bottom: 1px solid var(--color-grey-light);

            .accordion__title, .accordion__title-button{
                background-color: transparent;
            }
        }
    }
}
