.wide-nav__wrapper {
    display: none;
    &.toggle__target.open {
        display: block;
    }

    position: fixed;
    right: 0;
    top: $size__header--sm;
    width: 100%;
    height: calc(100% - $size__header--sm);
    z-index: 999;
    background: var(--color-white);
    box-shadow: rems(-10) 0 rems(10) 0 rgba(var(--color-black-rgb),0.25);
    padding-bottom: rems(20);

    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: rems(12);
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-grey-border);
        border-radius: rems(20);
        border: rems(3) solid white;
    }

    .wide-nav__container {
        padding: rems(20) 0 rems(20) 0;
        font-size: rems(24);
        height: auto;



        .wide-nav__header {
            padding: 0 rems(20) 0 rems(20);
            display: flex;
            flex-direction: row;
            height: rems(52);
            .wide-nav__header-search {
                flex: 1 0 calc(100% - rems(72));
                padding-right: rems(5);
                //padding-top: rems(4);
                input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]), textarea {
                    height: rems(34);
                    font-size: rems(15);
                }

                #coho__search--results {
                    display: none;

                    &.visible {
                        display: block;
                        position: absolute;
                        top: rems(58);
                        z-index: 999999;
                        //right: 0;
                        width: calc(100% - 34px);
                        max-width: rems(570);

                        .results__container {
                            background: var(--color-white);
                            border-radius: radius(small);
                            padding: rems(4);
                            //min-height: rems(100);
                            border: 1px solid var(--color-grey-light);
                            max-height: 75vh;
                            overflow-y: auto;


                            &::-webkit-scrollbar {
                                width: rems(12);
                            }

                            &::-webkit-scrollbar-track {
                                background: var(--color-grey-xlight);
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: var(--color-grey-border);
                                border-radius: rems(20);
                                border: rems(3) solid var(--color-grey-xlight);
                            }

                            .zero-state {
                                padding: rems(4) 0 0 rems(6);

                                p {
                                    text-shadow: none;
                                    color: var(--color-black);
                                    margin: 0;
                                }
                            }

                            a {
                                text-decoration: none;

                                .property {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    padding: rems(4);
                                    /*margin: rems(2) 0;*/
                                    border-radius: radius(small);

                                    &:hover {
                                        background: var(--color-grey-xlight);
                                    }

                                    .image {
                                        /*background: var(--color-teal-dark);*/
                                        border-radius: radius(small);
                                        padding: rems(4);
                                        padding-bottom: rems(4);
                                        margin-right: rems(8);

                                        .image__size {
                                            width: rems(64);
                                        }
                                    }

                                    .area__description {
                                        p {
                                            color: black;
                                            margin: 0;
                                            line-height: 1.2;
                                            text-shadow: none;
                                            color: var(--color-grey);
                                            font-size: rems(14);

                                            .span, strong {
                                                font-weight: 700;
                                                font-size: rems(16);
                                                color: var(--color-black);
                                            }
                                        }
                                    }
                                }

                                &.status--future {
                                    .property {
                                        background: var(--color-green-light);

                                        &:hover {
                                            background: var(--color-grey-xlight);
                                        }
                                    }
                                }

                                &.status--past {
                                    .property {
                                        background: var(--color-teal-light);

                                        &:hover {
                                            background: var(--color-grey-xlight);
                                        }
                                    }
                                }

                                &.status--cancelled {
                                    .property {
                                        background: var(--color-red-light);

                                        &:hover {
                                            background: var(--color-grey-xlight);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .wide-nav__header-options {
                flex-basis: rems(72);
            }
        }

        .wide-nav__header + .wide-nav__content {
            margin-top: rems(24);
        }

        .wide-nav__content {
            transition: margin-top 0.2s ease-in;
            padding: rems(20) rems(20) 0 rems(20);
            h5 {
                font-weight: 400;
                color: var(--color-grey)
            }
            h5 + * {
                margin-top: rems(8);
            }
            .wide-nav__content-global {
                padding-bottom: rems(10);

            }
            .wide-nav__content-start {
                padding-bottom: rems(10);
             }
            .wide-nav__content-new {
                padding-bottom: rems(10);
             }
            .wide-nav__content-contextual {
                padding-top: rems(10);
                padding-right: rems(10);
                .divider {
                    font-weight: 400;
                    color: var(--color-grey);
                    font-size: rems(12);
                    line-height: 1.2;
                    border-bottom: rems(1) solid var(--color-grey-xlight);
                    margin: rems(4) rems(10) rems(10) rems(10);
                    padding: 0 0 5px 0;
                }
                .accordion {
                    &.accordion--panel {
                        margin-bottom: rems(8);
                        .accordion__content {
                            padding-top: 0;
                            //padding-bottom: 0;
                            .card.card__access.card--image-left .card__image-content .card__content {
                                font-weight: 600;
                                padding-left: rems(6);
                                p {
                                    font-size: rems(14);
                                    line-height: 1.1;
                                }

                            }
                            .card.card__access.card--image-left .card__image-content .card__image {
                                padding-top: rems(4);
                                padding-left: rems(4);
                                .icon svg {
                                    width: rems(32);
                                    height: rems(32);
                                }
                            }
                            .card.card__access.card--image-left {
                                &.card--hasFocus {
                                    background: var(--color-orange-light);
                                    outline: rems(1) solid var(--color-orange);
                                    outline-offset: 0;
                                }
                            }
                        }
                    }
                    &.accordion--help {
                        margin-bottom: rems(30);
                        background: linear-gradient(90deg, var(--color-orange) 0%, var(--color-white) 1%, var(--color-white) 99%, var(--color-orange) 100%);
                        &.closed {
                            .accordion__title {
                                .accordion__title-button {
                                    &:hover {
                                        color: var(--color-text);
                                        background-color: var(--color-orange-light);
                                        background: linear-gradient(90deg, var(--color-orange) 0%, var(--color-orange-light) 1%, var(--color-orange-light) 99%, var(--color-orange) 100%);
                                    }
                                }
                            }
                        }
                    }
                }
             }

        }

    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .wide-nav__wrapper {
        top: 0;
        width: 90%;
        border-left: solid rems(1) var(--color-grey-light);
        height: 100%;
        z-index: 1000;
        .wide-nav__container {
            .wide-nav__header {
                height: rems(52);
            }
        }
    }
}
@include breakpoint(for-tablet-portrait-up) {
    .wide-nav__wrapper {
        max-width: rems(660);
    }
}

@include breakpoint(for-large-desktop-up) {

    .wide-nav {
        .container {
            margin-right: rems(30);
        }
        .showLeft { display: block; }
        .showRight { display: none; }
    }

    .wide-nav__wrapper {
        display: block;
        position: fixed;
        right: unset;
        left: 0;
        top: 0;
        width: calc(100% - rems(1020));
        max-width: $max-width__wide-nav;
        border-left: 0;
        border-right: solid rems(1) var(--color-grey-border);
        box-shadow: none;
        &.toggle__target {
            display: block;
        }

        .wide-nav__container {
            //padding: rems(20) rems(10) 0 rems(20);
            padding-top: rems(20);
            font-size: rems(24);
            .wide-nav__header {
                padding: 0 rems(10) 0 rems(20);
                .wide-nav__header-search {
                    flex: 1 0 calc(100% - rems(148));
                }
                .wide-nav__header-options {
                    flex-basis: rems(148);
                }
            }
            .wide-nav__content {
                padding: rems(8) rems(10) 0 rems(20);
                .accordion {
                    &.accordion--panel {
                        .accordion__title {
                            .accordion__title-button {
                                font-size: rems(16);
                                padding: rems(12);
                                padding-left: rems(16);
                                font-weight: 400;
                                color: var(--color-grey);

                                transition: padding 0.25s ease-in;
                            }
                        }
                    }
                 }
            }
        }
    }

    .wide-nav--right {
        .wide-nav__wrapper {
            right: 0;
            left: unset;
            border-left: solid rems(1) var(--color-grey-light);
            border-right: 0;
        }

        .container {
            margin-left: rems(30);
            margin-right: auto
        }

        .showLeft { display: none; }
        .showRight { display: block; }

    }

    .minimise-nav {
        .showLeft { display: block; }
        .showRight { display: none; }
    }

    .minimise-nav__wrapper {
        display: block;
        position: fixed;
        right: unset;
        z-index: 1001;
        background: #FFF;
        left: 0;
        top: 0;
        width: $max-width__minimise-nav;
        max-width: $max-width__minimise-nav;
        border-left: 0;
        border-right: solid rems(1) var(--color-grey-border);
        box-shadow: none;
        height: 100%;

        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: rems(6);
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-grey-border);
            border-radius: rems(10);
            border: rems(1) solid white;
        }

        &.toggle__target {
            display: block;
        }

        .minimise-nav__container {
            padding: rems(4) rems(7) rems(8) rems(7);

            .minimise-nav__content-contextual {
                margin-left: rems(-2);
                margin-right: rems(-2);
                a {
                    display:block;
                    width: rems(40);
                    height: rems(40);
                    margin-bottom: rems(6);
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &:focus {
                        outline: none;
                    }
                    img {
                        width: 100%;
                    }
                    svg {
                        width: rems(40);
                        height: rems(40);
                    }
                    &.card--hasFocus {
                        background-color: var(--color-orange-light);
                        outline: rems(1) solid var(--color-orange);
                        outline-offset: 0;
                    }
                }
                hr {
                    margin-bottom: rems(10);
                }
            }
        }
    }

    .wide-nav--right {
        .minimise-nav__wrapper {
            right: 0;
            left: unset;
            border-left: solid rems(1) var(--color-grey-light);
            border-right: 0;
        }

        .showLeft { display: none; }
        .showRight:not(.show-for-xlarge) { display: block; }
    }

    .wide-nav--right:not(.wide-nav--minimised) {
        .container {
            margin-left: rems(30);
            margin-right: auto;
        }
    }

    .wide-nav--minimised {
        .container {
            margin-left: auto;
            margin-right: auto;
        }
    }
}


.resizable-handle {
    width: rems(4);
    cursor: ew-resize;
    top: 0;
    bottom: 0;
    background-color: var(--color-grey-xlight);
    height: 100%;
    position: absolute;
    border-right: 1px solid var(--color-grey-light);
}


@media (min-width: 1756px) and (max-width: 1900px) {
    .wide-nav:not(.wide-nav--minimised) {
        .container{
            margin-right: calc((100% - $max-width__wide-nav - rems(960)) / 2 );
            margin-left: calc(((100% - $max-width__wide-nav - rems(960)) / 2) + $max-width__wide-nav );
        }
    }
    .wide-nav--right:not(.wide-nav--minimised) {
        .container{
            margin-left: calc((100% - $max-width__wide-nav - rems(960)) / 2 );
            margin-right: calc(((100% - $max-width__wide-nav - rems(960)) / 2) + $max-width__wide-nav );
        }

    }
    .wide-nav--minimised {
        .container {
            margin-left: auto;
            margin-right: auto;
        }
    }
  }

@include breakpoint(for-wide-desktop-up) {

    .wide-nav__wrapper {
        width: $max-width__wide-nav;
    }
    .wide-nav {
        .site__header--logged-in {
            .container {
                width: rems(1120);
            }
        }
        .container{
            width: rems(1120);
        }
    }

    .wide-nav:not(.wide-nav--minimised) {
        .container{
            margin-right: calc((100% - $max-width__wide-nav - rems(1120)) / 2 );
            margin-left: calc(((100% - $max-width__wide-nav - rems(1120)) / 2) + $max-width__wide-nav );
        }
    }

    .wide-nav--right:not(.wide-nav--minimised) {

        .container {
            margin-left: calc((100% - $max-width__wide-nav - rems(1120)) / 2 );
            margin-right: calc(((100% - $max-width__wide-nav - rems(1120)) / 2) + $max-width__wide-nav );
        }
    }
    .wide-nav--minimised {
        .container {
            margin-left: auto;
            margin-right: auto;
        }
    }

}

@media (min-width: 1440px) and (max-width: 1574px) {
    .wide-nav__wrapper {
        .wide-nav__container {
            .wide-nav__content {
                .wide-nav__content-contextual {
                    .accordion {
                        &.accordion--panel {
                            .accordion__content {
                                .col.col__lg--4 {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }
