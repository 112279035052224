.partners__cover{
    background-color: var(--color-blue-dark);
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: 95vh;
    padding-top: rems(80);
    position: relative;

    &::after{
        background: linear-gradient(rgba(#2d2d2d,0.9), rgba(#2d2d2d,0.6));
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }


    .partners__header{
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        z-index: 2;
    }
    
    .partners__cover-content{
        align-items: flex-start;
        display: block;
        flex: 1 0 auto;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: rems(20);
        padding-right: rems(20);
        position: relative;
        text-align: left;
        z-index: 1;

        h1 {
            text-shadow: 0px 2px 10px rgba(0,0,0,0.33);
        }
          .button--login {
            margin-left: rems(8);
          }    
    }
    
    
    &.partners__cover--slim {
        min-height: 15vh;
    }
    &.partners__cover--subtitled {
        .partners__cover-content{
            p {
                margin-bottom: 0;
            }
        }

    }      

}



@include breakpoint(for-tablet-portrait-up){
    .partners__cover {
        .partners__cover-content{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            max-width: rems(768);
            text-align: center;
            width: rems(768);
            h1 {
                margin-top: rems(-80);
            }
            .button--login {
              display: none;
            }        
        }    
        .landing {
            .partners__cover-content{
                padding-top: 10vh;
                justify-content: flex-start;
            }    
        } 
        &.partners__cover--slim {
            min-height: 25vh;

        }
        &.partners__cover--subtitled {
            min-height: 20vh;
            .partners__cover-content{
                p {
                    margin: 0;
                    margin-top: rems(-30);
                }
                h1 {
                    margin-top: 0;
                }
            }

        }   

    }
}




