.partner__holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .partner__holder-image {
        padding-bottom: rems(10);

        img {
            width: rems(120);
        }

        &--large {
            img {
                width: rems(240);
            }
        }
    }

    .partner__holder-content {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid var(--color-grey-light);
        padding-bottom: rems(20);
        margin-bottom: rems(30);

    }
}

.partner__holder-content--border {
    border-top: 1px solid var(--color-grey-border);
    padding-top: rems(20);
    margin-top: rems(30);
}


@include breakpoint(for-tablet-landscape-up) {
  

    .partner__holder-content--lower {
        max-width: rems(800);
        margin-left: auto;
        margin-right: auto;
        
    }


}
