conversation-tab {
    .conversation__grid-messages {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .conversation__grid-messages-content {
            flex: 1;
            width: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            gap: rems(10);

            &::-webkit-scrollbar {
                width: rems(12);
            }

            &::-webkit-scrollbar-track {
                background: white;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-grey-dark);
                border-radius: rems(20);
                border: rems(3) solid white;
            }

            .messages__details-wrapper {
                width: 100%;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li.messages__details {
                        display: flex;
                        flex-direction: column;
                        padding: rems(5) rems(8) 0 rems(8);
                        margin-bottom: rems(4);

                        .messages__details-container {
                            display: flex;
                            flex-direction: row;

                            .messages__details-thumbnail {
                                display: none;
                                padding-right: rems(8);
                                padding-top: rems(2);

                                img {
                                    width: rems(40);
                                }
                            }

                            .messages__details-content {
                                .messages__details-participant {
                                    font-weight: 700;
                                    line-height: 1;
                                    color: var(--color-grey-dark);
                                    font-size: rems(12);

                                    span.messages__details-when {
                                        color: var(--color-grey);
                                        font-size: 0.75rem;
                                        font-weight: 400;
                                        white-space: nowrap;
                                    }
                                }

                                .messages__details-text {
                                    white-space: pre-line;
                                    word-break: break-word;
                                    line-height: 1.4;
                                    padding-top: rems(4);
                                    //padding-bottom: rems(2);
                                    max-width: rems(660);
                                    font-size: rems(13);

                                    ul.photo-gallery {
                                        li {
                                            margin-right: 0;
                                            margin-bottom: 0;
                                            margin-top: rems(6);
                                        }
                                    }
                                }
                            }
                        }

                        &.message__thread--contextual {
                            background: var(--color-teal-xlight);

                            .messages__details-text {
                                color: var(--color-grey);
                                font-size: rems(12);
                            }
                        }

                        &.message__thread--sending {
                            color: var(--color-grey-light);
                        }

                        .messages__details-footer {
                            display: flex;
                            justify-content: flex-end;

                            .read__group {
                                line-height: 1;

                                li {
                                    background-color: transparent;
                                    display: inline-block;
                                    font-weight: 600;
                                    line-height: rems(18);
                                    height: rems(18);
                                    margin-right: 0;
                                    margin-left: rems(4);
                                    overflow: hidden;
                                    text-align: center;
                                    width: rems(18);
                                    font-size: rems(12);
                                    font-size: rems(12);
                                    box-shadow: 0 0 rems(4) rems(1) rgba(var(--color-black-rgb), 0.05);

                                    &:first-child {
                                        margin-left: 0;
                                    }

                                    img {
                                        width: rems(18);
                                    }

                                    .tooltip {
                                        background-color: var(--color-grey-light);
                                        border-radius: radius(small);
                                        box-shadow: 0 rems(2) rems(4) rgba(0,0,0,0.1);
                                        color: var(--color-black);
                                        display: none;
                                        font-size: rems(12);
                                        line-height: rems(12);
                                        right: 0;
                                        padding: rems(5);
                                        position: absolute;
                                        top: rems(-32);
                                        white-space: nowrap;
                                        min-width: max-content;
                                        font-weight: 600;

                                        &::after {
                                            border-left: rems(8) solid transparent;
                                            border-right: rems(8) solid transparent;
                                            border-top: rems(8) solid var(--color-grey-light);
                                            bottom: rems(-8);
                                            content: "";
                                            right: rems(1);
                                            position: absolute;
                                            height: 0;
                                            width: 0;
                                        }
                                    }

                                    &:hover {
                                        .tooltip {
                                            display: block;
                                        }
                                    }

                                    &.read__group-more {
                                        border: rems(1) solid var(--color-grey-light);

                                        &:hover {
                                            cursor: pointer;
                                            background-color: var(--color-grey-light);
                                        }
                                    }

                                    &.read__group-noimage {
                                        font-size: rems(10);
                                        font-weight: 700;
                                        background-color: var(--color-cyan-dark);
                                        color: var(--color-white);
                                        cursor: default;
                                    }
                                }
                            }

                            .message__failed {
                                font-size: rems(12);
                                color: var(--color-pink-xdark);
                                font-weight: 600;
                                padding-right: rems(8);

                                a {
                                    display: inline-block;
                                    padding: 0 0 0 rems(8);
                                    color: var(--color-pink-xdark);
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }

        .conversation__grid-messages-footer {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: radius(small);

            .conversation__grid-messages-footer-actions {
                padding: rems(4) rems(8);

                .message__contextual-options {
                    border-bottom: 0px;
                }
            }

            .conversation__grid-messages-footer-options {
                padding-right: rems(6);
                display: flex;
                flex-direction: row;

                .messages__option-button {
                    min-width: 0;
                    margin: 0;
                    background-color: var(--color-cyan-dark);
                    border-color: var(--color-cyan-xdark);
                    max-height: rems(34);
                    width: rems(30);
                    padding: rems(4);
                    padding-bottom: 0;

                    .icon, .icon svg {
                        width: rems(16);
                        height: rems(16);
                        fill: white;
                    }

                    &:last-child {
                        margin-left: rems(6);
                    }

                    &:focus {
                        border-color: var(--color-cyan-xdark);
                        box-shadow: none;
                    }
                }
            }

            .conversation__grid-messages-footer-send {
                padding: 0 rems(8);
                display: flex;
                flex-direction: row;

                textarea {
                    flex: 1;
                    margin-bottom: 0;
                    box-shadow: none;
                    padding: rems(4) rems(8);
                    font-size: rems(14);
                }

                button.button.messages__reply-button {
                    min-width: 0;
                    margin: 0;
                    margin-left: rems(8);
                    max-height: rems(34);
                    height: rems(34);
                    padding: rems(2);
                    padding-bottom: 0;
                    width: rems(34);
                    position: relative;

                    .icon, .icon svg {
                        fill: white;
                        width: rems(24);
                        height: rems(24);
                    }

                    &:focus {
                        border-color: var(--color-cyan-xdark);
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-up) {
    conversation-tab {
        .conversation__grid-messages {
            .conversation__grid-messages-content {
                .messages__details-wrapper {
                    ul {
                        li {
                            &.messages__details {
                                .messages__details-container {
                                    .messages__details-thumbnail {
                                        display: block;
                                    }

                                    .messages__details-content {
                                        .messages__details-participant {
                                            font-size: rems(12);
                                        }

                                        .messages__details-text {
                                            font-size: rems(14);
                                        }
                                    }
                                }


                                &.message__thread--contextual {
                                    .messages__details-container {
                                        .message__details-content {
                                            .messages__details-text {
                                                font-size: rems(14);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    conversation-tab {
        .conversation__grid-messages {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;

            .conversation__grid-messages-content {
                padding-bottom: rems(8);
                flex: 1;
                width: 100%;
                overflow-y: auto;
                display: flex;

                &::-webkit-scrollbar {
                    width: rems(12);
                }

                &::-webkit-scrollbar-track {
                    background: white;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--color-grey-dark);
                    border-radius: rems(20);
                    border: rems(3) solid white;
                }
                a:not(.file_document_download):not(.linkified) {
                    display: none;
                }
                .messages__details-wrapper {
                    ul {
                        li {
                            &.messages__details {
                                padding-right: 0;
                                .messages__details-container {
                                    .messages__details-content {
                                        .messages__details-text {
                                            font-size: rems(14);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .conversation__grid-messages-footer {
                width: 100%;

                .conversation__grid-messages-footer-actions {
                }


                .conversation__grid-messages-footer-options {
                    flex-direction: column;

                    .messages__option-button {

                        &:last-child {
                            margin-left: 0;
                            margin-top: rems(4);
                        }
                    }
                }

                .conversation__grid-messages-footer-send {
                   //padding-bottom: rems(8);

                    textarea {
                        font-size: rems(14);
                    }

                    button.button.messages__reply-button {
                        max-height: rems(56);
                        height: rems(56);
                        width: rems(44);

                        .icon, .icon svg {
                            fill: white;
                            width: rems(30);
                            height: rems(30);
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    conversation-tab {
        .conversation__grid-messages {
            flex: 1;
            .conversation__grid-messages-content {
                .messages__details-wrapper {
                    ul {
                        li {
                            &.messages__details {
                                padding-right: 0;

                                .messages__details-footer {
                                    padding: rems(2) 0;

                                    .read__group {

                                        li {
                                            line-height: rems(24);
                                            height: rems(24);
                                            width: rems(24);
                                            margin-left: rems(4);


                                            img {
                                                width: rems(18);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-large-desktop-up) {
    conversation-tab {
        .conversation__grid-messages {
            flex: 1;
            @-moz-document url-prefix() {
                max-height: calc(100vh - 150px); //Fix for firefox
            }
        }
    }
}

