@include breakpoint(for-tablet-portrait-up) {
    .compact__table.compact__table--narrow {

        tr {

            th, td {
                &.address {
                   // flex: 1 1 240px;
                }
                &.area {
                    flex: 1 1 300px;
                }
                &.occupied {
                    flex: 1 1 100px;
                }
            }
            td {
                &.actions {
                    .badge {
                        margin-right: rems(20);
                    }
                    display: flex;
                    justify-content: flex-end;
                    cursor: pointer;
                }
            }
        }
    }
}
