.coho__dark {

    .button__back {
        .icon {
            fill: var(--color-cyan);
            &:hover {
                fill: var(--color-cyan-dark);
            }
        }
    }

    .nav__secondary,
    .sub__nav-wrapper {
        border-bottom-color: var(--color-grey-dark);
    }

    .sub__nav-wrapper::after {
        background: none;

    }

    .sub__nav {
        li {
            > a {
                color: white;
                border-bottom-color: var(--color-black);
                &:hover {
                    color: var(--color-grey-light);
                }
            }
            &.active {
                > a {
                    background-color: transparent;
                    border-bottom-color: var(--color-magenta);
                }
            }
            &.active-override.active-override {
                > a {
                    background-color: transparent;
                }
            }

        }
    }

    .wide-nav__wrapper .wide-nav__container .wide-nav__content h5 {
        color: var(--color-grey-xlight);
    }

    .wide-nav__wrapper .wide-nav__container .wide-nav__content .wide-nav__content-contextual .divider {
        color: var(--color-grey-xlight);
    }

    @media (min-width: 90rem) {
        .wide-nav__wrapper .wide-nav__container .wide-nav__content .accordion.accordion--panel .accordion__title .accordion__title-button {
            color: var(--color-grey-xlight);
        }
    }
}
