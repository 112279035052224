.skeleton {
    display: block;
}

.skeleton__container {
    border: 1px solid var(--color-grey-xlight);
    border-radius: radius(medium);
    padding: rems(12);
}

/* Card preset */
.skeleton__card {
    border: 1px solid var(--color-grey-xlight);
    border-radius: radius(medium);
    overflow: hidden;
    &--image {
        display: block;
    }
    &--content {
        padding: rems(16);
        display: flex;
        flex-direction: column;
        gap: rems(12);
    }
}

/* List item preset */
.skeleton__list-item {
    display: flex;
    align-items: center;
    gap: rems(16);

    &--avatar {
        flex-shrink: 0;
    }

    &--content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: rems(8);
    }
}

.skeleton__table {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
  }
  
  .skeleton__table--row {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(var(--skeleton-cols), 1fr);
    gap: rems(12); // column gap
  
    &:not(:last-child) {
      margin-bottom: rems(12); // row spacing
    }
  }

.skeleton__table--cell {
    flex-shrink: 0;
}
  
.skeleton__dashboard {
    .dashboard__panel {
        h5 {
            gap: rems(6);
        }
    }
}
  
.skeleton__table--header {
    padding-bottom: rems(10);
    border-bottom: rems(1) solid var(--color-grey-xlight);
}


.p-skeleton {
    overflow: hidden;
    background: var(--color-grey-xlight);
    border-radius: radius(medium);
}

.p-skeleton::after {
    content: "";
    animation: p-skeleton-animation 1.2s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(212,216,222,0.5), rgba(255, 255, 255, 0));
}

[dir='rtl'] .p-skeleton::after {
    animation-name: p-skeleton-animation-rtl;
}

.p-skeleton-circle {
    border-radius: 50%;
}

.p-skeleton-animation-none::after {
    animation: none;
}

@keyframes p-skeleton-animation {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes p-skeleton-animation-rtl {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}