.data-centre__report {

    .data-centre__report--intro {
        .data-centre__report-title {
            font-size: rems(18);
            margin-bottom: 0;
        }
        .data-centre__report-dates {
            font-weight: 400;
            font-style: italic;
        }
    }

    table {
        width: 100%;
        thead,tbody {
            tr {
                display: flex;
                &.heading {
                    border-bottom: 1px solid var(--color-text);
                    padding-bottom: rems(2);
                    margin-bottom: rems(8);
                    &--no-border {
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
                &.total {
                    font-weight: 800;
                    border-top: 1px solid var(--color-text);
                    border-bottom: 1px solid var(--color-text);
                    padding: rems(2) 0;
                    margin: rems(8) 0;
                    th,td {
                        font-size: rems(14);
                        &.number {
                            font-size: rems(13);
                        }
                    }
                }
                &.sub-total {
                    font-weight: 600;
                    border-bottom: 1px solid var(--color-text);
                    padding: rems(2) 0;
                    margin: rems(6) 0;
                    th,td {
                        font-size: rems(14);
                        &.number {
                            font-weight: 800;
                            font-size: rems(13);
                        }
                    }
                }
                &.total + &.sub-total {
                    margin-top: 0;
                }
                th {
                    font-weight: 700;
                }
                th, td {
                    text-align: left;
                    padding-left: rems(20);
                    font-size: rems(13);
                    &:first-of-type {
                        padding-left: 0;
                    }
                    &.short-date {
                        flex-shrink: 0;
                        flex-basis: 12.5%;
                    }
                    &.short-text {
                        flex-shrink: 0;
                        flex-basis: 17.5%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    &.long-text {
                        flex-shrink: 2;
                        flex-basis: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    &.number {
                        flex-shrink: 0;
                        flex-basis: 10%;
                        text-align: right;
                    }
                    &.full {
                        flex-basis: 100%;
                    }
                }
            }
        }
        tfoot {
            tr {
                display: flex;
                &.total {
                    border-top: 1px solid var(--color-grey);
                    padding-top: rems(4);
                    margin-top: rems(8);
                    th {
                        font-weight: 400;
                        font-style: italic;
                    }
                }
            }
        }
    }

    &.data-centre__report--lined {
        table {
            tbody {
                tr:not(.heading,.total,.sub-total) {
                    border-bottom: 1px solid var(--color-grey-light);
                    padding-bottom: rems(1);
                    margin-bottom: rems(1);
                    &:last-of-type {
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

