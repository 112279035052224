.register__logo {
    display: block;
    margin-bottom: rems(30);
    width: rems(166);

    img {
        height: auto;
        width: 100%;
    }
}
.register__page, .register__page > ng-component {
    background-color: var(--color-white);
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.register__right {
    background-color: white;
}

.register__left {
    background-image: url(https://d19qeljo1i8r7y.cloudfront.net/web-images/headers/coho-landing-image.jpg);
    background-color: var(--color-teal);
    background-position: center left;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex: 1 0 auto;
    position: relative;
    height: 40vh;
    min-height: rems(240);

    .register__help-content {
        padding-top: rems(40);
        padding-right: rems(40);
        padding-left: rems(40);
        p {
            font-size: rems(36);
            color: var(--color-white);
            line-height: 1.1; 
            &.u-text--small {
                font-size: rems(20);
                line-height: 1.3;
            }
        }
    }
}

.register__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: auto;
    padding: rems(60) rems(40) rems(20) rems(40);
    width: 100%;

    .form {
        padding-left: 0;
        padding-right: 0;
    }
}

@include breakpoint(for-large-phone-up) {

    .register__left {

        height: 42vh;
        .register__help-content {
            p {
                font-size: rems(42);
                &.u-text--small {
                    font-size: rems(22);
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {

    .register__left {

        height: 35vh;
        .register__help-content {
            padding-top: 10vh;
            p {
                font-size: rems(42);
                &.u-text--small {
                    font-size: rems(22);
                }
            }
        }
    }
}

@include breakpoint(for-large-desktop-up) {

    .register__page > ng-component {
        flex-direction: row;

    }

    .register__left {
        width: 37.5%;
        //background-size: contain;
        //background-attachment: fixed;
        position: fixed;
        height: 100%;
        .register__help-content {
            position: fixed;
            width: 37.5%;
            top: 50%;
            transform: translate(0, -50%);
            text-align: right;
            padding-top: 0;
            padding-right: rems(40);
            padding-left: rems(40);
            p {
                font-size: rems(36);
                color: var(--color-white);
                line-height: 1.1; 
                &.u-text--small {
                    font-size: rems(20);
                    line-height: 1.3;
                }
            }
        }
    }

    .register__right {
        width: 100%;
        padding-left: 37.5%;

        .register__content {
            //max-width: rems(800);
        }
    }
}

