.expand-text--holder {
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    margin-bottom: rems(8);
    margin-top: rems(4);
    padding: rems(4) rems(8);

    .truncate__text {
        p {
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
        }
    }

}
