.section__header-actions {
    .form.searchbox {
        position: relative;
        padding: 0;

        input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
            margin-bottom: 0;
        }

        #coho__search--results {
            display: none;

            &.visible {
                display: block;
                position: absolute;
                top: rems(52);
                z-index: 999999;
                right: 0;
                width: 100%;

                .results__container {
                    background: var(--color-white);
                    border-radius: radius(small);
                    padding: rems(4);
                    //min-height: rems(100);
                    border: 1px solid var(--color-grey-light);
                    max-height: 75vh;
                    overflow-y: auto;


                    &::-webkit-scrollbar {
                        width: rems(12);
                    }

                    &::-webkit-scrollbar-track {
                        background: var(--color-grey-xlight);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--color-grey-border);
                        border-radius: rems(20);
                        border: rems(3) solid var(--color-grey-xlight);
                    }

                    .zero-state {
                        padding: rems(4) 0 0 rems(6);

                        p {
                            text-shadow: none;
                            color: var(--color-black);
                            margin: 0;
                        }
                    }

                    a {
                        text-decoration: none;

                        .property {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            padding: rems(4);
                            /*margin: rems(2) 0;*/
                            border-radius: radius(small);

                            &:hover {
                                background: var(--color-grey-xlight);
                            }

                            .image {
                                /*background: var(--color-teal-dark);*/
                                border-radius: radius(small);
                                padding: rems(4);
                                padding-bottom: rems(4);
                                margin-right: rems(8);

                                .image__size {
                                    width: rems(64);
                                }
                            }

                            .area__description {
                                p {
                                    color: black;
                                    margin: 0;
                                    line-height: 1.2;
                                    text-shadow: none;
                                    color: var(--color-grey);
                                    font-size: rems(14);

                                    .span, strong {
                                        font-weight: 700;
                                        font-size: rems(16);
                                        color: var(--color-black);
                                    }
                                }
                            }
                        }

                        &.status--future {
                            .property {
                                background: var(--color-green-light);

                                &:hover {
                                    background: var(--color-grey-xlight);
                                }
                            }
                        }

                        &.status--past {
                            .property {
                                background: var(--color-teal-light);

                                &:hover {
                                    background: var(--color-grey-xlight);
                                }
                            }
                        }

                        &.status--cancelled {
                            .property {
                                background: var(--color-red-light);

                                &:hover {
                                    background: var(--color-grey-xlight);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .section__header-actions {
        .form.searchbox {
            padding-left: rems(20);

            #coho__search--results.visible {
                width: rems(480);

                .results__container {


                    .zero-state {
                        padding: rems(4) rems(6) 0 rems(6);

                        p {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

