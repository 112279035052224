.conversation__page {
    .messages__details {
        .file_documents_container {
            margin-bottom: 0;
            margin-top: rems(4);
            width: 63vw;
            @include breakpoint(for-large-phone-up) {
                width: 72vw;
                .file_document_icon {
                    margin-bottom: 0.625rem;
                }
            }
            @include breakpoint(for-large-phone-landscape-up) {
                width: 42vw;
            }
            @include breakpoint(for-tablet-portrait-up) {
                width: 47vw;
                .file_document_icon {
                    margin-bottom: 0;
                }                
            }
            @include breakpoint(for-tablet-landcscape-up) {
                
            }
            @include breakpoint(for-small-desktop-up) {
                width: 33vw;
            }
            @include breakpoint(for-large-desktop-up) {
                width: 33vw;
            }
            .file_document_group {
                padding: 0.5rem;
            }
        }
    }
}
