
.static__header-wrapper {
    background-color: var(--color-teal-dark);
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    min-height: rems(185);
    position: relative;
    z-index: 1;

    &::after {
        background: linear-gradient(rgba(var(--color-blue-rgb),0.25), rgba(var(--color-blue-rgb),1));
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        right: 0;
    }

    .static__header {
        background-color: transparent;
        width: 100%;
        z-index: 10;
        height: rems(80);

        &.open {
            //height: max-content;
            background-color: var(--color-blue);

            ul {
                &#MainMenu {
                    background-color: var(--color-blue);
                }
            }
        }

        .container {
            padding-left: 0;
            padding-right: 0;
            position: relative;
            width: 100%;

            .header__logo {
                display: block;
                margin-left: rems(60);
                padding-bottom: rems(16);
                padding-top: rems(22);
                //height: rems(80);
                width: auto;
                z-index: 11;

                img {
/*                    display: block;
                    height: auto;
                    height: 100%;
                    z-index: 11;*/
                    width: rems(122);
                }

                &--reverse {
                    display: none;
                }
            }
        }

        .header__options {
            height: rems(80);
            position: absolute;
            right: 0;
            top: 0;
            width: rems(209);
            align-items: center;
            display: flex;
            padding-right: rems(10);


            .button__group {
                justify-content: flex-end;
            }

            .button {
                margin-bottom: 0;

                &#demo {
                    display: none;
                }
            }
        }
    }

    &--reverse {
        background-color: transparent;
        min-height: 0;

        &::after {
            display: none;
        }

        .static__header {
            &.open {
                .header__logo {
                    display: none;

                    &--reverse {
                        display: block;
                    }
                }
            }
        }
    }
}

.static__header-wrapper--reverse {
    .main__navigation {

        & > li {

            & > a {
                font-weight: 700;
            }
        }
    }

    .main__navigation--mega {

        li {

            a {
                color: var(--color-blue);
            }
        }
    }


    .main__menu-bar {
        background-color: var(--color-blue);
    }

    .main__menu-icon {

        &:focus {

            .main__menu-bar {
                background-color: var(--color-blue);
            }
        }
    }

    .static__header {
        &.open {
            .main__menu-bar {
                background-color: var(--color-white);
            }
        }
    }




}


.main__navigation {
    display: block;
    top: rems(80);

    &.open {
        padding-left: rems(20);

        li {
            a {
                display: block;
                padding: rems(4) 0;
            }

            ul {
                margin-bottom: rems(10);
                list-style-type: square;

                li {
                    font-size: rems(14);
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-up) {
    .static__header-wrapper {
        .static__header {
            .header__options {
                width: rems(209);

                .button {
                    &#demo {
                        display: block;
                    }
                }

                &.header__options--slim {
                    width: rems(100);
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .static__header-wrapper {
        min-height: rems(240);

        &--reverse {
            min-height: 0;
        }

        .static__header {

            .container {
                display: flex;
                flex-direction: row;

                .main__menu-icon {
                    display: none;
                }

                .main__navigation {
                    position: relative;
                }

                .header__logo {
                    margin-left: rems(20);
                    margin-right: rems(20);
                    padding-bottom: rems(10);
                    padding-top: rems(16);
                    img {
                        width: rems(157);
                    }
                }
                .header__options {
                    z-index: 4;
                }

            }

        }
    

        .main__navigation, .main__navigation.open {
            border-top: none;
            display: flex !important;
            flex-direction: row;
            flex: 1 0 auto;
            justify-content: flex-start;
            top: auto;
            width: auto;
            padding-right: rems(185);
            height: auto;
            z-index: 0;

            &.toggle__target {
                display: none !important;
            }

            li {
                background-color: transparent;
                border-left: none;

                > a {
                    display: block;
                    color: var(--color-white);
                    line-height: rems(32);
                    padding-bottom: rems(24);
                    padding-left: rems(20);
                    padding-right: rems(20);
                    padding-top: rems(24);
                    position: relative;


                    &:hover {
                        background-color: transparent;

                        &::after {
                            background-color: var(--color-white);
                            bottom: 0;
                            content: "";
                            display: block;
                            height: rems(4);
                            left: rems(20);
                            position: absolute;
                            right: rems(20);
                        }
                    }
                }

                &.active {
                    background-color: transparent;
                    border-left: none;

                    a {
                        &::after {
                            background-color: var(--color-pink);
                            bottom: 0;
                            content: "";
                            display: block;
                            height: rems(4);
                            left: rems(20);
                            position: absolute;
                            right: rems(20);
                        }

                        &:hover {
                            &::after {
                                background-color: var(--color-pink);
                            }
                        }
                    }
                }
            }
        }
    }

    .static__header-wrapper--reverse {
        .main__navigation, .main__navigation.open {

            li {
                > a {
                    font-weight: 700;
                    color: var(--color-blue);
                    &:hover {
                        background-color: transparent;

                        &::after {
                            background-color: var(--color-blue);
                        }
                    }
                }

                &.active {

                    a {
                        &::after {
                            background-color: var(--color-pink);
                        }

                        &:hover {
                            &::after {
                                background-color: var(--color-pink);
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .static__header-wrapper {
        .static__header {
            .container {
                max-width: none;
                width: rems(1120);
            }
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .static__header-wrapper {
        .static__header {
            .container {
                max-width: none;
                width: rems(1120);
            }
        }
    }
}



.megamenu {
    display: none;

    .row {
        margin-left: rems(-5);
        margin-right: rems(-5);
        width: calc(100% + 0.625rem)
    }

    .col {
        padding-left: rems(5);
        padding-right: rems(5);
    }

    p {
        a {
            color: var(--color-text);
        }
    }
}

.main__navigation {
    li {
        &.hovering {
            &:hover {
                a {
                    background-color: transparent;

                    &::after {
                        background-color: var(--color-white);
                        bottom: 0;
                        content: "";
                        display: block;
                        height: rems(4);
                        left: rems(20);
                        position: absolute;
                        right: rems(20);
                    }
                }
            }
        }
    }
}

.hover__trigger {
    &.hovering {
        .megamenu {
            display: block;
            background-color: var(--color-white);
            outline: 1px solid rgba(var(--color-grey-rgb),0.5);
            box-shadow: 0 rems(4) rems(16) 0 rgba(0,0,0,0.4);
            position: absolute;
            top: rems(80);
            left: 50%;
            transform: translateX(-50%);
            padding: rems(20);
            padding-bottom: 0;
            border-radius: radius(medium);
            width: rems(800);
            max-height: 60vh;

            h5, p {
                color: $color__text;
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.hover__trigger.hovering > a::after {
    background-color: var(--color-blue) !important;
    bottom: 0;
    content: "";
    display: block;
    height: rems(4);
    left: rems(20);
    position: absolute;
    right: rems(20);
}
