.landing__header{
    display: flex;
    flex: 1 0 auto;
    height: rems(80);
}

.landing__header-content{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    max-width: none;
    padding-bottom: rems(10);
    padding-left: rems(20);
    padding-right: rems(20);
    padding-top: rems(10);
    position: relative;
    width: 100%;
    justify-content: space-between;
}

.landing__header-logo{
    display: block;
    width: rems(140);

    img{
        display: block;
        height: auto;
        width: 100%;
    }
    
    &:focus { outline: none; }
}

.landing__header-actions{
    display: flex;
    .button {
        margin-bottom: 0;
    }
}

@include breakpoint(for-tablet-landscape-up){
    .landing__header-content{
        
        margin-left: auto;
        margin-right: auto;
        max-width: rems(1120);
        width: rems(1120);
    }    
}


