.form__options-group{
    border: 1px solid var(--color-grey-light);
    border-radius: radius(large);
    margin-bottom: rems(30);

    .form__group{
        margin: 0;
        padding: rems(10) rems(20);

        + .form__group{
            border-top: 1px solid var(--color-grey-light);        
        }
    }

    .form__switch, .form__label{
        margin-bottom: 0;
    }

    .form__options-group-content{
        padding: 0;

        .form__group{
            border-top: 1px solid var(--color-grey-light);

            &.form__group--collapse{
                border-top: none;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            }

        }

    }

}


.form__options-group-title{
    padding: rems(20) rems(20) rems(20) rems(20);

    .form__options-group-title-button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: block;
        font-family: $type__family;
        font-size: rems(18);
        font-weight: 700;
        padding: 0;
        position: relative;
        text-align: left;
        width: 100%;
    
        &:hover{
            color: var(--color-teal);
        }
    
        &::after{
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
            content: "";
            display: block;
            position: absolute;
            height: rems(20);
            right: rems(0);
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            transition: transform 0.2s ease-out;
            width: rems(20);
        }


        &:focus{
            outline: none;
            text-decoration: underline;
            
            &::after{
                background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_focus}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");

            }
        }

    }
}


.form__options-group-content{
    display: block;
}

.form__options-group-summary{
    color: var(--color-grey);
}

.form__options-group{
    &.closed{

        .form__options-group-title{
            .form__options-group-title-button{
    
                &::after{
                    transform: translateY(-50%) rotate(0deg);
                }    
            }
        }

        .form__options-group-content{
            display: none;
        }
    }
}