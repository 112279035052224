.button{
    &.button--small{
        font-size: rems(14);
        line-height: rems(16);
        min-width: rems(80);
        padding: rems(8);

        &.small-padding-no-margin{
            margin: 0;
            min-width: 0;
            padding: 0.5rem 1rem;
        }

        &.button--icon{
            padding-left: rems(32);
            position: relative;
            text-align: left;
            .icon{
                height: rems(20);
                position: absolute;
                left: rems(6);
                top: rems(6);
                width: rems(20);

                svg{
                    height: rems(20);
                    width: rems(20);
                }

            }
        }

    }
}

.page__section {
    &.page__section--slim {
        .button {
            &.button--small {
                //padding-top: 0;
                //padding-bottom: 0;  If this needs reinstating, make it more specific
            }
        }
    }
}
