.coho__dark {
    a {
        &.card {
            &:hover {
                color: var(--color-grey-light);
            }
        }
    }

    .card {
        background-color: var(--color-black-light);
        color: var(--color-grey-xlight);

        &.card--property {
            border-color: var(--color-grey-border);
        }

        &card--room {
            border-color: var(--color-grey-border);
        }

        &.card--property-has-actions {
            border-color: var(--color-pink-dark);
        }

        a {
            &.card__header {
                color: #6cb3d6;
                border-top-left-radius: $size__radius--md;
                border-top-right-radius: $size__radius--md;

                .icon {
                    fill: var(--color-blue-light);
                }

                &:hover {
                    background-color: var(--color-black-light);
                    color: var(--color-cyan);
                }
            }
        }

        .card__header {
            border-color: var(--color-grey-border);
        }

        &.card__knowledge {
            background-color: var(--color-pumpkin-xdark);
            .card__header {
                &:hover {
                    background-color: var(--color-pumpkin-dark);
                }
            }
        }
    }

    .page__section--alt {
        .card {
            background-color: var(--color-black);

            &.card__panel{
                &--actions {
                  background-color: var(--color-black);
                }
                &--other {
                    background-color: var(--color-grey-light);
                }
            }

            a {
                &.card__header {
                    color: var(--color-cyan);

                    &:hover {
                        background-color: var(--color-black);
                        color: #6cb3d6;
                    }
                }
            }
        }
    }

    .card__performance {
        padding-top: rems(6);
        min-height: rems(94);
        position: relative;

        &:focus {
            outline: none;
        }

        &--5 {
            background-color: var(--color-magenta-xdark);
        }

        &--1 {
            background-color: var(--color-green-xxdark);
        }

        &--2 {
            background-color: var(--color-green-xdark);
        }

        &--3 {
            background-color: var(--color-orange-dark);
        }

        &--4 {
            background-color: var(--color-orange-xdark);
        }

        &--inactive {
            background-color: var(--color-grey-border);
            cursor: default;

            &:focus {
                outline: 0;
            }
        }
    }

    rent-due-record-card {
        a.toggle__trigger {
            color: var(--color-grey-light);
        }

        .card {
            &.card--inner {
                .card__content {
                    .missed-payment__details {
                        border-bottom: rems(1) solid var(--color-grey-dark);
                        &.highlight {
                            background-color: var(--color-magenta-dark);
                        }
                    }
                }
            }
        }
    }
}


