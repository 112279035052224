signature-pad-editor {
    div {
        display: flex;
        //max-height: rems(300);

        canvas {
            border: rems(3) dashed var(--color-pink);
            border-radius: radius(large);
            background-color: var(--color-white);
            background-image: url(https://d19qeljo1i8r7y.cloudfront.net/web-images/logos/coho-esign.png);
            background-repeat: no-repeat;
            background-position-y: 95%;
            background-position-x: 98%;
            background-size: rems(100);

            @include breakpoint(for-tablet-portrait-up) {
                background-size: 20%;
            }
        }
    }

    button {
        background-color: var(--color-white);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
        color: var(--color-text);
        cursor: pointer;
        display: inline-block;
        font-family: $type__family;
        font-size: rems(14);
        font-weight: 600;
        font-weight: 700;
        line-height: rems(16);
        margin-top: rems(8);
        padding: rems(4) rems(8);
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-out;

        &:hover {
            border-color: var(--color-grey-border);
        }

        &:focus {
            border: 1px solid var(--color-grey-light);
            outline: none;
        }
    }
}

.signature__image {
    border: rems(2) solid var(--color-grey-light);
    border-radius: radius(large);
    background-color: var(--color-white);
    background-image: url(https://d19qeljo1i8r7y.cloudfront.net/web-images/logos/coho-esign.png);
    background-repeat: no-repeat;
    background-position-y: 95%;
    background-position-x: 98%;
    background-size: rems(100);
    margin-bottom: rems(10);

    @include breakpoint(for-tablet-portrait-up) {
        background-size: 20%;
    }

    img {
        height: rems(160);
    }
}
