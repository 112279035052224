.social__buttons{
    list-style: none;
    padding-left: 0;
    padding-right: 0;

    li{
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
    }

    a{
        .icon{
            fill: var(--color-grey);
        }

        &:hover{
            .icon{
                fill: var(--color-magenta);
            }
        }

    }
}