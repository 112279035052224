.coho__dark {
    .dashboard__container {
        background-color: var(--color-grey-dark);

       .dashboard__panel {
            border: 1px solid var(--color-grey-light);
            background-color: transparent;

            h5 {
                color: var(--color-grey-xlight);
    
                .icon, svg {
                    fill: var(--color-grey-xlight);
                }
            }
       }

       .update--info, .update {
        color: var(--color-grey-light);
        }

       .daily-tip {

        background-color: var(--color-teal-xxdark);

        .dismiss {
            color: var(--color-grey-light);
            &:hover {
                outline: 1px dotted var(--color-grey-light);
            }
        }
    }
    }
}