@include breakpoint(for-tablet-portrait-up) {
    .compact__table.compact__table--narrow {
        tr {

            th,
            td {

                &.truncate {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    position: relative; 

                }


                @for $i from 40 through 500 {
                    @if $i % 20==0 {
                        &.width-#{$i} {
                            flex: 1 1 #{$i}px;
                        }
                    }
                }

            }
        }
    }
}