.list__basic{
    padding-left: rems(16);
    // padding-right: 0;;

    li{
        margin-bottom: rems(10);
        padding-left: 0;
    }

    &.list__basic--nomargin {
        li{
            margin-bottom: 0;
        }
    }
}
