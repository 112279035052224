
.status__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% + rems(8));
    margin: 0 rems(-4);

    .status__holder {

        //border: 1px solid var(--color-grey-light);
        //border-radius: radius(medium);
        background-color: var(--color-white);
        //padding: rems(8);
        padding-bottom: rems(8);
        flex: 1;
        margin: 0 rems(4);
        height: 100%;
        margin-top: rems(4);

        &:first-of-type {
            margin-top: 0;
        }

        .status__holder--label {
            font-size: rems(12);
            width: 100%;
            font-weight: 600;
            color: var(--color-grey-border);
            margin-bottom: rems(4);
            line-height: 1;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
        }
    }

    .status__holder.status__holder--severity {
        ul {
            gap: rems(5);
            flex-wrap: wrap;
            li {
                margin: 0
            }
        }
    }

    + .status__container {
        margin-top: rems(8);
    }

    &[id^='maintenance'] {
        margin-bottom: rems(4);
    }
}


@include breakpoint(for-tablet-portrait-up) {

    .status__container {
        flex-direction: row;
        .status__holder {
            margin-top: 0;
            flex-basis: 100%;
            &--job {
                flex-basis: auto;
                min-width: rems(80);
            }
            &--severity {
                flex-basis: auto;
                min-width: rems(170);
            }
        }

    }

}
@include breakpoint(for-small-desktop-up) {

    .status__container {
        .status__holder {
            &--severity {
                //width: rems(302);
            }
        }

    }
}
