.coho__dark {
    file-uploader {
        .file_input_container {
            background-color: var(--color-black);
        }
    }
    .file_documents_container {
        .file_document_group {
            background-color: var(--color-grey-dark);
            border: 1px solid rgba(204,204,204,0.2);
            .file_document {
                .file_document_actions {
/*                    .button--ghost {
                        background-color: var(--color-black);
                        border-color: var(--color-grey-dark);
                        color: white;
                        box-shadow: 0 rems(2) rems(6) rgba(204,204,204,0.15);
                        &:hover {
                            border-color: var(--color-grey-border);
                        }
                    } */
                }
            }
        }
    }    
    
    .page__section--alt {
        file-uploader {
            .file_input_container {
                background-color: var(--color-grey-dark);
            }
        }        
        .file_documents_container {
            .file_document_group {
                background-color: var(--color-black);
                border: 1px solid rgba(204,204,204,0.2);
                .file_document {
                    .file_document_actions {   
/*                        .button--ghost {
                                background-color: var(--color-grey-dark);
                                border-color: var(--color-grey);
                                color: white;
                                box-shadow: 0 rems(2) rems(6) rgba(204,204,204,0.15);   
                            .icon {
                                fill: var(--color-grey-xlight);
                            }
                        } */                       
                    }
                }
            }
        }
    }
}




