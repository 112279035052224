//THIS HAS NOTHING TO DO WITH MODALS, IT CAN BE USED ANYWHERE. SEE EG. ON send-message-modal, upon completion
//Beautiful way to render completion of a form

.modal__body--holder {
    display: flex;
    flex-direction: row;
    &-border {
        border-top: rems(1) solid var(--color-teal-light);
        padding-top: rems(10);
        margin-top: rems(10);
    }
    .modal__image--holder {
        margin-right: rems(20);
        .icon, .icon svg {
            width: rems(24); height: rems(24);
        }
    }
    .modal__text--holder {
        p {
            margin-bottom: rems(8);
            &.u-margin-bottom--none {
                margin-bottom: 0;
            }
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: rems(14);
            line-height: 1.4;
        }
    }
}
