.card__service.card__service--off{
    background-color: var(--color-grey-xxlight);
    border: 1px solid var(--color-grey-light);
    box-shadow: none;
    
    .card__content {
        
        p { 
            padding-right: 0;
        }

    }
    .card__description {
        padding: 0 rems(10);
        p { 
            font-size: rems(14);
            font-weight: 400;
            line-height: 1.3;
            margin-bottom: rems(10);
            color: var(--color-grey-dark);
        }
    }

}

.card__service {
    display: flex;
    flex-direction: column;
    .card__image {
        max-width: rems(60);
        padding-top: rems(10);
        padding-left: rems(10);
    }
    .card__content--wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .card__content {
        padding: rems(10);
        
        p { 
            font-size: rems(16);
            font-weight: 700; 
            padding-right: rems(70);
            line-height: 1.2
        }

    }
    .card__status {
        position: absolute;
        top: rems(10);
        right: rems(10);
    }
    .card__actions {
        padding-right: rems(10);
        padding-left: rems(10);
        padding-top: rems(6);
        .button {
            margin-bottom: 0;
        }
    }
}


.card__service--overdue{
    align-items: center;
    color: var(--color-warning-dark);
    display: flex;

    .icon{
        display: inline;
        margin-right: rems(8);
        height: rems(20);
        width: rems(20);
    }

    svg{
        fill: var(--color-warning-dark);
        height: rems(20);
        width: rems(20);
    }

}
