.card__detail--holder {
    width: 100%;

    .card__detail--item-holder {
        display: flex;
        flex-direction: row;

        p {
            margin-bottom: rems(4);

        }

        .card__detail--label {
            font-size: rems(14);
            width: 35%;
            font-weight: 700;
        }

        .card__detail--item {
            width: 65%;
            font-size: rems(14);
        }
    }

    .file_documents_container {
        margin-top: rems(4);
        margin-bottom: 0;

        .file_document_group {
            border-radius: radius(medium);
            padding: rems(10);
        }
    }
    // &:nth-child(2) {
    //     padding-left: rems(20);
    // }
    h5 + .card__detail--item-holder {
        margin-top: 0;
    }

}

.card__detail--holder-wrapper--border {
    padding-bottom: rems(20);
    border-bottom: 1px solid var(--color-grey-light);
    margin-bottom: rems(20);
}

property-owners-organisation-panel-row {
    .card__detail--holder {
        &:nth-child(2) {
            padding-left: 0;
        }
    }
}



.instructions {
    display: flex;
    flex-direction: column;
    height: rems(240);
    overflow: auto;
    margin-bottom: 20px;
}

.instructions-files {
    display: flex;
    flex-direction: column;
    height: rems(350); //see tabs.scss where it's set for action-card-progressions.section__tabs to 480. this needs to be a bit smaller, since it's 480px
    overflow: auto;
    margin-bottom: 20px;
}

.card__detail--step-wrapper {
    display: flex;
    flex-direction: column;
    // height: rems(350); //see tabs.scss where it's set for action-card-progressions.section__tabs to 480. this needs to be a bit smaller, since it's 480px
    // overflow: auto;
    // margin-bottom: 15px;

    &.reduced {
        height: rems(200); //overwise all the stuff goes out of the tab thing.
    }

    .card__detail--step-holder {
        padding: 0 rems(4);
        display: flex;
        border-top: rems(1) dashed var(--color-grey-light);
        padding-top: rems(2);
        //margin-top: rems(4);
        width: 99%;

        &:first-of-type {
            border-top: 0;
            padding-top: 0;
            //margin-top: 0;
        }

        .card__detail--step-checkbox {
            label.form__checkbox {
                margin-bottom: 0;
                display: flex;
                height: rems(18);
                width: rems(28);
            }
        }
        .card__detail--step-description {
            padding-top: rems(4);
            padding-right: rems(10);

            p {
                font-size: rems(14);
                line-height: 1.4;
                margin-bottom: rems(0);
                &:last-of-type {
                    margin-bottom: rems(4);
                }
                &.u-text--small {
                    font-size: rems(12);
                }
            }
            flex: 1 1 100%;
        }
        .card__detail--step-assigned {
            width: rems(75);
            padding-top: rems(4);
            assignment-selector {
                .no-pointer,.no-pointer ul,.no-pointer ul li {
                    cursor: default;
                }
                a {
                    cursor: pointer;
                }
            }
        }
        .card__detail--step-actions {
            width: rems(30);
        }

        &.completed {
            background-color: var(--color-grey-xlight);
            border-radius: radius(small);
            border-bottom: 0;
            margin-bottom: rems(4);
        }
    }

    .card__detail--step-holder + .card__detail--step-holder.completed,
    .card__detail--step-holder.completed + .card__detail--step-holder {
        border-top: 0;
        padding-top: 0;
    }
}


@include breakpoint(for-tablet-portrait-up) {

    .card__detail--step-wrapper {
        height: rems(350); //see tabs.scss where it's set for action-card-progressions.section__tabs to 480. this needs to be a bit smaller, since it's 480px
        overflow: auto;
        margin-bottom: 15px;
    }

    .card__detail--holder-wrapper {
        display: flex;
        flex-direction: row;

        .card__detail--holder {

            &:nth-child(1) {
                padding-right: rems(20);
            }

            width: 33%;

            &.double {
                width: 66%;
            }

            .card__detail--item-holder {
                flex-direction: column;

                .card__detail--label {
                    font-size: rems(12);
                    width: 100%;
                    font-weight: 600;
                    color: var(--color-grey-border);
                    margin-bottom: rems(4);
                    line-height: 1;
                }

                .card__detail--item {
                    width: 100%;
                    font-size: rems(16);
                    line-height: 1.2;
                    margin-bottom: rems(10);

                    &.u-text--large {
                        font-size: rems(20);

                        span {
                            font-size: rems(16);
                        }
                    }
                }

            }

            .file_documents_container {
                width: 96%;
            }

            &-right {
                text-align: right;
            }

        }
    }

    action-card-progressions {
        .section__tabs {
            height: rems(480);
    
            &.hasInstructions {
                height: rems(720); //base *1,5
            }
    
            &.hasInstructionsFiles {
                height: rems(1200); //base x2,5
            }
        }
    
        .file_documents_container .file_document_actions, .cdk-drag-preview .file_document_actions {
            margin-top: rems(8);
        }
    }
}
