.coho__dark {

    .badge {
        background-color: var(--color-magenta-dark);
    }
    .status-badge {
        background-color: transparent;
        border: 2px solid var(--color-grey-border);
        color: var(--color-grey-border);

        .status-badge--on {
            border-color: var(--color-pink-dark);
            color: var(--color-pink-dark);
        }
    }

    .page__section--alt {
        .status-badge {
            background-color: transparent;

            &.status-badge--on {
                border-color: var(--color-pink-xdark);
                color: var(--color-pink-xdark);
            }
        }
    }

    .room {
        .room__content-section {
            h3 {
                &.heading--status-badge {
                    .status-badge {
                        background-color: transparent;
                        border: 1px solid var(--color-grey-border);
                        color: var(--color-grey-border);

                        &.status-badge--confirmed {
                            border-color: var(--color-green-xdark);
                            background-color: var(--color-green-dark);
                            color: white;
                        }

                        &.status-badge--unconfirmed {
                            border-color: var(--color-magenta-xdark);
                            background: var(--color-magenta-dark);
                            color: white;
                        }

                        &.status-badge--on {
                            background-color: var(--color-pink-dark);
                            border-color: var(--color-pink-xdark);
                            color: var(--color-grey-xlight);
                        }

                        &.status-badge--automatic {
                            background-color: var(--color-green-dark);
                            border-color: var(--color-green-xdark);
                            color: var(--color-grey-xlight);
                        }
                    }
                }
            }
        }
    }

    .info-badge {
        &.info-badge--transparent {
            color: var(--color-grey-xlight);
        }
    }

    .site__header--logged-in, .site__header--logged-out {
        .header__options {
            .dropdown__announcements,.dropdown__feedback {
                .header__options-button {
                    .badge {
                        background-color: var(--color-green-xxdark);
                    }
                }
            }
    
        }
    } 
}
