.sub__nav-wrapper{
    margin-bottom: rems(20);
    margin-left: rems(10);
    margin-right: rems(10);
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--color-grey-light);
    flex-direction: row;
}

.sub__nav {
    display: flex;
    list-style: none;
    margin: 0;
    //overflow-x: auto;
    overflow: hidden;
    padding: 0;
    flex: 1;
    flex-direction: row;

    a {
        align-items: center;
        border-radius: $size__radius--sm;
        color: var(--color-text);
        display: flex;
        flex-direction: row;
        padding: rems(10);
        text-decoration: none;
        white-space: nowrap;

        &:focus {
            outline: none;
        }

        .badge {
            margin-left: rems(5);
        }
    }


    li {
        display: flex;

        &:first-child {
            margin-left: 0;
        }

        a {
            border-bottom: 4px solid #ffffff;
        }

        &.active {

            > a {
                border-bottom: 4px solid #ffffff;
                font-weight: 700;
            }

            &.active {
                > a {
                    background-color: #ffffff;
                    border-bottom-color: var(--color-magenta);
                }
            }
        }

        &:last-child {
            padding-right: rems(20);
        }
    }
}

.sub__nav-search {
    margin-top: rems(12);
}



@include breakpoint(for-large-phone-up) {
    .sub__nav{
        li {
            a {
                padding: rems(15) rems(20);
            }
        }
    }
}



@include breakpoint(for-tablet-portrait-up) {

    .sub__nav-wrapper {
        overflow: auto;
        width: 100%;

        &::after {
            display: none;
        }
    }
}

.sub__nav-options {
    padding-left: rems(10);
    padding-right: rems(10);
}
