.compact__table {
    td {
        &.actions,&.options,&.checkbox {
            label.form__checkbox {
                align-items: unset;
                font-size: rems(16);
                margin-bottom: 0;
                min-height: rems($size__checkbox-size--sm);
                padding-left: rems($size__checkbox-size--sm);
                width: auto;

                .form__checkbox-label {

                    &::before {
                        //top: rems(-5);
                        height: rems($size__checkbox-size--sm);
                        width: rems($size__checkbox-size--sm);
                    }

                    &::after {
                        border-bottom: rems(3) solid var(--color-grey-light);
                        border-left: rems(3) solid var(--color-grey-light);
                        height: rems(6);
                        left: rems(3);
                        top: rems(3);
                        width: rems(10);
                    }
                }

                input[type=checkbox] {
                    //top: rems(-5);
                    height: rems($size__checkbox-size--sm);
                    width: rems($size__checkbox-size--sm);

                    &:checked {
                        + .form__checkbox-label {
                            &::after {
                                border-bottom: rems(3) solid var(--color-white);
                                border-left: rems(3) solid var(--color-white);
                            }
                        }

                    }

                    &:disabled {

                        &:checked {
                            + .form__checkbox-label {
                                &::after {
                                    border-bottom: rems(3) solid var(--color-grey);
                                    border-left: rems(3) solid var(--color-grey);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

