
$icon-arrow-down-width: 30px;
$icon-arrow-down-height: 30px;
$icon-arrow-left-width: 30px;
$icon-arrow-left-height: 30px;
$icon-arrow-right-width: 30px;
$icon-arrow-right-height: 30px;
$icon-arrow-up-width: 30px;
$icon-arrow-up-height: 30px;
$icon-coho-loader-old-width: 283.46px;
$icon-coho-loader-old-height: 283.46px;
$icon-coho-loader-width: 283.46px;
$icon-coho-loader-height: 283.46px;
$icon-icon-actions-width: 30px;
$icon-icon-actions-height: 30px;
$icon-icon-add-width: 30px;
$icon-icon-add-height: 30px;
$icon-icon-airport-width: 30px;
$icon-icon-airport-height: 30px;
$icon-icon-alert-width: 30px;
$icon-icon-alert-height: 30px;
$icon-icon-amazon-prime-video-width: 30px;
$icon-icon-amazon-prime-video-height: 30px;
$icon-icon-appliances-width: 80px;
$icon-icon-appliances-height: 80px;
$icon-icon-archive-width: 30px;
$icon-icon-archive-height: 30px;
$icon-icon-area-width: 30px;
$icon-icon-area-height: 30px;
$icon-icon-armchair-width: 80px;
$icon-icon-armchair-height: 80px;
$icon-icon-arrow-down-width: 30px;
$icon-icon-arrow-down-height: 30px;
$icon-icon-arrow-left-width: 30px;
$icon-icon-arrow-left-height: 30px;
$icon-icon-arrow-right-width: 30px;
$icon-icon-arrow-right-height: 30px;
$icon-icon-arrow-up-width: 30px;
$icon-icon-arrow-up-height: 30px;
$icon-icon-art-and-crafts-width: 30px;
$icon-icon-art-and-crafts-height: 30px;
$icon-icon-attach-file-width: 30px;
$icon-icon-attach-file-height: 30px;
$icon-icon-barclays-width: 1200px;
$icon-icon-barclays-height: 800px;
$icon-icon-bathroom-width: 80px;
$icon-icon-bathroom-height: 80px;
$icon-icon-bbq-width: 30px;
$icon-icon-bbq-height: 30px;
$icon-icon-bed-width: 30px;
$icon-icon-bed-height: 30px;
$icon-icon-bell-width: 30px;
$icon-icon-bell-height: 30px;
$icon-icon-bicycle-width: 30px;
$icon-icon-bicycle-height: 30px;
$icon-icon-bills-width: 30px;
$icon-icon-bills-height: 30px;
$icon-icon-block-width: 30px;
$icon-icon-block-height: 30px;
$icon-icon-boat-width: 30px;
$icon-icon-boat-height: 30px;
$icon-icon-boiler-width: 30px;
$icon-icon-boiler-height: 30px;
$icon-icon-calendar-width: 30px;
$icon-icon-calendar-height: 30px;
$icon-icon-card-width: 30px;
$icon-icon-card-height: 30px;
$icon-icon-cart-width: 30px;
$icon-icon-cart-height: 30px;
$icon-icon-checkboard-width: 100px;
$icon-icon-checkboard-height: 30px;
$icon-icon-child-width: 30px;
$icon-icon-child-height: 30px;
$icon-icon-chimney-width: 80px;
$icon-icon-chimney-height: 80px;
$icon-icon-circles-width: 30px;
$icon-icon-circles-height: 30px;
$icon-icon-clock-width: 30px;
$icon-icon-clock-height: 30px;
$icon-icon-clothes-line-width: 30px;
$icon-icon-clothes-line-height: 30px;
$icon-icon-coho-width: 30px;
$icon-icon-coho-height: 30px;
$icon-icon-commercial-width: 30px;
$icon-icon-commercial-height: 30px;
$icon-icon-contract-creation-width: 30px;
$icon-icon-contract-creation-height: 30px;
$icon-icon-conversation-width: 30px;
$icon-icon-conversation-height: 30px;
$icon-icon-copy-width: 30px;
$icon-icon-copy-height: 30px;
$icon-icon-credit-card-width: 30px;
$icon-icon-credit-card-height: 30px;
$icon-icon-crop-width: 30px;
$icon-icon-crop-height: 30px;
$icon-icon-cross-width: 30px;
$icon-icon-cross-height: 30px;
$icon-icon-dark-width: 30px;
$icon-icon-dark-height: 30px;
$icon-icon-delete-width: 30px;
$icon-icon-delete-height: 30px;
$icon-icon-deposit-width: 30px;
$icon-icon-deposit-height: 30px;
$icon-icon-description-width: 30px;
$icon-icon-description-height: 30px;
$icon-icon-desk-width: 30px;
$icon-icon-desk-height: 30px;
$icon-icon-disabled-access-width: 30px;
$icon-icon-disabled-access-height: 30px;
$icon-icon-discord-width: 30px;
$icon-icon-discord-height: 30px;
$icon-icon-dishwasher-width: 30px;
$icon-icon-dishwasher-height: 30px;
$icon-icon-disney-plus-width: 30px;
$icon-icon-disney-plus-height: 30px;
$icon-icon-document-width: 30px;
$icon-icon-document-height: 30px;
$icon-icon-door-handle-width: 80px;
$icon-icon-door-handle-height: 80px;
$icon-icon-double-room-width: 30px;
$icon-icon-double-room-height: 30px;
$icon-icon-down-arrow-width: 30px;
$icon-icon-down-arrow-height: 30px;
$icon-icon-download-width: 30px;
$icon-icon-download-height: 30px;
$icon-icon-drag-handle-width: 18px;
$icon-icon-drag-handle-height: 18px;
$icon-icon-drawers-width: 30px;
$icon-icon-drawers-height: 30px;
$icon-icon-edit-width: 30px;
$icon-icon-edit-height: 30px;
$icon-icon-email-width: 30px;
$icon-icon-email-height: 30px;
$icon-icon-emails-width: 30px;
$icon-icon-emails-height: 30px;
$icon-icon-en-suite-width: 30px;
$icon-icon-en-suite-height: 30px;
$icon-icon-ensuite-width: 30px;
$icon-icon-ensuite-height: 30px;
$icon-icon-estate-width: 30px;
$icon-icon-estate-height: 30px;
$icon-icon-expand-width: 30px;
$icon-icon-expand-height: 30px;
$icon-icon-expense-width: 30px;
$icon-icon-expense-height: 30px;
$icon-icon-extinguisher-width: 80px;
$icon-icon-extinguisher-height: 80px;
$icon-icon-facebook-width: 30px;
$icon-icon-facebook-height: 30px;
$icon-icon-female-width: 30px;
$icon-icon-female-height: 30px;
$icon-icon-females-width: 30px;
$icon-icon-females-height: 30px;
$icon-icon-fibre-width: 30px;
$icon-icon-fibre-height: 30px;
$icon-icon-file-width: 30px;
$icon-icon-file-height: 30px;
$icon-icon-filter-width: 30px;
$icon-icon-filter-height: 30px;
$icon-icon-find-width: 30px;
$icon-icon-find-height: 30px;
$icon-icon-fire-smoke-width: 80px;
$icon-icon-fire-smoke-height: 80px;
$icon-icon-fire-width: 30px;
$icon-icon-fire-height: 30px;
$icon-icon-fitness-width: 30px;
$icon-icon-fitness-height: 30px;
$icon-icon-floorplan-width: 30px;
$icon-icon-floorplan-height: 30px;
$icon-icon-focus-off-width: 30px;
$icon-icon-focus-off-height: 30px;
$icon-icon-focus-on-width: 30px;
$icon-icon-focus-on-height: 30px;
$icon-icon-food-width: 30px;
$icon-icon-food-height: 30px;
$icon-icon-fridge-freezer-width: 30px;
$icon-icon-fridge-freezer-height: 30px;
$icon-icon-furnished-width: 30px;
$icon-icon-furnished-height: 30px;
$icon-icon-fuse-box-width: 80px;
$icon-icon-fuse-box-height: 80px;
$icon-icon-games-width: 30px;
$icon-icon-games-height: 30px;
$icon-icon-garage-width: 30px;
$icon-icon-garage-height: 30px;
$icon-icon-garden-width: 30px;
$icon-icon-garden-height: 30px;
$icon-icon-gas-width: 80px;
$icon-icon-gas-height: 80px;
$icon-icon-gasboiler-width: 50px;
$icon-icon-gasboiler-height: 80px;
$icon-icon-gender-any-width: 30px;
$icon-icon-gender-any-height: 30px;
$icon-icon-good-low-width: 30px;
$icon-icon-good-low-height: 30px;
$icon-icon-good-width: 30px;
$icon-icon-good-height: 30px;
$icon-icon-grass-cutter-width: 80px;
$icon-icon-grass-cutter-height: 80px;
$icon-icon-great-low-width: 30px;
$icon-icon-great-low-height: 30px;
$icon-icon-great-width: 30px;
$icon-icon-great-height: 30px;
$icon-icon-happy-low-width: 30px;
$icon-icon-happy-low-height: 30px;
$icon-icon-happy-outline-width: 30px;
$icon-icon-happy-outline-height: 30px;
$icon-icon-happy-width: 30px;
$icon-icon-happy-height: 30px;
$icon-icon-heating-width: 80px;
$icon-icon-heating-height: 80px;
$icon-icon-hourglass-width: 30px;
$icon-icon-hourglass-height: 30px;
$icon-icon-house-width: 30px;
$icon-icon-house-height: 30px;
$icon-icon-id-width: 30px;
$icon-icon-id-height: 30px;
$icon-icon-images-width: 30px;
$icon-icon-images-height: 30px;
$icon-icon-income-width: 30px;
$icon-icon-income-height: 30px;
$icon-icon-info-reverse-width: 30px;
$icon-icon-info-reverse-height: 30px;
$icon-icon-info-width: 30px;
$icon-icon-info-height: 30px;
$icon-icon-instagram-width: 30px;
$icon-icon-instagram-height: 30px;
$icon-icon-keys-width: 80px;
$icon-icon-keys-height: 80px;
$icon-icon-kitchen-width: 80px;
$icon-icon-kitchen-height: 80px;
$icon-icon-kitchenette-width: 30px;
$icon-icon-kitchenette-height: 30px;
$icon-icon-landlord-width: 30px;
$icon-icon-landlord-height: 30px;
$icon-icon-last-child-width: 30px;
$icon-icon-last-child-height: 30px;
$icon-icon-leaking-width: 80px;
$icon-icon-leaking-height: 80px;
$icon-icon-left-arrow-width: 30px;
$icon-icon-left-arrow-height: 30px;
$icon-icon-light-width: 30px;
$icon-icon-light-height: 30px;
$icon-icon-lightbulb-width: 80px;
$icon-icon-lightbulb-height: 80px;
$icon-icon-lighting-width: 80px;
$icon-icon-lighting-height: 80px;
$icon-icon-lightning-width: 30px;
$icon-icon-lightning-height: 30px;
$icon-icon-link-width: 30px;
$icon-icon-link-height: 30px;
$icon-icon-linkedin-width: 30px;
$icon-icon-linkedin-height: 30px;
$icon-icon-list-width: 30px;
$icon-icon-list-height: 30px;
$icon-icon-living-room-width: 30px;
$icon-icon-living-room-height: 30px;
$icon-icon-logout-width: 30px;
$icon-icon-logout-height: 30px;
$icon-icon-looking-width: 30px;
$icon-icon-looking-height: 30px;
$icon-icon-love-width: 30px;
$icon-icon-love-height: 30px;
$icon-icon-mail-width: 30px;
$icon-icon-mail-height: 30px;
$icon-icon-male-width: 30px;
$icon-icon-male-height: 30px;
$icon-icon-males-width: 30px;
$icon-icon-males-height: 30px;
$icon-icon-mastercard-width: 47px;
$icon-icon-mastercard-height: 30px;
$icon-icon-maximise-width: 30px;
$icon-icon-maximise-height: 30px;
$icon-icon-maximum-stay-width: 30px;
$icon-icon-maximum-stay-height: 30px;
$icon-icon-message-off-width: 30px;
$icon-icon-message-off-height: 30px;
$icon-icon-message-width: 30px;
$icon-icon-message-height: 30px;
$icon-icon-minimise-width: 30px;
$icon-icon-minimise-height: 30px;
$icon-icon-minimum-stay-width: 30px;
$icon-icon-minimum-stay-height: 30px;
$icon-icon-mixed-use-width: 30px;
$icon-icon-mixed-use-height: 30px;
$icon-icon-mixed-width: 30px;
$icon-icon-mixed-height: 30px;
$icon-icon-more-width: 30px;
$icon-icon-more-height: 30px;
$icon-icon-movies-width: 30px;
$icon-icon-movies-height: 30px;
$icon-icon-music-width: 30px;
$icon-icon-music-height: 30px;
$icon-icon-nature-width: 30px;
$icon-icon-nature-height: 30px;
$icon-icon-netflix-width: 30px;
$icon-icon-netflix-height: 30px;
$icon-icon-new-tab-width: 30px;
$icon-icon-new-tab-height: 30px;
$icon-icon-new-window-width: 30px;
$icon-icon-new-window-height: 30px;
$icon-icon-new-width: 30px;
$icon-icon-new-height: 30px;
$icon-icon-nights-out-width: 30px;
$icon-icon-nights-out-height: 30px;
$icon-icon-no-pets-width: 30px;
$icon-icon-no-pets-height: 30px;
$icon-icon-no-smoking-width: 30px;
$icon-icon-no-smoking-height: 30px;
$icon-icon-off-road-parking-width: 30px;
$icon-icon-off-road-parking-height: 30px;
$icon-icon-off-suite-width: 30px;
$icon-icon-off-suite-height: 30px;
$icon-icon-offsuite-width: 30px;
$icon-icon-offsuite-height: 30px;
$icon-icon-open-width: 30px;
$icon-icon-open-height: 30px;
$icon-icon-option-width: 30px;
$icon-icon-option-height: 30px;
$icon-icon-other-card-width: 47px;
$icon-icon-other-card-height: 30px;
$icon-icon-other-gender-width: 30px;
$icon-icon-other-gender-height: 30px;
$icon-icon-outdoor-seating-width: 30px;
$icon-icon-outdoor-seating-height: 30px;
$icon-icon-painting-width: 80px;
$icon-icon-painting-height: 80px;
$icon-icon-parking-permit-width: 30px;
$icon-icon-parking-permit-height: 30px;
$icon-icon-parking-width: 30px;
$icon-icon-parking-height: 30px;
$icon-icon-parties-width: 30px;
$icon-icon-parties-height: 30px;
$icon-icon-pending-width: 30px;
$icon-icon-pending-height: 30px;
$icon-icon-pets-width: 30px;
$icon-icon-pets-height: 30px;
$icon-icon-phone-width: 30px;
$icon-icon-phone-height: 30px;
$icon-icon-picture-width: 30px;
$icon-icon-picture-height: 30px;
$icon-icon-pin-width: 30px;
$icon-icon-pin-height: 30px;
$icon-icon-plug-width: 30px;
$icon-icon-plug-height: 30px;
$icon-icon-port-width: 30px;
$icon-icon-port-height: 30px;
$icon-icon-premium-width: 30px;
$icon-icon-premium-height: 30px;
$icon-icon-press-width: 30px;
$icon-icon-press-height: 30px;
$icon-icon-private-bathroom-width: 30px;
$icon-icon-private-bathroom-height: 30px;
$icon-icon-processing-width: 30px;
$icon-icon-processing-height: 30px;
$icon-icon-professional-width: 30px;
$icon-icon-professional-height: 30px;
$icon-icon-pulse-width: 30px;
$icon-icon-pulse-height: 30px;
$icon-icon-quiet-nights-width: 30px;
$icon-icon-quiet-nights-height: 30px;
$icon-icon-quiet-nights-width: 30px;
$icon-icon-quiet-nights-height: 30px;
$icon-icon-rat-width: 80px;
$icon-icon-rat-height: 80px;
$icon-icon-reading-width: 30px;
$icon-icon-reading-height: 30px;
$icon-icon-reference-check-width: 30px;
$icon-icon-reference-check-height: 30px;
$icon-icon-reload-width: 30px;
$icon-icon-reload-height: 30px;
$icon-icon-rent-width: 30px;
$icon-icon-rent-height: 30px;
$icon-icon-repair-width: 30px;
$icon-icon-repair-height: 30px;
$icon-icon-report-width: 30px;
$icon-icon-report-height: 30px;
$icon-icon-right-arrow-width: 30px;
$icon-icon-right-arrow-height: 30px;
$icon-icon-rolling-width: 30px;
$icon-icon-rolling-height: 30px;
$icon-icon-room-width: 30px;
$icon-icon-room-height: 30px;
$icon-icon-rotate-left-width: 30px;
$icon-icon-rotate-left-height: 30px;
$icon-icon-rotate-right-width: 30px;
$icon-icon-rotate-right-height: 30px;
$icon-icon-sad-low-width: 30px;
$icon-icon-sad-low-height: 30px;
$icon-icon-sad-width: 30px;
$icon-icon-sad-height: 30px;
$icon-icon-send-width: 30px;
$icon-icon-send-height: 30px;
$icon-icon-settings-width: 30px;
$icon-icon-settings-height: 30px;
$icon-icon-shop-width: 30px;
$icon-icon-shop-height: 30px;
$icon-icon-signature-width: 30px;
$icon-icon-signature-height: 30px;
$icon-icon-silence-width: 30px;
$icon-icon-silence-height: 30px;
$icon-icon-single-gender-width: 30px;
$icon-icon-single-gender-height: 30px;
$icon-icon-single-room-width: 30px;
$icon-icon-single-room-height: 30px;
$icon-icon-sink-width: 30px;
$icon-icon-sink-height: 30px;
$icon-icon-smoking-width: 30px;
$icon-icon-smoking-height: 30px;
$icon-icon-snooze-width: 30px;
$icon-icon-snooze-height: 30px;
$icon-icon-sort-none-width: 30px;
$icon-icon-sort-none-height: 30px;
$icon-icon-sort-up-width: 30px;
$icon-icon-sort-up-height: 30px;
$icon-icon-sort-width: 30px;
$icon-icon-sort-height: 30px;
$icon-icon-sports-width: 30px;
$icon-icon-sports-height: 30px;
$icon-icon-stair-width: 80px;
$icon-icon-stair-height: 80px;
$icon-icon-star-width: 30px;
$icon-icon-star-height: 30px;
$icon-icon-stop-width: 30px;
$icon-icon-stop-height: 30px;
$icon-icon-student-width: 30px;
$icon-icon-student-height: 30px;
$icon-icon-students-width: 30px;
$icon-icon-students-height: 30px;
$icon-icon-sublettor-width: 30px;
$icon-icon-sublettor-height: 30px;
$icon-icon-success-width: 30px;
$icon-icon-success-height: 30px;
$icon-icon-suitcase-width: 30px;
$icon-icon-suitcase-height: 30px;
$icon-icon-task-width: 30px;
$icon-icon-task-height: 30px;
$icon-icon-tds-width: 222px;
$icon-icon-tds-height: 93px;
$icon-icon-tenant-any-width: 30px;
$icon-icon-tenant-any-height: 30px;
$icon-icon-tenants-width: 30px;
$icon-icon-tenants-height: 30px;
$icon-icon-thumbs-down-width: 30px;
$icon-icon-thumbs-down-height: 30px;
$icon-icon-thumbs-up-width: 30px;
$icon-icon-thumbs-up-height: 30px;
$icon-icon-tick-copy-width: 30px;
$icon-icon-tick-copy-height: 30px;
$icon-icon-tick-width: 30px;
$icon-icon-tick-height: 30px;
$icon-icon-tiktok-width: 30px;
$icon-icon-tiktok-height: 30px;
$icon-icon-tour-width: 30px;
$icon-icon-tour-height: 30px;
$icon-icon-transfer-width: 30px;
$icon-icon-transfer-height: 30px;
$icon-icon-travelling-width: 30px;
$icon-icon-travelling-height: 30px;
$icon-icon-tumble-dryer-width: 30px;
$icon-icon-tumble-dryer-height: 30px;
$icon-icon-tv-licence-width: 30px;
$icon-icon-tv-licence-height: 30px;
$icon-icon-tv-width: 30px;
$icon-icon-tv-height: 30px;
$icon-icon-twitter-width: 30px;
$icon-icon-twitter-height: 30px;
$icon-icon-unhappy-low-width: 30px;
$icon-icon-unhappy-low-height: 30px;
$icon-icon-unhappy-width: 30px;
$icon-icon-unhappy-height: 30px;
$icon-icon-unplugged-width: 30px;
$icon-icon-unplugged-height: 30px;
$icon-icon-unread-width: 30px;
$icon-icon-unread-height: 30px;
$icon-icon-unsilence-width: 30px;
$icon-icon-unsilence-height: 30px;
$icon-icon-unspecified-gender-width: 30px;
$icon-icon-unspecified-gender-height: 30px;
$icon-icon-up-arrow-width: 30px;
$icon-icon-up-arrow-height: 30px;
$icon-icon-user-width: 30px;
$icon-icon-user-height: 30px;
$icon-icon-users-width: 30px;
$icon-icon-users-height: 30px;
$icon-icon-van-width: 30px;
$icon-icon-van-height: 30px;
$icon-icon-vegetable-patch-width: 30px;
$icon-icon-vegetable-patch-height: 30px;
$icon-icon-video-games-width: 30px;
$icon-icon-video-games-height: 30px;
$icon-icon-video-width: 30px;
$icon-icon-video-height: 30px;
$icon-icon-viewing-width: 30px;
$icon-icon-viewing-height: 30px;
$icon-icon-virgin-sky-tv-width: 30px;
$icon-icon-virgin-sky-tv-height: 30px;
$icon-icon-visa-width: 47px;
$icon-icon-visa-height: 30px;
$icon-icon-wardrobe-width: 30px;
$icon-icon-wardrobe-height: 30px;
$icon-icon-warning-width: 30px;
$icon-icon-warning-height: 30px;
$icon-icon-washing-machine-width: 30px;
$icon-icon-washing-machine-height: 30px;
$icon-icon-waste-bin-width: 80px;
$icon-icon-waste-bin-height: 80px;
$icon-icon-wedges-width: 30px;
$icon-icon-wedges-height: 30px;
$icon-icon-wifi-router-width: 80px;
$icon-icon-wifi-router-height: 80px;
$icon-icon-wifi-width: 30px;
$icon-icon-wifi-height: 30px;
$icon-icon-window-width: 80px;
$icon-icon-window-height: 80px;
$icon-icon-wired-internet-width: 30px;
$icon-icon-wired-internet-height: 30px;
$icon-icon-workspace-width: 30px;
$icon-icon-workspace-height: 30px;
$icon-icon-xero-accounts-width: 80px;
$icon-icon-xero-accounts-height: 80px;
$icon-icon-xero-config-width: 80px;
$icon-icon-xero-config-height: 80px;
$icon-icon-xero-contacts-width: 80px;
$icon-icon-xero-contacts-height: 80px;
$icon-icon-xero-filter-width: 80px;
$icon-icon-xero-filter-height: 80px;
$icon-icon-xero-folder-width: 80px;
$icon-icon-xero-folder-height: 80px;
$icon-icon-xero-sync-width: 80px;
$icon-icon-xero-sync-height: 80px;
$icon-icon-xero-tax-width: 80px;
$icon-icon-xero-tax-height: 80px;
$icon-icon-youtube-width: 30px;
$icon-icon-youtube-height: 30px;
$icon-loader-width: 283.46px;
$icon-loader-height: 283.46px;

.arrow-down {
  width: $icon-arrow-down-width;
  height: $icon-arrow-down-height;
}

.arrow-left {
  width: $icon-arrow-left-width;
  height: $icon-arrow-left-height;
}

.arrow-right {
  width: $icon-arrow-right-width;
  height: $icon-arrow-right-height;
}

.arrow-up {
  width: $icon-arrow-up-width;
  height: $icon-arrow-up-height;
}

.coho-loader-old {
  width: $icon-coho-loader-old-width;
  height: $icon-coho-loader-old-height;
}
#gLogoRotateL,#CircleLeft{ -webkit-transform-origin: 40% 40%; -ms-transform-origin: 40% 40%; transform-origin: 40% 40%; }
#gLogoRotateR,#CircleRight{ -webkit-transform-origin: 60% 60%; -ms-transform-origin: 60% 60%; transform-origin: 60% 60%; }
#CircleLeft,#CircleRight{ -webkit-animation: anim1 2s infinite ease-in-out; animation: anim1 2s infinite ease-in-out; }
@-webkit-keyframes anim1{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes anim1{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
#gLogoRotateL,#gLogoRotateR{ -webkit-animation: anim2 3s infinite linear; animation: anim2 3s infinite linear; }
@-webkit-keyframes anim2{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes anim2{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.coho-loader {
  width: $icon-coho-loader-width;
  height: $icon-coho-loader-height;
}
#gLogoRotateL,#CircleLeft{ -webkit-transform-origin: 40% 40%; -ms-transform-origin: 40% 40%; transform-origin: 40% 40%; }
#gLogoRotateR,#CircleRight{ -webkit-transform-origin: 60% 60%; -ms-transform-origin: 60% 60%; transform-origin: 60% 60%; }
#CircleLeft,#CircleRight{ -webkit-animation: anim1 2s infinite ease-in-out; animation: anim1 2s infinite ease-in-out; }
@-webkit-keyframes anim1{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes anim1{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
#gLogoRotateL,#gLogoRotateR{ -webkit-animation: anim2 3s infinite linear; animation: anim2 3s infinite linear; }
@-webkit-keyframes anim2{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes anim2{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.icon-actions {
  width: $icon-icon-actions-width;
  height: $icon-icon-actions-height;
}

.icon-add {
  width: $icon-icon-add-width;
  height: $icon-icon-add-height;
}

.icon-airport {
  width: $icon-icon-airport-width;
  height: $icon-icon-airport-height;
}

.icon-alert {
  width: $icon-icon-alert-width;
  height: $icon-icon-alert-height;
}

.icon-amazon-prime-video {
  width: $icon-icon-amazon-prime-video-width;
  height: $icon-icon-amazon-prime-video-height;
}

.icon-appliances {
  width: $icon-icon-appliances-width;
  height: $icon-icon-appliances-height;
}

.icon-archive {
  width: $icon-icon-archive-width;
  height: $icon-icon-archive-height;
}

.icon-area {
  width: $icon-icon-area-width;
  height: $icon-icon-area-height;
}

.icon-armchair {
  width: $icon-icon-armchair-width;
  height: $icon-icon-armchair-height;
}

.icon-arrow-down {
  width: $icon-icon-arrow-down-width;
  height: $icon-icon-arrow-down-height;
}

.icon-arrow-left {
  width: $icon-icon-arrow-left-width;
  height: $icon-icon-arrow-left-height;
}

.icon-arrow-right {
  width: $icon-icon-arrow-right-width;
  height: $icon-icon-arrow-right-height;
}

.icon-arrow-up {
  width: $icon-icon-arrow-up-width;
  height: $icon-icon-arrow-up-height;
}

.icon-art-and-crafts {
  width: $icon-icon-art-and-crafts-width;
  height: $icon-icon-art-and-crafts-height;
}

.icon-attach-file {
  width: $icon-icon-attach-file-width;
  height: $icon-icon-attach-file-height;
}

.icon-barclays {
  width: $icon-icon-barclays-width;
  height: $icon-icon-barclays-height;
}

.icon-bathroom {
  width: $icon-icon-bathroom-width;
  height: $icon-icon-bathroom-height;
}

.icon-bbq {
  width: $icon-icon-bbq-width;
  height: $icon-icon-bbq-height;
}

.icon-bed {
  width: $icon-icon-bed-width;
  height: $icon-icon-bed-height;
}

.icon-bell {
  width: $icon-icon-bell-width;
  height: $icon-icon-bell-height;
}

.icon-bicycle {
  width: $icon-icon-bicycle-width;
  height: $icon-icon-bicycle-height;
}

.icon-bills {
  width: $icon-icon-bills-width;
  height: $icon-icon-bills-height;
}

.icon-block {
  width: $icon-icon-block-width;
  height: $icon-icon-block-height;
}

.icon-boat {
  width: $icon-icon-boat-width;
  height: $icon-icon-boat-height;
}

.icon-boiler {
  width: $icon-icon-boiler-width;
  height: $icon-icon-boiler-height;
}

.icon-calendar {
  width: $icon-icon-calendar-width;
  height: $icon-icon-calendar-height;
}

.icon-card {
  width: $icon-icon-card-width;
  height: $icon-icon-card-height;
}

.icon-cart {
  width: $icon-icon-cart-width;
  height: $icon-icon-cart-height;
}

.icon-checkboard {
  width: $icon-icon-checkboard-width;
  height: $icon-icon-checkboard-height;
}

.icon-child {
  width: $icon-icon-child-width;
  height: $icon-icon-child-height;
}

.icon-chimney {
  width: $icon-icon-chimney-width;
  height: $icon-icon-chimney-height;
}

.icon-circles {
  width: $icon-icon-circles-width;
  height: $icon-icon-circles-height;
}

.icon-clock {
  width: $icon-icon-clock-width;
  height: $icon-icon-clock-height;
}

.icon-clothes-line {
  width: $icon-icon-clothes-line-width;
  height: $icon-icon-clothes-line-height;
}

.icon-coho {
  width: $icon-icon-coho-width;
  height: $icon-icon-coho-height;
}

.icon-commercial {
  width: $icon-icon-commercial-width;
  height: $icon-icon-commercial-height;
}

.icon-contract-creation {
  width: $icon-icon-contract-creation-width;
  height: $icon-icon-contract-creation-height;
}

.icon-conversation {
  width: $icon-icon-conversation-width;
  height: $icon-icon-conversation-height;
}

.icon-copy {
  width: $icon-icon-copy-width;
  height: $icon-icon-copy-height;
}

.icon-credit-card {
  width: $icon-icon-credit-card-width;
  height: $icon-icon-credit-card-height;
}

.icon-crop {
  width: $icon-icon-crop-width;
  height: $icon-icon-crop-height;
}

.icon-cross {
  width: $icon-icon-cross-width;
  height: $icon-icon-cross-height;
}

.icon-dark {
  width: $icon-icon-dark-width;
  height: $icon-icon-dark-height;
}

.icon-delete {
  width: $icon-icon-delete-width;
  height: $icon-icon-delete-height;
}

.icon-deposit {
  width: $icon-icon-deposit-width;
  height: $icon-icon-deposit-height;
}

.icon-description {
  width: $icon-icon-description-width;
  height: $icon-icon-description-height;
}

.icon-desk {
  width: $icon-icon-desk-width;
  height: $icon-icon-desk-height;
}

.icon-disabled-access {
  width: $icon-icon-disabled-access-width;
  height: $icon-icon-disabled-access-height;
}

.icon-discord {
  width: $icon-icon-discord-width;
  height: $icon-icon-discord-height;
}

.icon-dishwasher {
  width: $icon-icon-dishwasher-width;
  height: $icon-icon-dishwasher-height;
}

.icon-disney-plus {
  width: $icon-icon-disney-plus-width;
  height: $icon-icon-disney-plus-height;
}

.icon-document {
  width: $icon-icon-document-width;
  height: $icon-icon-document-height;
}

.icon-door-handle {
  width: $icon-icon-door-handle-width;
  height: $icon-icon-door-handle-height;
}

.icon-double-room {
  width: $icon-icon-double-room-width;
  height: $icon-icon-double-room-height;
}

.icon-down-arrow {
  width: $icon-icon-down-arrow-width;
  height: $icon-icon-down-arrow-height;
}

.icon-download {
  width: $icon-icon-download-width;
  height: $icon-icon-download-height;
}

.icon-drag-handle {
  width: $icon-icon-drag-handle-width;
  height: $icon-icon-drag-handle-height;
}

.icon-drawers {
  width: $icon-icon-drawers-width;
  height: $icon-icon-drawers-height;
}

.icon-edit {
  width: $icon-icon-edit-width;
  height: $icon-icon-edit-height;
}

.icon-email {
  width: $icon-icon-email-width;
  height: $icon-icon-email-height;
}

.icon-emails {
  width: $icon-icon-emails-width;
  height: $icon-icon-emails-height;
}

.icon-en-suite {
  width: $icon-icon-en-suite-width;
  height: $icon-icon-en-suite-height;
}

.icon-ensuite {
  width: $icon-icon-ensuite-width;
  height: $icon-icon-ensuite-height;
}

.icon-estate {
  width: $icon-icon-estate-width;
  height: $icon-icon-estate-height;
}

.icon-expand {
  width: $icon-icon-expand-width;
  height: $icon-icon-expand-height;
}

.icon-expense {
  width: $icon-icon-expense-width;
  height: $icon-icon-expense-height;
}

.icon-extinguisher {
  width: $icon-icon-extinguisher-width;
  height: $icon-icon-extinguisher-height;
}

.icon-facebook {
  width: $icon-icon-facebook-width;
  height: $icon-icon-facebook-height;
}

.icon-female {
  width: $icon-icon-female-width;
  height: $icon-icon-female-height;
}

.icon-females {
  width: $icon-icon-females-width;
  height: $icon-icon-females-height;
}

.icon-fibre {
  width: $icon-icon-fibre-width;
  height: $icon-icon-fibre-height;
}

.icon-file {
  width: $icon-icon-file-width;
  height: $icon-icon-file-height;
}

.icon-filter {
  width: $icon-icon-filter-width;
  height: $icon-icon-filter-height;
}

.icon-find {
  width: $icon-icon-find-width;
  height: $icon-icon-find-height;
}

.icon-fire-smoke {
  width: $icon-icon-fire-smoke-width;
  height: $icon-icon-fire-smoke-height;
}

.icon-fire {
  width: $icon-icon-fire-width;
  height: $icon-icon-fire-height;
}

.icon-fitness {
  width: $icon-icon-fitness-width;
  height: $icon-icon-fitness-height;
}

.icon-floorplan {
  width: $icon-icon-floorplan-width;
  height: $icon-icon-floorplan-height;
}

.icon-focus-off {
  width: $icon-icon-focus-off-width;
  height: $icon-icon-focus-off-height;
}

.icon-focus-on {
  width: $icon-icon-focus-on-width;
  height: $icon-icon-focus-on-height;
}

.icon-food {
  width: $icon-icon-food-width;
  height: $icon-icon-food-height;
}

.icon-fridge-freezer {
  width: $icon-icon-fridge-freezer-width;
  height: $icon-icon-fridge-freezer-height;
}

.icon-furnished {
  width: $icon-icon-furnished-width;
  height: $icon-icon-furnished-height;
}

.icon-fuse-box {
  width: $icon-icon-fuse-box-width;
  height: $icon-icon-fuse-box-height;
}

.icon-games {
  width: $icon-icon-games-width;
  height: $icon-icon-games-height;
}

.icon-garage {
  width: $icon-icon-garage-width;
  height: $icon-icon-garage-height;
}

.icon-garden {
  width: $icon-icon-garden-width;
  height: $icon-icon-garden-height;
}

.icon-gas {
  width: $icon-icon-gas-width;
  height: $icon-icon-gas-height;
}

.icon-gasboiler {
  width: $icon-icon-gasboiler-width;
  height: $icon-icon-gasboiler-height;
}

.icon-gender-any {
  width: $icon-icon-gender-any-width;
  height: $icon-icon-gender-any-height;
}

.icon-good-low {
  width: $icon-icon-good-low-width;
  height: $icon-icon-good-low-height;
}

.icon-good {
  width: $icon-icon-good-width;
  height: $icon-icon-good-height;
}

.icon-grass-cutter {
  width: $icon-icon-grass-cutter-width;
  height: $icon-icon-grass-cutter-height;
}

.icon-great-low {
  width: $icon-icon-great-low-width;
  height: $icon-icon-great-low-height;
}

.icon-great {
  width: $icon-icon-great-width;
  height: $icon-icon-great-height;
}

.icon-happy-low {
  width: $icon-icon-happy-low-width;
  height: $icon-icon-happy-low-height;
}

.icon-happy-outline {
  width: $icon-icon-happy-outline-width;
  height: $icon-icon-happy-outline-height;
}

.icon-happy {
  width: $icon-icon-happy-width;
  height: $icon-icon-happy-height;
}

.icon-heating {
  width: $icon-icon-heating-width;
  height: $icon-icon-heating-height;
}

.icon-hourglass {
  width: $icon-icon-hourglass-width;
  height: $icon-icon-hourglass-height;
}

.icon-house {
  width: $icon-icon-house-width;
  height: $icon-icon-house-height;
}

.icon-id {
  width: $icon-icon-id-width;
  height: $icon-icon-id-height;
}

.icon-images {
  width: $icon-icon-images-width;
  height: $icon-icon-images-height;
}

.icon-income {
  width: $icon-icon-income-width;
  height: $icon-icon-income-height;
}

.icon-info-reverse {
  width: $icon-icon-info-reverse-width;
  height: $icon-icon-info-reverse-height;
}

.icon-info {
  width: $icon-icon-info-width;
  height: $icon-icon-info-height;
}

.icon-instagram {
  width: $icon-icon-instagram-width;
  height: $icon-icon-instagram-height;
}

.icon-keys {
  width: $icon-icon-keys-width;
  height: $icon-icon-keys-height;
}

.icon-kitchen {
  width: $icon-icon-kitchen-width;
  height: $icon-icon-kitchen-height;
}

.icon-kitchenette {
  width: $icon-icon-kitchenette-width;
  height: $icon-icon-kitchenette-height;
}

.icon-landlord {
  width: $icon-icon-landlord-width;
  height: $icon-icon-landlord-height;
}

.icon-last-child {
  width: $icon-icon-last-child-width;
  height: $icon-icon-last-child-height;
}

.icon-leaking {
  width: $icon-icon-leaking-width;
  height: $icon-icon-leaking-height;
}

.icon-left-arrow {
  width: $icon-icon-left-arrow-width;
  height: $icon-icon-left-arrow-height;
}

.icon-light {
  width: $icon-icon-light-width;
  height: $icon-icon-light-height;
}

.icon-lightbulb {
  width: $icon-icon-lightbulb-width;
  height: $icon-icon-lightbulb-height;
}

.icon-lighting {
  width: $icon-icon-lighting-width;
  height: $icon-icon-lighting-height;
}

.icon-lightning {
  width: $icon-icon-lightning-width;
  height: $icon-icon-lightning-height;
}

.icon-link {
  width: $icon-icon-link-width;
  height: $icon-icon-link-height;
}

.icon-linkedin {
  width: $icon-icon-linkedin-width;
  height: $icon-icon-linkedin-height;
}

.icon-list {
  width: $icon-icon-list-width;
  height: $icon-icon-list-height;
}

.icon-living-room {
  width: $icon-icon-living-room-width;
  height: $icon-icon-living-room-height;
}

.icon-logout {
  width: $icon-icon-logout-width;
  height: $icon-icon-logout-height;
}

.icon-looking {
  width: $icon-icon-looking-width;
  height: $icon-icon-looking-height;
}

.icon-love {
  width: $icon-icon-love-width;
  height: $icon-icon-love-height;
}

.icon-mail {
  width: $icon-icon-mail-width;
  height: $icon-icon-mail-height;
}

.icon-male {
  width: $icon-icon-male-width;
  height: $icon-icon-male-height;
}

.icon-males {
  width: $icon-icon-males-width;
  height: $icon-icon-males-height;
}

.icon-mastercard {
  width: $icon-icon-mastercard-width;
  height: $icon-icon-mastercard-height;
}

.icon-maximise {
  width: $icon-icon-maximise-width;
  height: $icon-icon-maximise-height;
}

.icon-maximum-stay {
  width: $icon-icon-maximum-stay-width;
  height: $icon-icon-maximum-stay-height;
}

.icon-message-off {
  width: $icon-icon-message-off-width;
  height: $icon-icon-message-off-height;
}

.icon-message {
  width: $icon-icon-message-width;
  height: $icon-icon-message-height;
}

.icon-minimise {
  width: $icon-icon-minimise-width;
  height: $icon-icon-minimise-height;
}

.icon-minimum-stay {
  width: $icon-icon-minimum-stay-width;
  height: $icon-icon-minimum-stay-height;
}

.icon-mixed-use {
  width: $icon-icon-mixed-use-width;
  height: $icon-icon-mixed-use-height;
}

.icon-mixed {
  width: $icon-icon-mixed-width;
  height: $icon-icon-mixed-height;
}

.icon-more {
  width: $icon-icon-more-width;
  height: $icon-icon-more-height;
}

.icon-movies {
  width: $icon-icon-movies-width;
  height: $icon-icon-movies-height;
}

.icon-music {
  width: $icon-icon-music-width;
  height: $icon-icon-music-height;
}

.icon-nature {
  width: $icon-icon-nature-width;
  height: $icon-icon-nature-height;
}

.icon-netflix {
  width: $icon-icon-netflix-width;
  height: $icon-icon-netflix-height;
}

.icon-new-tab {
  width: $icon-icon-new-tab-width;
  height: $icon-icon-new-tab-height;
}

.icon-new-window {
  width: $icon-icon-new-window-width;
  height: $icon-icon-new-window-height;
}

.icon-new {
  width: $icon-icon-new-width;
  height: $icon-icon-new-height;
}

.icon-nights-out {
  width: $icon-icon-nights-out-width;
  height: $icon-icon-nights-out-height;
}

.icon-no-pets {
  width: $icon-icon-no-pets-width;
  height: $icon-icon-no-pets-height;
}

.icon-no-smoking {
  width: $icon-icon-no-smoking-width;
  height: $icon-icon-no-smoking-height;
}

.icon-off-road-parking {
  width: $icon-icon-off-road-parking-width;
  height: $icon-icon-off-road-parking-height;
}

.icon-off-suite {
  width: $icon-icon-off-suite-width;
  height: $icon-icon-off-suite-height;
}

.icon-offsuite {
  width: $icon-icon-offsuite-width;
  height: $icon-icon-offsuite-height;
}

.icon-open {
  width: $icon-icon-open-width;
  height: $icon-icon-open-height;
}

.icon-option {
  width: $icon-icon-option-width;
  height: $icon-icon-option-height;
}

.icon-other-card {
  width: $icon-icon-other-card-width;
  height: $icon-icon-other-card-height;
}

.icon-other-gender {
  width: $icon-icon-other-gender-width;
  height: $icon-icon-other-gender-height;
}

.icon-outdoor-seating {
  width: $icon-icon-outdoor-seating-width;
  height: $icon-icon-outdoor-seating-height;
}

.icon-painting {
  width: $icon-icon-painting-width;
  height: $icon-icon-painting-height;
}

.icon-parking-permit {
  width: $icon-icon-parking-permit-width;
  height: $icon-icon-parking-permit-height;
}

.icon-parking {
  width: $icon-icon-parking-width;
  height: $icon-icon-parking-height;
}

.icon-parties {
  width: $icon-icon-parties-width;
  height: $icon-icon-parties-height;
}

.icon-pending {
  width: $icon-icon-pending-width;
  height: $icon-icon-pending-height;
}

.icon-pets {
  width: $icon-icon-pets-width;
  height: $icon-icon-pets-height;
}

.icon-phone {
  width: $icon-icon-phone-width;
  height: $icon-icon-phone-height;
}

.icon-picture {
  width: $icon-icon-picture-width;
  height: $icon-icon-picture-height;
}

.icon-pin {
  width: $icon-icon-pin-width;
  height: $icon-icon-pin-height;
}

.icon-plug {
  width: $icon-icon-plug-width;
  height: $icon-icon-plug-height;
}

.icon-port {
  width: $icon-icon-port-width;
  height: $icon-icon-port-height;
}

.icon-premium {
  width: $icon-icon-premium-width;
  height: $icon-icon-premium-height;
}

.icon-press {
  width: $icon-icon-press-width;
  height: $icon-icon-press-height;
}

.icon-private-bathroom {
  width: $icon-icon-private-bathroom-width;
  height: $icon-icon-private-bathroom-height;
}

.icon-processing {
  width: $icon-icon-processing-width;
  height: $icon-icon-processing-height;
}

.icon-professional {
  width: $icon-icon-professional-width;
  height: $icon-icon-professional-height;
}

.icon-pulse {
  width: $icon-icon-pulse-width;
  height: $icon-icon-pulse-height;
}

.icon-quiet-nights {
  width: $icon-icon-quiet-nights-width;
  height: $icon-icon-quiet-nights-height;
}

.icon-quiet-nights {
  width: $icon-icon-quiet-nights-width;
  height: $icon-icon-quiet-nights-height;
}

.icon-rat {
  width: $icon-icon-rat-width;
  height: $icon-icon-rat-height;
}

.icon-reading {
  width: $icon-icon-reading-width;
  height: $icon-icon-reading-height;
}

.icon-reference-check {
  width: $icon-icon-reference-check-width;
  height: $icon-icon-reference-check-height;
}

.icon-reload {
  width: $icon-icon-reload-width;
  height: $icon-icon-reload-height;
}

.icon-rent {
  width: $icon-icon-rent-width;
  height: $icon-icon-rent-height;
}

.icon-repair {
  width: $icon-icon-repair-width;
  height: $icon-icon-repair-height;
}

.icon-report {
  width: $icon-icon-report-width;
  height: $icon-icon-report-height;
}

.icon-right-arrow {
  width: $icon-icon-right-arrow-width;
  height: $icon-icon-right-arrow-height;
}

.icon-rolling {
  width: $icon-icon-rolling-width;
  height: $icon-icon-rolling-height;
}

.icon-room {
  width: $icon-icon-room-width;
  height: $icon-icon-room-height;
}

.icon-rotate-left {
  width: $icon-icon-rotate-left-width;
  height: $icon-icon-rotate-left-height;
}

.icon-rotate-right {
  width: $icon-icon-rotate-right-width;
  height: $icon-icon-rotate-right-height;
}

.icon-sad-low {
  width: $icon-icon-sad-low-width;
  height: $icon-icon-sad-low-height;
}

.icon-sad {
  width: $icon-icon-sad-width;
  height: $icon-icon-sad-height;
}

.icon-send {
  width: $icon-icon-send-width;
  height: $icon-icon-send-height;
}

.icon-settings {
  width: $icon-icon-settings-width;
  height: $icon-icon-settings-height;
}

.icon-shop {
  width: $icon-icon-shop-width;
  height: $icon-icon-shop-height;
}

.icon-signature {
  width: $icon-icon-signature-width;
  height: $icon-icon-signature-height;
}

.icon-silence {
  width: $icon-icon-silence-width;
  height: $icon-icon-silence-height;
}

.icon-single-gender {
  width: $icon-icon-single-gender-width;
  height: $icon-icon-single-gender-height;
}

.icon-single-room {
  width: $icon-icon-single-room-width;
  height: $icon-icon-single-room-height;
}

.icon-sink {
  width: $icon-icon-sink-width;
  height: $icon-icon-sink-height;
}

.icon-smoking {
  width: $icon-icon-smoking-width;
  height: $icon-icon-smoking-height;
}

.icon-snooze {
  width: $icon-icon-snooze-width;
  height: $icon-icon-snooze-height;
}

.icon-sort-none {
  width: $icon-icon-sort-none-width;
  height: $icon-icon-sort-none-height;
}

.icon-sort-up {
  width: $icon-icon-sort-up-width;
  height: $icon-icon-sort-up-height;
}

.icon-sort {
  width: $icon-icon-sort-width;
  height: $icon-icon-sort-height;
}

.icon-sports {
  width: $icon-icon-sports-width;
  height: $icon-icon-sports-height;
}

.icon-stair {
  width: $icon-icon-stair-width;
  height: $icon-icon-stair-height;
}

.icon-star {
  width: $icon-icon-star-width;
  height: $icon-icon-star-height;
}

.icon-stop {
  width: $icon-icon-stop-width;
  height: $icon-icon-stop-height;
}

.icon-student {
  width: $icon-icon-student-width;
  height: $icon-icon-student-height;
}

.icon-students {
  width: $icon-icon-students-width;
  height: $icon-icon-students-height;
}

.icon-sublettor {
  width: $icon-icon-sublettor-width;
  height: $icon-icon-sublettor-height;
}

.icon-success {
  width: $icon-icon-success-width;
  height: $icon-icon-success-height;
}

.icon-suitcase {
  width: $icon-icon-suitcase-width;
  height: $icon-icon-suitcase-height;
}

.icon-task {
  width: $icon-icon-task-width;
  height: $icon-icon-task-height;
}

.icon-tds {
  width: $icon-icon-tds-width;
  height: $icon-icon-tds-height;
}
.cls-1{fill:#001a96;}
.cls-2{fill:#00d99c;}

.icon-tenant-any {
  width: $icon-icon-tenant-any-width;
  height: $icon-icon-tenant-any-height;
}

.icon-tenants {
  width: $icon-icon-tenants-width;
  height: $icon-icon-tenants-height;
}

.icon-thumbs-down {
  width: $icon-icon-thumbs-down-width;
  height: $icon-icon-thumbs-down-height;
}

.icon-thumbs-up {
  width: $icon-icon-thumbs-up-width;
  height: $icon-icon-thumbs-up-height;
}

.icon-tick-copy {
  width: $icon-icon-tick-copy-width;
  height: $icon-icon-tick-copy-height;
}

.icon-tick {
  width: $icon-icon-tick-width;
  height: $icon-icon-tick-height;
}

.icon-tiktok {
  width: $icon-icon-tiktok-width;
  height: $icon-icon-tiktok-height;
}

.icon-tour {
  width: $icon-icon-tour-width;
  height: $icon-icon-tour-height;
}

.icon-transfer {
  width: $icon-icon-transfer-width;
  height: $icon-icon-transfer-height;
}

.icon-travelling {
  width: $icon-icon-travelling-width;
  height: $icon-icon-travelling-height;
}

.icon-tumble-dryer {
  width: $icon-icon-tumble-dryer-width;
  height: $icon-icon-tumble-dryer-height;
}

.icon-tv-licence {
  width: $icon-icon-tv-licence-width;
  height: $icon-icon-tv-licence-height;
}

.icon-tv {
  width: $icon-icon-tv-width;
  height: $icon-icon-tv-height;
}

.icon-twitter {
  width: $icon-icon-twitter-width;
  height: $icon-icon-twitter-height;
}

.icon-unhappy-low {
  width: $icon-icon-unhappy-low-width;
  height: $icon-icon-unhappy-low-height;
}

.icon-unhappy {
  width: $icon-icon-unhappy-width;
  height: $icon-icon-unhappy-height;
}

.icon-unplugged {
  width: $icon-icon-unplugged-width;
  height: $icon-icon-unplugged-height;
}

.icon-unread {
  width: $icon-icon-unread-width;
  height: $icon-icon-unread-height;
}

.icon-unsilence {
  width: $icon-icon-unsilence-width;
  height: $icon-icon-unsilence-height;
}

.icon-unspecified-gender {
  width: $icon-icon-unspecified-gender-width;
  height: $icon-icon-unspecified-gender-height;
}

.icon-up-arrow {
  width: $icon-icon-up-arrow-width;
  height: $icon-icon-up-arrow-height;
}

.icon-user {
  width: $icon-icon-user-width;
  height: $icon-icon-user-height;
}

.icon-users {
  width: $icon-icon-users-width;
  height: $icon-icon-users-height;
}

.icon-van {
  width: $icon-icon-van-width;
  height: $icon-icon-van-height;
}

.icon-vegetable-patch {
  width: $icon-icon-vegetable-patch-width;
  height: $icon-icon-vegetable-patch-height;
}

.icon-video-games {
  width: $icon-icon-video-games-width;
  height: $icon-icon-video-games-height;
}

.icon-video {
  width: $icon-icon-video-width;
  height: $icon-icon-video-height;
}

.icon-viewing {
  width: $icon-icon-viewing-width;
  height: $icon-icon-viewing-height;
}

.icon-virgin-sky-tv {
  width: $icon-icon-virgin-sky-tv-width;
  height: $icon-icon-virgin-sky-tv-height;
}

.icon-visa {
  width: $icon-icon-visa-width;
  height: $icon-icon-visa-height;
}

.icon-wardrobe {
  width: $icon-icon-wardrobe-width;
  height: $icon-icon-wardrobe-height;
}

.icon-warning {
  width: $icon-icon-warning-width;
  height: $icon-icon-warning-height;
}

.icon-washing-machine {
  width: $icon-icon-washing-machine-width;
  height: $icon-icon-washing-machine-height;
}

.icon-waste-bin {
  width: $icon-icon-waste-bin-width;
  height: $icon-icon-waste-bin-height;
}

.icon-wedges {
  width: $icon-icon-wedges-width;
  height: $icon-icon-wedges-height;
}

.icon-wifi-router {
  width: $icon-icon-wifi-router-width;
  height: $icon-icon-wifi-router-height;
}

.icon-wifi {
  width: $icon-icon-wifi-width;
  height: $icon-icon-wifi-height;
}

.icon-window {
  width: $icon-icon-window-width;
  height: $icon-icon-window-height;
}

.icon-wired-internet {
  width: $icon-icon-wired-internet-width;
  height: $icon-icon-wired-internet-height;
}

.icon-workspace {
  width: $icon-icon-workspace-width;
  height: $icon-icon-workspace-height;
}

.icon-xero-accounts {
  width: $icon-icon-xero-accounts-width;
  height: $icon-icon-xero-accounts-height;
}

.icon-xero-config {
  width: $icon-icon-xero-config-width;
  height: $icon-icon-xero-config-height;
}

.icon-xero-contacts {
  width: $icon-icon-xero-contacts-width;
  height: $icon-icon-xero-contacts-height;
}

.icon-xero-filter {
  width: $icon-icon-xero-filter-width;
  height: $icon-icon-xero-filter-height;
}

.icon-xero-folder {
  width: $icon-icon-xero-folder-width;
  height: $icon-icon-xero-folder-height;
}

.icon-xero-sync {
  width: $icon-icon-xero-sync-width;
  height: $icon-icon-xero-sync-height;
}

.icon-xero-tax {
  width: $icon-icon-xero-tax-width;
  height: $icon-icon-xero-tax-height;
}

.icon-youtube {
  width: $icon-icon-youtube-width;
  height: $icon-icon-youtube-height;
}

.loader {
  width: $icon-loader-width;
  height: $icon-loader-height;
}
#gLogoRotateL,#CircleLeft{ -webkit-transform-origin: 40% 40%; -ms-transform-origin: 40% 40%; transform-origin: 40% 40%; }
#gLogoRotateR,#CircleRight{ -webkit-transform-origin: 60% 60%; -ms-transform-origin: 60% 60%; transform-origin: 60% 60%; }
#CircleLeft,#CircleRight{ -webkit-animation: anim1 2s infinite ease-in-out; animation: anim1 2s infinite ease-in-out; }
@-webkit-keyframes anim1{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes anim1{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
#gLogoRotateL,#gLogoRotateR{ -webkit-animation: anim2 3s infinite linear; animation: anim2 3s infinite linear; }
@-webkit-keyframes anim2{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes anim2{ 0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

