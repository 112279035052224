.button{
    &.button--tag{
        background-color: var(--color-brown);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
        color: var(--color-white);
        cursor: pointer;
        display: inline-block;
        // margin-bottom: rems(10);
        // padding: rems(13) rems(12);
        position: relative;
        transition: all 0.2s ease-out;

        &:hover{
            background-color: var(--color-brown-dark);
        }

    }
    &.button--tag-zero {
        background-color: var(--color-brown-light);
        color: var(--color-text);
        &.button--icon .icon {
            fill: var(--color-text);
        }
    }
}

.tags__entity--teal {
    .button{
        &.button--tag{
            background-color: var(--color-teal);
        }
        &.button--tag-zero {
            background-color: var(--color-teal-light);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }
    }
}
.tags__entity--cyan {
    .button{
        &.button--tag{
            background-color: var(--color-cyan);
        }
        &.button--tag-zero {
            background-color: var(--color-cyan-light);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}
.tags__entity--violet {
    .button{
        &.button--tag{
            background-color: var(--color-violet);
        }
        &.button--tag-zero {
            background-color: var(--color-violet-light);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}
.tags__entity--pumpkin {
    .button{
        &.button--tag{
            background-color: var(--color-pumpkin);
        }
        &.button--tag-zero {
            background-color: var(--color-pumpkin-light);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}
.tags__entity--red {
    .button{
        &.button--tag{
            background-color: var(--color-red);
        }
        &.button--tag-zero {
            background-color: var(--color-red-light);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}
.tags__entity--green {
    .button{
        &.button--tag{
            background-color: var(--color-green-dark);
        }
        &.button--tag-zero {
            background-color: var(--color-green-dark);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}
.tags__entity--pink {
    .button{
        &.button--tag{
            background-color: var(--color-pink);
        }
        &.button--tag-zero {
            background-color: var(--color-pink);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}
.tags__entity--grey {
    .button{
        &.button--tag{
            background-color: var(--color-grey);
        }
        &.button--tag-zero {
            background-color: var(--color-grey-light);
            color: var(--color-text);
            &.button--icon .icon {
                fill: var(--color-text);
            }
        }    
    }
}