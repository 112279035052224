.button--advert{
    background-color: var(--color-violet-dark);
    border-color: var(--color-violet-xdark);
    color: var(--color-grey-xxlight);

    &:hover{
        background-color: var(--color-violet-xdark);
        border-color: var(--color-violet-xdark); 
        color: var(--color-grey-xxlight);
    }


    &:focus{
        border: 1px solid var(--color-violet-xdark);
        box-shadow: 0 0 0 rems(3) var(--color-violet-xdark);
        outline: none;
    }
}

.button--advert-alert{
    background-color: var(--color-magenta-dark);
    border-color: var(--color-magenta-xdark);
    color: var(--color-grey-xxlight);

    &:hover{
        background-color: var(--color-magenta-xdark);
        border-color: var(--color-magenta-xdark); 
        color: var(--color-grey-xxlight);
    }


    &:focus{
        border: 1px solid var(--color-magenta-xdark);
        box-shadow: 0 0 0 rems(3) var(--color-magenta-xdark);
        outline: none;
    }
}

@include breakpoint(for-small-desktop-up) {
    .button--advert {
        min-width: rems(170);
    }
}