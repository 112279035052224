.form__radio{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    margin-bottom: rems(10);

    input[type=radio]{
        appearance: none;
        background-color: white;
        border: 1px solid var(--color-grey-border);
        border-radius: 50%;
        display: flex;
        flex: 0 1 rems(32);
        height: rems(32);
        margin-right: rems(10);
        position: relative;
        top: 0;
        transition: 0.2s all ease-out;
        width: rems(32);

        &::after{
            background-color: var(--color-white);
            border-radius: 100%;
            content: "";
            display: block;
            height: rems(16);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: rems(16);
        }

        &:focus{
            box-shadow: 0px 0px 0px 2px var(--color-teal-dark);
            outline: none;
        }

        &:checked{

            &::after{
                background-color: var(--color-form);
            }

        }
    }

    &--small{
        input[type=radio]{
            flex: 0 1 rems(24);
            height: rems(24);
            width: rems(24);

            &::after{
                height: rems(12);
                width: rems(12);
            }

        }   
    }

    &--right{
        flex-direction: row-reverse;

        input[type=radio]{
            margin-left: rems(10);
            margin-right: 0;
        }
    }

}


@include breakpoint(for-large-phone-landscape-up){
    .form__radio{
        &--right-large-phone-up{
            flex-direction: row-reverse;
    
            input[type=radio]{
                margin-left: rems(10);
                margin-right: 0;
            }
        }
    }
}
