.marketing__cover{
    background-color: var(--color-blue-dark);
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: 95vh;
    padding-top: rems(80);
    position: relative;

    &::after{
        background: linear-gradient(rgba(#293b5b,1), rgba(#293b5b,0));
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }


    .marketing__header{
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        z-index: 2;
    }

    &--slim {
        min-height: 0;
        padding-bottom: rems(100);
    }
    &--slimmer {
        min-height: 0;
        padding-bottom: rems(40);
    }

}

.landing {
    .marketing__cover-content {
        min-height: rems(630);
    }
    .marketing__cover--slim, .marketing__cover--slimmer {
        .marketing__cover-content {
            min-height: 0;
        }
    }
    .marketing__cover {
        .button__group {
            &.button__group--centered {
                justify-content: left;
            }
        }
    }
}


.marketing__cover-content {
    align-items: flex-start;
    display: block;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: rems(20);
    padding-right: rems(20);
    position: relative;
    text-align: left;
    z-index: 1;

    h1, p {
        text-shadow: 0px 2px 10px rgba(0,0,0,0.33);
    }

    .button {
        font-size: rems(14);
        line-height: rems(16);
        min-width: rems(80);
        padding: rems(8);
    }

    .button--login {
        margin-left: 0.5rem;
    }

    .browse-link, .manager-link {
        color: white;

        &:focus {
            outline: none;
        }
    }

    .manager-link {
        display: flex;
        bottom: 1.875rem;
        position: absolute;
        left: 1.25rem;
        z-index: 100;
    }

    .marketing__cover-highlight {
        display: flex;
        bottom: 1.875rem;
        position: absolute;
        left: 1.25rem;
        z-index: 100;
        padding-right: rems(100);
        flex-direction: column;

        p {
            margin-bottom: rems(12);
        }

        .button {
            width: 8.5rem;
        }
    }
}


@media (min-width: 440px) {
    .marketing__cover-content .button {
        font-size: rems(16);
        line-height: rems(20);
        min-width: rems(120);
        padding: rems(13) rems(12);
    }

}


@include breakpoint(for-tablet-portrait-up) {

    .landing {
        .marketing__cover-content {
            min-height: rems(660);

            .button--register {
                display: none;
            }
        }
        .marketing__cover--slim, .marketing__cover--slimmer {
            .marketing__cover-content {
                min-height: 0;
            }
        }

        .marketing__cover {
            .button__group {
                &.button__group--centered {
                    justify-content: center;
                }
            }
        }
    }

    .marketing__cover-content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        max-width: rems(768);
        text-align: center;
        width: rems(768);


        .button__group {
            justify-content: center;
        }

        .button--login, .manager-link {
            display: none;
        }

        .marketing__cover-highlight {
            width: 100%;
            left: unset;
            bottom: 7rem;
            padding-right: 1.25rem;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            p {
                max-width: rems(400);
                margin-bottom: rems(20);
            }

            .button {
                width: 8.5rem;
            }
        }
    }

    .landing {
        .marketing__cover-content {
            padding-top: rems(80);
            justify-content: flex-start;
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .marketing__cover-content {
        .marketing__cover-highlight {
            p {
                max-width: 100%;
            }
        }
    }
}


@keyframes scroll_indicator {
    0% {
        opacity: 0;
        transform: translateY(rems(-20));
    }

    100% {
        opacity: 1;
        transform: translateY(rems(0));
    }
}


.marketing__cover-indicator {
    bottom: rems(30);
    position: absolute;
    right: rems(20);
    z-index: 100;

    .icon svg {
        animation: scroll_indicator 2s linear 1s infinite alternate;
        fill: #ffffff;
        opacity: 0;
        height: rems(30);
        width: rems(30);
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .marketing__cover { 
        min-height: 100vh;
        &--slim,&--slimmer {
            min-height: 0;
        }
    }

    .marketing__cover-indicator {
        left: 50%;
        margin-left: rems(-5);
        right: auto;

        .icon {
            height: rems(48);
            width: rems(48);
        }
    }
}

@media screen and (max-height: 770px) {
    .landing {
        .marketing__cover-content {
            padding-top: rems(20);
            min-height: rems(880);
        }
    }
}
@media screen and (max-height: 770px) and (min-width: 330px)  {
    .landing {
        .marketing__cover-content {
            min-height: rems(768);
        }
    }
}
@media screen and (max-height: 770px) and (min-width: 414px)  {
    .landing {
        .marketing__cover-content {
            min-height: rems(650);
        }
    }
}
