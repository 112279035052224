.nav__secondary{
    border-bottom: 1px solid var(--color-grey-light);
    padding-bottom: rems(10);
    padding-top: rems(10);
    .container {
        display: flex;
        flex-direction: column;
        a {
            flex: 1 1 100%;
            &.button__forward {
                justify-content: flex-end;
            }

            &:focus {
                outline: 0;
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {

    .nav__secondary{
        .container {
            flex-direction: row;
            a {
                flex: 1 1 50%;
            }
        }
    }

}