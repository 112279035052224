.supplier__detail--holder {

    border: 1px solid var(--color-violet-light);
    border-radius: radius(medium);
    padding: rems(8) rems(10);
    height: 100%;

    .supplier__detail--label {
        font-size: rems(12);
        width: 100%;
        font-weight: 600;
        color: var(--color-grey-border);
        margin-bottom: rems(4);
        line-height: 1;
    }

    .supplier__detail--wrapper {
        p {
            font-size: rems(18);
            margin-bottom: 0;
        }
        h4 {}
        .file_documents_container {
            margin-bottom: 0;
        }
    }

    &-lowlight {
        background-color: var(--color-grey-xlight);
    }

    &-white {
        background-color: var(--color-white);
    }
}

