.report {
    max-width: 800px;
    min-width: 600px;
    margin: auto;
    padding: rems(30) rems(10);
    font-size: rems(14);
    line-height: 1.4;
    font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;

    p {
        padding: 0;
        margin: 0;
        line-height: 1.4;
    }

    .report__header {
        padding-bottom: rems(8);
        margin-bottom: rems(8);
        border-bottom: 1px solid var(--color-black);
        display: flex;
        .report__header--image {
            img {
                width: rems(36);
            }
        }
        .report__header--content {
            margin-left: rems(20);

        }
    }

    .report__main-heading {
        width: 100%;
        font-weight: 700;
        font-size: rems(20);
        border-bottom: 1px solid var(--color-grey-light);
        padding-bottom: rems(6);
        margin-bottom: rems(12);
        padding-top: rems(12);
    }

}

@media (min-width: 800px) {
    .report__wrapper {
        background: var(--color-grey-xlight);
        height: 100vh;
        padding-top: 1.5rem;

        .report {
            background: white;
            min-height: 96vh;
            padding: rems(30) rems(30);
            -webkit-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            -moz-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
        }
    }
}

@media print {
    .report__wrapper {
        background: none;
        padding-top: 0;

        .report {
            background: none;
            min-height: 0;
            padding: 0 rems(10) 0 rems(10);
            box-shadow: none;
        }
    }
    .report__header--mobile {
        display: none;
    }
}
