.pricing__container {
    position: relative;
    background: var(--color-white);
    padding: rems(16);
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    outline: rems(1) solid var(--color-grey-light);
    &:hover {
        outline: rems(4) solid var(--color-green-dark);
        
    }
    h3 {
        font-size: rems(18);
        text-transform:uppercase;
        line-height: 1.3;
    }
    .text__price,.billed{
        margin: 0;
        padding-top: rems(10);
        color: #999;
        line-height: 1.1;
        font-size: 0.75rem;
        text-transform: uppercase;
        strong {
            color: var(--color-green-xdark);
            font-weight: 400;
            font-size: rems(56);
        }
        &.soon {
            padding-top: rems(18);
            padding-bottom: rems(16);
            line-height: 1;
            strong{
                color: var(--color-green-xdark);
                font-size: rems(42);
            }
        }
    }
    .billed {
        font-size: 0.625rem;
        padding-top: rems(8);
    }
    .title {
        font-size: 0.75rem;
        margin: 0;
        color: #999;
        text-transform: uppercase;
    }
    .subhead {
        font-size: rems(14);
        color: var(--color-grey-border);
        //padding-top: rems(18);
        margin-bottom: 0;
        line-height: 1.3;
    }
    .pricing__container--includes  {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-start;
        border-top: 1px solid var(--color-grey-light);
        // border-bottom: 1px solid var(--color-grey-light);
        padding-top: rems(20);
        margin-top: rems(6);
        //margin-bottom: rems(12);
        p {
            line-height: 1.3;
            margin-bottom: rems(12);

            &:not(.title) {
                font-size: rems(14);
            }
            &:last-child {
                margin-bottom: rems(12);
            }
        }
        ul {
            margin-bottom: 0;
            text-align: left;

            padding-left: rems(30);
            li {
                line-height: 1.3;
                font-size: rems(14);
                padding-left: rems(8);
                &::marker {
                    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.270,3.737 C9.586,3.737 12.275,6.425 12.275,9.741 C12.275,13.058 9.586,15.746 6.270,15.746 C2.954,15.746 0.266,13.058 0.266,9.741 C0.266,6.425 2.954,3.737 6.270,3.737 z' fill='%23E7F8E4'/%3E%3Cpath d='M6.338,14.382 C6.338,14.382 6.262,14.253 6.262,14.253 C5.099,12.271 2.007,8.064 1.976,8.022 C1.931,7.961 1.931,7.961 1.931,7.961 L2.985,6.921 C2.985,6.921 6.319,9.249 6.319,9.249 C8.418,6.525 10.376,4.654 11.653,3.561 C13.051,2.366 13.960,1.816 13.970,1.811 C13.990,1.798 13.990,1.798 13.990,1.798 L15.777,1.798 C15.777,1.798 15.606,1.950 15.606,1.950 C11.217,5.859 6.460,14.168 6.413,14.252 C6.338,14.382 6.338,14.382 6.338,14.382 z' fill='%235FA94F'/%3E%3C/svg%3E%0A");
                }
                div {
                    padding: rems(4) rems(4) rems(4) rems(8);
                    background: #fff7db;
                    border-radius: radius(small);
                    margin: rems(2) 0 rems(4) 0;
                    font-size: rems(12);
                }
            }
        }
    }
    a { font-size: 0.875rem; }
    &.pricing__container--coming-soon,&.pricing__container--disabled {
        background: var(--color-white);
        outline: rems(1) solid var(--color-grey-light);
        &:hover {
            cursor: default;
            outline: rems(1) solid var(--color-grey-light);
        }
        h3,p:not(.title),li {
            color: var(--color-grey-dark);
        }
    }
}
.pricing__container--selected {
    outline: rems(1) solid var(--color-green-dark);
    &:hover {
        outline: rems(4) solid var(--color-green-dark);
        
    }
    .selected {
        margin-top: rems(2);
        margin-bottom: 0;
        span{
            background-color: var(--color-green-xdark);
            color: var(--color-white);
            text-transform: uppercase;
            font-size: rems(12);
            font-weight: 800;
            padding: rems(2) rems(4);
            border-radius: radius(small);
        }
    }
}
.pricing__container--recommended {
    outline: rems(1) solid var(--color-grey-dark);
    &:hover {
        outline: rems(4) solid var(--color-green-dark);
        
    }
    .recommended {
        margin-top: rems(2);
        margin-bottom: 0;
        span{
            background-color: var(--color-grey-dark);
            color: var(--color-white);
            text-transform: uppercase;
            font-size: rems(12);
            font-weight: 800;
            padding: rems(2) rems(4);
            border-radius: radius(small);
        }
    }
}
.pricing__container--current {
    //background: var(--color-cyan-light);
    outline: rems(1) solid var(--color-grey-light);
    &:hover {
        cursor: default;
    }
    .text__price,.billed{
        strong {
            color: var(--color-grey-dark);
        }
    }
    .current {
        margin-top: rems(2);
        margin-bottom: 0;
        span{
            background-color: var(--color-grey-dark);
            color: var(--color-white);
            text-transform: uppercase;
            font-size: rems(12);
            font-weight: 800;
            padding: rems(2) rems(4);
            border-radius: radius(small);
        }
    }
}


.pricing__container--features {
    .subhead {
        margin-top: 0;
        padding-top: 0;
        flex: 1;
    }
    .pricing__container--includes  {
        p {
            margin-bottom: rems(8);
            line-height: 1.3rem;
            &:last-child {
                margin-bottom: rems(12);
            }
            &.disabled {
                color: var(--color-grey-light);
            }
        }
    }
}

.pricing__container--form-element {
    label {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    input {
        display: none;
    }
}

.pricing__container--hidden-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    margin: 0;
}

#pricing-panels {
    &.annual {
        .text__price,.billed {
            &.monthly { display: none;}
            &.annual { display: block;}
        }
    }
    &.monthly {
        .text__price,.billed {
            &.monthly { display: block;}
            &.annual { display: none;}
        }
    }
}

subscription-payment-required {
    form-message {
        display: block;
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .pricing__container {
        h3 {
            min-height: rems(54);
        }
        h3 + * {
            margin-top: 0;
        }

        &.pricing__container--selected {
            h3 {
                min-height: 0;
            }
            .selected {
                min-height: rems(28);
            }
        }
        &.pricing__container--recommended {
            h3 {
                min-height: 0;
            }
            .recommended {
                min-height: rems(28);
            }
        }

        &.pricing__container--current {
            h3 {
                min-height: 0;
            }
            .current {
                min-height: rems(28);
            }
        }
    }
}
@include breakpoint(for-small-desktop-up) {
    .pricing__container--includes  {
        padding: 0 rems(20);
        &.pricing__container--includes-wider {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
