.sprite-interest-icon-arts-and-crafts-large, .sprite-interest-icon-fitness-large, .sprite-interest-icon-food-large, .sprite-interest-icon-games-large, .sprite-interest-icon-movies-large,
.sprite-interest-icon-music-large, .sprite-interest-icon-nature-large, .sprite-interest-icon-nights-out-large, .sprite-interest-icon-parties-large, .sprite-interest-icon-quiet-nights-large,
.sprite-interest-icon-reading-large, .sprite-interest-icon-sports-large, .sprite-interest-icon-travelling-large, .sprite-interest-icon-tv-large, .sprite-interest-icon-video-games-large {
    display: inline-block;
    background: url('https://d19qeljo1i8r7y.cloudfront.net/sprites/sprite-interests.png') no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left;
}

.sprite-interest-icon-arts-and-crafts-large {
    background-position: -5px -0px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-fitness-large {
    background-position: -370px -0px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-food-large {
    background-position: -735px -0px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-games-large {
    background-position: -5px -365px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-movies-large {
    background-position: -370px -365px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-music-large {
    background-position: -735px -365px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-nature-large {
    background-position: -5px -730px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-nights-out-large {
    background-position: -370px -730px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-parties-large {
    background-position: -735px -730px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-quiet-nights-large {
    background-position: -5px -1095px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-reading-large {
    background-position: -370px -1095px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-sports-large {
    background-position: -735px -1095px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-travelling-large {
    background-position: -5px -1460px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-tv-large {
    background-position: -370px -1460px;
    width: 360px;
    height: 360px;
}

.sprite-interest-icon-video-games-large {
    background-position: -735px -1460px;
    width: 360px;
    height: 360px;
}

