.ob__connection-holder {
    border: rems(1) solid var(--color-grey-light);
    padding: rems(12);
    border-radius: radius(medium);
    margin-bottom: rems(10);
    &:last-child {
        margin-bottom: rems(20);
    }
    &--reconnect {
        border-color: var(--color-magenta);
        outline: 1px solid var(--color-magenta-xdark);
        box-shadow: 0 0 0 rems(3) rgba(var(--color-magenta-xdark-rgb),0.15);
    }
    .ob__connection-holder--header {
        display: flex;
        padding-bottom: rems(8);
        
        .ob__connection-holder--header-bank {
            display: flex;
            align-items: center;
            .ob__connection-holder--header-image {
                img {
                    max-height: rems(38);
                    border: 1px solid var(--color-grey-xlight);
                    border-radius: radius(small);
                }
            }
            .ob__connection-holder--header-bank-name {
                width: max-content;
                padding-left: rems(10);
                font-weight: 800;
                font-size: rems(18);
            }
        }

        .button__group {
            justify-content: flex-end;
            .button {
                margin-bottom: 0;
            }
        }
    }
    .ob__connection-holder--content {
        display: flex; 
        flex-direction: column;
        padding-bottom: rems(10);
        .ob__connection-holder--content-left {
            flex: 1 1 65%;
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                margin-top: rems(20);
                li {
                    display: flex;
                    flex-direction: column;
                    font-size: rems(14);
                    line-height: 1.5;
                    margin-bottom: rems(16);
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    a {
                        color: var(--color-grey-dark);
                        margin-left: rems(10);
                        &:first-of-type {
                            margin-left: 0;
                        }
                        &:focus {
                            outline: 0;
                        }
                    }
                    .account--name {
                        input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                            &.form__input--small{
                                padding: rems(2) rems(4);
                                margin-bottom: rems(2);
                            }
                        }
                    }
                }
            }
        }
        .ob__connection-holder--content-right {
            
            padding-top: rems(20);
        }
    }

    .ob__connection-holder--footer {
        //padding-top: rems(20);
        p {margin-bottom: 0;}
    }
}

#transactions-organisation-panel {
    .heading--settings {
        span {
            margin-left: rems(6);
        }
    }
    .heading--section.heading--preview-image .heading--settings {
        margin-left: auto;
    }
    .heading--section .heading--preview {
        &.hide-for-small {
            display: none;
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .ob__connection-holder {
    
        .ob__connection-holder--content {
            flex-direction: row;
            .ob__connection-holder--content-left {
                ul {
                    li {
                        margin-bottom: rems(4);
                        flex-direction: row;
                        .account--description {
                            width: rems(250);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-right: rems(20)
                        }
                        .account--name {
                            width: rems(160);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .account--link {
                            padding-left: rems(12);
                        }
                    }
                }
            }
            .ob__connection-holder--content-right {
                justify-content: flex-end;
                flex: 1 1 50%;
                text-align: right;
                padding-top: 0;
            }

        }

        .ob__connection-holder--footer {
            padding-top: rems(20);
         }
    
    }

    #transactions-organisation-panel {
        .heading--settings {
            span {
                margin-left: rems(12);
            }
        }
        .heading--section .heading--preview {
            &.hide-for-small {
                display: block;
            }
        }
        .heading--section.heading--preview-image .heading--settings {
            margin-left: rems(16);
        }
    }

}

