.coho__dark {
    .dropdown__menu-item {
        &:hover {
            background-color: var(--color-blue-dark);
        }
    }
    //searchable-dropdown

    .dropdown__menu{
        li{
            &.internal {
                background-color: var(--color-violet-xdark);
            }
    
        }
    
    }

    .form {
        &.form--inline {
            .ng-select {
                input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]), textarea {
                    background: transparent;
                    border: 0;
                    box-shadow: none;
                }
            }
        }
    }
    
    .ng-select {


        input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
            background: transparent;
            border: 0;
            box-shadow: none;
            &:focus {
                border: 0;
                box-shadow: none;            }
        }

        &.ng-select-opened {
            > .ng-select-container {
                background: var(--color-grey-border);
                color: var(--color-black);
                border-color: darken(#959595, 10) #959595 lighten(#959595, 5);

                .ng-arrow {
                    border-color: transparent transparent darken(#959595, 20);

                    &:hover {
                        border-color: transparent transparent darken(#959595, 60);
                    }
                }
            }
        }

        &.ng-select-focused {
            &:not(.ng-select-opened) > .ng-select-container {
                border-color: var(--color-grey);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
            }
        }

        &.ng-select-disabled {
            > .ng-select-container {
                background-color: var(--color-grey-dark);
                border-color: var(--color-grey-border);
                color: var(--color-grey);
            }
        }

        .ng-has-value .ng-placeholder {
            display: none;
        }

        .ng-select-container {
            color: var(--color-black);
            background-color: var(--color-grey-border);
            border: 1px solid var(--color-grey-light);

            &:hover {
                box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
            }
        }
        .ng-select-container .ng-value-container .ng-placeholder {
            color: var(--color-black);
        }

        &.ng-select-multiple {
            &.ng-select-disabled {
                > .ng-select-container .ng-value-container .ng-value {
                    background-color: var(--color-grey-light);
                    border: 1px solid lighten(#959595, 10);
                }
            }

            .ng-select-container {
                .ng-value-container {

                    .ng-value {
                        color: var(--color-grey-xlight);
                        background-color: var(--color-grey-dark);

                        &.ng-value-disabled {
                            background-color: var(--color-grey-light);
                        }

                        .ng-value-icon {

                            &:hover {
                                background-color: darken(#EDEDED, 5);
                            }

                            &.left {
                                border-right: 1px solid darken(#EDEDED, 10);

                                @include rtl {
                                    border-left: 1px solid darken(#EDEDED, 10);
                                }
                            }

                            &.right {
                                border-left: 1px solid darken(#EDEDED, 10);

                                @include rtl {
                                    border-right: 1px solid darken(#EDEDED, 10);
                                }
                            }
                        }
                    }

                    .ng-input {

                        > input {
                            color: var(--color-grey-xlight);
                        }
                    }
                }
            }
        }

        .ng-clear-wrapper {
            color: darken(#959595, 20);

            &:hover .ng-clear {
                color: #D0021B;
            }
        }
    }

    .ng-dropdown-panel {
        background-color: var(--color-grey-dark);
        border: 1px solid var(--color-grey-border);
        box-shadow: 0 1px 0 rgba(255,255,255, 0.06);

        &.ng-select-top {
            border-bottom-color: lighten(#959595, 10);
        }

        &.ng-select-right {
            border-bottom-color: lighten(#959595, 10);
        }

        &.ng-select-bottom {
            border-top-color: lighten(#959595, 10);
        }

        &.ng-select-left {
            border-bottom-color: lighten(#959595, 10);
        }

        .ng-dropdown-header {
            border-bottom: 1px solid var(--color-grey-border);
        }

        .ng-dropdown-footer {
            border-top: 1px solid var(--color-grey-border);
        }

        .ng-dropdown-panel-items {
            .ng-optgroup {
                color: var(--color-grey-light);

                &.ng-option-marked {
                    background-color: var(--color-grey);
                }

                &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                    color: var(--color-grey-light);
                    background-color: var(--color-grey-xlight);
                }
            }

            .ng-option {
                background-color: var(--color-grey-dark);
                color: var(--color-grey-xlight);

                &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                    color: var(--color-grey-dark);
                    background-color: var(--color-grey-xlight);
                }

                &.ng-option-marked {
                    background-color: var(--color-grey);
                    color: var(--color-grey-xlight);
                }

                &.ng-option-disabled {
                    color: var(--color-grey-light);
                }
            }
        }
    }
}
