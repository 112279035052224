.card__feedback {
    padding-top: rems(6);
    min-height: rems(94);
    position: relative;

    &:focus {
        outline: none;
    }

    .card__score {
        padding-top: 0;
        font-size: rems(24);
        line-height: 1;
        color: var(--color-black);
        text-align: center;
        padding-bottom: rems(10);
    }

    .card__month {
        color: var(--color-black);
        font-size: rems(12);
        text-align: center;
        line-height: 1.3;
        text-transform: uppercase;
        padding-top: rems(6);
    }

    &.sad {
        background-color: var(--color-pink-light);
    }

    &.unhappy {
        background-color: var(--color-warning-light);
    }

    &.happy {
        background-color: var(--color-orange-light);
    }

    &.good {
        background-color: var(--color-green-light);
    }

    &.great {
        background-color: var(--color-green-dark);

        .card__score,.card__month {
            color: var(--color-white);
        }
    }

    .feedback--icons {
        display: flex;
        justify-content: center;
        padding-top: rems(4);
        padding-bottom: rems(4);
        .feedback--icons-holder {
            background-color: transparent;
            border: 0;
            .icon, .icon svg {
                width: rems(50);
                height: rems(50);
            }
        }
    }

}
