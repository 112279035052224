.form__multi-switch{
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-light);
    border-radius: radius(large);
    display: flex;
    margin-bottom: rems(10);
    width: 100%;

    &.form__input--small {
        width: max-content;
        border-radius: radius(medium);
    }
}

.form__multi-switch-option{
    display: flex;
    flex: 1 0 33.33%;
    position: relative;

    &--four {
        flex: 1 0 25%;
    }

    &:hover{
        cursor: pointer;
    }
    
    .form__multi-switch-label{
        align-items: center;
        border: rems(1) solid transparent;
        border-radius: rems(6);
        color: var(--color-text);
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        font-size: rems(14);
        font-weight: 700;
        justify-content: center;
        line-height: rems(16);
        padding: rems(10) rems(10);
        position: relative;
        text-align: center;
        width: 100%;

        .form__multi-switch-label-title{
            font-weight: 700;
        }

        .form__multi-switch-label-subtext{
            color: var(--color-grey);
            font-size: rems(14);
            font-weight: 400;
        }

        &::before{
            background-color: var(--color-grey-light);
            content: "";
            display: block;
            height: rems(20);
            position: absolute;
            right: rems(-2);
            top: 50%;
            transform: translateY(-50%);
            width: rems(1);
        }

    }

    input[type=radio]{
        left: -3000px;
        opacity: 0;
        position: absolute;


        &:checked{
            + .form__multi-switch-label{
                background-color: var(--color-teal);
                border-color: var(--color-teal-dark);
                box-shadow: inset 0px 0px 0px 2px var(--color-teal-dark), 0px 2px 5px rgba(0,0,0,0.2);
                color: var(--color-white);
                font-weight: 700;
                position: relative;
                z-index: 200;

                &::before{
                    display: none;
                }

                .form__multi-switch-label-subtext{
                    color: var(--color-white);
                    font-weight: normal;
                }

            }
        }

        &:focus{
            + .form__multi-switch-label{
                box-shadow: 0px 0px 0px 4px rgba(var(--color-teal-rgb), 0.5);
            }
        }

        &:disabled{
            + .form__multi-switch-label{
                color: var(--color-teal-light);
                font-weight: 400;

                .form__multi-switch-label-subtext{
                    color: var(--color-grey-light);
                }
            }

            &:checked{
                + .form__multi-switch-label{
                    background-color: var(--color-white);
                    border-color: var(--color-teal-light);
                    box-shadow: inset 0px 0px 0px 2px var(--color-teal-light), 0px 2px 5px rgba(0,0,0,0.2);

                    color: var(--color-teal-light);
                    font-weight: 700;


                    .form__multi-switch-label-subtext{
                        color: var(--color-grey-light);
                    }
                }
            }

        }


        &:required{
            + .form__multi-switch-label{
                background-color: rgba(var(--color-pink-rgb), 0.2);
                border-color: var(--color-pink);
                box-shadow: $shadow__required;
                color: var(--color-magenta-dark);
            }
        }


    }

    // READ ONLY

    &.read-only{
        input[type=radio]{
            + .form__multi-switch-label{
                background-color: var(--color-white);
                border-color: var(--color-grey-xlight);
                box-shadow: none;
            }
            
            &:checked{
                + .form__multi-switch-label{
                    border-color: var(--color-grey);
                    box-shadow: 0 0 0 rems(2) var(--color-grey);
                }
            }

        }
    }

    &:first-child{

        input[type=radio]{
            &:disabled{
                + .form__multi-switch-label{
                    border-bottom-left-radius: radius(large);
                    border-top-left-radius: radius(large);
                }
                &:checked{
                    + .form__multi-switch-label{
                        border-radius: radius(large);
                    }
                }
            }
        }
    }


    &:last-child{

        input[type=radio]{
            &:disabled{
                + .form__multi-switch-label{
                    border-bottom-right-radius: radius(large);
                    border-top-right-radius: radius(large);
                }


            &:checked{
                + .form__multi-switch-label{
                    border-radius: radius(large);
                }
            }
            }

        }

        .form__multi-switch-label{ 
            &::before{
                display: none;
            }
        }
    }   

}

.form__input--small {
    .form__multi-switch-option {
        .form__multi-switch-label{
            min-width: rems(70);
            border-radius: rems(4);
            font-size: rems(12);
            font-weight: 600;
    
            line-height: rems(14);
            padding: rems(2) rems(4);

            &::before {
                height: rems(14);
            }
    
        }
    }
}

#pricing {
    .form__multi-switch-option{
        input[type=radio]{
            &:checked{
                + .form__multi-switch-label{
                    background-color: var(--color-green-dark);
                    border-color: var(--color-green-xdark);
                    box-shadow: inset 0px 0px 0px 2px var(--color-green-xdark), 0px 2px 5px rgba(0,0,0,0.2);
                }
            }

            &:focus{
                + .form__multi-switch-label{
                    box-shadow: inset 0px 0px 0px 2px var(--color-green-xdark), 0px 2px 5px rgba(0,0,0,0.2);
                }
            }

        }
    
    }
}


@include breakpoint(for-tablet-portrait-up){
    .form__multi-switch-option{
        .form__multi-switch-label{
            font-size: rems(16);
            line-height: rems(20);
        }
    }
    .form__input--small {
        .form__multi-switch-option {
            .form__multi-switch-label{
                min-width: rems(88);
                font-size: rems(14);
                line-height: rems(16);
                padding: rems(4) rems(8);
            }
        }
    }
}