.profile__preview--card, .profile__preview--room {
    .profile__status {
        padding: rems(5);
        position: absolute;
        top: rems(-40);
        left: rems(58);
        height: rems(38);
        border-radius: rems(20);
        border: rems(3) solid var(--color-white);
        box-shadow: 0 rems(1) rems(4) rgba(0,0,0,0.2);

        .icon, svg {
            width: rems(22);
            height: rems(22);
        }


        &.profile__status--unplugged {
            background-color: var(--color-grey-light);
        }

        &.profile__status--invited {
            background-color: var(--color-orange-dark);
        }

        &.profile__status--joined {
            background-color: var(--color-blue);
        }

        &.profile__status--anonymous {
            background-color: var(--color-cyan-light);
        }

        &.profile__status--additional {
            left: rems(90);
            background-color: var(--color-grey-light);
            width: rems(38);
            line-height: 1.4;
            font-weight: 800;
            text-align: center;
        }
    }
}

.profile__preview--room {
    .profile__status {
        top: rems(54);
    }
    .profile__preview-text {
        margin-left: rems(32);
    }
}

.profile__status--wrapper {
    display: flex;
    align-items: center;

    .profile__status {
        padding: rems(5);
        height: rems(28);
        border-radius: rems(16);
        //border: rems(3) solid var(--color-white);
        //box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.2);

        .icon, svg {
            width: rems(18);
            height: rems(18);
        }

        &.profile__status--joined {
            background-color: var(--color-blue);
        }
    }

    .profile__status--content {
        padding-left: rems(8);
    }
}

