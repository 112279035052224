.form__tag {
    &.major {
        padding: rems(8) rems(16);
        min-width: max-content;

        h2 {
            font-size: rems(16);
            line-height: rems(20);

            a {
                text-decoration: none;
                color: var(--color-black);

                &:focus {
                    outline: none;
                }
            }
        }

        cursor: pointer;

        &.active {
            color: white;
            background-color: var(--color-primary);
            border-color: var(--color-primary);

            &:hover {
                background-color: var(--color-primary-dark);
            }
        }
    }

    &.minor {
        padding: rems(8) rems(16);
        min-width: max-content;

        h3 {
            font-size: rems(16);
            line-height: rems(20);
        }

        cursor: pointer;
        border-color: var(--color-brown-dark);

        &:hover {
            background-color: var(--color-grey-xlight);
            color: var(--color-black);
        }
    }
}

.marketing__block--browse-header {
    h3 {
        a {
            color: var(--color-primary);
            text-decoration: none;

            &:focus {
                outline: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
