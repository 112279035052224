.card__actionsummary {
    padding: rems(4) rems(4) rems(6) rems(8);
    display: flex;
    flex-direction: row;
    align-items: center;
    &:focus {
        outline: none;
    }
    &--actions {
        background-color: var(--color-magenta-xdark);
        &:hover {
            background-color: var(--color-magenta);
        }
    }
    &--clear {
        background-color: var(--color-green-xxdark);
        &:hover {
            cursor: default;
            box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
        }
    }
    .card__icon {
        padding-right: rems(8);
        padding-top: rems(8);
        .icon {
            height: rems(24);
            width: rems(24); 
        }
    }
    .card__title-wrapper{
        .card__title {
            line-height: 1.5;
            color: var(--color-white);
            font-size: rems(16);
            &:hover {
                color: white;
            }
            &-larger {
                line-height: 1;
                font-size: rems(18);
                font-weight: 700;
                span {
                    font-weight: 400;
                }
            }
        }
        .card__sub-title {
            color: var(--color-white);
            font-size: rems(10);
            font-weight: 400;
            line-height: 1;
            padding-bottom: rems(4);
        }
    }
    &.active {
        position: relative;
        &::after{
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23e9e9e9' d='M17.000,23.000 L17.000,21.000 L27.000,21.000 L27.000,23.000 L17.000,23.000 zM17.000,16.000 L27.000,16.000 L27.000,18.000 L17.000,18.000 L17.000,16.000 zM17.000,11.000 L27.000,11.000 L27.000,13.000 L17.000,13.000 L17.000,11.000 zM17.000,6.000 L27.000,6.000 L27.000,8.000 L17.000,8.000 L17.000,6.000 zM3.000,21.000 L13.000,21.000 L13.000,23.000 L3.000,23.000 L3.000,21.000 zM3.000,16.000 L13.000,16.000 L13.000,18.000 L3.000,18.000 L3.000,16.000 zM3.000,11.000 L13.000,11.000 L13.000,13.000 L3.000,13.000 L3.000,11.000 zM3.000,6.000 L13.000,6.000 L13.000,8.000 L3.000,8.000 L3.000,6.000 z'/></svg>");  
            content: "";
            display: block;
            position: absolute;
            height: rems(20);
            right: rems(4);
            top: rems(4);
            transition: transform 0.2s ease-out;
            width: rems(20);
        }  
    }
}

action-card {
    action-dispatcher {
        .form {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
