.step__message {
    background-color: var(--color-grey-xxlight);
    border-radius: radius(medium);
    display: flex;
    flex-direction: row;
    font-size: rems(14);
    line-height: rems(20);
    margin-bottom: rems(20);
    overflow: hidden;
    width: 100%;
    border: 1px solid var(--color-grey-light);
    align-items: center;

}

step-message {
    .step__message {
        margin-bottom: rems(8);
    }
}

step-message + step-message {
    display: block;
    margin-top: rems(10);
}

step-message + .button__group > .button__group-item,
step-message + .form.form--inline.form--nomargin,
step-message + div[id*=tenancy-agreement],
step-message + user-identity-verification,
step-message + form#uiv {
    margin-top: rems(20);
}

.step__message-icon {
    // background-color: var(--color-grey-light);
    padding: rems(10);

    .icon {
        display: block;
        fill: #ffffff;
        height: rems(30);
        width: rems(30);

        svg {
            height: rems(30);
        }
    }
}

.step__message-text {
    font-size: rems(14);
    padding: rems(10) rems(10) rems(10) 0;
    line-height: 1.4;
    width: 100%;

    .step__message-title {
        font-size: rems(10);
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: rems(4);
        font-weight: 600;
    }
}

.step__message--action {
    //background-color: var(--color-pink-light);
    background-color: var(--color-white);
    border: 1px solid rgba(var(--color-magenta-xdark-rgb),0.5);
    box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-magenta-xdark-rgb),0.30);

    &.step__message--subtle {
        box-shadow: none;
    }

    .step__message-icon {
        .icon {
            fill: var(--color-pink-dark);
        }
    }

    .step__message-text {
        .step__message-title {
            //color: var(--color-magenta-dark);
        }
    }
}

.step__message--waiting {
    //background-color: var(--color-orange-light);
    background-color: var(--color-white);
    border: 1px solid rgba(var(--color-orange-xdark-rgb),0.5);
    box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-orange-xdark-rgb),0.30);

    &.step__message--subtle {
        box-shadow: none;
    }

    .step__message-icon {
        .icon {
            fill: var(--color-orange-xdark);
        }
    }

    .step__message-text {
        .step__message-title {
            //color: var(--color-orange-xdark);
        }
    }

}

.step__message--success {
    background-color: var(--color-white);
    border: 1px solid rgba(var(--color-green-xdark-rgb),0.5);
    box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-green-xdark-rgb),0.30);


    &.step__message--subtle {
        box-shadow: none;
    }
    
    .step__message-icon {
        .icon {
            fill: var(--color-green-xdark);
        }
    }

    .step__message-text {
        .step__message-title {
            //color: var(--color-green-xdark);
        }
    }

}

.step__message--reject {
    background-color: var(--color-white);
    border: 1px solid rgba(var(--color-pink-xdark-rgb),0.5);
    box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-pink-xdark-rgb),0.30);

    .step__message-icon {
        .icon {
            fill: var(--color-pink-xdark);
        }
    }

    .step__message-text {
        .step__message-title {
            //color: var(--color-pink-xdark);
        }
    }
}

.step__message--info {
  .step__message-icon {
    .icon {
      fill: var(--color-black);
    }
  }
}

file-uploader + step-message > .step__message,
.accordion + step-message > .step__message {
    margin-top: rems(20);
}
