.conversation__grid-header-context-status,
.conversation__grid-messages-footer-actions,
.context__list-actions {
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: rems(10);
        margin: 0;
        margin-top: rems(5);
        line-height: rems(14);
        list-style: none;
        padding: 0;
        width: 100%;
        width: 100%;
        justify-content: center;

        li {
            background-color: var(--color-grey);
            border: 1px solid var(--color-grey-dark);
            border-radius: rems(4);
            color: var(--color-white);
            display: flex;
            flex: 0 0 1rem;
            margin-bottom: rems(5);
            margin-right: rems(5);
            padding: rems(2) rems(5);
            min-width: max-content;
            font-weight: 700;

            &.pulse {
                animation: pulse-grey 2s infinite;
            }

            &.optional, &.optional.inactive:hover {
                border-color: var(--color-grey-light);
                background-color: var(--color-white);
                color: var(--color-black);
            }

            &.completed, &.completed.inactive:hover, &.available, &.available.inactive:hover , &.rejected, &.rejected.inactive:hover {
                border-color: var(--color-cyan-xdark);
                background-color: var(--color-cyan-dark);
                color: var(--color-white);

                &.pulse {
                    animation: pulse-cyan 2s infinite;
                }
            }

            &.low, &.low.inactive:hover, &.arranged, &.arranged.inactive:hover, &.confirmed, &.preconfirmed, &.active {
                border-color: var(--color-green-xdark);
                background-color: var(--color-green-dark);
                color: var(--color-white);
                justify-content: center;

                &.pulse {
                    animation: pulse-green 2s infinite;
                }
            }

            &.waiting {
                border-color: var(--color-green);
                background-color: var(--color-green-light);
                color: var(--color-black-light);

                &.pulse {
                    animation: pulse-green 2s infinite;
                }
            }

            &.medium, &.medium.inactive:hover, &.open, &.open.inactive:hover, &.progress, &.progress.inactive:hover, &.preconfirmed.not-confirmed {
                border-color: var(--color-orange-xdark);
                background-color: var(--color-orange-dark);
                color: var(--color-white);
                justify-content: center;

                &.pulse {
                    animation: pulse-orange 2s infinite;
                }
            }

            &.high, &.high.inactive:hover, &.required, &.required.inactive:hover, &.new, &.new.inactive:hover, &.unconfirmed.warning, &.error {
                border-color: var(--color-pink-xdark);
                background-color: var(--color-pink-dark);
                color: var(--color-white);
                justify-content: center;

                &.pulse {
                    animation: pulse-pink 2s infinite;
                }
            }

            &.inactive {
                background-color: var(--color-grey-xxlight);
                border-color: var(--color-grey-light);
                color: var(--color-grey-border);

                &:hover {
                    cursor: pointer;
                    background-color: var(--color-grey);
                    border-color: var(--color-grey-dark);
                    color: var(--color-white);
                }
            }

            &.readonly {
                &:hover {
                    cursor: default;
                }

                &.inactive:hover {
                    background-color: var(--color-grey-xxlight);
                    border-color: var(--color-grey-light);
                    color: var(--color-grey-border);
                }
            }
        }
    }
}
action-card {
    .context__list-actions {
        ul {
            li {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
.page__section-content--past {
    .compact__table tr.summary td .context__list-actions.context__list-actions-single {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                color: var(--color-black);
                padding: 0;
                margin: 0;
                background-color: transparent;
                border: 0;
                font-weight: 600;
                font-size: rems(14);
            }
        }
    }
}

@keyframes pulse-grey {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(var(--color-grey-dark-rgb), 0.7);
    }

    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(var(--color-grey-dark-rgb), 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(var(--color-grey-dark-rgb), 0);
    }
}

.conversation__grid-messages-footer-actions {
    ul {
        justify-content: left;
        font-size: rems(12);

        li {
            flex: none;
            padding: rems(4) rems(8);
        }
    }
}

.context__list-actions {
    ul {
        font-size: rems(12);

        li {
            padding: rems(4) rems(8);
        }
    }
}

span.status--high{
    background-color: var(--color-pink-dark);
    color: var(--color-white);
    border: 1px solid var(--color-pink-xdark);
    border-radius: 0.25rem;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 5px;
    line-height: 14px;
}

span.status--medium{
    background-color: var(--color-orange-dark);
    color: var(--color-white);
    border: 1px solid var(--color-orange-xdark);
    border-radius: 0.25rem;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 5px;
    line-height: 14px;
}

span.status--low{
    background-color: var(--color-green-dark);
    color: var(--color-white);
    border: 1px solid var(--color-green-xdark);
    border-radius: 0.25rem;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 5px;
    line-height: 14px;
}

@include breakpoint(for-small-desktop-up) {
    .conversation__grid-header-context-status,
    .conversation__grid-messages-footer-actions,
    .context__list-actions {
        ul {
            font-size: rems(14);

            li {
                padding: rems(4) rems(10);
            }
        }
    }

    .conversation__grid-messages-footer-actions {
        ul {
            li {
                border-width: 1px;
            }
        }
    }
}
