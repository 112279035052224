#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    /*.cover {
        background: #23324d;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;
        opacity: 0.4;
    }

    .app-loader {
        width: 256px;
        height: 256px;
        position: fixed;
        left: calc(50% - 128px);
        top: calc(50% - 128px);
        z-index: 10002;
    }*/
}

.body-loading {
    background-color: #293B5B;
}
