.form__switch{
    align-items: center;
    display: flex;

    &.disabled, &.read-only {
        cursor: auto;
        pointer-events: none;
    }
}


.form__switch-label{
    color: var(--color-text);
    display: flex;
    flex: 0 1 auto;
    font-weight: 700;
    line-height: rems(24);
    margin-left: rems(10);
    height: 100%;
}

#pricing-toggle {
    &.form__switch {
        .form__switch-label{
            line-height: 1;
            font-size: rems(16);
            align-items: center;
            span {
                &.hidden {
                    display: none;
                }
            }
        }
        .form__switch-box{
            &:checked{

                + .form__switch-paddle{
                    background-color: var(--color-green-dark);
                    border-color: var(--color-green-xdark);

                    &::after{
                        background-color: var(--color-white);
                        border-color: var(--color-green-xdark);
                        left: rems(30);
                    }
                }
            }
            &:focus{
                + .form__switch-paddle{
                    box-shadow: 0 0 0 rems(3) rgba(var(--color-green-rgb),0.5);
                }
            }
        }

    }
}

.form__switch-box{
    position: absolute;
    margin-bottom: 0;
    opacity: 0;
}

.form__switch-input{
    border-radius: radius(medium);
    cursor: pointer;
    display: flex;
    height: rems(36);
    position: relative;
    width: rems(64);
}

.form__switch-paddle{
    background: var(--color-white);
    border: rems(1) solid var(--color-grey-border);
    border-radius: rems(18);
    box-shadow: $shadow__inset;
    cursor: pointer;
    display: block;
    height: rems(36);
    position: relative;
    transition: all 0.3s ease-in-out;
    width: rems(64);

    &::after{
        background: var(--color-white);
        border: 1px solid var(--color-grey-border);
        border-radius: rems(15);
        box-shadow: $shadow__up;
        content: '';
        display: block;
        height: rems(30);
        left: rems(2);
        position: absolute;
        top: rems(2);
        width: rems(30);
        transition: all 0.2s ease-out;
    }
}

.form__switch--labelled {
    .form__switch-input {
        width: rems(72);
    }

    .form__switch-box {
        + .form__switch-paddle {
            width: rems(72);

            &::before {
                font-size: rems(13);
                line-height: rems(33);
                font-weight: 700;
            }
        }
    }

    .form__switch-box {
        &:checked {
            + .form__switch-paddle {
                &::after {
                    left: rems(38);
                }

                &::before {
                    color: var(--color-white);
                    content: "ON";
                    padding-left: rems(11);
                }
            }
        }

        &:not(:checked) {
            + .form__switch-paddle {
                background: var(--color-grey-xxlight);

                &::before {
                    content: "OFF";
                    padding-left: rems(37);
                    padding-right: rems(8);
                }
            }
        }
    }

    &.form__switch--right {
        .form__switch-box {
            &:checked {
                + .form__switch-paddle {


                    &::before {
                        padding-right: rems(44);
                    }
                }
            }

            &:not(:checked) {
                + .form__switch-paddle {


                    &::before {
                    }
                }
            }
        }
    }
}

.form__switch-box{
    &:checked{

        + .form__switch-paddle{
            background-color: var(--color-form);
            border-color: var(--color-form-dark);

            &::after{
                background-color: var(--color-white);
                border-color: var(--color-form-dark);
                left: rems(30);
            }
        }
    }

    &:focus{
        + .form__switch-paddle{
            box-shadow: $shadow__outline;
        }
    }
}

.form__switch{
    &.disabled{
        .form__switch-paddle{
            border-color: var(--color-grey-light);
            background-color: var(--color-grey-xlight);
        }
        .form__switch-label {
            color: var(--color-grey);
        }
        .form__switch-box{

            + .form__switch-paddle{
                &::after{
                    border-color: var(--color-grey-light);
                }
                &::before {
                    color: var(--color-grey-light);
                }
            }

            &:checked{
                + .form__switch-paddle{
                    background-color: var(--color-form-light);

                    &::after{
                        border-color: var(--color-grey-light);
                    }
                }
            }
        }
    }
}

// OPTIONS

.form__switch{
    &.form__switch--right{
        flex-direction: row-reverse;
        justify-content: space-between;

        .form__switch-label{
            margin-left: rems(0);
            margin-right: rems(10);
        }
    }

    &.form__switch--regular{
        .form__switch-label{
            font-weight: normal;
        }
    }
}

// SMALL

.form__switch {
    &.form__switch--small {
        margin-bottom: rems(4);

        .form__switch-paddle {
            border-radius: rems(18);
            height: rems(22);
            width: rems(32);

            &::after {
                border-radius: rems(8);
                height: rems(16);
                left: rems(2);
                top: rems(2);
                width: rems(16);
            }
        }

        .form__switch-box {
            &:checked {
                + .form__switch-paddle {
                    &::after {
                        left: rems(12);
                    }
                }
            }
        }

        .form__switch-input {
            height: rems(24);
            width: rems(32);
        }

        .form__switch-label {
            font-size: rems(14);
            font-weight: 600;
        }

        &.form__switch--labelled {
            .form__switch-input {
                width: rems(48);
            }

            .form__switch-paddle {
                width: rems(48);
            }

            .form__switch-box {
                + .form__switch-paddle {
                    &::before {
                        line-height: rems(11);
                        font-size: rems(11);
                        position: absolute;
                        top: 5px;
                    }
                }

                &:checked {
                    + .form__switch-paddle {
                        &::after {
                            left: rems(27);
                        }

                        &::before {
                            padding-left: rems(6);
                        }
                    }
                }

                &:not(:checked) {
                    + .form__switch-paddle {

                        &::before {
                            padding-left: rems(21);
                            padding-right: rems(8);
                        }
                    }
                }
            }
        }
    }
}

.switch__container {
    display: flex;
    flex-direction: column;
    padding-top: rems(10);
}
@include breakpoint(for-tablet-portrait-up) {
    .switch__container {
        padding-top: 0;
        flex-direction: row;
        .form__switch {
            padding-right: rems(16);
        }
    }
}


actions {
    .form {
        .form__grid-element {
            switch {
                .form__switch.form__switch--small.form__switch--labelled {
                    padding-top: rems(2);
                    margin-bottom: 0;
                }
            }
        }
    }
}
