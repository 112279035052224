maintenance-issues, maintenance-issues-tenant, maintenance-issues-owner, maintenance-issue-page {
    .compact__table {
        &.compact__table--narrow {
            tr {

                &.summary {
                    &.open {
                        border: 0;
                        padding: 0;
                        td {
                            display: none;

                            &.date, &.property {
                                display: none;
                            }
                        }
                    }
                }
                &.details {
                    background-color: var(--color-white);
                    td {background-color: var(--color-white);}

                     .title_and_hierarchy {  //JON TODO
                        form {
                            display: flex;
                            margin-top: rems(10);

                            textbox {
                                width: rems(200);
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {

    maintenance-issues, maintenance-issues-tenant,maintenance-issues-owner {
        .compact__table {
            &.compact__table--narrow {
                tr {

                    &.summary {
                        &.open {
                            padding: rems(4) 0;
                            border-top: rems(1) solid rgba(var(--color-black-rgb),0.1);
                            td {
                                display: table-cell;
                                padding: 0 rems(8);

                                &.date, &.property {
                                    display: table-cell;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {

    maintenance-issues, maintenance-issues-tenant,maintenance-issues-owner {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    &.summary {
                        &.open {
                            td {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
