.coho__dark {

    .step__message--action {
        //background-color: var(--color-pink-light);
        background-color: var(--color-pink-xdark);
        border: 1px solid rgba(var(--color-pink-light-rgb),0.5);
        box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-pink-rgb),0.30);

        .step__message-icon {
            .icon {
                fill: var(--color-white);
            }
        }

        .step__message-text {
            color: var(--color-white);

            .step__message-title {
                color: var(--color-white);
            }
        }
    }

    .step__message--waiting {
        //background-color: var(--color-orange-light);
        background-color: var(--color-orange-xdark);
        border: 1px solid rgba(var(--color-orange-light-rgb),0.5);
        box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-orange-rgb),0.30);

        .step__message-icon {
            .icon {
                fill: var(--color-white);
            }
        }

        .step__message-text {
            color: var(--color-white);

            .step__message-title {
                color: var(--color-white);
            }
        }
    }

    .step__message--success {
        background-color: var(--color-green-xdark);
        border: 1px solid rgba(var(--color-green-light-rgb),0.5);
        box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-green-rgb),0.30);

        .step__message-icon {
            .icon {
                fill: var(--color-white);
            }
        }

        .step__message-text {
            color: var(--color-white);

            .step__message-title {
                color: var(--color-white);
            }
        }
    }

    .step__message--reject {
        background-color: var(--color-pink-xdark);
        border: 1px solid rgba(var(--color-pink-light-rgb),0.5);
        box-shadow: 0 rems(2) rems(6) 0 rgba(var(--color-pink-rgb),0.30);

        .step__message-icon {
            .icon {
                fill: var(--color-white);
            }
        }

        .step__message-text {
            color: var(--color-white);

            .step__message-title {
                color: var(--color-white);
            }
        }
    }
}
