.badge {
    align-items: center;
    background-color: var(--color-pink);
    border-radius: 100%;
    color: white;
    display: flex;
    font-size: rems(14);
    font-weight: 700;
    justify-content: center;
    height: rems(25);
    padding: rems(4);
    text-align: center;
    width: rems(25);

    &.badge-over-99 {
        //justify-content:space-around;
        //font-size: rems(13);
        span {
            font-size: rems(11);
        }
    }

    &.badge-zero {
        background-color: var(--color-green-dark);
    }
  
    &.no_count {
      width: rems(12);
      height: rems(12);
    }
}



.status-badge{
    background-color: white;
    border-radius: radius(medium);
    border: 2px solid var(--color-grey);
    color: var(--color-grey);
    display: inline-block;
    font-size: rems(14);
    font-weight: 700;
    line-height: rems(17);
    padding: rems(2) rems(4) rems(2) rems(4);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;

    &.status-badge--on{
        border-color: var(--color-pink-dark);
        color: var(--color-pink-dark);
    }

    &.status-badge--automatic{
        border-color: var(--color-green-xdark);
        color: var(--color-green-xdark);
    }

}


.info-badge {
    background-color: var(--color-grey-light);
    border-radius: radius(medium);
    border: 1px solid var(--color-grey-border);
    color: var(--color-black);
    display: inline-block;
    font-size: rems(10);
    padding: rems(1) rems(6);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: normal;
    height:fit-content;
    margin-left: rems(8);

    &:first-of-type,&.no-margin {
        margin-left: 0;
    }

    &.info-badge--dark-orange {
        border-color: var(--color-orange-xxdark);
        background-color: var(--color-orange-xdark);
        color: white;
    }
    &.info-badge--orange {
        border-color: var(--color-orange-xdark);
        background-color: var(--color-orange-dark);
        color: white;
    }
    &.info-badge--light-orange {
        border-color: var(--color-orange-dark);
        background-color: var(--color-orange);
        color: white;
    }
    &.info-badge--transparent {
        border-color: var(--color-grey-light);
        background-color: transparent;
        color: var(--color-grey-dark);
    }
    &.info-badge--dark-green {
        border-color: var(--color-green-xxdark);
        background-color: var(--color-green-xdark);
        color: white;
    }
    &.info-badge--green {
        border-color: var(--color-green-xdark);
        background-color: var(--color-green-dark);
        color: white;
    }
    &.info-badge--light-green {
        border-color: var(--color-green-xdark);
        background-color: var(--color-green-dark);
        color: var(--color-grey-xxlight);
    }
    &.info-badge--teal {
        border-color: var(--color-teal-xdark);
        background-color: var(--color-teal-dark);
        color: white;
    }
    &.info-badge--pink {
        border-color: var(--color-pink-xdark);
        background-color: var(--color-pink-dark);
        color: white;
    }
    &.info-badge--magenta {
        border-color: var(--color-magenta-xdark);
        background-color: var(--color-magenta-dark);
        color: white;
    }
    &.info-badge--cyan {
        border-color: var(--color-cyan-xdark);
        background-color: var(--color-cyan-dark);
        color: white;
    }
    &.info-badge--grey {
        border-color: var(--color-grey-border);
        background-color: var(--color-grey-light);
        color: (black);
    }
    &.info-badge--red {
        border-color: var(--color-red-xdark);
        background-color: var(--color-red-dark);
        color: (white);
    }
    &.info-badge--light-red {
        border-color: var(--color-red-dark);
        background-color: var(--color-red);
        color: (white);
    }

    &.inactive {
        background-color: var(--color-white);
        border-color: var(--color-grey-light);
        color: var(--color-grey);

        &:hover {
            cursor: pointer;
            background-color: var(--color-grey-xlight);
            //&.info-badge--orange {
            //    border-color: var(--color-orange-xdark);
            //    background-color: var(--color-orange-dark);
            //    color: white;
            //}
            //&.info-badge--green {
            //    border-color: var(--color-green-xdark);
            //    background-color: var(--color-green-dark);
            //    color: white;
            //}
            //&.info-badge--teal {
            //    border-color: var(--color-teal-xdark);
            //    background-color: var(--color-teal-dark);
            //    color: white;
            //}
            //&.info-badge--pink {
            //    border-color: var(--color-pink-xdark);
            //    background-color: var(--color-pink-dark);
            //    color: white;
            //}
            //&.info-badge--magenta {
            //    border-color: var(--color-magenta-xdark);
            //    background-color: var(--color-magenta-dark);
            //    color: white;
            //}
            //&.info-badge--cyan {
            //    border-color: var(--color-cyan-xdark);
            //    background-color: var(--color-cyan-dark);
            //    color: white;
            //}
        }
    }

    &.inactive-nohover {
        background-color: var(--color-white);
        border-color: var(--color-grey-light);
        color: var(--color-grey);
    }

    &--minwidth-40 {
        min-width: rems(42);
    }

    &--medium {

        padding: 0 rems(4);
        font-size: rems(13);
    }
    &--large {
        padding: rems(2) rems(6);
        font-size: rems(14);
        p {
          font-size: rems(14);
        }
    }
    &--larger {

        padding: rems(4) rems(10);
        font-size: rems(16);
    }
}


