.landing__page{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    
    .marketing__block {
        padding-top: 0;
        padding-bottom: 0;
        &.marketing__block--level2 {
            //padding-top: rems(60);
        }
    }
    
    .marketing__price-range {
    }

    .page__section--alt {
        background-color: var(--color-teal-xlight);
    }
    
}

.landing__email-form {
  background-color: #fafafa;
  border: 0.0625rem solid #e9e9e9;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.75rem -0.25rem rgba(0, 0, 0, 0.3);
  padding: 1.25rem;

  form {
    .col {
      margin-bottom: 0;
    }
  }
}

.demo__page {
    &.site__content {
        background-color: var(--color-teal-light);

        .landing__header, .page__section {
            background: var(--color-white);
        }

        .page__section--highlight {
            background-color: var(--color-teal-light);
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .landing__page {

        .marketing__price-range {
            margin-right: rems(32);
        }


        .marketing__block-image {
            width: 50%;
            padding-left: 2vw;
        }

        .marketing__block-content {
            width: 50%;
            padding-right: 2vw;
        }

        .marketing__block--reverse {
            .marketing__block-content {
                padding-left: 2vw;
            }
            .marketing__block-image {
                padding-right: 2vw;
            }
        }

        .marketing__block--equal {
            .marketing__block-image {
                width: 60%
            }

            .marketing__block-content {
                width: 40%;
            }
        }

        .marketing__block--level2 {
            .marketing__block-image {
                width: 50%
            }

            .marketing__block-content {
                width: 50%;
            }
        }

        .marketing__block--narrow {
            .marketing__block-image {
                width: 40%
            }

            .marketing__block-content {
                width: 60%;
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .landing__page {
        .marketing__block-image {
            width: 40%;
            padding-left: 0;
        }

        .marketing__block-content {
            width: 60%;
            padding-right: 0;
        }

        .marketing__block--reverse {
            .marketing__block-content {
                padding-left: 0;
            }

            .marketing__block-image {
                padding-right: 0;
            }
        }

        .marketing__block--equal {
            .marketing__block-image {
                width: 60%
            }

            .marketing__block-content {
                width: 40%;
            }
        }

        .marketing__block--level2 {
            .marketing__block-image {
                width: 45%
            }

            .marketing__block-content {
                width: 55%;
            }
        }

        .marketing__block--narrow {
            .marketing__block-image {
                width: 35%
            }

            .marketing__block-content {
                width: 65%;
            }
        }
    }
}
@include breakpoint(for-small-desktop-up) {
    .landing__page {
        .marketing__block--narrow {
            .marketing__block-image {
                width: 30%
            }

            .marketing__block-content {
                width: 70%;
            }
        }
    }
}


