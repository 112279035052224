.accordion{
    &.page__accordion{
        background-color: transparent;
        border-bottom: 1px solid var(--color-grey-light);
        box-shadow: none;
        margin-bottom: 0;
    
        .accordion__title{
            border-bottom: none;
            
            .accordion__title-button{
                background-color: transparent;
                border-radius: rems(0);
                color: var(--color-text);
                font-size: rems(16);
                font-weight: 800;
                padding-left: rems(40);
                padding-right: rems(0);
                text-decoration: none;

                &:hover{
                    color: var(--color-teal-dark);
                    text-decoration: underline;
                }
            
                &::after{
                    left: 0;
                    top: rems(20);
                }
    
            }
        }

        .accordion__content{
            background-color: transparent;
            padding: 0 0 rems(20) 0;
        }

        h1, h2, h3, h4, h5, h6{
            + .accordion__content{
                margin-top: 0;
            }
        }

    
    }
    
    + .accordion.accordion--flat{
        margin-top: 0;
    }
}

@include breakpoint(for-tablet-portrait-up){
    .accordion{
        &.page__accordion{
        
            .accordion__title{
                
                .accordion__title-button{
                    font-size: rems(20);
                
                    &::after{
                        top: rems(22.5);
                    }
        
                }
            }
        }
    }
}