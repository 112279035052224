.u-padding-bottom--none{
    padding-bottom: 0;
}

.u-padding-top--none{
    padding-top: 0;
}

.u-padding-bottom--10{
    padding-bottom: rems(10);
}

.u-padding-top--10{
    padding-top: rems(10);
    &forced {
        padding-top: rems(10) !important;
    }
}
.u-padding-top--5{
    padding-top: rems(5);
}

.u-padding-bottom--20{
    padding-bottom: rems(20);
}

.u-padding-bottom--30{
    padding-bottom: rems(30);
}

.u-padding-top--20{
    padding-top: rems(20);
}

.u-padding-top--30{
    padding-top: rems(30);
}

.u-padding-top--40{
    padding-top: rems(40);
}

.u-padding--wide {
    padding-left: rems(30);
    padding-right: rems(30);
}

.u-padding--10 {
    padding: 10px;
}

// Padding main is for the first element after the header or sub nav

.u-padding--main{
    margin-top: rems(30);
}
.u-padding--inline{
    margin-top: rems(20);
}

.u-padding--subnav{
    margin-top: rems(30);
}

.u-padding-left--20 {
    padding-left: rems(20);
}
.u-padding-right--20 {
    padding-right: rems(20);
}

@include breakpoint( for-tablet-portrait-up ){
    .u-padding--main{
        margin-top: rems(60);
    }
}
