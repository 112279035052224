.card {
    &.card__static-mega {
        &.card--image-left {
            .card__image-content {
                //position: relative;

                .card__image {
                    padding-top: rems(8);
                    max-width: rems(60);
                    margin-bottom: 0;
                    padding-left: rems(8);

                    img {
                        height: auto;
                        width: 100%;
                    }
                }

                .card__content {
                    padding: rems(8);
                    padding-left: rems(16);

                    h5 {
                        font-size: rems(16);
                    }

                    h5 + p {
                        margin-top: rems(4);
                    }

                    p {
                        font-size: rems(14);
                        line-height: 1.3;
                    }
                }
            }
        }

        &:hover {
            box-shadow: 0 rems(2) rems(8) 0 rgba(var(--color-pink-rgb),0.3);
            outline: 1px solid rgba(var(--color-pink-rgb),0.3);
        }
    }
}
