.button__back{
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: rems(16);
    line-height: rems(24);
    padding: rems(0) rems(10) rems(0) rems(0);
    position: relative;
    text-decoration: none;

    .icon{
        fill: var(--color-blue);
        height: rems(24);
        margin-right: rems(5);
        width: rems(24);

        svg{
            height: rems(24);
            width: rems(24);
        }

    }

    &:hover{
        text-decoration: underline;
    }
}

.button__forward{
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: rems(16);
  line-height: rems(24);
  padding: rems(0) rems(0) rems(0) rems(10);
  position: relative;
  text-decoration: none;

  .icon{
    fill: var(--color-blue);
    height: rems(24);
    margin-right: rems(5);
    width: rems(24);

    svg{
      height: rems(24);
      width: rems(24);
    }

  }

  &:hover{
    text-decoration: underline;
  }
}


// <svg width="12px" height="20px" viewBox="0 0 12 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
//     <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <path d="M12.5857864,4.58578644 C13.366835,3.80473785 14.633165,3.80473785 15.4142136,4.58578644 C16.1952621,5.36683502 16.1952621,6.63316498 15.4142136,7.41421356 L7.41421356,15.4142136 C6.63316498,16.1952621 5.36683502,16.1952621 4.58578644,15.4142136 L-3.41421356,7.41421356 C-4.19526215,6.63316498 -4.19526215,5.36683502 -3.41421356,4.58578644 C-2.63316498,3.80473785 -1.36683502,3.80473785 -0.585786438,4.58578644 L6,11.1715729 L12.5857864,4.58578644 Z" id="Path-Copy" fill="#0951AA" fill-rule="nonzero" transform="translate(6.000000, 10.000000) rotate(90.000000) translate(-6.000000, -10.000000) "></path>
//     </g>
// </svg>