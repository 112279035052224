// This is a temporary additional file to tweak the management landing styles
// It should be deleted when Simon has "improved" the new /landing page

.landing {
    .marketing__header-content {
        .marketing__header-actions {
            .button--login {
                margin-right: 0.5rem;
            }

            .button--register {
                display: flex;
            }
        }
    }

    .marketing__cover {
        .marketing__cover-content {
            .button--login {
                margin-left: .5rem;
            }

            p {
                a {
                    color: #f3f3f3;
                }
            }
        }
    }

    .marketing__block {
        &.marketing__block--header {
            border-bottom: 0;
            padding-bottom: 1rem;

            .marketing__block-content {
                text-align: center;

                p {
                    @include breakpoint(for-tablet-portrait-up) {
                        margin: 0 auto;
                    }
                }
            }
        }

        .marketing__block-content {
            padding-left: 1rem;
            padding-right: 1rem;
            align-self: flex-start;
        }

        &.marketing__block--action {
            &.divider {
                margin-bottom: 3rem;
                border-bottom: 1px solid #cccccc;
                padding-bottom: 3rem;
            }
        }
    }
}
