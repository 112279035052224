.profile__mini-image{
    border: 1px solid #ffffff;
    width: rems(40);

    img{
        display: block;
        height: auto;
        width: 100%;
    }
}

.profile__image {
    border-radius: radius(small);
    display: flex;
    flex: 0 0 auto;
    flex-basis: rems(80);
    height: rems(80);
    overflow: hidden;
    position: relative;
    // margin-bottom: rems(20);
    max-width: rems(80);
    width: rems(80);

    &.profile__image--4x3 {
        flex-basis: rems(107);
        max-width: rems(107);
        width: rems(107);
    }

    img {
        display: block;
        height: auto;
        width: 100%;
    }
}

.page__public,.room {
    .profile__image {
        box-shadow: 0 0.125rem 0.625rem 0 rgba(0,0,0,.15);
    }
}

.profile__preview{
    display: flex;
    flex: 1 0 auto;
    min-height: rems(90);
}

.profile__preview-interests{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    margin-top: rems(5);
    padding: 0;

    .tooltip{
        background-color: var(--color-blue-dark);
        border-radius: radius(small);
        box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
        color: white;
        display: none;
        font-size: rems(12);
        line-height: rems(12);
        left: 50%;
        padding: rems(5);
        position: absolute;
        top: rems(-32);
        transform: translateX(-50%);
        white-space: nowrap;
        word-wrap: none;

        &::after{
            border-left:  rems(8) solid transparent;
            border-right: rems(8) solid transparent;
            border-top:   rems(8) solid var(--color-blue-dark);
            bottom: rems(-8);
            content: "";
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            height: 0; 
            width: 0; 

        }
    }

    .caption{
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: rems(14);
        line-height: rems(20);
        text-align: center;
    }

    li{
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        padding: rems(2);
        position: relative;
        width: rems(28);

        &:hover{
            .tooltip{
                display: block;
            }
        }

    }

    img{
        height: rems(24);
        width: rems(24);
    }


    .icon{
        fill: var(--color-grey);
        height: rems(24);
        width: rems(24);
    }

    &--large{
        margin-bottom: rems(20);

        li{
            padding: rems(10);
            width: rems(92);
        }

        img{
            height: rems(72);
            width: rems(72);
        }
    }

}

.profile__preview-text{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-left: rems(20);

    .nickname{
        color: var(--color-grey);
        font-size: rems(16);
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6{
        padding-top: 0;
    }

}


.profile__preview--organisation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: rems(4);

    .profile__preview--organisation-image {
        padding-left: 0;
        max-width: rems(28);
        overflow: visible;
        margin-right: rems(10);
        width: rems(28);

        .image__size {
            box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
        }
    }

    .profile__preview--organisation-title {
        h5 {
            font-size: rems(14);
            line-height: 1.3;
        }
    }
}

.landlord-badge{
    background-color: var(--color-magenta);
    border-radius: radius(small);
    color: var(--color-white);
    display: inline-block;
    font-size: rems(10);
    font-weight: 700;
    line-height: rems(10);
    margin-top: rems(-2);
    padding: rems(3) rems(5) rems(2) rems(5);
    text-transform: uppercase;
    vertical-align: middle;
}

.profile__preview{
    position: relative;

    p:last-of-type{
        margin-bottom: 0;
    }
}


// Profile preview for room cards

.profile__preview{
    
    &--card{
        .profile__image{
            border: rems(3) solid var(--color-white);
            box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.2);
            top: rems(-90);
            position: absolute;
            background-color: var(--color-white);
        }

        .profile__preview-text{
            margin-left: 0;
            padding-top: rems(10);
            width: min-content;
        }
    }

}


// SMALL VERSION

.profile__preview{
    &.profile__preview--small{
        flex-direction: column;
        min-height: 0;

        .profile__image{
            flex-basis: rems(40);
            height: rems(40);
            max-width: rems(40);
            width: rems(40);
        }

        .profile__preview-text{
            margin-left: 0;
        }
    }

    &.profile__preview--large{
        margin-bottom: rems(30);

        .profile__image{
            flex-basis: rems(100);
            height: rems(100);
            max-width: rems(100);
            width: rems(100); 
        }

        .profile__preview-text{
            margin-left: rems(20);
        }

    }

}

.profile__contact{
    border-top: rems(1) solid var(--color-grey-xlight);
    font-size: rems(14);
    list-style: none;
    margin-bottom: rems(20);
    margin-top: rems(20);
    padding: 0;
    width: 100%;

    .profile__contact-actions{
        padding-left: rems(10);
        a{
            color: var(--color-grey);
        }
    }

    .profile__contact-details{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        > span {
            display: inline-block;
            min-width: rems(84);
        }
    }

    li{
        border-bottom: rems(1) solid var(--color-grey-xlight);


        &:last-child{
            margin-bottom: 0;
            //border-bottom: 0;
        }
    }

    .profile__contact-item{
        display: flex;
        justify-content: space-between;
        padding-bottom: rems(10);
        padding-top: rems(10);
        width: 100%;

        .loader{
            padding: 0;
        }

        input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]){
            margin-bottom: 0;
        }

        &.profile__contact--editing{
            flex-direction: column;

            .profile__contact-actions{
                padding-left: 0;
            }
        }
    }
}

.profile__preview.profile__preview--room-small {
    margin-bottom: 0;
    text-align: left;
    min-height: 0;


    .profile__image {
        margin-top: rems(6);
        margin-left: rems(2);
        width: 3rem;
        max-width: 3rem;
        height: 3rem;
        flex-basis: 3rem;
        margin-right: rems(10);
        margin-bottom: rems(20);  // Added this to prvide sufficient space for the not joined/invited icons on anonymouse profiles.
    }

    .profile__status {
        padding: rems(3);
        top: rems(41);
        left: rems(37);
        height: rems(22);
        border: rems(2) solid var(--color-white);
        padding-top: 0;

        .icon, svg {
            width: rems(12);
            height: rems(18);
        }
    }

    .profile__preview-text {
        margin-left: rems(8);

        h4 {
            padding: 0;
            font-size: rems(16);
        }

        p {
            font-size: rems(14);
            line-height: 1.2;

            &:first-of-type {
            }

            &:last-of-type {
                margin-top: rems(4);
                margin-bottom: rems(4);
            }
        }
    }

    .profile__preview-interests {
        margin-top: 0;

        li {
            width: rems(24);
        }

        img {
            height: rems(20);
            width: rems(20);
        }
    }
}

@include breakpoint(for-large-phone-up){
    .profile__contact{
        .profile__contact-item{
            &.profile__contact--editing{
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]){
                    margin-right: rems(10);
                    width: 100%;
                }

                .profile__contact-actions{
                    display: flex;
                    align-items: center;
                    flex: 1 0 auto;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    padding-left: rems(10);
                    margin-left: auto;
                    justify-content: space-between;
                }
            }
        }
    }
}

.modal-profile-holder {
    background: var(--color-grey-xxlight);
    border: 1px solid var(--color-grey-xlight);
    padding: rems(8);
    border-radius: $size__radius--md;

    .profile__preview {
        //margin-bottom: 0;
    }
}

.onboarding-profile-holder {
    background: var(--color-grey-xxlight);
    border: 1px solid var(--color-grey-xlight);
    padding: rems(2);
    border-radius: $size__radius--sm;
    cursor: pointer;

    .profile__preview {
        margin-bottom: 0;

        .profile__image {
            margin: rems(2);
            width: rems(36);
            max-width: rems(36);
            height: rems(36);
            flex-basis: rems(36);
            box-shadow: 0 .125rem .625rem #00000026;
        }

        .profile__preview-text{
            h4 {
                font-size: rems(15);
                line-height: 1.4;
            }
            p { margin: 0 !important;}
        }
    }

    &.selected {
        outline: 1px solid var(--color-grey-light);
        border: 1px solid var(--color-grey-light);
        box-shadow: 0 .125rem .625rem #00000026;
    }
    &:hover {
        box-shadow: 0 .125rem .625rem #00000026;
    }

    &.action {
        background-image: linear-gradient(90deg, var(--color-grey-xxlight) 75%, rgba(var(--color-magenta-rgb),0.25) 100%);
        border-color: var(--color-magenta);
    }
    &.waiting {
        background-image: linear-gradient(90deg, var(--color-grey-xxlight) 75%, rgba(var(--color-orange-rgb),0.25) 100%);
        border-color: var(--color-orange);
    }
    &.complete {
        background-image: linear-gradient(90deg, var(--color-grey-xxlight) 75%, rgba(var(--color-green-rgb),0.25) 100%);
        border-color: var(--color-green);
    }
}
