find-home-search>.form {
    position: relative;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    #coho__search--results {
        display: none;
        &.visible {
            display: block;
            position: absolute;
            width:inherit;
            top: rems(52);
            z-index: 999999;
            .results__container {
                background: white;
                border-radius: radius(medium);
                text-align: left;
                padding: rems(4) rems(6); 
                min-height: rems(100);
				border: 1px solid var(--color-grey-light);
                .zero-state {
                    padding: rems(16) 0 0 rems(6);
                    p {
                        text-shadow: none;
                        color: var(--color-black);
                        margin: 0;
                    }
                }

                a {
                    text-decoration: none;
                    .area {
                       
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        padding: rems(6);
                        margin: rems(4) 0;
                        border-radius: radius(small);
                        &:hover {
                            background: var(--color-grey-xlight);
                        }                    
                        .area__icon {
                            background: var(--color-teal-dark);
                            border-radius: radius(small);
                            padding: rems(8);
                            padding-bottom: rems(4);
                            margin-right: rems(12);
                            .icon-area {
                                width: rems(24);
                                height: rems(24);
                            }
                            .icon svg {
                                width: rems(24);
                                height: rems(24);
                            }                          
                        }
                        .area__description {
                            p {
                                color:black;  
                                margin: 0;
                                line-height: 1.2;
                                text-shadow: none;
                                color: var(--color-grey);
                                font-size: rems(14);
                                .span, strong,
                                {
                                    font-weight: 700;
                                    font-size: rems(16);
                                    color: var(--color-black);
                                }
                            }

                        }

                    }
                }
            }
        }
    }
}


.modal {
    find-home-search > .form {
        #coho__search--results {
            &.visible {
                .results__container {
                    border: 1px solid var(--color-grey-light);
                }
            }
        }
    }
}
