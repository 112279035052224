conversations {
    overflow: hidden;

    .conversation__grid-messages-content {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;

        application-form-questions {
            display: flex;
            justify-content: center;
        }
    }

    .load-more {
        display: flex;
        justify-content: center;
        padding: rems(10);
        align-items: center;
        gap: rems(5);

        &:hover {
            cursor: pointer;
            color: var(--color-primary);
            background-color: var(--color-grey-xxlight);

          p {
            font-weight: 700;
          }
        }
    }

    .no_active_conversation {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--color-grey-xxlight);
        color: var(--color-grey-light);

        svg {
            fill: var(--color-grey-light);
            width: rems(60);
            height: rems(60);
        }
    }

  .conversation_list_holder {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
  }

    .conversations_list_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        gap: 20px;

        .bottom_actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
        }
    }


}


//Header on top of the conversation list

filter-and-new-conversation {
    display: flex;
    flex-direction: column;

    .search_and_new {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding: rems(10) rems(10) rems(10) rems(10);

        .show_property {
          display: flex;
          gap: 5px;
          align-items: center;
        }

        .actions {
            display: flex;
            gap: rems(10);

            loader-button {
                flex-shrink: 0;

                .button {
                    margin: 0;
                }
            }
        }
    }

    .property_and_free_search {
      padding: rems(0) rems(10);
      display: flex;

      searchable-dropdown {
        flex: 1;
        min-width: rems(150);
      }

      textbox {
        flex: 1;
        min-width: rems(150);

        &.hidden {
          display: none;
        }
      }
    }

    .all_filters {
        border-bottom: 1px solid var(--color-grey-light);
        padding: rems(0) rems(10) rems(10) rems(10);

        .filters_actions {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .conversation_types_filters{
                display: flex;
            }

            .archive_or_unread {
                display: flex;
            }
        }

        .message_delivery_type_filter {
            margin-top: rems(10)
        }
    }


}

//Each item on the conversation list

conversation-list-item {
  display: flex;
  flex-direction: column;
  padding: rems(10);
  cursor: pointer;
  border-bottom: 1px solid var(--color-grey-light);
  gap: rems(10);

  p {
    margin-bottom: 0;
  }

  .body {
    display: flex;
    justify-content: space-between;

      &.has_selector_form {
          align-items: center;
          gap: 10px;
      }

      .main_info {
          display: flex;
          justify-content: space-between;
          flex: 1;
      }

      .text_and_icon {
          display: flex;
          gap: 5px;
          align-items: center;

          p {
              line-height: 1.3rem;
          }

          svg {
              fill: var(--color-grey);
              width: rems(20);
              height: rems(20);
          }

          .subtitle_and_title {
              display: flex;
              flex-direction: column;
          }
      }
  }

  .date_unread_muted {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: end;
      min-width: 45px;

    .thread__list-unread {
      border: rems(5) solid var(--color-pink-dark);
      height: rems(10);
      width: rems(10);
      border-radius: 50%;
    }

    .thread__list-muted {
      height: rems(20);

      svg {
        width: rems(12);
        height: rems(12);
      }
    }
  }


    .statuses-and-assignee {
        display: flex;
        row-gap: 5px;
        column-gap: 10px;
        align-items: center;
        flex-wrap: wrap;
      
        .button__menu {
          min-width: 160px;
        }

        .button__menu-item {
            img{
                width: 20px;
                height: 20px;
                border-radius: 100px;
            }
            padding: 5px;
            font-size: 12px;
            color: var(--color-grey)
        }
    }

  .assignee {
    display: flex;
    gap: 5px;
    align-items: center;

    img{
      width: 20px;
      height: 20px;
      border-radius: 100px;
      object-fit: cover;
    }

    p {
      font-size: 12px;
      color: var(--color-grey)
    }
  }

  .status {
    background-color: var(--color-cyan);
    border: solid 1px var(--color-cyan);
    border-radius: radius(medium);
    padding: rems(2) rems(4);
    font-size: rems(10);
    font-weight: 700;
    line-height: rems(14);
    width: fit-content;
    text-transform: uppercase;
    color: var(--color-white);

    &.low {
      background-color: var(--color-green-dark);
      border: solid 1px var(--color-green-dark);
    }
    &.medium {
      background-color: var(--color-orange-dark);
      border: solid 1px var(--color-orange-dark);
    }
    &.high {
      background-color: var(--color-pink);
      border: solid 1px var(--color-pink);
    }
  }
}

conversation-list-item.active {
  background-color: var(--color-grey-xlight);
}

conversation-list-item:hover {
  background-color: var(--color-grey-xxlight);
}

// HEADER ABOVE THE MESSAGES

conversation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-light);
  padding: rems(10);
  flex-wrap: wrap;
  gap: rems(10);

  p{
    margin-bottom: 0;
  }

      a {
        cursor: pointer;
        color: var(--color-text);
      }

    a:hover {
        color: var(--color-primary)
    }

    .info_and_return {
        display: flex;
        flex-wrap: nowrap;
        gap: rems(10);
        align-items: center;
        max-width: 75%;
        flex: 2;
    }

      .info {
        display: flex;
        flex-direction: column;
        gap: rems(5);
        min-width: rems(200);
      }

    .actions {
        display: flex;
        align-items: center;
        gap: rems(5);
        flex-wrap: wrap;
        justify-content: end;

        loader-button {
            flex-shrink: 0;

            .button {
                margin-bottom: 0;
            }
        }
    }
}

// DETAILS

conversation-details {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--color-grey-light);
    width: 25vw; /* This means 25% of the viewport width */

    .participants_and_details {
      padding: rems(10);
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      overflow: auto;
    }

    .participant_addition_form {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        searchable-dropdown {
            flex: 1;
            margin-right: 10px
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    viewing-request-conversation-details, maintenance-conversation-details, onboarding-conversation-details, message-conversation-details {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      flex: 1;
      width: 100%;
    }
}

conversation-details.showDetailsOnly {
    width: 100%;
    height: 100%;
    flex: 1;
}

// MESSAGE ITEM

message-list-item, .message-list-item {

  p {
    margin-bottom: 0;
  }

  .message_failed {
    padding-right: rems(8);
    p{
      font-size: rems(12);
      color: var(--color-pink-xdark);
      font-weight: 600;
    }
  }

    // WHEN IT'S AN ACTION/HISTORY ITEM
    .contextual {
      margin-bottom: rems(2.5);

        .details_container {
            .final_message_content {
                margin: rems(3) auto;
                padding: rems(10);
                border: rems(0.25) solid #CCC;
            }
        }

        .details_container {
            display: flex;
            justify-content: center;

            .details_content {
                display: flex;
                justify-content: center;
                border-radius: radius(medium);
                align-items: center;
                flex-wrap: wrap;
                max-width: 70%;

                p {
                  font-size: rems(12);
                  margin-bottom: 0;
                  color: var(--color-grey-border);
                  line-height: 1.2rem;
                  text-align: center;
                }

                .details_time {
                    margin-left: rems(8);
                }
            }
        }
    }

    // WHEN IT'S FROM CURRENT USER
    .current_user {
      margin-bottom: rems(10);
        .details_container {
            display: flex;
            justify-content: flex-end;

            .details_content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                background-color: var(--color-primary-light-3);
                padding: rems(4) rems(8);
                border-radius: radius(medium);
                max-width: 90%;

                &.can_delete {
                    cursor: pointer;
                }

                .details_text {
                    word-break: break-word;
                    p {
                      margin-bottom: 0;
                      white-space:pre-wrap;
                    }
                }

                .time_and_read {
                    display: flex;
                    justify-content: flex-end;
                    gap: rems(4);

                    .details_time {
                        display: flex;
                        justify-content: flex-end;
                    }

                    .read_group {
                        display: flex;
                        gap: rems(4);
                        align-items: center;

                        .profile__mini-image {
                            border: none;
                            width: fit-content;
                        }

                        img{
                            width: rems(18) !important;
                            object-fit: cover;
                            border-radius: 100px;
                        }

                        .read_group_more {
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

      .message_failed {
        display: flex;
        justify-content: flex-end;
      }
    }

    .current_user.sending {
        .details_text {
            word-break: break-word;
            p {
                color: var(--color-grey-light);
                white-space:pre-wrap;
            }
        }
    }

    // WHEN IT'S FROM OTHER USERS
    .other_users {
      margin-bottom: rems(10);
        .details_container {
            display: flex;
            justify-content: flex-start;
            gap: rems(5);

            .details_thumbnail {
                img {
                    width: rems(48) !important;
                    object-fit: cover;
                    border-radius: 100px;
                }
            }

            .details_content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                background-color: var(--color-grey-xxlight);
                padding: rems(8);
                border-radius: radius(medium);
                max-width: 90%;

                .details_text {
                    word-break: break-word;
                    margin-top: rems(4);
                    p {
                        margin-bottom: 0;
                        white-space:pre-wrap;
                    }
                }

                .time_and_read {
                    display: flex;
                    justify-content: flex-end;
                    gap: rems(4);

                    .details_time {
                        display: flex;
                        justify-content: flex-end;
                    }

                    .read_group {
                        display: flex;
                        gap: rems(4);
                        align-items: center;

                        .profile__mini-image {
                            border: none;
                            width: fit-content;
                        }

                        img{
                            width: rems(18) !important;
                            object-fit: cover;
                            border-radius: 100px;
                        }

                        .read_group_more {
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

            }
        }

        .message_failed {
            display: flex;
            justify-content: flex-start;
        }
    }
}

// MESSAGE SENDER
message-sender {
  padding: rems(10);
  background-color: var(--color-grey-xlight);

  .warning_changed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rems(10);
    padding-bottom: rems(10);
  }

  conversation-context-form {
    .footer_actions {
      padding-bottom: rems(10);
    }
  }

  .message_form {
    display: flex;
    align-items: center;
    gap: 5px;
    &.reverse {
        flex-direction: row-reverse;
    }

    textarea {
        min-height: rems(36);
        margin: 0 rems(4);
        width: calc(100% - 8px);
        &:focus{
            border: rems(1) solid var(--color-grey-border);
            box-shadow: 0 0 0 rems(1) rgba(var(--color-grey-border-rgb),0.25);
        }
    }
  }
}

//QUICK ANSERS

.quick_answers {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 200px;
  overflow: auto;

  .quick_answer_holder {
    display: flex;
    background-color: var(--color-white);
    border-radius: radius(medium);
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    gap: 5px;

    p {
      padding: 5px;
    }

  }

  .quick_answer_zero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    svg-icon {
      svg {
        height: 20px;
        width: 20px;
        fill: var(--color-grey);
      }
    }
  }
}



// PARTICIPANTS

conversation-participants {
    .accordion__content{
        display: flex;
        flex-direction: column;
        gap: rems(10);

        .tenants_and_button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rems(10);
        }

        .user_name_img.tenants:hover {
            cursor: pointer;
            color: var(--color-primary);
        }
    }
}

.user_name_img {
    display: flex;
    align-items: center;
    gap: rems(10);

    img {
        object-fit: cover;
        width: rems(32);
        height: rems(32);
        border-radius: 100px;
    }
}

// CONVERSATION MESSAGES

conversation-messages {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .history_items_hider {
    display: flex;
    justify-content: center;
    align-items:  center;
    padding: rems(10) rems(0);

    p {
      font-size: rems(12);
    }

  }
}

