.context__list {
    //width: 95%;
    //margin: 0 auto;
    padding: rems(8) 0;
    font-size: rems(14);
    line-height: 1.3;
    text-align: left;

    ul:not(.context-item--hidden-content ul) {
        list-style: none;
        padding: 0;
        margin: 0;

        li:not(.context-item--hidden-content ul li) {
            clear: both;
            display: block;
            width: 100%;
        }
    }

}

.conversation__page {
    .context__list, .conversation__grid-header {
        .form__grid-2--medium {
            .form__grid-element {
                flex-basis: 100%;
                max-width: 100%;
                width: 100%;
            }
        }

    }
    .conversation__grid-header {
        .form.form--inline {
            text-align: left;
        }
    }
}

.context-item {
    display: flex;
    align-items: center;
    line-height: 1.3;
    flex-direction: column;
    border: 1px solid var(--color-grey-light);
    border-radius: radius(small);
    margin: rems(6) 0;
}

.conversation__page {
    .conversation__grid-wrapper {
        .conversation__grid {
            .conversation__grid-right {
                .conversation__grid-header {
                    .conversation__grid-header-context {
                        .context-item {
                            .button__group {
                                justify-content: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

.context-title {
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 0;
    position: relative;

    .context-title--button {
        font-weight: 700;
        color: var(--color-black);
        text-decoration: none;
        font-size: rems(16);
        padding: rems(10) rems(32) rems(10) rems(32);
        position: relative;
        line-height: 1.2;

        &:focus {
            outline: none;
        }

        &::after {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>            <path fill='%23919191' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
            content: "";
            display: block;
            position: absolute;
            height: rems(20);
            left: rems(4);
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            //transform: scale(-1);
            transition: transform 0.2s ease-out;
            width: rems(20);
        }
    }

    .context-status {
        position: absolute;
        right: rems(6);
        background-color: var(--color-grey);
        border: 1px solid var(--color-grey-dark);
        border-radius: rems(4);
        color: var(--color-white);
        font-size: rems(10);
        line-height: 1;
        margin-top: rems(9);
        margin-bottom: rems(5);
        padding: rems(4) rems(6);
        min-width: max-content;
        font-weight: 700;
        text-transform: uppercase;
    }
}

@include breakpoint(for-small-desktop-up) {

}

.collapsed {
    .context-title {
       .context-title--button{
           &::after{
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23CCCCCC' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");

            }
        }
    }
}
.not-collapsible {

    .context-title {
       .context-title--button{
            background-color: var(--color-grey-xxlight);
            cursor: default;
            &::after{
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path fill='%23919191' d='M9.586,21.586 C8.805,22.367 8.805,23.633 9.586,24.414 C10.367,25.195 11.633,25.195 12.414,24.414 C20.414,16.414 20.414,16.414 20.414,16.414 C21.195,15.633 21.195,14.367 20.414,13.586 C12.414,5.586 12.414,5.586 12.414,5.586 C11.633,4.805 10.367,4.805 9.586,5.586 C8.805,6.367 8.805,7.633 9.586,8.414 C16.172,15.000 16.172,15.000 16.172,15.000 C16.172,15.000 9.586,21.586 9.586,21.586 z' /> </svg>");
            }
        }
    }
}

.context-item--complete {

    .context-item {
        border-color: var(--color-green-dark);
    }

    .context-title {
        .context-title--button {
            //background-color: var(--color-green-light);
            &:hover {
                color: var(--color-green-xdark);
            }

            &::after {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%235FA94F' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
            }
        }

        .context-status {
            background-color: var(--color-green-xdark);
            border-color: var(--color-green-dark);
        }
    }

    .context-item--hidden-content {
        border-top: 1px solid var(--color-green);
    }

    &.collapsed {
        .context-title {
            .context-title--button {
                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%235FA94F' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");
                }
            }
        }
    }
}
.context-item--current {

    .context-item {
        border-color: var(--color-orange-dark);
    }

    .context-title {
        .context-title--button {
            //background-color: var(--color-orange-light);
            &:hover {
                color: var(--color-orange-xdark);
            }

            &::after {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23c38006' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
            }
        }

        .context-status {
            background-color: var(--color-orange-xdark);
            border-color: var(--color-orange-dark);
        }
    }

    .context-item--hidden-content {
        border-top: 1px solid var(--color-orange);
    }

    &.collapsed {
        .context-title {
            .context-title--button {
                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23c38006' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");
                }
            }
        }
    }
}
.context-item--overdue, .context-item--action {

    .context-item {
        border-color: var(--color-pink-dark);
    }

    .context-title {
        .context-title--button {
            //background-color: var(--color-pink-light);

            &:hover {
                color: var(--color-pink-xdark);
            }

            &::after {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23ED2B67' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z' transform='matrix(1 0 0 -1 0 30)'/></svg>");
            }
        }

        .context-status {
            background-color: var(--color-pink-xdark);
            border-color: var(--color-pink-dark);
        }
    }

    .context-item--hidden-content {
        border-top: 1px solid var(--color-pink);
    }

    &.collapsed {
        .context-title {
            .context-title--button {
                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23ED2B67' d='M6.51471863,13 L12.9995949,12.9995949 L13,6.51471863 C13,5.46828436 13.8036551,4.6095538 14.8274323,4.52205982 L15,4.51471863 C16.0543618,4.51471863 16.9181651,5.33059642 16.9945143,6.36545628 L17,6.51471863 L17.0004051,12.9995949 L23.4852814,13 C24.5898509,13 25.4852814,13.8954305 25.4852814,15 C25.4852814,16.1045695 24.5898509,17 23.4852814,17 L17.0004051,17.0004051 L17,23.4852814 C17,24.5317156 16.1963449,25.3904462 15.1725677,25.4779402 L15,25.4852814 C13.9456382,25.4852814 13.0818349,24.6694036 13.0054857,23.6345437 L13,23.4852814 L12.9995949,17.0004051 L6.51471863,17 C5.41014913,17 4.51471863,16.1045695 4.51471863,15 C4.51471863,13.8954305 5.41014913,13 6.51471863,13 Z'/></svg>");
                }
            }
        }
    }
}

.context-item--future {

}

.context-item--skip {
    .context-item {
        border-color: color(grey, light);
    }

    .context-title {
        .context-title--button {
            font-weight: 400;
            color: var(--color-grey);
        }
    }

    .context-status {
        background-color: color(grey, light);
        border-color: color(grey,light);
    }
}

.context-item--hidden-content {
    display: block;
    margin-top: 0;
    padding: rems(10) rems(10);
    width: 100%;
    color: var(--color-black);
    font-size: rems(14);
    line-height: 1.5;
    p:last-of-type {
        //margin-bottom: 0;
    }
    button,.button {
        margin-bottom: 0;
    }
    .form__message {
        margin-top: rems(10);
    }
}
.collapsed {
    .context-item--hidden-content {
        display: none;
    }
}
onboarding-step {
    .context-item--hidden-content {
        p:last-of-type:not(p.step__message-title) {
           // margin-bottom: 0;
        }
    }
}
onboarding-step {
    .context-item--hidden-content {
        p + .button__group > .button__group-item {
            padding-top: rems(20);
        }
        p + step-message > .step__message {
            margin-top: rems(20);
        }
    }
}

.context__list-actions {
    p {
        margin-top: rems(8);
        padding-top: rems(8);
        text-align: center;
        margin-bottom: 0;
        font-size: rems(12);
        color: var(--color-grey);
        border-top: 1px solid var(--color-grey-xlight)
    }
    //padding-top: rems(12);
    ul {
        &:last-of-type {
        }
        li {
            font-size: rems(12);
        }
    }
    .context__list-actions-group {
         &:last-of-type {
            padding-bottom: rems(8);
            border-bottom: 1px solid var(--color-grey-xlight);
            margin-bottom: rems(20);
        }
    }

}
@include breakpoint(for-large-phone-landscape-up) {

}
@include breakpoint(for-small-desktop-up) {
    .context__list{
        font-size: rems(16);
        line-height: unset;
        padding-bottom: rems(12);
    }
    .context__list-actions {
        //padding-top: rems(12);
        padding-bottom: rems(20);
        li {
            font-size: rems(12);
        }
    }
}
