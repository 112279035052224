.ra__message {
    background-color: var(--color-grey-xxlight);
    border-radius: radius(medium);
    display: flex;
    flex-direction: column;
    font-size: rems(14);
    line-height: rems(20);
    margin-bottom: rems(20);
    overflow: hidden;
    width: fit-content;
    border: 3px double var(--color-grey-light);
    align-items: top;

    &--border {
        border: 3px double var(--color-grey-light);
    }

    &--no-border {
        border: none;
    }

    &--full-width {
        width: 100%;
    }

    .ra__message--left {
        display: flex;
        flex-direction: row;
    }

    .ra__message--right {
        padding: 0 rems(30) rems(10) rems(60);

        p {
            font-size: rems(14);
            margin-bottom: rems(4);
        }
    }

}



// form-message {
//     .ra__message {
//         margin-bottom: rems(8);
//     }

//     &:last-child {
//         .ra__message {
//             margin-bottom: rems(20);
//         }
//     }
// }

// form-message + h1 {
//     margin-top: rems(20);
// }

// .room__content-section {
//     .ra__message {
//         margin-bottom: rems(20);
//     }
// }

.ra__message-icon {
    // background-color: var(--color-grey-light);
    padding: rems(10);
    display: flex;
    align-items: flex-start;
    padding-right: rems(20);

    .icon {
        display: block;
        fill: #ffffff;
        height: rems(30);
        width: rems(30);

        svg {
            height: rems(30);
        }
    }
}

.ra__message-text {
    padding: rems(10) rems(10) rems(10) rems(0);
    width: 100%;

    .button, .button.button--small, .button.button--tiny {
        margin-bottom: 0;
    }

    .button--space {
        margin-left: rems(16);
    }

    *:last-child {
        margin-bottom: 0;
    }

    h5 + p {
        margin-top: rems(4);
    }

    h5 {
        font-weight: 600;
    }

    p {
        font-size: rems(14);
    }

}

.ra__message--large{
    border-radius: $size__radius;

    .ra__message__text{
        padding: rems(20);
    }
}



.ra__message--waiting {
    background-color: var(--color-green-light);
    border-color: var(--color-green-xdark);

    .ra__message-icon {
        .icon {
            fill: var(--color-green-xdark);
        }
    }

    &.ra__message--border {
        border: 1px solid var(--color-green-dark);
    }

    &.ra__message--no-border {
        border: none;
    }
}

.ra__message--queued {
    background-color: var(--color-orange-light);
    border-color: var(--color-orange-xdark);

    .ra__message-icon {
        .icon {
            fill: none;
            stroke: var(--color-orange-dark);
        }
    }

    &.ra__message--border {
        border: 1px solid var(--color-orange-dark);
    }

    &.ra__message--no-border {
        border: none;
    }
}

.ra__message--matches {
    background-color: var(--color-green-dark);
    border-color: var(--color-green-xdark);
    color: white;

    .ra__message-icon {
        .icon {
            fill: white;
        }
    }

    &.ra__message--border {
        border-color: var(--color-green-xdark);
    }

    .ra__message-text {
        color: white;
        font-weight: 700;
    }

    &.ra__message--no-border {
        border: none;
    }
}

@include breakpoint (for-tablet-landscape-up) {
    .ra__message {
        flex-direction: row;
        .ra__message--left {
            width: 50%;
        }
        .ra__message--right {
            width: 50%;
            padding-top: rems(8);
            padding-right: rems(10);
            padding-left: rems(20);
        }
    }

}

