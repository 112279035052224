.coho__dark {
    .feedback {
        background-color: var(--color-grey-dark);
        border: 1px solid var(--color-grey-border);
        .feedback__message {
            h4 {
                color: var(--color-white);
            }
            p {
                color: var(--color-grey-xlight);
            }

        }
        .feedback--icons {
            .feedback--icons-holder {
                background-color: var(--color-grey-light);
                border: 1px solid var(--color-rating-gold);
                &:hover {

                    outline: 2px solid var(--color-rating-gold);
                }
                p {
                    color: var(--color-black);
                }
                .icon {
                    fill: var(--color-black);
                }

                &.sad {
                    outline-color: var(--color-pink-dark);
                    
                    &.active {
                        background-color: rgba(var(--color-pink-xdark-rgb),0.75);
                        border-color: var(--color-pink-dark);
                        outline: 1px solid var(--color-pink-dark);
                    }
                    
                }
                &.happy {
                    outline-color: #edc32b;
                    
                    &.active {
                        background-color: rgba(#b67600,0.75);
                        border-color: #edc32b;
                        outline: 1px solid #edc32b;
                    }
                    
                }
                &.great {
                    outline-color: var(--color-green-dark);
                    
                    &.active {
                        background-color: rgba(var(--color-green-xdark-rgb),0.75);
                        border-color: var(--color-green-dark);
                        outline: 1px solid var(--color-green-dark);
                    }
                    
                }
                
            }
        }
        &.reduced {
            &.great,&.sad,&.happy {
                background-color: var(--color-grey);
            }
            .feedback--icons {
                .feedback--icons-holder {
                    background: var(--color-white);
                    &.sad,&.happy,&.great {
                        &.active {
                            background-color: transparent;
                        }          
                    }
                }
            }
        }
    }

    .rating__stars {
        .rating__stars--icon {
            &.hovered-1,&.selected-1 {
                .icon.icon--light-grey {
                    fill: var(--color-pink-dark);
                }
            }
            &.hovered-2,&.selected-2 {
                .icon.icon--light-grey {
                    fill: var(--color-warning-dark);
                }
            }
            &.hovered-3,&.selected-3 {
                .icon.icon--light-grey {
                    fill: var(--color-orange-dark);
                }
            }
            &.hovered-4,&.selected-4 {
                .icon.icon--light-grey {
                    fill: var(--color-green-dark);
                }
            }
            &.hovered-5,&.selected-5 {
                .icon.icon--light-grey {
                    fill: var(--color-green-xxdark);
                }
            }
            
        }
    }
}