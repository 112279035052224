.coho__dark {
    .content__notification {
        background-color: var(--color-black);

        &--light {
            background-color: var(--color-grey-dark);
        }
    }
}

