.coho__dark {
    .invoice-box, .invoice-box tr.footer {
        background: var(--color-grey-light);
    }

    .invoice-box table tr.footer {
        border-color: var(--color-black);
    }

    .invoice-box table tr.heading td {
        border-color: var(--color-black);
    }

    .invoice-box table tr.item td {
        border-color: var(--color-grey-dark);
    }

    .invoice-box table tr.subtotal td:nth-child(2) {
        border-color: var(--color-grey-dark);
    }


    .invoice-box table tr.total td:nth-child(2) {
        border-color: var(--color-grey-dark);
    }

    .invoice-box .footer {
        background: var(--color-grey-light);
    }

    .site__content {

        &.empty__page {
            background-color: var(--color-grey-light);
        }
    }
}
