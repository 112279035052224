panel-info-box {
    &.col.col__md--6.editing, &.col.col__md--6.viewing {
        width: 100%;
            @include breakpoint(for-tablet-landscape-up) {
                width: 80%;
            }
    }
}

.card__info {
    .card__content {
        max-height: rems(180);
        position: relative;
        overflow: hidden;
        margin-bottom: rems(20);

        .card__content--fade {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            margin: 0;
            padding: rems(30) 0;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAA8CAMAAACQLyDIAAAAhFBMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9hWbqAAAAALHRSTlP68Orezrusg0Q7KSEVCAH15NjUx8KzpZ+alZCKenVwamVgW1ZQTDQvGhANBQpaEmcAAAEBaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8P3hwYWNrZXQgYmVnaW49Iu+7vyI/PjxyOlJERiB4bWxuczpyPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj48cjpEZXNjcmlwdGlvbiB4bWxuczp4PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj48eDpDcmVhdGVEYXRlPjIwMjAtMDctMjVUMTA6NTk6NTArMDE6MDA8L3g6Q3JlYXRlRGF0ZT48L3I6RGVzY3JpcHRpb24+PC9yOlJERj48P3hwYWNrZXQgZW5kPSJyIj8+MjYkfQAAAEtJREFUCNcdwYURhAAABLF9F9zdHfrvjxsSbD2wdGfD1BVD/7ow89OvTowM9HS0NNRUlHy0ICcjJSHmrREvDQl4qo/HQ13uelOHywkqEgQchTFUvwAAAABJRU5ErkJggg==');
            background-repeat: repeat-x;
            background-position: top center;
        }
    }

    &.card__info--editing, &.card__info--viewing {
        .card__content {
            max-height: none;
            position: inherit;
            overflow: visible;
            margin-bottom: 0;

            .card__content--fade {
                display: none;
            }
        }
    }

    &.card__info--hidden {
        background: #F9F9F9;

        .card__header h4, .card__content p {
            color: var(--color-grey-border);
        }

        .card__content--fade {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAA8CAMAAACQLyDIAAAAh1BMVEX5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fkAAAB/CJEhAAAALXRSTlP88N/OwLZ8aDIpIBUOCAP49evo5NjUxq+qpZ+alY+KhXVvYFtVUUtHQj04GwCdhvzmAAABAWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iPz48cjpSREYgeG1sbnM6cj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+PHI6RGVzY3JpcHRpb24geG1sbnM6eD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyI+PHg6Q3JlYXRlRGF0ZT4yMDIwLTA3LTI1VDEwOjU5OjUwKzAxOjAwPC94OkNyZWF0ZURhdGU+PC9yOkRlc2NyaXB0aW9uPjwvcjpSREY+PD94cGFja2V0IGVuZD0iciI/PjI2JH0AAABKSURBVAjXDcGDAcQAAATBfSO2bfRfX26GE1stNdXQg7/+9Ks7GysLMxMjAz0f7Wh5a0NNRUlBTkZKwkufGvPQiJC7Bvh43NTFQS4iwARIh72XGwAAAABJRU5ErkJggg==');
        }
    }

  .error-summary {
        padding: rems(20);
        padding-top: 0;
    }
}

.infobox__panels-editor {
    width: 100%;
    .form__label--medium{
        line-height: rems(26);
        margin-bottom: 0;
    }
    h4 + * { margin-top: rems(10); }
    h4 + p { margin-top: 0; }
}

.infobox__panels-description {
    display: inline-block;
    margin-top: rems(3);
    min-height: rems(30);    
    width: 100%;
    font-size: rems(14);
    li {
        font-size: rems(16);
    }
}
.infobox__panels-files{
    display: inline-block;
    margin-top: rems(10);
    min-height: rems(30);
    width: 100%;
    .file_document {
       margin-top: rems(6); 
    }
    .file_document_name {
        font-weight: 900;
    }
}

.infobox__panels-video{
    display: block;
    width:100%;
    margin-top: rems(30);
    min-height: rems(30);
    .embed-container { 
        position: relative; 
        padding-bottom: 56.25%; 
        @media (min-width:72rems) {
           padding-bottom: 57.25%; 
        }
        height: 0; 
        overflow: hidden; 
        max-width: 100%; 
        iframe, object, embed { 
            position: absolute; 
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%; 
        } 
    } 
   
}

.infobox__panels-photos{
    display: flex;
    flex-wrap: wrap;
    margin-top: rems(20);
    padding: 0;

    li{
        align-items: center;
        border: 1px solid var(--color-grey-xlight);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: rems(10);
        padding: rems(5);
        width: rems(90);

        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .col {
        .infobox__panels-description,
        .infobox__panels-files,
        .infobox__panels-video {
            width:86%;
        }
    }
    .col.col__md--6 {
        .infobox__panels-description,
        .infobox__panels-files,
        .infobox__panels-video {
            width:100%;
        }
    }
}
