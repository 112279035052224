.button {
    &.pulse {
        animation: pulse-magenta 2s infinite;
    }

    &.button--secondary {
        &.pulse {
            animation: pulse-cyan 2s infinite;
        }
    }

    &.button--ghost {
        &.pulse {
            animation: pulse-grey 2s infinite;
        }
    }
}

.button__group-item {
    &.pulse {
        .button {
            animation: pulse-magenta 2s infinite;
            
            &.button--secondary {
                animation: pulse-cyan 2s infinite;
            }
            &.button--ghost {
                animation: pulse-grey 2s infinite;
            }
        }
    }
}