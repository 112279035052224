.coho__dark {
    .finance__zero-state {

        .finance__zero-state--overlay {
            background-color: var(--color-black);
            background-color: rgba(var(--color-black-rgb),0.9);
        }
    }

    .page__section--alt {
        .finance__zero-state {
            .finance__zero-state--overlay {
                background-color: var(--color-grey-dark);
                background-color: rgba(var(--color-grey-dark-rgb),0.9);
            }
        }
    }

    #profitability-reports .card.card--report a.card__content h5 {
        color: var(--color-grey-xlight);
    }

    #profitability-reports .accordion__description, #settlements .accordion__description {
        color: var(--color-grey-light);
    }

    settlement-financial-items .settlement__item:hover {
        background-color: var(--color-grey);
    }

}
