.button {
      &.button--transparent {
        background-color: transparent;
        border: 1px solid var(--color-white);
        border-radius: radius(medium);
        box-shadow: 0 rems(2) rems(6) rgba(0, 0, 0, 0.15);
        color: var(--color-white);
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: all 0.2s ease-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &:focus {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid var(--color-grey-light);
          box-shadow: 0 0 0 rems(3) rgba(0, 0, 0, 0.1);
          outline: none;
        }
      }

    &.button--transparent-full {
        background-color: transparent;
        color: var(--color-text);
        display: inline-block;
        position: relative;
        transition: all 0.2s ease-out;
        border: 1px solid transparent;
        box-shadow: none;

        //svg {
        //    fill: var(--color-text);
        //}

        &:hover {
            background-color: var(--color-grey-xxlight);
            border: 1px solid transparent;
        }

        &:focus {
            background-color: var(--color-grey-xxlight);
            border: 1px solid transparent;
            outline: none;
        }

        &:disabled {
            &:hover {
                background-color: transparent;
            }
        }

        &.light-weight {
            font-weight: 600;
        }

        &.light-blue-bg{
            background-color: var(--color-primary-light-2);
        }
        &.grey-bg{
            color: var(--color-white);
            svg {
                fill: var(--color-white);
            }
            background-color: var(--color-grey-dark);
          &:disabled {
            svg {
              fill: var(--color-grey-light);
            }
          }
        }
        &.light-grey-bg{
            background-color: var(--color-grey-xlight);
        }
    }
}
