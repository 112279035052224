.show-for-small {
    display: block;
}
.hide-for-small {
    display: none;
}
.hide-for-xsmall {
    display: none;
}
.show-for-large {
    display: none;
}
.hide-for-large {
    display: block;
}
.show-for-xlarge {
    display: none;
}
.hide-for-xlarge {
    display: block;
}


@include breakpoint(for-large-phone-up) {
    .hide-for-xsmall {
        display: block;
    }
}


@include breakpoint(for-tablet-portrait-up) {

    .show-for-small {
        display: none;
    }

    .hide-for-small {
        display: block;
    }
}

@include breakpoint(for-small-desktop-up) {

    .show-for-large {
        display: block;
    }
    .hide-for-large {
        display: none;
    }

}

@include breakpoint(for-large-desktop-up) {

    .show-for-xlarge {
        display: block;
    }
    .hide-for-xlarge {
        display: none;
    }

}
