.card__performance {
    padding-top: rems(6);
    min-height: rems(94);
    position: relative;

    &:focus {
        outline: none;
    }

    &--5 {
        background-color: var(--color-magenta-xdark);
    }

    &--1 {
        background-color: var(--color-green-xxdark);
    }

    &--2 {
        background-color: var(--color-green-xdark);
    }

    &--3 {
        background-color: var(--color-orange-dark);
    }

    &--4 {
        background-color: var(--color-orange-xdark);
    }

    &--inactive {
        background-color: var(--color-grey-border);
        cursor: default;

        &:focus {
            outline: 0;
        }
    }

    .card__amount {
        padding-top: rems(16);
        font-size: rems(38);
        line-height: 1;
        color: var(--color-white);
        text-align: center;

        &--null,&--null-rent {
            font-size: rems(18);
            font-weight: 400;
            padding-top: rems(30);
        }

        &:hover {
            color: white;
        }

        span {
            font-weight: 400;
            font-size: rems(20);
        }
    }

    &--smaller {
        .card__amount {
            font-size: rems(32);
            padding-top: rems(22);
        }
    }

    .card__subtitle {
        //padding-top: rems(4);
        font-size: rems(14);
        line-height: 1;
        color: var(--color-white);
        text-align: center;

        &:hover {
            color: white;
        }
    }

    .card__title {
        color: var(--color-white);
        font-size: rems(12);
        text-align: center;
        //text-transform: uppercase;
        line-height: 1.5;
        position: absolute;
        bottom: rems(4);
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;

        &:hover {
            color: white;
        }
    }

    &.card__performance--subtitle {
        .card__amount {
            padding-top: rems(4);
        }
    }

    &.card__performance--feedback {
        &.card__performance--1 {
            background-color: var(--color-magenta-xdark);
        }
    
        &.card__performance--5 {
            background-color: var(--color-green-xxdark);
        }
    
        &.card__performance--4 {
            background-color: var(--color-green-xdark);
        }
    
        &.card__performance--3 {
            background-color: var(--color-orange-dark);
        }
    
        &.card__performance--2 {
            background-color: var(--color-magenta-dark);
        }
    
        &.card__performance--0 {
            background-color: var(--color-grey-border);
            cursor: default;
    
            &:focus {
                outline: 0;
            }
        }
    }
}

performance-chart-age-bar {
  display: block;
  height: rems(300);
  position: relative;
}

performance-chart-gender-pie {
  display: block;
  height: rems(240);
  margin-bottom: rems(50);
  @include breakpoint(for-tablet-portrait-up) { margin-bottom: 0;}
  position: relative;

  .button--ghost {
    &.selected {
      background-color: var(--color-teal-light);
    }
  }

  .button--space {
    margin-left: rems(20);
  }

}