.form__button-content{
    h3 + *{
        margin-top: rems(10);
    }

    img, svg{
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: rems(120);
        width: 100%;
    }

    *:last-child{
        margin-bottom: 0;
    }
}
