
p-editor {
    .ql-snow .ql-editor {
        .p {
            font-size: rems(16);
            line-height: rems(24);
        }
        
        
        li {
            font-size: rems(16);
        }
    
        p, ol, ul, dl, table{
            margin-top: 0;
            margin-bottom: rems(20);
        }
    
    
        h1 + *, h2 + *, h3 + *, h4 + *, h5 + *, h6 + *{
            margin-top: rems(20);
        }
    
    
        h1, h2, h3, h4, h5, h6{
            font-weight: 800;
            margin: 0;
            margin-bottom: 0;
            letter-spacing: -0.1px;
        }
    
        h1, .heading--1{ font-size: rems(30);  line-height: rems(38); }
        h2, .heading--2{ font-size: rems(24);  line-height: rems(30); }
        h3, .heading--3{ font-size: rems(20);  line-height: rems(25); }
        h4, .heading--4{ font-size: rems(18);  line-height: rems(22); }
        h5, .heading--5{ font-size: rems(16);  line-height: rems(20); }
        h6, .heading--6{ font-size: rems(16);  line-height: rems(20); }
    
    
        @include breakpoint(for-tablet-portrait-up){
            .heading--mega{ font-size: rems(48);  line-height: rems(60); }
            h1, .heading--1{ font-size: rems(36);  line-height: rems(45); }
            h2, .heading--2{ font-size: rems(28);  line-height: rems(35); }
            h3, .heading--3{ font-size: rems(24);  line-height: rems(30); }
            h4, .heading--4{ font-size: rems(20);  line-height: rems(24); }
        }
    }

}



