.button--tooltip{
    position: relative;

    .button--tooltip-content{
        background-color: var(--color-magenta);
        bottom: 30px;
        border-radius: radius(medium);
        box-shadow: rems(0) rems(2) rems(10) rgba(0,0,0,0.2);
        color: var(--color-white);
        display: block;
        font-size: rems(14);
        left: 50%;
        line-height: rems(20);
        margin-bottom: rems(-12);
        max-width: rems(400);
        width: max-content;
        opacity: 0;
        padding: rems(5) rems(10);
        position: absolute;
        transform: translateX(-50%);
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;


        &::after{
            border-left:  rems(8) solid transparent;
            border-right: rems(8) solid transparent;
            border-top:   rems(8) solid var(--color-magenta);
            bottom: rems(-8);
            content: "";
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            height: 0;
            width: 0;

        }

        &.button--tooltip-right{
            left: rems(-12);
            right: auto;
            transform: none;

            &::after{
                left: rems(16);
                right: auto;
                transform: none;
            }
        }

        &.button--tooltip-left{
            left: auto;
            right: rems(-12);
            transform: none;

            &::after{
                left: auto;
                right: rems(16);
                transform: none;
            }
        }

    }

    &:hover {
      .button--tooltip-content{
        margin-bottom: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    &.open {
        .button--tooltip-content{
            margin-bottom: 0;
            opacity: 1;
            visibility: visible;
        }
    }

}

.button--secondary {
    &.button--tooltip {
        .button--tooltip-content {
            background-color: var(--color-cyan);
            color: var(--color-white);
            &::after{
                border-top-color: var(--color-cyan);
            }
        }
    }
}
.button--ghost {
    &.button--tooltip {
        .button--tooltip-content {
            background-color: var(--color-white);
            color: var(--color-black);
            outline: rems(1) solid var(--color-black);
            text-align: left;
            &::after{
                border-top-color: var(--color-black);
            }
        }
    }
}

.button--small {
    &.button--tooltip{
        .button--tooltip-content{
            bottom: rems(44);
            font-size: rems(13);
            line-height: 1.4;
        }
    }
}
