.form__checkbox-button{
    display: block;
    margin-bottom: rems(20);
    position: relative;

    &:hover{
        cursor: pointer;
    }

    input[type=checkbox]{
        left: -3000px;
        opacity: 0;;
        position: absolute;



        + .form__checkbox-button-label{
            @include input__up;
            margin-bottom: rems(10);
            padding: rems(10) rems(20);
            text-align: center;
        }

        &:checked{

            + .form__checkbox-button-label{
                background-color: var(--color-form-light);
                border: rems(1) solid var(--color-form);
                box-shadow: $shadow__radio-buttons;
                color: var(--color-blue);
                display: block;
                font-weight: 800;
                // letter-spacing: -0.0125rem;
            }

        }

        &:focus{
            + .form__checkbox-button-label{
                border-color: var(--color-form);
                box-shadow: $shadow__radio-buttons--focus, $shadow__outline;   
            }
        }

        &:disabled{
            + .form__checkbox-button-label{
                background-color: var(--color-grey-xlight);
                border-color: var(--color-grey);
                color: var(--color-grey);
            }

            &:checked{
                + .form__checkbox-button-label{
                    box-shadow: $shadow__radio-buttons--disabled;
                }
            }

        }   



    }

    &.required{
        input[type=checkbox]{

            + .form__checkbox-button-label{
                // background-color: rgba(var(--color-pink-rgb), 0.2);
                border-color: var(--color-pink);
                box-shadow: $shadow__required;
                // color: var(--color-magenta-dark);
            }

            &:focus{
                + .form__checkbox-button-label{
                    background-color: white;
                    border-color: var(--color-form);
                    box-shadow: $shadow__radio-buttons--focus, $shadow__outline;
                    color: var(--color-text);   
                }
            }

            &:checked{
                + .form__checkbox-button-label{
                    background-color: var(--color-form-light);
                border: rems(1) solid var(--color-form);
                    box-shadow: $shadow__radio-buttons;
                    color: var(--color-text);   
                }
            }
        }
    }

    &.read-only{
        input[type=checkbox]{
            + .form__checkbox-button-label{
                background-color: var(--color-white);
                border-color: var(--color-grey-xlight);
                box-shadow: none;
            }
            
            &:checked{
                + .form__checkbox-button-label{
                    border-color: var(--color-grey);
                    box-shadow: 0 0 0 rems(2) var(--color-grey)
                }
            }

        }
    }


}

// GRID

.form__grid-element{

    .form__checkbox-button{
        //background-color: white;
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
        margin-bottom: 0;
        width: 100%;

        .form__checkbox-button-label{
            height: 100%;
        }

    }

}
