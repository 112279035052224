.button{
    &.button--ghost{
        background-color: var(--color-white);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
        color: var(--color-text);
        cursor: pointer;
        display: inline-block;
        // margin-bottom: rems(10);
        // padding: rems(13) rems(12);
        position: relative;
        transition: all 0.2s ease-out;

        &:hover{
            border-color: var(--color-grey-border);
        }

        &:focus{
            border: 1px solid var(--color-grey-light);
            //box-shadow: 0 0 0 rems(3) var(--color-text);
            outline: none;
        }

        &.internal {
            background-color: var(--color-violet-light);
            background-color: rgba(var(--color-violet-light-rgb),0.25);
        }


        &:disabled, &.disabled{
            background-color: var(--color-grey-xxlight);
            border-color: var(--color-grey-light);
            box-shadow: none;
            color: var(--color-grey-light);

            &:hover{
                background-color: var(--color-grey-xxlight);
                border-color: var(--color-grey-light);
                box-shadow: none;
                color: var(--color-grey-light);
            }

            &.button--icon {
                svg {
                    fill: var(--color-grey-light);
                }
            }

        }
    }


}
