body.coho__dark {

    // $color__grey--light: #CCCCCC;
    // $color__grey--border: #959595;
    // $color__grey: #5F5F5F;
    // $color__grey--dark: #515151;
    // $color__grey--xlight: #ededed;
    // $color__grey--light-1: #cccccc;
    // $color__grey--light-2: #ededed;
    // $color__grey--light-3: #f7f7f7;
    // $color__black: #262626;
    // $color__black--light: #3D3D3D;

    background-color: var(--color-black);
    color: var(--color-grey-light);

    .site__header {
        background-color: var(--color-blue-dark);
    }

    .site__content {
        background-color: var(--color-black);
    }

    .section__header {
        border-bottom-color: var(--color-black-light);
    }

    .page__section--alt {
        background-color: var(--color-black-light);
    }

    .page__section--highlight {
        background-color: var(--color-teal-xdark);
    }

    .room {
        background-color: var(--color-black-light);

        .room__heading-title h2 + p {
            color: var(--color-grey-light);
        }
    }

    .footer {
        background-color: var(--color-blue-xdark);

        .footer__links {
            a {
                color: var(--color-grey-xlight);
            }
        }

        p.copyright {
            color: var(--color-grey-xlight);
        }
    }

    hr {
        border-bottom: 1px solid rgba(var(--color-grey-xlight-rgb),0.9);
    }


    .room {
        border-left: rems(5) solid var(--color-teal-xdark);
        border-right: rems(5) solid var(--color-teal-xdark);
    }
}
