// Property

.page__section-content--compact,.card--property-horizontal-v2-multi {
    .row {
        margin-left: rems(-5);
        margin-right: rems(-5);
        width: auto;

        .col {
            padding-left: rems(5);
            padding-right: rems(5);
        }
    }

    .card {
        &.card--property {
            flex-direction: row;
            align-items: center;

            .card__image {
                max-width: rems(80);
                border-top-left-radius: radius(medium);
                border-bottom-left-radius: radius(medium);
                border-top-right-radius: 0;
            }

            .card__completion { 
                height: rems(5);
            }

            .card__content {
                padding: 0 rems(12) 0 rems(8);

                .heading--4 {
                    font-size: rems(16);
                    line-height: 1.1;
                    padding-bottom: rems(2);
                }

                p {
                    font-size: rems(12);
                    margin-bottom: 0;
                    line-height: 1.4
                }

                .text__price {
                    display: none;
                }

                .card__property-actions {
                    position: absolute;
                    top: rems(0);
                    right: rems(-10);
                    padding-top: 0;
                    min-height: 0;

                    span {
                        &.badge {
                            justify-content: space-around;
                            align-items: end;
                            border-radius: 0;
                            border-bottom-left-radius: 100%;
                            font-size: rems(12);
                            padding-top: 0;
                            padding-right: 0;
                            line-height: 2.2;
                        }
                    }
                }
            }

            &.inactive__modal-wrapper {
                .inactive__modal {
                    padding: 0;

                    .inactive__modal-content {
                        justify-content: center;
                        margin-right: rems(20);

                        .button {
                            font-size: rems(14);
                            line-height: rems(16);
                            min-width: rems(80);
                            padding: rems(8);

                            &.button--icon {
                                padding-left: 0;
                                min-width: 2.125rem;
                                min-height: 2.125rem;

                                .icon {
                                    height: rems(20);
                                    position: absolute;
                                    left: rems(6);
                                    top: rems(6);
                                    width: rems(20);

                                    svg {
                                        height: rems(20);
                                        width: rems(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.card--room {
            flex-direction: column;
            //align-items: center;
            border: 0;

            .card--routing {
                display: flex;
            }

            .card__image {
                max-width: rems(80);
                border-top-left-radius: radius(medium);
                border-bottom-left-radius: radius(medium);
            }

            .info-badge {
                top: rems(4);
                right: rems(4);
            }

            .card__content {
                padding: 0 rems(12) 0 rems(12);

                .heading--4 {
                    font-size: rems(16);
                    line-height: 1.4;
                    margin-bottom: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }

                p {
                    font-size: rems(12);
                    margin-bottom: 0;
                    margin-top: 0;
                    line-height: 1.4
                }

                .card__room-profile {
                    .profile__preview {
                        min-height: 0;
                    }
                }
            }

            &.inactive__modal-wrapper {
                .inactive__modal {
                    padding: 0;

                    .inactive__modal-content {
                        justify-content: center;
                        margin-right: rems(20);

                        .button {
                            font-size: rems(14);
                            line-height: rems(16);
                            min-width: rems(80);
                            padding: rems(8);

                            &.button--icon {
                                padding-left: 0;
                                min-width: 2.125rem;
                                min-height: 2.125rem;

                                .icon {
                                    height: rems(20);
                                    position: absolute;
                                    left: rems(6);
                                    top: rems(6);
                                    width: rems(20);

                                    svg {
                                        height: rems(20);
                                        width: rems(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-up) {
    .page__section-content--compact {
        .card {
            &.card--property {
                .card__image {
                    max-width: rems(100);
                }

                .card__content {
                    p {
                        font-size: rems(12);
                        line-height: 1.3;
                    }

                    .card__property-actions {

                        span {
                            &.badge {
                                width: rems(30);
                                height: rems(30);
                                font-size: rems(14);
                            }
                        }
                    }
                }
            }

            &.card--room {
                .card__image {
                    max-width: rems(100);
                }

                .card__content {
                    p {
                        font-size: rems(14);
                    }
                }
            }
        }
    }
}
