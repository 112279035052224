.profile__viewing{
    display: flex;
    flex: 1 0 auto;
    margin-bottom: rems(20);
    flex-direction: column;
    position: relative;
    p {
        margin-top: rems(4);
        margin-bottom: 0;
    }

    .profile__viewing-text{
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: rems(16) 0;
        h1, h2, h3, h4, h5, h6{
            padding-top: 0;
        }

        &.profile__viewing-lead {
            p {
                border-bottom: 1px solid var(--color-grey-xlight);
                padding-bottom: rems(6);
                margin-bottom: rems(6);
            }
        }

    } 

    &.profile__preview--small{

        .profile__image{
            flex-basis: rems(50);
            height: rems(50);
            max-width: rems(50);
            width: rems(50);
            &.profile__image--property {
                flex-basis: rems(66);
                max-width: rems(66);
                width: rems(66);
            }
        }
        .profile__preview-text{
            margin-left: 0;
        }
        .truncate__text {
            margin-top: rems(4);
        }
        
    }    
    

}


@include breakpoint(for-large-phone-landscape-up) {
    .profile__viewing{
        flex-direction: row;
        .profile__viewing-text{
            margin: 0;
        }   
        .profile__image {
            margin-right: rems(20);
        }
        .profile__viewing-actions{
            margin: 0 0 0 rems(20);
        }
    }    
}




