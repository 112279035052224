.button--secondary{
    background-color: var(--color-cyan);
    border-color: var(--color-cyan-dark);
    color: var(--color-white);

    &:hover{
        background-color: var(--color-cyan-dark);
        border-color: var(--color-cyan-xdark); 
        color: var(--color-white);
    }


    &:focus{
        border: 1px solid var(--color-cyan);
        box-shadow: 0 0 0 rems(3) var(--color-cyan-xdark);
        outline: none;
    }
}