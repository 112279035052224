quick-access, organisation-quick-access, owner-quick-access, owner-property-quick-access, supplier-quick-access, .organisation-quick-add {
    &.toggle__target {
        &.open {
            &>.row.row--space-half {
                margin-top: rems(20);
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    quick-access, organisation-quick-access, owner-quick-access, owner-property-quick-access, supplier-quick-access, .organisation-quick-add {
        &.toggle__target {
            display: block;
        }
    }
}
