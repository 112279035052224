cookie-consent-modal {
    .cookie-consent {
        background-color: rgba(30, 30, 30, 0.9);
        min-height: 30px;
        font-size: 16px;
        color: #ccc;
        line-height: 30px;
        padding: 12px 0 12px 30px;
        position: fixed;
        bottom: 5px;
        left: 5px;
        right: 5px;
        visibility: hidden;
        opacity: 0;
        z-index: 9999;
        transition: visibility 0s linear 500ms, opacity 500ms;
        
        &.shown {
            opacity: 1;
            visibility: visible;
        }

        p {
            display: inline-block;
            margin-bottom: 0;
            padding-top: 8px;
        }

        a {
            color: #4B8EE7;
            text-decoration: none;

            &.cookie-button {
                background-color: #CC0A73;
                color: #FFF;
                display: inline-block;
                border-radius: 5px;
                padding: 5px 20px;
                cursor: pointer;
                float: right;
                margin: 0 30px 0 10px;

                &:hover {
                    background-color: #AE0862;
                }
            }
        }
    }
}