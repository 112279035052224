.coho__dark {
    .news__page {
        .news__meta {
            span {
                color: var(--color-grey-xlight);
            }
        }

        .page__content {
            .news__article-summary {
                border-top: 1px solid var(--color-grey);

                .news__article-meta {
                    color: var(--color-grey-xlight);
                }

                h2 {
                    a {
                        color: var(--color-grey-xlight);
                        text-decoration: none;

                        &:hover {
                            color: var(--color-grey-light);
                        }
                    }
                }
            }


            article {

                .guest__credit {

                    color: var(--color-grey-light);
                    border-top: 1px solid var(--color-grey);
                }

                .guest__profile {
                    background-color: var(--color-grey-dark);
                    box-shadow: 0 0.125rem 0.625rem 0 rgba(204, 204, 204, 0.15);

                    color: var(--color-grey-xlight);
                    p {
                        color: var(--color-grey-xlight);
                    }
                }

            }
        }
    }
 }
