.coho__dark {
    .form {
        &.form--payment {
            background: var(--color-green-light);
            h4 {
                color: var(--color-text);
            }
        }
    }

    label.p-FieldLabel.Label {
        color: var(--color-grey-xlight);
    }
    p.TermsText {
        color: var(--color-grey-light);
    }

    .payments__details, .payments__details li {
        border-color: var(--color-grey-border);
    }

    .payments__details-invoice {
        a {
            color: var(--color-grey-xlight);
        }
    }

    .payment__card-holder {
        background: var(--color-grey-dark);

        &.payment__card-holder--warning {
            background-color: var(--color-pink-xdark);

            .payment__card-holder--expiry {
                color: var(--color-pink-light)
            }
        }
    }
}
