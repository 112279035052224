@use "sass:math";
@import "_vars";
@import "_base";
@import "_colours";
@import "range-slider";
@import "cookies";



.form__radio-button, .form__checkbox-button {
  cursor: pointer;
}

.button--right {
  display: inline-block;
  float: right;
}

.message__nav {
  li {
    cursor: pointer;
  }
}

p-autoComplete {
  .ui-autocomplete {
    width: 100%;
  }
}

p.forgotten {
  margin-top: 0.5rem;
  margin-bottom: 0;

  a {
    color: var(--color-grey);
  }
}

a.long-link {
    overflow-wrap: break-word;
    word-break:break-all;
    @include breakpoint (for-large-phone-up) {
        word-break:normal ;
    }
}

input#currentAddress {
  margin-bottom: 0.5rem;
}

.welcome_container {
  margin-bottom: 3.75rem;
}

#address .address_container .form__label {
  margin-bottom: 0;
}

#address .address_container {
  margin-bottom: 1rem;
}

.svg-icon-square {
  width: 30px;
}

.file_editor_container .button__group .button__group-item .svg-icon-square {
  width: 24px !important;
}

.form__button-content {
  .svg-icon-square {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 9rem;
    width: 100%;
  }
}

[hidden] {
  display: none !important;
}

.form--width-override {
  max-width: initial;
}

.sub__nav {
  li {
    &.active-override {

      > a {
        border-bottom: 4px solid var(--color-white);
        font-weight: 700;
        padding: rems(10) rems(20);
      }

      &.active-override {
        > a {
          background-color: var(--color-white);
          border-bottom-color: var(--color-magenta);
        }
      }
    }
  }
}

.landing__left {
  .landing__content {
    h1 {
      span {
        white-space: nowrap;
      }
    }
  }
}

//Override conversation textbox so it remains expanded if it contains any text
.message__action-message {
  &.expanded {
    height: rems(200);
  }
}


//Telephone number labels for the room cards
.card__room-profile p

//fix the view terms link to look like a hyperlink
span.pointer {
  cursor: pointer;
  color: #007ad9;

  &:hover {
    text-decoration: underline;
    color: #116fbf;
  }
}


// Additional text helper classes
.u-text-color--green {
  color: var(--color-green-xdark);
}

.u-text-color--blue {
  color: var(--color-blue);
}

.u-text-color--pink {
  color: var(--color-pink-xdark);
}

//Additional margin class
.u-margin-top--6 {
  margin-top: rems(6);
}


// Changes to landlord page modal header
.modal-header {
  display: flex;
  flex: 1 0 auto;
  margin-bottom: 1.5rem;

  .modal-image-holder {
    display: flex;
    min-width: 4rem;
    width: 4rem;

    .marketing__service-modal-img {
      height: 4rem;
      width: 4rem;
    }
  }

  .modal-heading-holder {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 2rem;
  }
}

.modal-description {
}

.modal-content {
  .marketing__service-badge {
    font-size: 0.925rem;
  }

  h5 + * {
    margin-top: 0.75rem;
  }

  p {
    color: var(--color-grey);
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0 1rem 0 0.25rem;
  }
}

.modal__close {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

// additional styling for management page




// Minor improvements to readability of configure services
.service__preview--outer {
  .service__preview {
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    @include breakpoint(for-tablet-landscape-up) {
      margin-bottom: 2.5rem;
    }

    border-bottom: 1px solid var(--color-grey-light);

    .service__preview-description {
      padding-top: 1rem;
    }

    .service__preview-content > div > p {
      color: var(--color-grey-border);
    }

    .service__preview-content > div.service__preview-content--selected > p {
      color: var(--color-text);
    }
  }
}






// Minor style chnages for tenant registration locations
.filtered-addresses {
  border: 1px solid #E9E9E7;
  border-radius: 5px;
  padding: 0.5rem;
  background: var(--color-grey-xxlight);
  min-height: 10rem;

  .form__tags {
    &:last-child {
      padding-bottom: 0;
    }

    .form__tag {
      border-color: #999999;

      &:hover {
        color: var(--color-black);
        background: #e9e9e9;
      }

      &.form__tag--parent {
        padding-bottom: 0.25rem;
        font-size: 1rem;
        background: transparent;

        &:hover {
          background: transparent;
          color: var(--color-black);
        }
      }

      .form__tag-close {
        color: var(--color-black);

        &:hover {
          color: #063C7F;
        }
      }
    }
  }

  .form__tags:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

// UX fix for tag buttons, make the whole button work not just the symbol
.form__tag .form__tag-close {
  top: 2px;
  width: 100%;
  padding-left: 72%;
}

// Fix for shitty button focus/position in gallery
.gallery .slick-arrow.slick-prev:focus,
.gallery .slick-arrow.slick-next:focus {
  box-shadow: none;
}

.gallery .slick-arrow {
  top: calc(50% + 3rem);
}

// Added a hover state for radio buttons
.form__grid-element {
  .form__radio-button {
    .form__radio-button-label {
      &:hover {
        background-color: var(--color-grey-xxlight);
        border-color: #818181;
      }
    }
  }
}

// Fix long room name
.room__heading-title h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80vw;

  @include breakpoint(for-tablet-portrait-up) {
    max-width: 24rem;
  }

  @include breakpoint(for-small-desktop-up) {
    max-width: 36rem;
  }
}

.accordion.form__options-group {
  .accordion__content {
    padding: 0;
  }
}

// Read-only/Required  radio buttons
.form__radio.read-only {
  color: #959595;

  input[type=radio] {
    border-color: $color__grey--light;

    &:checked::after {
      background-color: var(--color-grey-border);
    }
  }
}

.form__radio.required {
  color: var(--color-pink);

  input[type=radio] {
    border-color: var(--color-pink);

    &:checked::after {
      background-color: var(--color-pink);
    }
  }
}

// Messages placeholder text
.messages {
  p {
    &.placeholder {
      padding: 1rem;
      color: #999999;
    }
  }
}

// Messages message box scroll
.message__action-message {
  overflow: hidden;

  &:focus {
    overflow: auto;
  }
}

// Added participanmts heading
.message__thread-header-participants-heading {
  color: #999999;
  padding: 0.625rem 0 0 0.625rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.message__thread-header-participants {
  padding-top: 0;
}

// remove focus box on message participants
.message__thread-header-participant a:focus,
.message__nav-toggle button:focus {
  outline: none;
}

// Change height of message preview box and position of unread indicator
.message__thread-preview {
  min-height: 0;
}

.message__preview-count {
  top: 0.9375rem;
  left: 0.25rem;
}

@include breakpoint(for-tablet-portrait-up) {
  .message__thread-details {
    min-height: 0;
  }
}

.message__thread-header-icon {
  img, svg {
    width: rems(100);
  }
}

// Messsages - layout changes
.section__header.section__header-messages {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: rems(5);
}

.message__content {
  min-height: 0;
  height: 30rem;
}

.page__section-repairs {
  .message__content {
    height: 27.5rem;
  }
}

.messages {
  min-height: 35rem;
}

.page__section-myhome, .page__section-manage {
  padding-top: 0;
}

// Register - About
.form__container {
  max-width: 40rem;

  .registration-image {
    padding-bottom: rems(20);
    width: 100%;

    img {
      height: auto;
      max-width: 100%;
    }
  }
}



// File uploader required highlight
new-file-uploader .file_input_container {
  &.required {
    border: 0.1875rem dashed var(--color-pink);
    background-color: var(--color-pink-light);
  }
}


//add required border to RTE
.tox.tox-tinymce {
  border-radius: rems(4);
}

.required {
  .tox.tox-tinymce {
    border-color: var(--color-pink);

    .tox-toolbar__primary {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23EB2B67'/%3E%3C/svg%3E") left 0 top 0 var(--color-pink-light);
    }
  }

  .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid var(--color-pink);
  }
}

// Change to organisation of action cards to reduce height, no longer stacking a very large padded image.
#actions {
  .card.card--image-left .card__image-content {
    flex-direction: row;

    .card__image {
      max-width: rems(88);
      margin-left: 0;
      margin-right: 0;
      width: auto;
      padding-left: rems(16);
      margin-bottom: rems(8);
      margin-bottom: rems(8);

      @include breakpoint(for-small-desktop-up) {
        max-width: rems(80);
      }
    }
  }
}

@media (min-width: 72rem) {
  #actions {
    .card.card--image-left .card__image-content {
      .card__image {
        max-width: rems(80);
      }
    }
  }
}

//  Line up tenant panel labels and fields
.profile__contact-details > span {
  display: inline-block;
  width: rems(84);
}

// Small change to notification setting for vertical alignment of descriptions
.accordion.form__options-group {
  .form__grid-element {
    display: flex;
    justify-content: center;
  }
}

//Notification settings display
.accordion.form__options-group {
  .form__group {
    &.readonly {
      display: none;
    }

    .form__group {
      padding: 0;

      &.form__group--inline-aligned-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        @include breakpoint(for-tablet-portrait-up) {
          justify-content: flex-end;
        }

        padding: 0.5rem 0;

        label.form__label.form__label--regular {
          width: auto;
          margin-right: rems(12);
        }
      }

      &.form__group--inline-aligned-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5rem 0;
      }
    }

    .form__grid.form__grid-2--small {
      .form__grid-element {
        flex-basis: 60%;
        &:first-child {
          flex-basis: 40%;
        }
      }
    }

    .form__switch-label {
      text-align: left;
    }

    .form__label {
        white-space: unset;
    }
  }
}

multiline-textbox {
    width: 100%;
    display: flex;
    flex-direction: column;

    .character_count {
        display: flex;
        justify-content: end;
    }
}

form-navigate-away-confirm {
  z-index: 100000;
}



property-details-panel {
    .form {
        max-width: unset;
    }
}

.pointer {
    cursor: pointer;
}

svg-icon.show-password{
    svg {
        height: 30px !important;
        padding-top: 7px;
    }
}

// Annotate on front-end items that are only visible on the dev environment to boost developer confidence in those
// items being displayed.
//debug-info {
    //display: block;
    //margin-top:rems(10);
    //&:first-of-type {
        //margin-top: rems(30);
    ///}
//}

.debug {
  background-color: var(--color-orange-light);
  border: rems(1) solid var(--color-grey-light);
  padding: rems(16);
  //margin-top: rems(20);
  font-size: rems(13);
    margin-bottom: rems(10);
    p {
      font-size: rems(13);
      margin-bottom: rems(8);
      line-height: 1.5;
    }

 }

.requiresStylingReview {
  background-color: var(--color-cyan-light);
  border: dashed rems(1) var(--color-cyan-dark);
  padding: rems(4);
}


// Tooltip styling as per https://popper.js.org/docs/v2/tutorial/
#reusable-tooltip {
  background: #333;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
}

#reusable-tooltip #arrow, #reusable-tooltip #arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#reusable-tooltip #arrow {
  visibility: hidden;
}

#reusable-tooltip #arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#reusable-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#reusable-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#reusable-tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#reusable-tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

// We only want our tooltip to show when hovering or focusing the button.
#reusable-tooltip {
  /* ... */
  display: none;
}

#reusable-tooltip[data-show] {
  display: block;
}


//----------------------------------------------------------------------------------------------------------------------------------
// TO be reworked by jon
// Jon, search for 'financial-items, tenancies-recurring-transactions-card' in the code base to see how I've had to copy the financial items styling
tenancies-recurring-transactions-row {
  // A label style
  span.step {
    border-radius: 0.25rem;
    display: flex;
    flex: 0 0 1rem;
    background-color: var(--color-magenta-xxdark);
    display: inline-block;
    margin: 0;
    width: auto;
    text-transform: uppercase;
    margin: 0 0.25rem 0 0;
    padding: 0.25rem 0.375rem;
    color: var(--color-white);
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.015625rem;
  }
}

tenancies-recurring-transactions-card {
  tr.trtActionNull {
    color: var(--color-black);
  }

  tr.trtActionCreate {
    color: var(--color-green-xdark);
  }

  tr.trtActionUpdate {
    color: var(--color-green-xdark);
  }

  tr.trtActionDelete {
    color: var(--color-magenta-dark);;
  }

  tr.trtActionSkip {
    color: var(--color-grey-light);
    font-style: italic;
  }

  tr.trtActionDirty {
    color: var(--color-magenta-dark);
  }

  tr.exitingRecord {
  }

  tr.newRecord {
    color: var(--color-grey-light);
    font-style: italic;
  }

  tr.unrelatedRecord {
    color: var(--color-grey-light);
    font-style: italic;
  }
}

tenancy-add-recurring-transaction {
  tr.unrelatedRecord {
    color: var(--color-grey-light);
    font-style: italic;
  }
}


/* Putting something here to be correctly filed later */

span.pill {

    background-color: var(--color-grey);
    border: 1px solid var(--color-grey-dark);
    border-top-color: var(--color-grey-dark);
    border-right-color: var(--color-grey-dark);
    border-bottom-color: var(--color-grey-dark);
    border-left-color: var(--color-grey-dark);
    border-radius: .25rem;
    color: var(--color-white);
    padding: .125rem .3125rem;
    font-weight: 700;
    margin-right: .25rem;
    white-space: nowrap;
    font-size: rems(12);
    line-height: rems(12);

    &.green {
        border-color: var(--color-green-xdark);
        background-color: var(--color-green-dark);
        color: var(--color-white);
    }

    &.grey {
        border-color: var(--color-grey-dark);
        background-color: var(--color-grey);
        color: var(--color-white);
    }


}



//----------------------------------------------------------------------------------------------------------------------------------


.qrcodeImage {
    width: max-content;
}

/* Postcode Anywhere */
.pca {
    .pcaautocomplete {
        border: 0 !important;
        .pcalist
        {
            border-top-width: 1px;
            border-top-style: solid;
            border-top-color: var(--color-grey-xlight);
            margin-top: rems(2);
            border-radius: radius(small);
        }
    }
}
.pcaitem {
    font-family: $type__family;
    font-size: rems(14);
}

.rendered {
    ul {
        padding-left: rems(16);
        margin-bottom: rems(8);
        margin-top: rems(8);
    }
}

//Rich text editor
.p-editor-container {
  h1, h2, p, li {
    font-family: $type__family;
  }

  &.required {
      .p-editor-toolbar {
        border-top:var(--color-pink) solid 2px !important;
        border-right: var(--color-pink) solid 2px !important;
        border-left: var(--color-pink) solid 2px !important;
      }
      .p-editor-content {
          border: var(--color-pink) solid 2px !important;
      }
  }
}

property-access-code {
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

    .actions {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
}

.zero__state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  //padding: rems(40) 0;

  p {
    color: var(--color-grey)
  }

  svg {
    fill: var(--color-grey)
  }
}


.history__input-form {
    display: flex;
    flex-direction: row;
    .history__input-form--inputs {
        flex: 1 1 100%;
        textarea {
           // margin-top: rems(1);
            min-height: rems(35);
        }
    }
    .history__input-form--buttons {
        width: rems(181);
        margin-left: rems(10);
        .button--ghost {
            .icon {
                fill: var(--color-teal);
            }
        }
    }
}

@include breakpoint(for-wide-desktop-up) {

}

.unavailabilities_notes {
  font-size: rems(12);
  line-height: 1.2;
}

inventory-template-item-form {
    .form_inline_and_button {
        display: flex;
        gap: 10px;
        align-items: end;
    }
    .button__group {
        width: fit-content !important;
        flex-wrap: nowrap;
    }
}

.flex--column {
    display: flex;
    flex-direction: column;
}

.u-height--500 {
    max-height: 500px
}

.overflow--scroll {
    overflow-y: scroll;
}


.form_title_and_close {  //JON TODO
    display: flex;
    justify-content: space-between;
    margin-bottom: rems(10);
    loader-button {
        margin-top: 0;
        button {
            margin-bottom: 0;
        }
    }
}

.buttons_form_aligned_right {//JON TODO
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.new_application_form_questions_selection {//JON TODO
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    .questions_body {
        flex: 1;
    }
}

.border-color--white {
    border-color: var(--color-white) !important;
}

.u-width--fit-content {
    width: fit-content;
}

.u-flex--1 {
    flex: 1;
}

.u-flex{
    display: flex;
}

.u-gap--10{
    gap: 10px;
}

.u-gap--20{
    gap: 20px;
}

.document-viewer-modal-video {
    max-height: 70vh;
}

.document-viewer-modal-img {
    max-height: 70vh;
    object-fit: contain;
}

.cdk-overlay-container {
    z-index: 1001 !important;
}

.pre-wrap {
  white-space:pre-wrap;
}

.gocardless-container {
    border: rems(1) solid var(--color-grey-border);
    border-radius: radius(medium);
    padding: rems(12) rems(12) 0 rems(12);
}

tbody.max-height--500 {
    overflow: auto;
    max-height: 500px;
}

.checkbox-with-link-clickable-area{
    font-size: rems(14) !important;
    color: var(--color-text) !important;
}

.pagination {
    background: var(--color-grey-xxlight);
    border-radius: radius (small);
    padding: rems(4) rems(8) rems(2) rems(8);
    display: flex;
    align-items: center;
    gap: rems(12);
    p,.button {
        margin-bottom: 0;
    }
}

.u-width--100 {
    width: 100%;
}

.u-opacity--50 {
    opacity: 0.5;
}

missing-rent-charges {
    .compact__table {
        &--narrow {
            tr {
                &.ignored {
                    background-color: var(--color-teal-xlight);
                }
            }
        }
    }
}

