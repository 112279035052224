.button{
    &.button--payment{
        background-color: var(--color-green-dark);
        border-color: var(--color-green-xdark);
        color: var(--color-white);

        &:hover{
            background-color: var(--color-green-xdark);
            border-color: var(--color-green-xdark); 
            color: var(--color-white);
        }
        
        &:focus{
            border: 1px solid var(--color-green);
            box-shadow: 0 0 0 rems(3) var(--color-green-xdark);
            outline: none;
        }
        &:disabled, &.disabled{
            background-color: var(--color-grey-border);
            border-color: var(--color-grey-border);
            color: white;
    
            &:hover{
                background-color: var(--color-grey-border);
                border-color: var(--color-grey-border);
                cursor: default;
            }
    
        }
    }
}

.pricing__container--selected {
    .button{
        &.button--payment{
            background-color: var(--color-green-light);
            border-color: var(--color-grey-light);
            box-shadow: none;
            color: var(--color-green-xdark);
    
            &:hover{
                cursor: default;
            }  
        }
    }
}