.toggle-bar{
    background-color: var(--color-blue);
    border-radius: radius(small);
    display: block;
    margin-top: rems(3);
    position: absolute;
    height: rems(3);
    width: rems(18);
}

.toggle-icon{
    background-color: transparent;
    border: none;
    display: block;
    height: rems(16);
    left: rems(0);
    padding: 0;
    position: absolute;
    width: rems(60);

    &:focus{
        outline: none;

        .toggle-bar{
            //background-color: var(--color-grey-xlight);
        }

    }

}
.toggle-icon{
    .toggle-bar{
        left: 50%;
        top: 50%;
        transition: all 0.25s ease-out;

        &:nth-child(1){
            transform: translateY(rems(-8)) translateX(rems(-12));
        }

        &:nth-child(2){
            transform: translateY(rems(-2)) translateX(rems(-12));
            width: rems(12);
        }

        &:nth-child(3){
            transform: translateY(rems(4)) translateX(rems(-12));
        }
    }

    &.open{
        .toggle-bar{
            &:nth-child(1){
                transform: translateY(rems(-2)) translateX(rems(-12)) rotate(45deg);
            }
    
            &:nth-child(2){
                opacity: 0;
                transform: translateY(rems(-2)) translateX(rems(-12)) scale(0.5);
            }
    
            &:nth-child(3){
                transform: translateY(rems(-2)) translateX(rems(-12)) rotate(-45deg);
            }          
        }
    }
}

.toggle-heading {
    margin-bottom: 0;
    padding-left: rems(30);
}

.toggle__desktop {
    display: none;
}

@include breakpoint(for-tablet-portrait-up){
    .toggle-icon, .toggle-heading.toggle__trigger {
        display: none;
    }
    .toggle-heading {
        padding-left: 0;
        margin-bottom: rems(20);
    }

    .toggle__desktop {
        display: block;
    }

}
