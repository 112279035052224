.magic__page {
  .site__header--logged-in .main__navigation:not([class=open]), .site__header--logged-out .main__navigation:not([class=open]) {
    display: none;
  }
  .main__menu-icon {
    display: none;
  }

  .header__logo {
    margin-left: rems(20);
  }
  
  .footer {
    .col {
      &.not--magic {
        display: none;
      }
    }
  }
}