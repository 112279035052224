.truncate__text{
    --lh: 1.5rem;
    --max-lines: 2;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    position: relative;

    &::after{
        //background-color: white;
        bottom: 0;
        content: "…";
        display: block;
        position: absolute;
        right: 0;
    }

    &.toggle__target{
        display: block;
    }

    &.open{
        display: block;
        max-height: none;

        &::after{
            display: none;
        }

    }

    &.small {
        --lh: 1.2rem;
    }
}

.toggle__trigger:focus {
    outline: 0;
}
