.card{
    background-color: #ffffff;
    box-shadow: 0 rems(2) rems(10) 0 rgba(0,0,0,0.15);
    border-radius: radius(medium);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    text-decoration: none;
    transition: box-shadow 0.2s ease-out;

    &.card--inline{
        height: auto;
    }

    &.pointer {
        cursor: pointer;
    }

}

a.card, .card--wrapper{
    &:hover{
        box-shadow: 0 rems(8) rems(16) 0 rgba(0,0,0,0.15);
        color: var(--color-text);  
    }
}


.card--wrapper {
    a.card--routing {
        &:focus { 
            outline: 0;
        }
    }
}

.card--focus {
    background: var(--color-orange-light);
    background: rgba(var(--color-orange-light-rgb),0.5);
    box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-orange-xxdark-rgb),0.25);
    outline: rems(1) solid rgba(var(--color-orange-dark-rgb),0.5);
    &:focus { 
        outline: rems(3) solid rgba(var(--color-orange-dark-rgb),0.75);
    }
}

.card--routing {
    color: var(--color-text);
    text-decoration: none;
    height: 100%;
    &:hover{
        color: var(--color-text);  
    }
}

.card__intro {
    padding: rems(20);
    padding-bottom: 0;

    .tags__container {
        padding-bottom: 0;
    }

}

.card__content {
    padding: rems(20);
    width: 100%;

    > *:last-child {
        margin-bottom: 0;
    }

    .profile__preview-interests {
        margin-top: 0;
    }

    .text__headline {
        font-weight: 700;
        margin-top: rems(5);
    }

    .text__price {
        margin-top: rems(5);
    }
}

.card__image{
    overflow: hidden;
    position: relative;
    width: 100%;

    img{
        height: auto;
        width: 100%;
    }
}

.card__image-caption{
    background-color: rgba(var(--color-black-rgb),0.9);
    border-bottom-left-radius: radius(small);
    color: white;
    font-size: rems(14);
    left: rems(20);
    line-height: rems(18);
    padding: rems(4) rems(10);
    position: absolute;
    bottom: rems(30);
    z-index: 900;

    &--highlight {
        background-color: rgba(var(--color-teal-xdark-rgb),0.9);
    }
}


.card__header {
    border-bottom: 1px solid var(--color-grey-xlight);
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    padding: rems(10) rems(15) rems(10) rems(20);
    width: 100%;

    &.card__header--action {
        h4 {
            font-size: rems(18);
            line-height: rems(22);
        }
    }

    .card__header-content {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    &.card__header--status {
        display: flex;
        flex-direction: row;

        h3, h4, h5 {
            overflow: hidden;
        }

        .card__header--status-holder {
            display: flex;
            flex-direction: row;
        }

        .status-badge {
            display: block;
            height: rems(24);
            margin-top: 0;
            margin-left: rems(8);

            &:first-of-type {
                margin-left: auto;
            }

            &.status-none {
                border-color: var(--color-grey-xdark);
                background-color: var(--color-grey-dark);
                color: var(--color-white);
            }

            &.status-low {
                border-color: var(--color-green-xdark);
                background-color: var(--color-green-dark);
                color: var(--color-white);
            }

            &.status-high {
                border-color: var(--color-pink-xdark);
                background-color: var(--color-pink-dark);
                color: var(--color-white);
            }

            &.status-medium {
                border-color: var(--color-orange-xdark);
                background-color: var(--color-orange-dark);
                color: var(--color-white);
            }
        }
    }

    &.card__header--actions {
        //display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        h4 {
            overflow: hidden;
        }

        h4 + * {
            margin-top: 0;
        }

        .card__action-items {
            padding: 0;
            padding-right: rems(4);
            margin-top: 0;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
}

a.card__header{
    align-items: flex-start;
    color: var(--color-blue);
    display: flex;
    flex-direction: column;
    font-weight: 500;
    padding: rems(10) rems(40) rems(10) rems(20);
    position: relative;
    text-decoration: none;
    transition: background-color 0.2s ease-out;

    &:hover{
        background-color: var(--color-grey-xxlight);
        color: var(--color-blue);
        text-decoration: none;
    }

    .icon{
        fill: var(--color-blue);
        margin-right: rems(10);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: rems(24);
    }

}

.card__actions{
    margin-top: auto;
    padding: rems(0) rems(20) rems(10) rems(20);
    width: 100%;
}

.card__badge{
    background-color: rgba(var(--color-pink-rgb),0.9);
    border-radius: radius(small);
    box-shadow: 0 rems(2) 0 rems(0) rgba(0,0,0,0.5);
    color: white;
    font-size: rems(14);
    left: rems(10);
    line-height: rems(18);
    padding: rems(4) rems(10);
    position: absolute;
    top: rems(10);
    z-index: 900;
}

.card{
    &.card--image-left{
        align-content: flex-start;

        .card__image{
            padding-top: rems(20);
            max-width: rems(104);
            margin-left: 0;
            margin-right: 0;
            margin-bottom: rems(8);
            padding-left: rems(16);
            img {
                height: auto;
                width: 100%;
            }
        }

        .card__image-content{
            display: flex;
            flex: 1 0 auto;
            flex-direction: row;
        }
    }
}

.card {
    &.card__access {
        &.card--image-left {
            .card__image-content {
                //position: relative;

                .card__image {
                    padding-top: rems(8);
                    max-width: rems(40);
                    margin-bottom: 0;
                    padding-left: rems(8);

                    img {
                        height: auto;
                        width: 100%;
                    }
                }

                .card__content {
                    padding: 0;
                    padding-left: rems(8);
                    display: flex;
                    align-items: center;
                    font-weight: 800;

                    .badge {
                        position: absolute;
                        right: rems(8);
                        top: 50%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
.card {
    &.card__action {
        &.card--image-left {
            .card__image-content {
                .card__content {
                    p {
                        font-size: rems(14);
                    }
                }
            }
        }
    }
}
.card{
    &.card__source {
        &.card--image-left{
            .card__image-content{
                .card__image{
                    padding-top: rems(8);
                    max-width: rems(40);
                    margin-bottom: 0;
                    padding-left: rems(8);
                    img {
                        height: auto;
                        width: 100%;
                    }
                }
                .card__content {
                    padding: 0;
                    padding-left: rems(8);
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                }
            }
        }
    }
}


compliancy-item {
    .card {
        .file_documents_container {
            margin-bottom: rems(4);
            .file_document_group, .cdk-drag-preview .file_document_group {
                padding: rems(8);
            }
        }
    }
}

a.appstore-icon {
    text-decoration: none;
    img {
        width: rems(163);
        margin-right: rems(8);
    }
    &:last-child {
        img {
            margin-right: 0;
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .card--adjusted {
        height: auto;
        min-height: calc(100% - 1.9rem);

        &.tabs {
            min-height: calc(100% - 2.7rem)
        }
    }

    .card--auto {
        height: auto;
        min-height: auto;
    }

    .card__header {
        &.card__header--status {
            h3, h4, h5 {
                overflow: hidden;
            }
        }
    }
}




@include breakpoint(for-tablet-landscape-up) {

    .card {
        &.card--image-left {
            flex-direction: column;
            flex-wrap: wrap;

            .card__image-content {
                flex-direction: row;
            }

            .card__image {
                margin-left: 0;
                margin-right: auto;
                padding-left: rems(20);
                width: rems(140);
            }

            .card__actions {
                //margin-left: 6.75rem;
                //width: calc(100% - 6.75rem);
            }
        }
    }

    a.appstore-icon {
     
        &:last-of-type {
            margin-left: rems(8);
        }
    }
}

