

@include breakpoint(for-tablet-portrait-up) { 
    .compact__table {
        &.compact__table--narrow {
            tr {
                &.property-separator {
                    border-top: 0;
                    padding-top: rems(20);
                    font-weight: 700;
                }
            }
        }
    }
}