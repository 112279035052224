.coho__dark {
    .profile__team {
        border-color: var(--color-grey-border);

        .profile__team-actions {
            a {
                color: var(--color-grey-xlight);
            }
        }
    }

    .profile__image {
        background: var(--color-grey-xlight);
        border-color: var(--color-grey-xlight);
    }


    .profile__preview--card .profile__status, .profile__preview--room .profile__status {
        border-color: var(--color-grey-xlight);
    }
}
