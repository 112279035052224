

.timeline {
    list-style: none;
    padding-left: 0;

    li {
        display: flex;
        flex-direction: row;
        padding-bottom: rems(16);
        position: relative;
        font-size: rems(16);
        .icon{
            margin-right: rems(40);
            margin-top: rems(-2);
            width: rems(24);
        }


        &::before{
            background-color: white;
            border: 2px solid var(--color-grey);
            border-radius: 100%;
            content: "";
            display: block;
            height: rems(12);
            left: rems(40);
            position: absolute;
            top: rems(6);
            width: rems(12);
            z-index: 10;
        }

        &::after{
            border-right: 2px solid var(--color-grey);
            bottom: rems(-12);
            content: "";
            display: block;
            left: rems(45);
            position: absolute;
            top: rems(12);
        }

        &:last-child{
            &::after{
                display: none;
            }
        }

    }

}

history-item.timeline {
    display: block;
    list-style: none;
    padding-left: 0;

    .history__item{
        display: flex;
        flex-direction: row;
        //padding-bottom: rems(16);
        position: relative;
        font-size: rems(16);
        padding-left: rems(20);
        font-size: rems(14);
        margin-top: rems(9);

        &.history__item--log {
            display: block;
            font-size: rems(14);
            line-height: 1.3;
            padding-bottom: rems(4);

            .history__item--log-icons {
                display: inline-block;
                padding-right: rems(4);
                span {
                    &.category-icon {
                        padding-right: rems(22);
                    }
                }
            }
        }

        .icon{
            margin-right: rems(40);
            margin-top: rems(-2);
            width: rems(24);
        }


        &::before{
            background-color: white;
            border: 2px solid var(--color-grey);
            border-radius: 100%;
            content: "";
            display: block;
            height: rems(12);
            left: 0;
            position: absolute;
            top: rems(6);
            width: rems(12);
            z-index: 10;
        }

        &::after{
            border-right: 2px solid var(--color-grey);
            bottom: rems(-12);
            content: "";
            display: block;
            left: rems(5);
            position: absolute;
            top: rems(12);
        }

        &.history__item--log {
            &::before{
                top: rems(3);
            }
        }

        &.history__item--note {
            display: block;
            padding-top: rems(4);
            padding-bottom: rems(4);
            .history__item--note-container {
                background: var(--color-teal-xlight);
                border: 1px solid var(--color-teal-light);
                border-radius: radius(small);
                padding: rems(2) rems(4);
                p {
                    line-height: 1.3;
                    margin-bottom: 0;
                    &.action {
                        font-size: rems(14);
                    }
                }
            }
            &.note__contextual {
                .history__item--note-container {
                    background: var(--color-grey-xxlight);
                    border: 1px solid var(--color-grey-xlight);
                }
            }
        }

    }
    &:last-of-type {
        .history__item {
            &::after{
                display: none;
            }
        }
    }

}

@include breakpoint(for-tablet-portrait-up) {

    history-item.timeline {
        .history__item{
            margin-top: 0;
            &.history__item--log {
                .history__item--log-icons {
                    vertical-align: middle;

                }
            }


        }

    }

}

@include breakpoint(for-tablet-landscape-up) {

    .card__content--right {
        .tab-pane {
            history-item.timeline {

                .history__item{
                    padding-left: 0;
                    &::before{
                        display: none;
                    }

                    &::after{
                        display: none;
                    }

                }
            }
        }
    }

}

//DARK MODE
.coho__dark {
    .history__item{
        &.history__item--note {
            .history__item--note-container {
                background: var(--color-teal-xlight);
                border: 1px solid var(--color-teal-light);
            }
        }
        &.note__contextual {
            .history__item--note-container {
                background: var(--color-grey-dark) !important;
                border: 1px solid var(--color-grey) !important;
            }
        }
    }

}
