@page {
    size: A4;
}
.performance-box {
    font-weight: 400;
    margin: auto;
    padding: rems(20);
    font-size: rems(14);
    line-height: rems(24);
    font-family: "Mulish", "Muli", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
    color: var(--color-black);
    background-color: var(--color-white);
    line-height: 1.4;
    max-width: 800px;
    min-width: 600px;
}

@include breakpoint(for-tablet-landscape-up) {
    .performance-box {
        padding: rems(30);
    }
}

@media (min-width: 800px) {
    .performance__wrapper {
        background: var(--color-grey-xlight);
        height: 97vh;
        padding-top: 1.5rem;

        .performance-box {
            background: white;
            min-height: 96vh;
            padding: rems(30) rems(30);
            -webkit-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            -moz-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
        }
    }
}

@media print {
    .performance__wrapper {
        background: none;
        padding-top: 0;

        .performance-box {
            background: none;
            min-height: 0;
            padding: 0 rems(10) 0 rems(10);
            box-shadow: none;
        }
    }
}
