.marketing__price-range{
    background-color: white;
    border: rems(1) solid var(--color-grey-xlight);
    border-radius: radius(large);
    box-shadow: 0 rems(4) rems(12) rems(-4) rgba(0,0,0,0.25);
    padding: rems(20);
}

.marketing__price-range-output{
    position: relative;
}

.marketing__price-range-disclaimer{
    height: rems(96);
    position: relative;
    width: 100%;
    bottom: 1rem;
    left: 2rem;    
}

.marketing__price-range-disclaimer-text{
    bottom: 0;
    color: var(--color-teal-dark);
    font-size: rems(14);
    position: absolute;
    left: rems(80);
}

.marketing__price-range-disclaimer-arrow{
    left:0;
    position: absolute;
    top: rems(30);
    transform: scaleX(-1) rotate(120deg);
}

@include breakpoint(for-tablet-portrait-up){
    .marketing__price-range-disclaimer{
        height: rems(72);
        position: relative;
        width: 100%;
        left: -6rem;
    }
    
    .marketing__price-range-disclaimer-text{
        bottom: 0;
        color: var(--color-teal-dark);
        font-size: rems(14);
        position: absolute;
        left: rems(200);
    }
    
    .marketing__price-range-disclaimer-arrow{
        left:rems(240);
        position: absolute;
        top: rems(0);
        transform: scaleX(1) rotate(40deg);
    }
}

@include breakpoint(for-tablet-landscape-up){
    .marketing__price-range-disclaimer{
        bottom: rems(32);
        left: 18rem;
        height: rems(96);
        position: absolute;
        width: rems(300);
    }
    
    .marketing__price-range-disclaimer-text{
        bottom: auto;
        color: var(--color-teal-dark);
        font-size: rems(14);
        position: absolute;
        left: rems(50);
        right: 0;
        top: 0;
    }
    
    .marketing__price-range-disclaimer-arrow{
        bottom: rems(20);
        left: rems(-10);
        position: absolute;
        top: auto;
        transform: scaleY(-1) rotate(40deg);
    }
}
