toast {
    .p-toast {
        font-family: $type__family;
        opacity: 0.95;
        .p-toast-message {
            white-space: pre-line;

            .p-toast-message-content {
                h4 {
                    margin-top: rems(8);
                }
                h4 + p {
                    margin-top: rems(4);
                }
                .p-toast-message-icon {
                    font-size: rems(24);
                    margin-top: rems(2);
                }
                .p-toast-message-text {
                    .p-toast-summary {
                        font-size: rems(18);
                    }
                    margin-left: rems(12);
                }
            }
            &.p-toast-message-success {
                background: var(--color-green-light);
                color: var(--color-green-xxdark);
                .p-toast-message-icon {
                    color: var(--color-green-xxdark);

                }
                .p-button {
                    background-color: var(--color-green-xxdark);
                    border: 1px solid var(--color-green-xxdark);
                }
            }
            &.p-toast-message-error {
                background: var(--color-pink-light);
                color: var(--color-pink-xdark);
                .p-toast-message-icon {
                    color: var(--color-pink-xdark);
                }
                .p-button {
                    background-color: var(--color-pink-xdark);
                    border: 1px solid var(--color-pink-xdark);
                }
            }
            &.p-toast-message-info {
                background: var(--color-cyan-light);
                color: var(--color-cyan-xdark);
                .p-toast-message-icon {
                    color: var(--color-cyan-xdark);
                }
                .p-button {
                    background-color: var(--color-cyan-xdark);
                    border: 1px solid var(--color-cyan-xdark);
                }
            }
            &.p-toast-message-warn {
                background: var(--color-orange-light);
                color: var(--color-orange-xxdark);
                .p-toast-message-icon {
                    color: var(--color-orange-xxdark);
                }
                .p-button {
                    background-color: var(--color-orange-xxdark);
                    border: 1px solid var(--color-orange-xxdark);
                }
            }
        }
    }
}
