.card {
    &.card__mega {
        &.card--image-left {
            .card__image-content {
                //position: relative;

                .card__image {
                    padding-top: rems(8);
                    max-width: rems(60);
                    margin-bottom: 0;
                    padding-left: rems(8);
                    padding-bottom: rems(2);

                    .icon, svg {
                        width: rems(36);
                        height: rems(36);
                    }
                }

                .card__content {
                    padding: rems(8);
                    padding-top: rems(16);
                    padding-bottom: rems(16);

                    h5 {
                        font-size: rems(16);
                    }

                    h5 + p {
                        margin-top: rems(4);
                    }

                    p {
                        font-size: rems(14);
                        line-height: 1.3;
                    }
                }
            }
        }
    }
}

.page__section--highlight {
    .card {
        &.card__mega {
            background-color: var(--color-teal-dark);
            .card__image {
                svg,.icon {
                    width: rems(36);
                    height: rems(36);
                }
            }
            .card__content {
                p {
                    font-weight: 700;
                    color: var(--color-white);
                    a {
                        color: var(--color-white);
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .card {
        &.card__mega {
            &.card--image-left {
                .card__image-content {
                    .card__image {
                        padding-top: rems(16);
                        padding-left: rems(16);
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
