//NOTHING RELATED TO THE MODAL COMPONENT
//EG IN owner-action-card.component.html
//THIS IS SIMILAR TO THE INACTIVE STUFF, IT'S A WRAPPER WITH A SPECIFIC STYLING

.snooze__modal-wrapper{
    position: relative;
}

.snooze__modal{
    background-color: rgba(var(--color-white-rgb), 0.95);
    @include shadow__low;
    //border: rems(1) solid var(--color-grey-light);
    border-radius: radius(medium);
    bottom: rems(0);
    display: flex;
    flex-direction: column;
    left: rems(0);
    overflow: auto;
    padding: rems(0);
    //margin: 0 rems(10);
    position: absolute;
    right: rems(0);
    top: rems(0);
}

.snooze__modal-content{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: rems(600);
    text-align: center;
}
