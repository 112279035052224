.button__options{
    position: relative;

    .button__options-toggle{
        &::after{
            border-left: rems(6) solid transparent;
            border-right: rems(6) solid transparent;
            border-top: rems(6) solid var(--color-grey);
            content: "";
            display: inline-block;
            height: 0;
            vertical-align: middle;
            width: 0;
        }
    }

    .button__menu{
        bottom: rems(64);
        display: none;
        left: rems(0);
        position: absolute;

        &:after{
            background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='10px' viewBox='0 0 20 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' fill-rule='nonzero'><path d='M20,-1.24344979e-14 L11.4142136,8.58578644 C10.633165,9.36683502 9.36683502,9.36683502 8.58578644,8.58578644 L0,-1.24344979e-14 L20,-1.24344979e-14 Z' id='Path' fill='%23CCCCCC'></path><path d='M18.509,1.0658141e-14 L10.7071068,7.87867966 C10.3466228,8.23916362 9.77939176,8.26689315 9.38710056,7.96186826 L9.29289322,7.87867966 L1.49,1.0658141e-14 L18.509,1.0658141e-14 Z' id='Path' fill='%23FFFFFF'></path></g></g></svg>");
            bottom: rems(-10);
            content: "";
            display: block;
            height: rems(10);
            left: rems(16);
            position: absolute;
            width: rems(20);
        }

    }

    &.open{
        .button__menu{
            display: flex;
        }
    }

    &.left{
        .button__menu{
            left: -10px;

            &::after{
                left: rems(16);
            }
        }

    }

    &.right{
        .button__menu{
            left: auto;
            right: rems(0);

            &::after{
                left: auto;
                right: rems(16);
            }
        }
    }

    &.center{
        .button__menu{
            left: 50%;
            transform: translateX(-50%);

            &::after{
                left: 50%;
                margin-left: rems(-10);
                right: auto;
            }
        }
    }

    &.down {
        .button__menu {
            top: 48px;
            bottom: unset;

            &:after {
                top: -10px;
                bottom: unset;
                transform: rotate(180deg);
            }
        }
    }
}


.button__menu-item{
    color: var(--color-text);
    padding: rems(10);
    text-decoration: none;

    &:hover{
        background-color: var(--color-grey-xxlight);
        color: var(--color-blue);
    }
}

.button__menu{
    background-color: var(--color-white);
    border: rems(1) solid var(--color-grey-light);
    border-radius: radius(medium);
    @include shadow__high;
    display: flex;
    flex-direction: column;
    min-width: rems(200);
    z-index: 999;

    .button__menu-item{
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        img {
            height: rems(25);
            width: rems(25);
            border-radius: 50%;
            object-fit: cover;
        }


        &:first-child{
            border-top-left-radius: radius(medium);
            border-top-right-radius: radius(medium);
        }

        &:last-child{
            border-bottom-left-radius: radius(medium);
            border-bottom-right-radius: radius(medium);
        }
    }
}

// Fixes for the action workflow panes
.section__tabs .tab-container .tab-panes .tab-pane .button__menu .button__menu-item img { width: rems(25); }
.section__tabs .tab-container .tab-panes .tab-pane .button__menu {

    bottom: unset;
    left: unset;
    top: rems(8);
    right: rems(0);
    &.left{
        .button__menu{
            left: auto;
            right: rems(0);

            &::after{
                left: auto;
                right: rems(16);
            }
        }
    }
    &:after{
        background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='10px' viewBox='0 0 20 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' fill-rule='nonzero'><path d='M20,-1.24344979e-14 L11.4142136,8.58578644 C10.633165,9.36683502 9.36683502,9.36683502 8.58578644,8.58578644 L0,-1.24344979e-14 L20,-1.24344979e-14 Z' id='Path' fill='%23CCCCCC'></path><path d='M18.509,1.0658141e-14 L10.7071068,7.87867966 C10.3466228,8.23916362 9.77939176,8.26689315 9.38710056,7.96186826 L9.29289322,7.87867966 L1.49,1.0658141e-14 L18.509,1.0658141e-14 Z' id='Path' fill='%23FFFFFF'></path></g></g></svg>");
        bottom: unset;
        top: rems(-10);
        content: "";
        display: block;
        height: rems(10);
        left: unset;
        right: rems(16);
        position: absolute;
        width: rems(20);
        transform: rotate(180deg);
    }

}
assignment-selector {
    .button__menu-item {
        padding: rems(6) rems(10);
    }
}



