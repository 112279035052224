.photo-gallery{
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: rems(10);
    margin-bottom: 0;
    padding: 0;
    gap: rems(10);
    align-items: flex-start;

    li{
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: rems(100);
      height: rems(100);
      border-radius: radius(medium);
      transition: transform 0.3s ease-in-out;
      position: relative;
      z-index: 1;

      img{
          display: block;
          height: 100%;
          width: 100%;
          border-radius: radius(medium);
          object-fit: cover
      }

      &:hover {
        transform: scale(1.05);
        z-index: 2;
      }
    }

    li.more {
      cursor: pointer;
      font-size: rems(20);
      color: var(--color-grey-light);
      background-color: var(--color-grey-xxlight);

      &:hover {
        transform: none;
      }
    }
}
