@page {
    size: A4;
}
.invoice-box {
    //max-width: 800px;
    margin: auto;
    padding: rems(20);
    //border: 1px solid #eee;
    //box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: rems(14);
    line-height: rems(24);
    font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
    color: var(--color-black);
    background-color: var(--color-white);
    line-height: 1.4;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: rems(5);
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2),
.invoice-box table tr td:nth-child(3),
.invoice-box table tr td:nth-child(4) {
    text-align: right;
}

.invoice-box table tr.top td {
    font-size: rems(12);
}

.invoice-box table tr.top td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.footer table td {
    font-size: 11px;
    line-height: 1.3;
}

.invoice-box table tr.heading td {
    border-bottom: 1px solid var(--color-grey);
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid var(--color-grey-light);
}

.invoice-box table tr.item td span {
    font-size: rems(13);
    line-height: 1.2;
}

.invoice-box table tr.item.last td {
    //border-bottom: none;
}

.invoice-box table tr.gap td {
    height: rems(20);
}
.invoice-box table tr.gap.big-gap td {
    height: rems(40);
}


.invoice-box table tr.subtotal td:nth-child(3), 
.invoice-box table tr.subtotal td:nth-child(4) {
    border-top: 1px solid var(--color-grey-light);
    border-bottom: 1px solid var(--color-grey-light);
}

.invoice-box table tr.vattotal td:nth-child(4) {
}


.invoice-box table tr.total td:nth-child(3),
.invoice-box table tr.total td:nth-child(4) {
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    font-weight: bold;
    //margin-bottom: 40px;
}
.invoice-box table tr.payment-footer {
    td {
        background: var(--color-grey-xxlight);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(small);
        padding: rems(5) rems(10);
    }
}
.invoice-box .footer {
    //position: fixed;
    bottom: 0;
    background-color: var(--color-white);
    color: var(--color-black);
    display: flex;
    font-size: rems(12);
    line-height: 1.4;
    //width: 93%;

    .footer-left {
    }

    .footer-right {
        flex: 1 1 auto;
        align-items: flex-end;
        text-align: right;
        //padding-right: rems(20);
    }
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box .footer {
        position: relative;
        width: 100%;

        .footer-right {
            padding-right: 0;
        }
    }


}

/** RTL **/
.rtl {
    direction: rtl;
    font-family: "Mulish", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
}

.rtl table {
    text-align: right;
}

.rtl table tr td:nth-child(2) {
    text-align: left;
}

/** Paid/Overdue graphics **/

.invoice-box {
    position: relative;

    .paid {
        position: absolute;
        right: rems(30);
        top: rems(120);

        img {
            width: 100%;
            max-width: rems(120);
        }
    }

    .overdue {
        position: absolute;
        right: rems(30);
        top: rems(120);

        img {
            width: 100%;
            max-width: rems(120);
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .invoice-box {
        font-size: rems(16);
        padding: rems(30);

        .paid {
            right: rems(30);
            top: rems(115);

            img {
                max-width: rems(200);
            }
        }

        .overdue {
            right: rems(30);
            top: rems(100);

            img {
                max-width: rems(240);
            }
        }

        .footer {

            .footer-right {
                //padding-right: rems(30);
            }
        }
    }

    .invoice-box table tr.top td {
        font-size: rems(16);
    }
}
