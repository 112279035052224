@include breakpoint (for-large-phone-landscape-up) {
    h1,h2,h3,h4 {
        span {
        white-space: nowrap;
        }
    }
}
@include breakpoint (for-tablet-landscape-up) {
    .landing__page {
        h1, .heading--1 {
            font-size: rems(48);
            line-height: 1.1;
        }

        h2 {
            font-size: rems(36);
            line-height: 1.2;
        }

        .u-text--large {
            font-size: rems(20);
        }

        .page__section {
            li  {
                font-size: rems(18);
            }
        
            p {
                font-size: rems(18);
                &.u-text--small {
                    font-size: rems(14);
                }
            }
        }
    }
}


