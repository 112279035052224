.date-selector {
    background: var(--color-white);
    border: rems(1) solid var(--color-grey-light);
    border-radius: radius(medium);
    margin-bottom: rems(4);
    padding: rems(2) rems(10);
    width: 100%;
    transition: box-shadow 0.2s ease-out;
    max-height: rems(42);

    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='12px' viewBox='0 0 20 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='M16.5857864,0.585786438 C17.366835,-0.195262146 18.633165,-0.195262146 19.4142136,0.585786438 C20.1952621,1.36683502 20.1952621,2.63316498 19.4142136,3.41421356 L11.4142136,11.4142136 C10.633165,12.1952621 9.36683502,12.1952621 8.58578644,11.4142136 L0.585786438,3.41421356 C-0.195262146,2.63316498 -0.195262146,1.36683502 0.585786438,0.585786438 C1.36683502,-0.195262146 2.63316498,-0.195262146 3.41421356,0.585786438 L10,7.17157288 L16.5857864,0.585786438 Z' id='Path'></path></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: rems(16);

    position: relative;

    p {
        margin-bottom: 0;
        line-height: 1.3;
        &.date-selector__range {
            font-weight: 600;
        }
        &.date-selector__dates {
            font-size: rems(12);
            color: var(--color-grey-border);
        }
    }
    &:hover {
        cursor: pointer;
        border: rems(1) solid var(--color-form);
        box-shadow: 0 0 0 rems(1) rgba(var(--color-form-rgb),0.25);
        outline: none;
    }

    &__options {
        position: absolute;
        background: var(--color-grey-xlight);
        border: rems(1) solid var(--color-grey-light);
        border-radius: radius(medium);
        visibility: hidden;
        z-index: 1;
        padding: rems(4);

        .date-selector__option {
            background: var(--color-white);
            border-radius: radius(medium);
            width: 100%;
            margin-bottom: rems(4);
            padding: rems(2) rems(10);
            transition: box-shadow 0.2s ease-out;
            min-height: rems(42);
            max-height: rems(42);
            &:last-of-type {
                margin-bottom: 0;
            }
            &--custom {
                display: flex;
                align-items: center;
                
                .date-selector__option--custom-dates {
                    visibility: hidden;
                }
                &.open {
                    max-height: fit-content;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: rems(4);
                    &:hover {
                        background: var(--color-white);
                        outline: none;
                        box-shadow: none;
                    }
                    .date-selector__option--custom-dates {
                        padding: rems(4) 0 rems(8) 0;
                        .form__label {
                            margin-bottom: rems(2);
                        }
                    }
                }
            }
            &:hover {
                background: var(--color-form-light);
                outline: rems(1) solid var(--color-form);
                box-shadow: 0 0 0 rems(1) rgba(var(--color-form-rgb),0.25);
                p {
                    &.date-selector__dates {
                        color: var(--color-grey);
                    }
                }
            }
            &.selected {
                background: var(--color-form-dark);
                color: var(--color-white);
                p {
                    &.date-selector__dates {
                        color: var(--color-grey-xlight);
                    }
                }
            }

        }

        &.open {
            visibility: visible;
            .date-selector__option {
                &--custom {
                    &.open {
                        .date-selector__option--custom-dates {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }


}

@include breakpoint(for-tablet-landscape-up) {
    .date-selector {
        &__options {
            top: rems(44);
            left: rems(-1);
            width: calc(100% + 1px);
        }
    }
}