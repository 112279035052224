.coho__rating{
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    color: var(--color-grey);
    display: inline-block;
    font-size: rems(14);
    //margin-bottom: rems(10);
    margin-top: 0;
    //padding: rems(2);
    text-transform: uppercase;
    vertical-align: middle;
    width: auto;
}


.coho__rating-icon{
    display: inline-block;
    height: rems(18);
    margin-left: rems(4);
    margin-top: rems(-4);
    vertical-align: middle;
    width: rems(18);

    .icon{
        display: block;
        fill: var(--color-rating-bronze);
        height: rems(18);
        width: rems(18);

        svg{
            height: rems(18);
            width: rems(18);
        }
    }
}

.coho__rating-text{
    display: inline-block;
    //height: rems(24);
    margin-left: rems(3);
    margin-right: rems(5);
    vertical-align: middle;
    font-size: rems(12);
    line-height: 1.4;
    padding-bottom: rems(3);
}

.coho__rating{
    &.coho__rating--bronze{
        .coho__rating-icon{
            .icon{ 
                fill: var(--color-rating-bronze);
            }
        }
    }

    &.coho__rating--silver{
        .coho__rating-icon{
            .icon{ 
                fill: var(--color-rating-silver);
            }
        }
    }
    
    &.coho__rating--gold{
        .coho__rating-icon{
            .icon{ 
                fill: var(--color-rating-gold);
            }
        }
    }    
}
