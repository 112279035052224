@keyframes loading-button{
    0%{
        transform: scale(0.2);
    }

    50%{
        opacity: 1;
        transform: scale(1);
    }

    100%{
        transform: scale(0.2);
    }
}

@keyframes loading-button-2{
    0%{
        transform: scale(0.5);
    }

    50%{
        opacity: 1;
        transform: scale(1);
    }

    100%{
        transform: scale(0.5);
    }
}
loader-button {
    position: relative;

    .button--loading{
        padding-left: rems(30);
        position: relative;

        &::after{
            animation: 1s ease-in infinite forwards loading-button;
            background-color: rgba(var(--color-white-rgb), 0.75);
            border-radius: 100%;
            content: "";
            display: block;
            height: rems(10);
            left: rems(15);
            margin-top: rems(-5);
            opacity: 0;
            position: absolute;
            top: 50%;
            width: rems(10);
        }

        &::before{
            animation: 1s ease-in infinite forwards loading-button-2;
            background-color: rgba(var(--color-white-rgb), 0.25);
            // border: 2px solid white;
            border-radius: 100%;
            content: "";
            display: block;
            height: rems(20);
            left: rems(10);
            margin-top: rems(-10);
            opacity: 0;
            position: absolute;
            top: 50%;
            width: rems(20);
        }

        &.button--small {
            padding-left: rems(30) !important;
            &::before{
                left: rems(5);
            }
            &::after{
                left: rems(10);
            }
        }

        &.button--icon-only::before {
            left: rems(6);
        }
        &.button--icon-only::after {
            left: rems(11);
        }

        &.button--transparent-full::before, &.button--transparent::before, &.button--ghost::before {
            background-color: rgba(var(--color-text-rgb), 0.25);
        }

        &.button--transparent-full::after, &.button--transparent::after, &.button--ghost::after {
            background-color: rgba(var(--color-text-rgb), 0.75);
        }
    }

    //HOVER NAME
    .hover_name {
        display: none;
    }

    &:hover {
        .hover_name {
            display: block;
            position: absolute;
            z-index: 980;
            color: var(--color-white);
            background: rgba(var(--color-text-rgb), 0.75);
            border-radius: radius(small);
            padding: rems(4);
            width: max-content;

            p {
                font-size: 11px;
                line-height: 1rem;
                margin-bottom: 0;
            }
        }
    }

    // The expander menu of this button

    .menu {
        display: block;
        position: absolute;
        z-index: 980;
        min-width: rems(160);
        background: var(--color-white);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    }

    .menu-options {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .menu-options li {
        display: flex;
        align-items: center;
        gap: rems(8);
        padding: rems(8) rems(12);
        cursor: pointer;
        font-size: rems(14);
        border-bottom: 1px solid var(--color-grey-light);

        svg-icon {
          width: 20px;
          height: 20px;
          svg {
            fill: var(--color-grey);
            width: 20px;
            height: 20px
          }
        }

    }

    .menu-options li:last-child {
        border-bottom: none !important;
    }

    .menu-options li:hover {
        background-color: var(--color-grey-xxlight);
    }

    /* Menu position styles */
    .menu--down {
        top: calc(100% + 3px); /* Align the top of the menu with the bottom of the button */
        right: 3px; /* Align the right of the menu with the right of the button */
    }

    .menu--up {
        bottom: calc(100% - 3px); /* Align the bottom of the menu with the top of the button */
        right: 3px; /* Align the right of the menu with the right of the button */
    }

    .menu--right {
        top: 3px; /* Align the top of the menu with the top of the button */
        left: calc(100% + 3px); /* Align the left of the menu with the right of the button */
    }

    .menu--left {
        top: 3px; /* Align the top of the menu with the top of the button */
        right: calc(100% - 3px); /* Align the right of the menu with the left of the button */
    }
}


