body{
    background-color: var(--color-blue);
    min-height: 100%; 
}
  
html{
    height: 100%;
}
  
body, html{
    margin: 0;
    padding: 0;
    position: relative;
    scroll-behavior: smooth;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
  
.sr-only{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

*:focus{
    outline: rems(3) solid var(--color-magenta);
    outline-offset: rems(2);
}

.nofocus {
    outline: 0;
}

app-root{
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}


// Light mode tweaks for iOS status bar

.site__content {
    background-color: var(--color-white);
}






