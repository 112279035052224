pagination {
    .p-component,.p-link,.p-inputtext {
        font-family: $type__family;
        &:focus {
            box-shadow: none;
        }
    }

    .p-paginator {
        margin-top: rems(8);
        background: var(--color-grey-xlight);
        color: var(--color-black);
        border-color: var(--color-grey-border);
        border-radius: radius(small);
        padding: rems(2) 0;
        .p-paginator-first {
            height: rems(32);
        }
        .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, 
        .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,  
        .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, 
        .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
            background: var(--color-grey-light);
            color: var(--color-black);
        }

        .p-dropdown { 
            &:not(.p-disabled){
                &.p-focus {
                    border-color: var(--color-grey-border);
                    box-shadow: none;
                }
            }

            height: rems(30);
            .p-dropdown-label {
                font-size: rems(14);
                padding-top: rems(2);
            }
            .p-dropdown-trigger {
                width: rems(30);
                .pi {
                    font-size: rems(12);
                }
            }
            .p-dropdown-panel {
                .p-dropdown-items {
                    .p-dropdown-item {
                        color: var(--color-black);
                        font-size: rems(14);
                        padding-top: rems(4);
                        padding-bottom: rems(4);
                        &.p-highlight {
                            color: var(--color-white);
                            background: var(--color-teal-dark);
                        }
                        &:not(.p-highlight):not(.p-disabled):hover {
                            background: var(--color-grey-xlight);
                            color: var(--color-black);
                        }
                    }
                }
            }
            
        }

        .p-paginator-pages {

            .p-paginator-page {
                font-size: rems(14);
                height: rems(30);
                min-width: rems(30);
                border-radius: radius(small);
                margin: 0 rems(2);

                &:not(.p-highlight):hover {
                    background: var(--color-grey-light);
                    color: var(--color-black);
                }
                &.p-highlight {
                    color: var(--color-white);
                    background: var(--color-teal-dark);
                    border-color: var(--color-teal-dark);
                }
                &.p-link {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .p-paginator-first, 
        .p-paginator-prev, 
        .p-paginator-next, 
        .p-paginator-last {
            height: rems(30);
            min-width: rems(30);
            border-radius: radius(small);
            margin: 0 rems(2);
            &:not(.p-disabled):not(.p-highlight):hover {
                background: var(--color-grey-light);
                color: var(--color-black);
            }
        }
    }
    
}
