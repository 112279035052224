.site__header--logged-in, .site__header--logged-out {

    .container {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        width: 100%;
    }

    .main__navigation {
        background-color: var(--color-blue);
        display: block;
        top: rems(80);

        li {
            border-left: rems(4) solid var(--color-blue);

            &.active {
                background-color: var(--color-blue-dark);
                border-left: rems(4) solid var(--color-pink);
            }

            a {
                display: block;
            }
        }

        li.message_redirection {
            a {
                display: flex;
                align-items: center;
                gap: 7.5px;
            }
        }
    }

    .header__options {
        height: rems(80);
        position: absolute;
        right: 0;
        top: 0;
        width: rems(144);
        z-index: 102;

        .dropdown {
            .dropdown__menu,.feedback__menu {
                border-top-right-radius: 0;
                top: rems(64);
            }

            &.open {
                .header__options-button {
                    background-color: var(--color-magenta);
                    //position: relative;
                    z-index: 999;
                }
            }
        }

        .dropdown__announcements {
            .header__options-button {
                .badge {
                    background-color: var(--color-green-dark);
                }
            }

            &.open {
                .header__options-button {
                    background-color: var(--color-green-xdark);
                }
            }
        }

        .dropdown__feedback {
            .header__options-button {
                .badge {
                    background-color: var(--color-green-dark);
                    transition: all 300ms;
                    &.pulse {
                        animation: pulse-green-bigger 2s infinite;
                    }
                }
            }

            &.open {
                .header__options-button {
                    background-color: var(--color-pumpkin-dark);

                    &.alert {
                        background-color: var(--color-green-xdark);
                    }
                    .badge {
                        &.pulse {
                            animation: none;
                        }
                    }
                }
            }
        }


    }

    .header__profile-image {
        border: rems(2) solid var(--color-blue);
        border-radius: radius(medium);
        margin-bottom: 0;
        margin-top: rems(2);
        height: rems(32);
        width: rems(32);
    }

    .dropdown__announcements, .dropdown__feedback {
        .header__profile-image {
            border: 0;
            margin-top: rems(8);
        }
    }
    .dropdown__messages {
        .header__profile-image {
            border: 0;
            margin-top: rems(22);
        }
    }

    .header__options-button {
        background-color: transparent;
        border: none;
        height: $size__header--sm;
        outline: none;
        padding-left: rems(20);
        padding-right: rems(16);
        position: absolute;
        right: 0;
        top: 0;
        z-index: 4;

        &::after {
            content: url("data:image/svg+xml; utf8, <svg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'><path class='path' fill='%23ffffff' d='m4.70710678 5.29289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l4 4.00000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.00000002-4.00000002c.3905243-.39052429.3905243-1.02368927 0-1.41421356s-1.0236893-.39052429-1.4142136 0l-3.2928932 3.29289322z'/></svg>");
            display: block;
            height: rems(16);
            padding: 0;
            position: absolute;
            left: rems(3);
            top: rems(26);
            width: rems(16);
        }

        .badge {
            border: none;
            box-shadow: 0 2px 0px rgba(0,0,0,0.5);
            position: absolute;
            right: rems(4);
            top: rems(8);
        }

        &:focus {
            .header__profile-image {
                border: 2px solid var(--color-white);
            }
        }
    }

    .dropdown__announcements {
        .header__options-button {
            right: rems(116);
            padding-left: rems(8);
            padding-right: rems(8);

            &::after {
                display: none;
            }

            .badge {
                border: none;
                box-shadow: 0 2px 0px rgba(0,0,0,0.5);
                position: absolute;
                right: rems(4);
                top: rems(8);
            }

            &:focus {
                .header__profile-image {
                    border: 0;
                }
            }

            .icon-bell svg {
                height: rems(28);
                width: rems(28);
            }
        }
    }
    .dropdown__feedback {
        .header__options-button {
            right: rems(116);
            padding-left: rems(8);
            padding-right: rems(8);

            &::after {
                display: none;
            }

            .badge {
                border: none;
                box-shadow: 0 2px 0px rgba(0,0,0,0.5);
                position: absolute;
                right: rems(6);
                top: rems(14);
            }

            &:focus {
                .header__profile-image {
                    border: 0;
                }
            }

            .icon-happy-outline svg {
                height: rems(28);
                width: rems(28);
            }
        }
    }
    .dropdown__messages {
        .header__options-button {
            right: rems(68);
            padding-left: rems(8);
            padding-right: rems(8);

            &::after {
                display: none;
            }

            .badge {
                border: none;
                box-shadow: 0 2px 0px rgba(0,0,0,0.5);
                position: absolute;
                right: 0;
                top: rems(8);
                &.no_count {
                    top: rems(20);
                    right: rems(4);
                }
            }

            &:focus {
                .header__profile-image {
                    border: 0;
                }
            }

            .icon-conversation svg {
                height: rems(28);
                width: rems(28);
            }
        }
    }
    &.site__header--announcements.site__header--feedback  {
        .dropdown__feedback {
            .header__options-button {
                right: rems(164);
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .site__header--logged-in, .site__header--logged-out {

        .header__options {
            .dropdown {
                .dropdown__menu,.feedback__menu {
                    top: rems(80);
                }
            }
        }


        .header__profile-image {
            margin-top: rems(12);
        }
        .dropdown__announcements, .dropdown__feedback {
            .header__profile-image {
                margin-top: rems(18);
            }
        }
        .dropdown__messages {
            .header__profile-image {
                margin-top: rems(32);
            }
        }
        .header__profile-image {
            height: rems(36);
            width: rems(36);
        }
        .header__options-button {
            height: $size__header--md;
            padding-left: rems(24);
            padding-right: rems(24);

            &::after {
                top: rems(36);
                left: rems(6);
            }

            .badge {
                right: rems(10);
                top: rems(16);
            }
        }
        .dropdown__announcements {
            .header__options-button {
                right: rems(144);  // was 84
                padding-left: rems(12);
                padding-right: rems(12);
                .badge {
                    right: rems(10);
                    top: rems(16);
                }
                .icon-bell svg{
                    height: rems(30);
                    width: rems(30);
                }

            }
        }
        .dropdown__feedback {
            .header__options-button {
                right: rems(144); // was 84
                padding-left: rems(12);
                padding-right: rems(12);
                .badge {
                    right: rems(10);
                    top: rems(16);
                }
                .icon-happy-outline {
                    height: rems(30);
                    width: rems(30);
                }
            }
        }
        .dropdown__messages {
            .header__options-button {
                right: rems(84);
                padding-left: rems(12);
                padding-right: rems(12);
                .badge {
                    right: rems(10);
                    top: rems(16);
                    &.no_count {
                        top: rems(32);
                        right: rems(12);
                    }
                }
                .icon-conversation {
                    height: rems(30);
                    width: rems(30);
                }
            }
        }
        &.site__header--announcements.site__header--feedback  {
            .dropdown__feedback {
                .header__options-button {
                    right: rems(204); // was 144
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {

    // LOGGED IN SPECIFIC

    .site__header--logged-in {
        .main__navigation {
            padding-right: rems(144);
        }

        .header__options {
            width: rems(85);
        }
        .dropdown__announcements {
            display: block;
        }
        .dropdown__feedback {
            display: block;
        }
        &.site__header--announcements,&.site__header--feedback  {
            .main__navigation {
                padding-right: rems(205); // was 145
            }
            .header__options {
                width: rems(205); // was 145
            }
            .dropdown__feedback {
                .header__options-button {
                    right: rems(144); // was 84
                }
            }
        }
        &.site__header--announcements.site__header--feedback  {
            .main__navigation {
                padding-right: rems(264); // was 204
            }
            .header__options {
                width: rems(264);  // was 204
            }
            .dropdown__feedback {
                .header__options-button {
                    right: rems(204); // was 144
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up){
    .site__header--logged-in,.site__header--logged-out{
        .container{
            max-width: none;
            width: rems(1120);
        }
    }
}

/*@include breakpoint(for-small-desktop-up){
    .site__header--logged-in, .site__header--logged-out{
        .container{
            max-width: none;
            width: rems(1200);
    }
    }
}
*/

@include breakpoint(for-large-desktop-up){

    .site__header--logged-in, .site__header--logged-out {

        .container {
            display: flex;
            flex-direction: row;
        }

        .header__options {
            //right: rems(20);
        }

        .main__navigation {
            border-top: none;
            display: flex;
            flex-direction: row;
            flex: 1 0 auto;
            justify-content: flex-start;
            top: auto;
            width: auto;

            &:not([class="open"]) {
                display: flex;
            }

            li {
                background-color: transparent;
                border-left: none;

                a {
                    color: var(--color-white);
                    line-height: rems(32);
                    padding-bottom: rems(24);
                    padding-left: rems(20);
                    padding-right: rems(20);
                    padding-top: rems(24);
                    position: relative;


                    &:hover {
                        background-color: transparent;

                        &::after {
                            background-color: var(--color-white);
                            bottom: 0;
                            content: "";
                            display: block;
                            height: rems(4);
                            left: rems(20);
                            position: absolute;
                            right: rems(20);
                        }
                    }
                }

                &.active {
                    background-color: transparent;
                    border-left: none;

                    a {
                        &::after {
                            background-color: var(--color-pink);
                            bottom: 0;
                            content: "";
                            display: block;
                            height: rems(4);
                            left: rems(20);
                            position: absolute;
                            right: rems(20);
                        }

                        &:hover {
                            &::after {
                                background-color: var(--color-pink);
                            }
                        }
                    }
                }

                &.manage {
                    display: flex;
                    flex: 0 0 auto;
                    margin-left: auto;

                    + .manage {
                        margin-left: 0;
                    }
                }
            }
        }

        .header__logo {
            margin-left: rems(20);
            margin-right: rems(20);
        }
    }

}
