compliancy-item {display: block;}

.card {
    &--compliancy {
        .card__image-content {
            align-items: center;

            .card__image {
                width: rems(64);
            }

            .card__content {
                padding-right: rems(8);
            }
        }
        /*        .card__content {
            display: block;
        }*/

        .card__actions {
            .file_documents_container {
                .file_document_icon {
                    display: none;

                    @include breakpoint(for-tablet-portrait-up) {
                        display: flex;
                    }
                }
            }
        }

        &.card--compliancy-htrg {
            .card__image-content {
                align-items: flex-start;

                .card__image {
                    width: 0;

                    img {
                        display: none;
                    }

                    @include breakpoint(for-tablet-portrait-up) {
                        width: 4rem;

                        img {
                            display: block;
                        }
                    }
                }
            }

            .button__group {
                &.button__group--stacked {
                    grid-row-gap: 0;
                    row-gap: 0;

                    .button__group-item {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

.compact__table tr.details td .card--compliancy {
    .card__content,.card__actions {
        padding: rems(10) rems(14);
    }
}

