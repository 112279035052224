.page__header{
    background-color: var(--color-teal-dark);
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    min-height: rems(240);
    position: relative;
    justify-content: flex-end;

    &::after{
        background: linear-gradient(rgba(var(--color-blue-rgb),0), rgba(var(--color-blue-rgb),1));
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.page__header-content{
    max-width: rems(640);
    padding-bottom: rems(15);
    padding-top: rems(30);
    position: relative;
    z-index: 2;
    h1, .heading--1 {
        font-size: 2.5rem;
    }
}

.page__header-title{
    + .page__header-description{
        margin-top: rems(10);
    }
}

@include breakpoint( for-tablet-portrait-up ){
    .page__header{
        //min-height: rems(400);
    }

    .page__header-content{
        //padding-bottom: rems(30);
        //padding-top: rems(50);
        h1, .heading--1 {
            font-size: 3rem;
            line-height: 3.125rem;
        }
    }
}
