.u-text-align--left{
    text-align: left;
}

.u-text-align--right{
    text-align: right;
}

.u-text-align--right-bigger {
    text-align: left;

    @include breakpoint(for-tablet-portrait-up) {
        text-align: right;
    }
}

.u-text-align--center{
    text-align: center;
}



.u-text--height-1 {
    line-height: 1.2;
}

.u-text--1-line-max {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* limit to 1 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.u-text--2-lines-max {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.u-text--click {
    cursor: pointer;
}

.u-text--click:hover {
    color: var(--color-primary);
}

.u-text-color--grey{
    color: var(--color-grey);
}
.u-text-color--text{
    color: var(--color-text);
}
.u-text-color--light-grey {
    color: var(--color-grey-border);
}


.u-text-color--magenta{
    color: var(--color-magenta);
}

.u-text-color--teal{
    color: var(--color-teal-dark);
}
.u-text-color--dark-teal {
    color: var(--color-teal-xdark);
}
.u-text-color--green{
    color: var(--color-green-dark);
}
.u-text-color--orange{
    color: var(--color-orange-xdark);
}
.u-text-bg--green{
    background: var(--color-green-dark);
    color: white;
    font-weight: 700;
    font-size: rems(14);
    padding: rems(1) rems(4);
    border-radius: $size__radius--sm;
    margin: 0 rems(4);
}
.u-text-bg--pink {
    background: var(--color-pink-dark);
    color: white;
    font-weight: 700;
    font-size: rems(14);
    padding: rems(1) rems(4);
    border-radius: $size__radius--sm;
    margin: 0 rems(4);
}
.u-text-bg--orange {
    background: var(--color-orange-dark);
    color: white;
    font-weight: 700;
    font-size: rems(14);
    padding: rems(1) rems(4);
    border-radius: $size__radius--sm;
    margin: 0 rems(4);
}
.u-text-bg--blue {
    background: var(--color-cyan-dark);
    color: white;
    font-weight: 700;
    font-size: rems(14);
    padding: rems(1) rems(4);
    border-radius: $size__radius--sm;
    margin: 0 rems(4);
}
.u-text-bg--grey{
    background: var(--color-grey);
    color: white;
    font-weight: 700;
    font-size: rems(14);
    padding: rems(1) rems(4);
    border-radius: $size__radius--sm;
}
.u-text--large{
    font-size: rems(18);
    line-height: rems(26);
}
.u-text--larger{
    font-size: rems(20);
    line-height: rems(26);
}
.u-text--largest{
    font-size: rems(24);
    line-height: rems(28);
}

.u-text--small{
    font-size: rems(14);
    line-height: 1.4;
}

.u-text--small-inside {
    p {
        font-size: rems(14);
        line-height: rems(20);
    }
}

.u-text--tiny{
    font-size: rems(12);
    line-height: rems(16);
}

.u-text--bold{
    font-weight: 700;
}

.u-text--light {
    font-weight: 400;
}
.u-text--medium {
    font-weight: 600;
}

.u-text--hidden {
    display: none;
}
.page__section.collapsed {
    .u-text--hidden {
        display: block;
    }
}

.u-text-line-breaks {
    white-space: pre-wrap;
}
.u-text-no-breaks {
    white-space: nowrap;
}

.capitalise-first-letter::first-letter {
    text-transform: capitalize;
}

.u-text--caption {
    font-weight: 400;
    font-size: rems(13);
    color: var(--color-grey);
}
