.card__filters {
    padding: rems(4);

    &:focus {
        outline: none;
    }

    &--action {
        background-color: var(--color-magenta-xdark);

        &:hover {
            background-color: var(--color-magenta);
        }
    }

    &--active {
        background-color: var(--color-green-xxdark);

        &:hover {
            background-color: var(--color-green-xdark);
        }
    }

    &--active-2 {
        background-color: var(--color-green-xdark);

        &:hover {
            background-color: var(--color-green-dark);
        }
    }

    &--active-3 {
        background-color: var(--color-green-dark);

        &:hover {
            background-color: var(--color-green);
        }
    }

    &--progress {
        background-color: var(--color-orange-xdark);

        &:hover {
            background-color: var(--color-orange-dark);
        }
    }

    &--inactive {
        background-color: var(--color-grey);
        cursor: default;

        &:hover {
            background-color: var(--color-grey-border);
        }

        &:focus {
            outline: 0;
        }
    }

    .card__count {
        font-size: rems(48);
        line-height: 1;
        color: var(--color-white);
        text-align: center;

        &:hover {
            color: var(--color-white);
        }
    }

    .card__title {
        color: var(--color-white);
        font-size: rems(12);
        text-align: center;
        //text-transform: uppercase;
        line-height: 1.5;

        &:hover {
            color: var(--color-white);
        }
    }

    &--filter, &--filter-2 {
        .card__count {
            font-size: rems(36);
        }
    }

    &--filter {
        .card__count {
            color: var(--color-black-light);

            &:hover {
                color: var(--color-black);
            }
        }

        .card__title {
            color: var(--color-black-light);

            &:hover {
                color: var(--color-black);
            }
        }
    }

    &--filter-2 {
        background-color: var(--color-grey-xxlight);

        &:hover {
            background-color: var(--color-grey-xlight);
        }

        .card__count {
            color: var(--color-black-light);

            &:hover {
                color: var(--color-black);
            }
        }

        .card__title {
            color: var(--color-black-light);

            &:hover {
                color: var(--color-black);
            }
        }
    }

    &.active {
        position: relative;

        &::after {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path fill='%23e9e9e9' d='M17.000,23.000 L17.000,21.000 L27.000,21.000 L27.000,23.000 L17.000,23.000 zM17.000,16.000 L27.000,16.000 L27.000,18.000 L17.000,18.000 L17.000,16.000 zM17.000,11.000 L27.000,11.000 L27.000,13.000 L17.000,13.000 L17.000,11.000 zM17.000,6.000 L27.000,6.000 L27.000,8.000 L17.000,8.000 L17.000,6.000 zM3.000,21.000 L13.000,21.000 L13.000,23.000 L3.000,23.000 L3.000,21.000 zM3.000,16.000 L13.000,16.000 L13.000,18.000 L3.000,18.000 L3.000,16.000 zM3.000,11.000 L13.000,11.000 L13.000,13.000 L3.000,13.000 L3.000,11.000 zM3.000,6.000 L13.000,6.000 L13.000,8.000 L3.000,8.000 L3.000,6.000 z'/></svg>");
            content: "";
            display: block;
            position: absolute;
            height: rems(20);
            right: rems(4);
            top: rems(4);
            transition: transform 0.2s ease-out;
            width: rems(20);
        }
    }
}
