
.item__holder {
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    background-color: var(--color-grey-xxlight);
    background-color: rgba(var(--color-grey-xxlight-rgb),0.5);
    padding: rems(8);
    margin-bottom: rems(8);

        &--white {
            background-color: var(--color-white);

            .item__holder--status-wrapper {
                display: flex;
                justify-content: space-between;
                gap: rems(10);

              .item__holder--actions {
                //the below makes the button go-out of the holder
                //min-width: max-content;
              }
            }

        }

        &--lighter-borders {
            border: 1px solid var(--color-grey-xlight);
        }

        &--action-borders {
            border: 1px solid color(pink) !important;
        }

        &--completed-borders {
          border: 1px solid color(green) !important;
        }

        &--small-corners {
            border-radius: radius(small);
        }
  
        &--nomargin {
            margin-bottom: 0;
        }

        &--small-margin {
            margin-top: rems(1);
            margin-bottom: rems(6);
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        &--status {
            background-color: var(--color-magenta-light);
            background-color: rgba(var(--color-magenta-light-rgb),0.5);

            .item__holder--status-wrapper {
                display: flex;
                justify-content: space-between;
                gap: rems(10);

                .item__holder--actions {
                    min-width: max-content;
                }
            }
        }
        &--invoices {
            background-color: var(--color-white);
            .item__holder--status-wrapper {
                display: flex;
                justify-content: space-between;
                gap: rems(10);

                .item__holder--actions {
                    min-width: max-content;
                }

                .button {
                    margin-bottom: 0;
                }
            }
            .accordion {
                margin-bottom: 0;
            }
        }

        &--tenant {
            border: 0;
            padding: 0;
            padding-bottom: rems(10);
            background-color: transparent;
            // &:nth-of-type(odd) {
            //     background-color: transparent;
            // }
            span.info-badge {
                margin-left: rems(12);
            }
        }

    .file_documents_container {
        margin-bottom: 0;
    }

    .item__holder--label {
        font-size: rems(12);
        width: 100%;
        font-weight: 400;
        color: var(--color-grey);
        margin-bottom: rems(4);
        line-height: 1;

        &-subtext {
            font-style: italic;
            font-weight: 600;
        }

    }

    .expand-text--holder {
        margin-top: rems(8);
        //margin-bottom: rems(12);
        background: transparent;
        border: 0;
        padding: 0;
    }
    .expand-text--holder.editable {
        cursor: pointer;
    }
    .form_options_container {
        margin-top: 0;
        margin-bottom: 0;
    }
    file-uploader, new-file-uploader {
        .photo-gallery {
            margin-top: rems(10);
            margin-bottom: 0;
            li {
                justify-content: space-between;
                margin-right: 0;
                margin-bottom: 0;
                background: var(--color-white);
                border-color: var(--color-grey-light);
            }
        }
    }

}

.accordion.accordion--panel.accordion--panel-compact .accordion__content .item__holder p:last-of-type {
    margin-bottom: 0;
}



// CUSTOM HOLDER ITEM FOR MAINTENANCE

.item__holder__flex {
  display: flex;
  flex-direction: column;
  padding: rems(8);
  margin-bottom: rems(8);
  border: 1px solid var(--color-grey-light);
  border-radius: radius(medium);
  background-color: var(--color-magenta-light);
  gap: 10px;

  .label {
    font-size: 12px;
  }

    .flex_row {
        display: flex;
        flex-wrap: wrap;
    }

    .flex_column {
        display: flex;
        flex-direction: column;
    }

    .gap_10 {
        gap: 10px;
    }

  .status_and_quick_actions { //JON TODO
    display: flex;
    justify-content: space-between;
    align-items: center;

    .status {
      display: flex;
      flex-direction: column;
    }

    .quick_actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;

      button {
        margin: 0;
      }
    }
  }

  .workflow_actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.item__holder__flex.white {
    border: 1px solid var(--color-grey-xlight);
    background-color: var(--color-white);
}

.coho__dark{
    .item__holder__flex {
        background-color: var(--color-magenta-xxdark);
        border: 1px solid var(--color-grey-dark);
    }
}


@include breakpoint(for-large-phone-landscape-up){
  .item__holder__flex {
    .workflow_actions {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
}

@include breakpoint(for-tablet-portrait-up){
    .item__holder {
        &--status {
            .form_options_container {
                .form_option-item {
                    .form_option_name {
                        flex-grow: 1;
                        flex-basis: fit-content;
                        P {
                            min-width: rems(200);
                            padding-right: rems(20);
                        }
                    }
                }
            }
        }
    }
  .item__holder__flex {
    .workflow_actions {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
}

@include breakpoint(for-small-desktop-up){
    .item__holder {
        &--status {
            .form_options_container {
                .form_option-item {
                    .form_option_name {
                        flex-grow: 1;
                        flex-basis: 35%;
                        P {
                            min-width: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
  .item__holder__flex {
    .workflow_actions {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
}
