.coho__dark {

    .modal__overlay {
        background-color: rgba(var(--color-blue-xdark-rgb),0.98);
    }

    .modal {
        background-color: var(--color-grey-dark);
    }

    .modal__header {
        background-color: var(--color-grey-dark);
    }

    .modal__close {
        background-color: var(--color-grey-dark);
        background-color: rgba(var(--color-grey-dark-rgb),0.75);
    }

    .snooze__modal,
    .terms__modal {
        background-color: rgba(var(--color-grey-dark-rgb), 0.95);
        box-shadow: 0 rems(3) rems(6) 0 rgba(255,255,255,0.10);
    }

    .inactive__modal-wrapper .inactive__modal {
        background-color: rgba(var(--color-grey-rgb), 0.8);
    }


    .page__section--alt {
        .snooze__modal,
        .terms__modal {
            background-color: rgba(var(--color-black-rgb), 0.95);
            box-shadow: 0 rems(3) rems(6) 0 rgba(255,255,255,0.10);
        }
    }
}
