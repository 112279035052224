.card__room-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: rems(10);
    padding-bottom: rems(10);

    h4{
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text__price{
        text-align: right;
        margin-left: rems(10);
        margin-top: 0;
        max-width: rems(80);
        min-width: rems(80);
        width: rems(80);

        strong{
            display: block;
        }
    }

}

.card__room-profile {
    padding-bottom: rems(0);

    h4 + p {
        margin-top: 0;
    }

    p {
        margin-bottom: rems(6);
        line-height: 1.4;

        &.label-small {
            margin-bottom: 0;
            line-height: 1;
            font-size: rems(10);
        }

        &.label-tel {
            margin-top: 5px;
            line-height: 1;
            font-size: 0.675rem;
        }
    }

    .profile__preview--card {
        display: block;

        .profile__preview-text {
            padding-top: rems(8);
            width: 100%;
        }

        .profile__preview-text--contact-holder {
            padding-top: rems(8);
        }

        .profile__preview-text--contact {
            display: flex;
            align-items: center;

            a {
                margin-left: rems(4);
                font-size: rems(13);
                text-decoration: none;
                color: var(--color-black);

                &:hover {
                    color: var(--color-grey);
                    border-bottom: 1px solid var(--color-grey-light);
                }

                &:focus {
                    outline: none;
                }
            }

            margin-bottom: 0;
            line-height: 1.5;
            overflow: hidden;

            svg-icon {
                height: rems(16);
            }

            .icon svg {
                width: rems(14);
                height: rems(14);
                margin-right: rems(2);
                margin-bottom: rems(1);
            }

            .icon svg.icon-email {
                width: rems(16);
                height: rems(16);
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}

.card {
    &.card--room {
        border: rems(2) solid var(--color-grey-xxlight);
    }

    &.card--room-is-available {
        border: rems(2) solid var(--color-orange-dark);
    }
}



@include breakpoint(for-tablet-portrait-up) {
    .card--room-future {
        .card__room-title {
            .text__price {
                max-width: 33%;
                width: 33%;
            }

            h4 {
                white-space: nowrap;
            }
        }
    }
}
