p{
    font-size: rems(16);
    line-height: rems(24);

    &.lede{
        color: var(--color-grey);
        font-size: rems(20);
        line-height: rems(30);
    }

}

.text__price{

    p{  
        color: var(--color-grey);
        font-size: rems(14);
        line-height: rems(20);
        margin-bottom: 0;
    }

    strong{
        color: var(--color-pink);
        font-size: rems(24);
        font-weight: 700;
    }

    &.text__price--large{
        strong{
            font-size: rems(32);
        }
    }

    &.text__price--smaller {
        strong{
            font-size: rems(18);
        }
    }

}



.text__room {
    font-size: rems(16);
    color: var(--color-grey);
}
