finance-chart-monthly-bar {
    display: block;
    height: rems(300);
    position: relative;
}

finance-chart-category-pie {
    display: block;
    height: rems(240);
    margin-bottom: rems(50);
    @include breakpoint(for-tablet-portrait-up) { margin-bottom: 0;}
    position: relative;

    .button--ghost {
        &.selected {
            background-color: var(--color-teal-light);
        }
    }

    .button--space {
        margin-left: rems(20);
    }

}

.finance__zero-state {
    position: relative;
    img {
        display: block;
        width: 100%;
    }
    .finance__zero-state--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-white);
        background-color: rgba(var(--color-white-rgb),0.9);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.expense__zero-state {
    position: relative;

    img {
        display: block;
        width: 80%;
        margin: 0 auto;
    }

    .expense__zero-state--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-white);
        background-color: rgba(var(--color-white-rgb),0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 rems(50);
        text-align: center;
    }
}
.page__section--alt {
    .finance__zero-state {
        .finance__zero-state--overlay {
            background-color: var(--color-grey-xxlight);
            background-color: rgba(var(--color-grey-xxlight-rgb),0.9);
        }
    }
    .expense__zero-state {
        .expense__zero-state--overlay {
            background-color: var(--color-grey-xxlight);
            background-color: rgba(var(--color-grey-xxlight-rgb),0.9);
        }
    }
}

settlement-financial-items {
    .settlement__item {
        .form__checkbox {
            .icon, svg {
                width: rems(18);
                height: rems(18);
            }

            font-weight: 600;
            font-size: rems(14);
            margin-bottom: 0;

            .form__checkbox-label {
                margin-left: rems(6);
                line-height: 1.2;
            }
        }

        padding: rems(2) rems(4);
        margin-bottom: 0;
        border-top: rems(1) solid var(--color-grey-light);

        &:last-child {
            margin: 0;
            border-bottom: rems(1) solid var(--color-grey-light);
        }

        &:hover {
            background-color: var(--color-grey-xlight);
        }

        .settlement__item--intro {
            display: flex;
            flex-direction: column;
            //justify-content: space-between;
            //align-items: center;

            .settlement__item--intro-checkbox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                label.form__checkbox.form__input--small.form__checkbox {
                    margin-bottom: 0;
                }

                .button.button--icon.button--icon-only.button--small {
                    min-width: rems(24);
                    height: rems(24);
                    min-height: 0;
                    margin-bottom: 0;

                    .icon {
                        left: rems(4);
                        top: rems(2);

                        svg {
                            height: rems(16);
                            width: rems(16);
                        }
                    }
                }
            }

            .settlement__item--intro-details {
                display: flex;
                flex-direction: row;
                margin-left: rems(50);
                margin-bottom: rems(4);
                //padding-top: rems(6);

                div {
                    color: var(--color-grey);
                    font-size: rems(12);
                    border-left: 1px solid var(--color-grey);
                    padding-left: rems(4);
                    margin-left: rems(4);
                    margin-top: rems(-4);
                    height: rems(12);
                    line-height: rems(12);

                    &:first-child {
                        border-left: 0;
                        padding-left: 0;
                        margin-left: 0;
                    }
                }
            }
        }

        .settlement__item--items {
            padding-top: rems(10);
            display: none;
            flex-direction: column;

            &.editing {
                display: flex;
            }

            & > div {
                background-color: var(--color-teal-xlight);
                padding: rems(8);

                &:last-child {
                    margin-top: rems(8);
                }

                .settlement__items {
                    display: flex;
                    justify-content: space-between;

                    &.settlement__items--available {
                        border-top: rems(1) dotted var(--color-teal);
                        padding-top: rems(2);
                        margin-top: rems(2);
                        font-weight: 600;
                    }
                }
            }
        }

        &:nth-child(even) {
            //background-color: var(--color-teal-xlight);

            .settlement__item--items {

                & > div {
                    //background-color: var(--color-white);
                    //outline: rems(1) solid rgba(var(--color-teal-rgb),.5);
                }
            }
        }

        .button__group {
            padding: rems(10) rems(5);

            .button {
                margin-bottom: 0;
            }
        }
    }
}

#profitability-reports, #settlements {
    .accordion__description {
        font-size: rems(14);
        color: var(--color-grey);
    }
}

#profitability-reports {
    .card.card--report {
        &:hover {
            box-shadow: 0 rems(8) rems(16) 0 rgba(0,0,0,0.15);
            color: var(--color-text);
        }

        a.card__content {
            text-decoration: none;

            &:focus {
                outline: none;
            }

            h5 {
                color: var(--color-text);
            }
        }
    }
}

.financial_item_mini_maintenance {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@include breakpoint(for-tablet-landscape-up) {
    settlement-financial-items {
        .settlement__item {
            .settlement__item--items {
                flex-direction: row;

                & > div {
                    flex-basis: 50%;


                    &:last-child {
                        margin-top: 0;
                        margin-left: rems(8);
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }

                .settlement__items {
                }
            }
        }
    }
}
