.button {
    &.button--collapse,
    &.button--uncollapse {
        &.hidden {
            display: none;
        }
        background-color: var(--color-teal-light);
        color: var(--color-black);
        font-size: rems(12);
        font-weight: 400;
        padding: rems(4) rems(6);
        margin-bottom: 0;
        &.button--icon{
            padding-left: rems(24);
            position: relative;
            text-align: left;
            .icon{
                height: rems(16);
                position: absolute;
                left: rems(4);
                top: rems(4);
                width: rems(16);

                svg{
                    height: rems(16);
                    width: rems(16);
                }

            }
        }
        &.button--active {
            background-color: var(--color-teal-dark);
            color: white;
            .icon { fill: var(--color-white); }
        }
    }
}


