
@mixin nav-block($color) { 
    background-color: color($color,light);
    border-color: color($color,dark);
    &.nav-block--selected {
        background-color: color($color,dark);
        border-color: color($color,xdark);
        outline-color: color($color,xdark)
    }
    &:hover {
        outline-color: color($color,xdark);
    }
}

.card__nav {
    .nav-blocks {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 100;
    }

    .nav-block {
        background-color: var(--color-grey-light);
        border: rems(1) solid var(--color-grey-dark);
        width: rems(12);
        height: rems(12);
        display: inline-block;
        margin: rems(6) rems(6) rems(16) rems(6);  

        &:focus {
            outline: 0;
        }

        &.nav-block--selected {
            background-color: var(--color-grey-border);
            border-color: var(--color-grey-dark);
            outline: 1px solid var(--color-grey-dark);
            outline-offset: 0;
            box-shadow: 0 rems(2) rems(6) 0 rgba(0,0,0,0.25);
        }

        &:hover{
            outline: 1px solid var(--color-grey-dark);
            outline-offset: 0;
        }

    }


    .nav-block--green {
        @include nav-block(green)
    }

    .nav-block--teal {
        @include nav-block(teal)
    }

    .nav-block--pink {
        @include nav-block(pink)
    }

    .nav-block--orange {
        @include nav-block(orange)
    }

    .nav-block--red {
        @include nav-block(red)
    }

    .nav-block--magenta {
        @include nav-block(magenta)
    }

    .nav-block--cyan {
        @include nav-block(cyan)
    }


}

  
.page__section-content--compact {
    .card__nav {
        border-top: rems(1) solid var(--color-grey-light);
        background-color: var(--color-grey-xlight);
        background-color: rgba(var(--color-grey-xlight-rgb),0.5);
        .nav-blocks {
            justify-content: flex-end;
            padding-right: rems(2);
            padding-top: rems(2);        
            }
    
        .nav-block {
            margin-bottom: rems(8);
        }
    }
}