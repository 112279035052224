#quick-access, #quick-add {
    &.hidden {
        display: none;
    }
    &.hidden.clicked {
        //padding-bottom: 0;
        display: block;
        .grid__layout {
            position: fixed;
            bottom: 0;
            right: 0;
            width: 100%;
            min-width:rems(250);
            z-index: 9999;
            padding: rems(12) rems(12) rems(8) rems(20);
            background: white;
            border: 1px solid var(--color-grey-light);
            box-shadow: 0 rems(2) rems(15) rems(2) rgba(0,0,0,0.15);
            border-radius: radius(small);
            max-height: 100%;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: rems(12);
            }

            &::-webkit-scrollbar-track {
                background: var(--color-grey-xlight);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-grey-border);
                border-radius: rems(20);
                border: rems(3) solid var(--color-grey-xlight);
            }

            .row {
                h6 {
                    font-size: rems(20);
                    padding: rems(8) 0;
                }
                .col.col__sm--6.col__md--4.col__lg--3 {
                    width: 100%;
                    .card.card--image-left .card__image-content {
                        padding: rems(6) 0;
                    }
                    .card__content {
                        //padding-right: rems(42);
                        p {
                            //font-size: rems(18);
                        }
                    }
                    a:focus {
                        outline: 0;
                    }
                }
            }

            &>.row {
                position: relative;
            }

            .quick-access__close, .quick-add__close{
                background-color: transparent;
                border: none;
                color: var(--color-magenta);
                cursor: pointer;
                font-size: rems(40);
                line-height: rems(15);
                padding: rems(10);
                position: absolute;
                right: 0;
                top: rems(-20);
                z-index: 900;

                .icon{
                    fill: var(--color-magenta);
                }

                &:hover{
                    color: var(--color-text);
                    .icon{
                        fill: var(--color-text);
                    }
                }
                &:focus {
                    outline: 0;
                }
            }

        }
    }
}


#quick-access__modal {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;

    &.visible {
        opacity: 1;
        transform: none;
        visibility: visible;
        z-index: 99;
        position: relative;
    }

    a {
        padding: rems(14) rems(6);
        position: fixed;
        text-align: center;
        font-size: rems(16);
        text-decoration: none;
        border-top-right-radius: radius(small);
        bottom: 0;
        color: white;
        width: 33.25%;

        &:hover {
            color: white;
            background: var(--color-pink-xdark);
        }

        &:focus {
            outline: none;
        }

        &.quick-add {
            background: var(--color-cyan-dark);
            left: 0;
        }

        &.quick-access {
            background: var(--color-pink-dark);
            left: 33.45%;
        }

        &.go-to-top {
            background: var(--color-teal-dark);
            right: 0;
        }
    }

    &.hidden {
        opacity: 0;
        visibility: hidden;
    }
}

@include breakpoint(for-large-phone-up) {
    #quick-access__modal {
        a {
            padding: rems(18) rems(8);
            position: fixed;
            text-align: center;
            font-size: rems(20);
        }
    }
}


@include breakpoint(for-tablet-portrait-up) {
    #quick-access__modal {
        a {
            padding: rems(8) rems(20);
            text-align: left;
            transform: rotate(-90deg);

            &.quick-add {
                width: auto;
                bottom: 50px;
                right: -48px;
                left: unset;
            }

            &.quick-access {
                width: auto;
                bottom: 201px;
                right: -61px;
                left: unset;
            }

            &.go-to-top {
                width: auto;
                bottom: 349px;
                right: -44px;
                border-top-left-radius: 0.125rem;
            }
        }
    }

    #quick-access, #quick-add {
        &.hidden.clicked {
            .grid__layout {
                position: fixed;
                bottom: 0;
                right: 0;
                width: rems(280);
                z-index: 9999;
                padding: rems(12) rems(12) rems(8) rems(20);
                //background: white;
                //border: 1px solid var(--color-grey-light);
                //box-shadow: 0 rems(2) rems(15) rems(2) rgba(0,0,0,0.15);
                border-radius: radius(small);
                max-height: 90vh;
                overflow-y: scroll;

                .row {
                    h6 {
                        font-size: rems(18);
                        padding: rems(4) 0;
                    }

                    .col.col__sm--6.col__md--4.col__lg--3 {
                        .card.card--image-left .card__image-content {
                            padding: 0;
                        }

                        .card__content {
                            p {
                                //font-size: rems(18);
                            }
                        }
                    }
                }

                .quick-access__close, .quick-add__close {
                    font-size: rems(30);
                    top: rems(-24);
                }
            }
        }
    }
}

