.filter__options {
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 0; 
    
    li{
        clear: both;
        display: inline-block;
        margin-bottom: rems(16);
        min-height: rems(30);
        position: relative;
        .filter__options-icon{
            position: absolute;
            width: rems(24);
            .icon{
                display: block;
                fill: var(--color-teal);
                height: rems(24);
                width: rems(24);
            }
        }
        .filter__options-text{
            display: inline-block;
            margin-left: rems(30);
            margin-top: rems(3);
            min-height: rems(30);
            margin-right: rems(12);
        }
    }
    &.filter__options--small{
        font-size: rems(14);

        li{
            margin-bottom: rems(5);
        }

        .filter__options-icon{
            
            .icon{
                height: rems(24);
                width: rems(24);

                svg{
                    height: rems(24);
                    width: rems(24);
                }

            }
        }

        .filter__options-text{
            margin-top: 0;
            min-height: rems(20);
        }

    }
    &.filter__options--noborder {
        border-bottom: 0;
    }
}

.filter__options--container {
    display: flex;
    border-bottom: 1px solid var(--color-grey-xlight);
    flex-direction: column-reverse;
    search-summary {
        flex: 1;
    }
    .filter__options{
        list-style: none;
        padding: 0;
        margin-bottom: 0; 
        margin-top: rems(10);
        flex: 1;
        border-bottom: 0;
        li {
            clear: both;
            display: inline-block;
            margin-bottom: rems(16);
            min-height: rems(26);
            position: relative;
            .filter__options-icon{
                position: absolute;
                width: rems(24);
                .icon{
                    display: block;
                    fill: var(--color-teal);
                    height: rems(24);
                    width: rems(24);
                }
            }  
            .filter__options-text{
                display: inline-block;
                margin-left: rems(26);
                min-height: rems(30);
                margin-right: rems(8);
            }
            .filter__options-title{
                display: inline-block;
                margin-right: rems(8);
                color: white;
                background: var(--color-teal);
                padding: 0 rems(6);
                border-radius: $size__radius--sm;
                min-width: rems(48);
            }
        }
        &.filter__options--small{
            font-size: rems(12);
            li{
                margin-bottom: rems(5);
            }
            .filter__options-icon{
                .icon{
                    height: rems(20);
                    width: rems(20);

                    svg{
                        height: rems(20);
                        width: rems(20);
                    }

                }
            }
            .filter__options-text{
                margin-top: 0;
                min-height: rems(20);
            }

        } 
        &.filter__settings {
            padding-top: rems(8);
            margin-top: 0;
            li {
                .filter__options-icon{
                    .icon{
                        fill: var(--color-grey-border);
                    }
                }  
                .filter__options-text{
                    color:var(--color-grey-border);
                }
                .filter__options-title{
                    background: var(--color-grey-border);

                }
            }
        }
    }   
    .filter__actions {
        .button {
            //margin-bottom: 0;
            min-width: rems(89);
            width: 100%;
            margin-bottom: rems(10);
            .icon{
                height: rems(20);
                width: rems(20);

                svg{
                    height: rems(20);
                    width: rems(20);
                }

            }             
        }       
    }    
}

@include breakpoint(for-large-phone-landscape-up) {
    .filter__actions {
        .button {
            margin-bottom: 0;
            width: auto;
        }
    }

    .filter__options--container {
        flex-direction: row;
    }
}









