@use "sass:math";
@import "_vars";
@import "_colours";
@import "_base";

@import "../images/svg/svg-symbols.scss";
@import "components/typography/import";
@import "components/cards/import";
@import "components/images/import";
@import "components/layout/import";
@import "components/compact/import";
@import "components/forms/import";
@import "components/buttons/import";
@import "components/conversations/import";
@import "components/users/import";
@import "components/content/import";
@import "components/header/import";
@import "components/footer/import";
@import "components/galleries/import";
@import "components/navigation/import";
@import "components/modals/import";
@import "components/accordions/import";
@import "components/dropdown/import";
@import "components/splash-pages/import";
@import "components/coho-rating/import";
@import "components/marketing/import";
@import "components/helpers/import";
@import "components/icons/import";
@import "components/loaders/import";
@import "components/services/import";
@import "components/room/import";
@import "components/tooltip/tooltip";
@import "components/pages/import.scss";
@import "components/payments/import.scss";
@import "components/sms/import.scss";
@import "components/dark/import.scss";
@import "components/quick-access/import.scss";
@import "components/reports/import.scss";
@import "components/search/import.scss";
@import "components/articles/import.scss";
/*@import "components/seo-pages/import.scss"; Feature pages not yet in use */
@import "components/landing-pages/import";
@import "components/features/import";
@import "components/styleguide/styleguide";
@import "components/partners/import";
@import "components/finance/import";
@import "components/my-home/import";
@import "components/magic/import";
@import "components/data-centre/import";
@import "components/suppliers/import";
@import "components/tenant-find/tenant-find";
@import "components/my-viewings/my-viewings";
@import "components/notifications/import";

body{
    background-color: var(--color-white);
}

a{
    color: var(--color-cyan);

    &:hover{
        color: var(--color-cyan-dark);
        text-decoration: none;
    }
}
.symbols--hide{
    display: none;
}
