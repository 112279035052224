.form__edit-group{
    display: flex;
    flex-direction: column;
    margin-bottom: rems(20);
    width: 100%;
}

.form__edit-group-input{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.form__edit-group-input{
    margin-bottom: rems(20);
}

.form__edit-group-action{
    align-items: flex-start;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;

    .button {
        margin-bottom: 0;
    }
}

.form__edit-group-image{
    
    img{
        display: block;
        height: auto;
        margin-bottom: rems(20);
        width: rems(120);    
    }
}

@include breakpoint( for-tablet-portrait-up ){
    .form__edit-group{
        align-items: flex-end;
        flex-direction: row;

        &.form__edit-group-image{
            align-items: flex-start;
            flex-direction: column;

            .form__edit-group-input{
                width: 100%;
            }

        }

    }

    .form__edit-group-input{
        margin-bottom: rems(0);
        margin-right: rems(10);
    }

    .form__edit-group-action{
        margin-bottom: rems(0);
    }
}

.form__edit-group:not(.is-editing){
    input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]), textarea{
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
    }

    button.button{
        background-color: var(--color-white);
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
        color: var(--color-text);
    }
}

.form__options-group{
    .form__edit-group{
        margin-bottom: rems(10);
        
        .form__label{
            margin-bottom: rems(10);
        }
    }
}