.content__notification{
    background-color: var(--color-cyan-light);
    border-radius: radius(large);
    color: var(--color-blue);
    padding: rems(20);
    margin-bottom: rems(20);

    *:last-child{
        margin-bottom: 0;
    }
    
    &-content{
        max-width: rems(600);
    }

    &-title{
        align-items: center;
        display: flex;
        margin-bottom: rems(10);
        justify-items: center;

        .icon{
            fill: var(--color-blue);
            margin-right: rems(10);
        }

        h1, h2, h3, h4, h5, h6{
            padding-top: 0;
        }

    }

    &--light{
        background-color: white;;
    }

    &--BILLING {
        background-color: var(--color-green-light);
        color: var(--color-black);
        .icon{
            fill: var(--color-green-xdark);
        }
    }

}

.page__section--alt{
    .content__notification{
        border: 1px solid var(--color-blue);
    }
}