.marketing_items_container {
    display: flex;
    flex-direction: column;
    margin-bottom: rems(8);

    .marketing_item_group {
        display: flex;
        flex-direction: column;

        + .marketing_item_group {
            border-top: 1px solid rgba(0,0,0,0.2);
            margin-top: rems(20);
            padding-top: rems(20);
        }
    }

    .marketing_item {
        align-items: flex-start;
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .marketing_item_group {
        background-color: white;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: radius(large);
        padding: rems(20) rems(20) rems(10) rems(20);
    }

    .marketing_item_title {
        border-bottom: 1px solid rgba(0,0,0,0.2);
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        font-weight: 700;
        padding-bottom: rems(10);
        margin-bottom: rems(20);
        width: 100%;

        .marketing_item_actions {
            padding-top: rems(12);
        }
    }

    .marketing_item-item {
        align-items: flex-start;
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;

        + .marketing_item-item {
            border-top: 1px solid rgba(0,0,0,0.1);
            margin-top: rems(20);
            padding-top: rems(20);
        }
    }

    .marketing_item_icon {
        display: flex;
        margin-bottom: rems(10);
        margin-right: rems(10);
        width: rems(36);

        svg {
            height: rems(36);
            width: rems(36);
        }

        img {
            height: auto;
            width: rems(36);
        }
    }

    .marketing_item-item {
        &.marketing_item_image {
            .marketing_item_icon {
                display: flex;
                margin-bottom: rems(10);
                margin-right: rems(10);
                width: rems(64);

                img {
                    display: block;
                    height: auto;
                    width: rems(64);
                }
            }
        }
    }

    .marketing_item_name {
        font-size: rems(14);
        overflow: hidden;
        display: block;
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]) {
            padding: rems(6);
            font-size: rems(14);
        }
    }

    .marketing_item_actions {
        margin-top: rems(10);

        .button {
            margin-right: rems(5);

            + .button {
                margin-left: rems(0);
            }

            &:last-child {
                //margin-bottom: 0;
                margin-right: 0;
            }
        }
    }
}



@include breakpoint(for-large-phone-up) {
    .marketing_items_container {
        .marketing_item {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .marketing_item_group {
            padding: rems(20);
        }

        .marketing_item_title {
        }

        .marketing_item-item {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .marketing_item_icon {
            margin-bottom: 0;

            img {
                height: rems(32);
                width: rems(32);
            }
        }

        .marketing_item-item {
            &.marketing_item-image {
                .marketing_item_icon {

                    + .marketing_item_actions {
                        margin-left: auto;
                    }
                }
            }
        }

        .marketing_item_name {
            flex: 1 1 35%;
            margin-right: rems(24);
        }

        .marketing_item_actions {
            margin-top: rems(0);
            margin-bottom: 0;

            .button {
                margin-bottom: 0;
            }
        }
    }
}

@include breakpoint(for-large-phone-up) {
    .form--inline .marketing_items_container {
        .marketing_item_actions {
            .marketing_item_download {
                &.button.button--small.button--icon {
                    text-indent: rems(999);
                    padding-left: rems(24);
                    max-width: 0;
                    min-width: 0;

                    @include breakpoint(for-small-desktop-up) {
                        text-indent: 0;
                        padding-left: rems(32);
                        min-width: rems(80);
                        max-width: unset;
                    }

                    .icon {
                        text-indent: 0;
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .marketing_items_container {
        .marketing_item_title {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            .marketing_item_actions {
                padding-top: 0;
                display: flex;
            }
        }
    }
}


