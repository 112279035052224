.icon, .icon-settings, .icon svg {
    &.icon--cyan {
        fill: var(--color-cyan);
    }

    &.icon--blue {
        fill: var(--color-blue);
    }

    &.icon--green {
        fill: var(--color-green);
    }

    &.icon--darkgreen {
        fill: var(--color-green-dark);
    }
    &.icon--xdarkgreen {
        fill: var(--color-green-xdark);
    }

    &.icon--pink {
        fill: var(--color-pink);
    }

    &.icon--darkpink {
        fill: var(--color-pink-xdark);
    }

    &.icon--magenta {
        fill: var(--color-magenta);
    }

    &.icon--dark-magenta {
        fill: var(--color-magenta-xdark);
    }

    &.icon--orange {
        fill: var(--color-orange);
    }

    &.icon--grey {
        fill: var(--color-grey);
    }

    &.icon--light-grey {
        fill: var(--color-grey-border);
    }

    &.icon--very-light-grey {
        fill: var(--color-grey-light);
    }

    &.icon--light2-grey {
        fill: var(--color-grey-xlight);
    }

    &.icon--light1-grey {
        fill: var(--color-grey-light);
    }

    &.icon--very-light-grey {
        fill: #d5d5d5;
    }

    &.icon--teal {
        fill: var(--color-teal);
    }

    &.icon--dark-teal {
        fill: var(--color-teal-xdark);
    }

    &.icon--white {
        fill: white;
    }

    &.icon--warning {
        fill: var(--color-warning-dark);
    }

    &.icon--violet {
        fill: var(--color-violet-dark);
    }

    &.icon--black {
        fill: var(--color-text);
    }

}

.icon-settings {
    cursor: pointer;
}
