.coho__dark {
    .room {
        .room__content-section {
            .room__content-sub-section {
                .room__content-profile-holder {
                    background: var(--color-black);
                    border-color: var(--color-grey-border);
                }
            }
        }
    }

    .modal-profile-holder {
        background: var(--color-black);
        border: 1px solid var(--color-grey-border);
    }

    .profile__contact .profile__contact-actions a {
        color: var(--color-grey-xlight);
    }

    .card__room-profile .profile__preview--card .profile__preview-text--contact a {
        color: var(--color-grey-xlight);
        &:hover {
            color: var(--color-white);
            border-color: var(--color-white);
        }
    }
}
