.sms__header{
    display: flex;
    flex: 1 0 auto;
    height: rems(80);
    background: var(--color-blue);
}

.sms__header-content{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    max-width: none;
    padding-bottom: rems(10);
    padding-left: rems(20);
    padding-right: rems(20);
    padding-top: rems(10);
    position: relative;
    width: 100%;
}

.sms__header-logo{
    display: block;
    width: rems(116);

    img{
        display: block;
        height: auto;
        width: 100%;
    }
}

.sms__header-actions{
    display: none;
}

@include breakpoint(for-tablet-landscape-up){
    .sms__header-content{
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        max-width: rems(960);
        width: rems(960);
    }    

    .sms__header-actions{
        display: flex;
    }
}

@include breakpoint(for-small-desktop-up){
    .sms__header-content{
            max-width: none;
            width: rems(1200);
    }
}