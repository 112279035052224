.button--icon{
    padding-left: rems(48);
    position: relative;
    text-align: left;
    .icon{
        display: block;
        fill: #ffffff;
        height: rems(30);
        position: absolute;
        left: rems(9);
        top: rems(8);
        width: rems(30);

        svg{
            height: rems(30);
            width: rems(30);
        }

    }

    &.button--ghost{
        .icon{
            fill: var(--color-text);
        }
    }

    &.button--cancel{
        .icon{
            fill: var(--color-text);
        }
    }
}

.button.button--icon.button--icon-only {
    min-width: 3rem;
    padding-left: 0;
    min-height: 3rem;
    &.button--small {
        padding-left: 0;
        min-width: 2.125rem;
        min-height: 2.125rem;
    }
    &.button--tiny {
        padding-left: 0;
        min-width: rems(23);
        min-height: rems(24);
    }
    &.button--tiniest {
        padding-left: 0;
        min-width: rems(23);
        min-height: rems(24);
    }
}

.button--info-icon {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    min-width: 1px;
    position: relative;
    text-align: left;
    width: rems(30);
    height: rems(30);

    border-radius: 16px;

    .icon{
        display: block;
        fill: #ffffff;
        height: rems(30);
        position: absolute;
        left: -1px;
        top: -1px;
        width: rems(30);

        svg{
            height: rems(30);
            width: rems(30);
        }

    }

    &:hover{
        background-color: transparent;
        border-color: transparent;
        animation: pulse-cyan 2s infinite;
    }

    &:focus{
        outline: none;
        //border: 0;
        box-shadow: none;
    }
    &.button {
        padding: 0;
        min-width: 0;
        //margin-bottom: 0;
        &.button--small {
            padding: 0;
            min-width: 0;
            width: rems(20);
            height: rems(20);
            margin-left: rems(10);
            .icon{
                height: rems(24);
                left: -2.5px;
                top: -3px;
                width: rems(24);

                svg{
                    height: rems(24);
                    width: rems(24);
                }

            }
        }
        &.button--tiny {
            padding: 0;
            margin: 0;
            min-width: 0;
            width: 18px;
            height: 18px;
            .icon{
                height: 18px;
                width: 18px;
                left: -1px;
                top: -1px;

                svg{
                    height: 18px;
                    width: 18px;
                }

            }
        }
    }

}

.form__label {
    .button--info-icon {
        &.button {
            margin-bottom: 0;
            &.button--small {
                padding: 0;
                min-width: 0;
                width: rems(20);
                height: rems(18);
                margin-top: rems(3);
                .icon{
                    height: rems(20);
                    left: -1px;
                    top: -1px;
                    width: rems(20);
    
                    svg{
                        height: rems(20);
                        width: rems(20);
                    }
    
                }
            }
        }
    }
}


info-button {
    p.info-button--intro {
        color: var(--color-grey);
        font-size: rems(13);
        line-height: 1.4;
        margin-bottom: rems(20);
        &.u-margin-bottom--none {
            margin-bottom: 0;
        }
        span {
            text-decoration: underline;
            color: var(--color-blue);
            cursor: pointer;
        }
    }
}
