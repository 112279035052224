.dropdown{
    position: relative;

    .dropdown__menu, .feedback__menu{
        display: none;
    }

    &.open{
        .dropdown__menu, .feedback__menu{
            display: block;
        }
    }


}

.dropdown__menu{
    background-color: white;
    border: 2px solid var(--color-magenta);
    border-radius: radius(medium);
    box-shadow: 0 rems(2) rems(20) rgba(0,0,0,0.33);
    list-style: none;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    min-width: rems(280);
    position: absolute;
    z-index: 900;
    width: 100%;

    &.dropdown__menu--right{
        right: rems(0);
    }

    li{
        border-bottom: 1px solid var(--color-grey-light);

        &:last-child{
            border-bottom: none;
        }

        a{
            color: inherit;
            text-decoration: none;
        }

        ul{
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &.internal {
            background-color: var(--color-violet-light);
            background-color: rgba(var(--color-violet-light-rgb),0.25);
        }

    }

}

.dropdown__menu-title{
    background-color: var(--color-magenta);
    color: var(--color-white);
    font-size: rems(14);
    font-weight: 500;
    line-height: rems(14);
    display: flex;
    padding: rems(10);
    width: 100%;
    align-items: center;
    .icon {

        fill: var(--color-white);
        width: rems(20);
        height: rems(20);
        margin-right: rems(8);
        svg {
            width: rems(20);
            height: rems(20);
        }
    }
    .dropdown__menu-title--with-details {
        padding: rems(2) rems(10);
    }
    .dropdown__menu-title--details {
        display: flex;
        flex-direction: column;
        line-height: rems(20);
        span {
            &:first-of-type {
                font-weight: 600;
                font-size: rems(15);
            }
            &:last-of-type {
                color: var(--color-grey-xlight);
            }
        }
    }
}

.dropdown__menu{
    li{
        .dropdown__menu-title{
            a{
                flex: 1;
                text-align: end;
                color: var(--color-white);
                font-weight: 400;
                text-decoration: underline;
            }
        }
    }
}

.dropdown__menu-section{
    border-top: 1px solid var(--color-grey-light);
}

.dropdown__menu-item{
    display: flex;
    padding: rems(10);
    font-size: rems(14);
    .icon {

        fill: var(--color-black);
        width: rems(20);
        height: rems(20);
        margin-right: rems(8);
        svg {
            width: rems(20);
            height: rems(20);
        }

    }
    &:hover{
        background-color: var(--color-blue);
        color: white;
        outline: none;
        .icon {
            fill: var(--color-white);
        }
    }

}

.dropdown__announcements {
    .dropdown__menu {
        border: 2px solid var(--color-green-xdark);
    }

    .dropdown__menu-title {
        background-color: var(--color-green-xdark);
    }

    .dropdown__menu-item {
        display: block;
        padding: rems(10);
        font-size: rems(14);
        position: relative;

        &:hover {
            background-color: white;
            color: var(--color-black);
        }

        &.unread {
            background-color: var(--color-green-light);

            &:hover {
                background-color: var(--color-green-light);
            }
        }

        h5 {
            margin-bottom: rems(8);
        }

        h5 + * {
            margin-top: 0;
        }

        p {
            font-size: rems(14);
            line-height: 1.3;
            margin-bottom: rems(8);
        }

        a {
            color: var(--color-teal);
            text-decoration: underline;
        }

        .close {
            background-color: transparent;
            border: none;
            color: var(--color-magenta);
            cursor: pointer;
            font-size: rems(40);
            line-height: rems(15);
            padding: rems(10);
            position: absolute;
            right: rems(-8);
            top: rems(-4);
            z-index: 900;

            .icon {
                fill: var(--color-magenta);
            }

            &:hover {
                color: var(--color-text);

                .icon {
                    fill: var(--color-text);
                }
            }

            &:focus {
                outline: 0;
            }
        }
    }
}


.dropdown__feedback{
    .feedback__menu {
        min-width: rems(280);
        position: absolute;
        z-index: 900;
        //width: 100%;

        &.feedback__menu--right{
            right: rems(0);
        }
        .feedback__menu-main{

            list-style: none;
            overflow: hidden;
            padding-left: 0;
            padding-right: 0;

            &.dropdown__menu--right{
                right: rems(0);
            }

            >li{
                background-color: white;
                border: 2px solid var(--color-pumpkin-dark);
                border-radius: radius(medium);
                box-shadow: 0 rems(2) rems(20) rgba(0,0,0,0.25);
                margin-top: rems(4);

                &.highlight {
                    border: 2px solid var(--color-green-xdark);
                }

                &:first-of-type {
                    border-top-right-radius: 0;
                    margin-top: 0;
                }


                a{
                    color: inherit;
                    text-decoration: none;
                }

                ul{
                    list-style: none;
                    margin-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                }

            }

        }
        .dropdown__menu-title{
            background-color: var(--color-pumpkin-dark);
            color: var(--color-white);
            font-size: rems(14);
            font-weight: 500;
            line-height: rems(14);
            display: flex;
            padding: rems(10);
            width: 100%;
            align-items: center;
            .icon {

                fill: var(--color-white);
                width: rems(20);
                height: rems(20);
                margin-right: rems(8);
                svg {
                    width: rems(20);
                    height: rems(20);
                }
            }
        }
        .highlight {
            .dropdown__menu-title {
                background-color: var(--color-green-xdark);
            }
        }
        .dropdown__menu-item {
            display: block;
            padding: rems(10);
            font-size: rems(14);
            position: relative;
            .icon, .icon svg {
                width: rems(30);
                height: rems(30);
                margin-right: 0;
            }
            &:hover {
                background-color: white;
                color: var(--color-black);
                .icon {
                    fill: var(--color-black);
                }
            }

            &.unread {
                background-color: var(--color-pumpkin-light);

                &:hover {
                    background-color: var(--color-pumpkin-light);
                }
            }

            h5 {
                margin-bottom: rems(8);
            }

            h5 + * {
                margin-top: 0;
            }

            p:not(.feedback--icons-holder p) {
                font-size: rems(14);
                line-height: 1.3;
                margin-bottom: rems(4);
                &:last-of-type {
                    margin-bottom: rems(8);
                }
            }

            a {
                color: var(--color-teal);
                text-decoration: underline;
            }
            .feedback__10 {
                .form__radio-button .form__radio-button-label {
                    font-size: rems(14);
                }
            }
            .feedback__5,.feedback__10 {
                .form__radio-button .form__radio-button-label {
                    font-size: rems(14);
                }
                .form__grid-element:first-of-type {
                    .form__radio-button .form__radio-button-label {
                        background-color: var(--color-red-xlight);
                    }
                }
                .form__grid-element:last-of-type {
                    .form__radio-button .form__radio-button-label {
                        background-color: var(--color-green-light);
                    }
                }

            }



        }
    }
}
@include breakpoint(for-large-phone-landscape-up) {

}
@include breakpoint(for-small-desktop-up) {
    .dropdown__menu-item{
        padding: rems(6) rems(10);
    }
}
