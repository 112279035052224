.conversation__page .conversation__grid-wrapper .conversation__grid .conversation__grid-right .conversation__grid-header {
    .conversation__grid-header-context-profile {
        display:none;
    }

}
.conversation__page .conversation__grid-wrapper .conversation__grid .conversation__grid-right .conversation__grid-header {
    viewing-view .profile__container {
        display: block;
        width: max-content;
        min-width: 60%;
        max-width: 80%;
        margin: rems(10) auto rems(16) auto;
        padding: rems(8);
        border: rems(1) solid var(--color-grey-light);
        border-radius: radius(medium);

        .profile__preview {
            margin-bottom: 0;
            text-align: left;

            .profile__image {
                display: none;
            }

            .profile__preview-text {
                margin-left: rems(8);

                h4 {
                    padding: 0;
                    font-size: rems(16);
                }

                p {
                    font-size: rems(14);
                    line-height: 1.2;

                    &:first-of-type {
                    }

                    &:last-of-type {
                        margin-top: rems(4);
                        margin-bottom: rems(4);
                    }
                }
                .profile__preview--organisation {
                    display: none;
                }
            }
        }
    }

    viewing-view {
        .form__message,
        .form.form--inline {
            margin-left: auto;
            margin-right: auto;
            max-width: 90%;
        }
        .form.form--inline {
            padding: rems(12) rems(12) rems(4) rems(12);
        }
    }

    onboarding-view {
        .profile__preview {
            .profile__preview-text {
                h4 {
                    text-align: left;
                }
            }
        }
    }

}

@include breakpoint(for-large-phone-landscape-up) {
    .conversation__page .conversation__grid-wrapper .conversation__grid .conversation__grid-right .conversation__grid-header {
        viewing-view .profile__container {
            .profile__preview {

                .profile__image {
                    display: flex;
                }

                .profile__preview-text {
                    .profile__preview--organisation {
                        display: flex;
                    }
                }
            }
        }
    }

    .conversation__page .conversation__grid-wrapper .conversation__grid .conversation__grid-right .conversation__grid-header {
        .conversation__grid-header-context-profile {
            display: block;
            width: max-content;
            min-width: 60%;
            max-width: 80%;
            margin: rems(10) auto rems(16) auto;
            padding: rems(4) rems(8);
            border: rems(1) solid var(--color-grey-light);
            border-radius: radius(medium);
            text-align: left;

            .profile__preview {
                margin-bottom: 0;
                text-align: left;

                .profile__image {
                    margin-top: rems(6);
                    margin-left: rems(2);
                    width: 3rem;
                    max-width: 3rem;
                    height: 3rem;
                    flex-basis: 3rem;
                    margin-right: rems(10);
                }

                .profile__status {
                    padding: rems(3);
                    top: rems(41);
                    left: rems(37);
                    height: rems(22);
                    border: rems(2) solid var(--color-white);
                    padding-top: 0;

                    .icon, svg {
                        width: rems(12);
                        height: rems(18);
                    }
                }

                .profile__preview-text {
                    margin-left: rems(8);

                    h4 {
                        padding: 0;
                        font-size: rems(16);
                    }

                    p {
                        font-size: rems(14);
                        line-height: 1.2;

                        &:first-of-type {
                        }

                        &:last-of-type {
                            margin-top: rems(4);
                            margin-bottom: rems(4);
                        }
                    }
                }

                .profile__preview-interests {
                    margin-top: 0;

                    li {
                        width: rems(24);
                    }

                    img {
                        height: rems(20);
                        width: rems(20);
                    }
                }
            }

            p {
                font-size: rems(14);
                line-height: 1.2;
            }
        }
    }
}
