.coho__dark {
    .profile__infobox {
        .profile__infobox-actions {
            a {
                color: var(--color-grey-light);
            }
        }
    }
    
    .card__info {
        .card__content {
            .card__content--fade {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAA8CAMAAACQLyDIAAAAflBMVEUmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYmJiYAAABH4vVzAAAAKnRSTlMF+/Xw6t/OwLJ3YlgxKSARC+TZ1Ma5qqWgmpWPioV/cGpQS0ZBPTgaFwAefjHuAAABAWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iPz48cjpSREYgeG1sbnM6cj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+PHI6RGVzY3JpcHRpb24geG1sbnM6eD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyI+PHg6Q3JlYXRlRGF0ZT4yMDIwLTEwLTE2VDEyOjUwOjUyKzAxOjAwPC94OkNyZWF0ZURhdGU+PC9yOkRlc2NyaXB0aW9uPjwvcjpSREY+PD94cGFja2V0IGVuZD0iciI/PoUtgzsAAABKSURBVAjXJcGFEYQAAASx5RV3d5f+G+RmSLh4hBroyYGvnrq6s7GyMDPhqK0jA5b2dLQ01FSUFOSYmvHXlJ8mxHw14qNvfamBcQMPHgPmTahKQwAAAABJRU5ErkJggg==')
            }
        }
    }
}