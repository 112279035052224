.csv-import__preview {
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    padding: rems(10) rems(10) rems(6) rems(10);
    background-color: var(--color-white);

    .compact__table {
        &.compact__table--narrow {
            border-color: var(--color-grey-xlight);
            &.compact__table--transactions {
    
                margin-bottom: rems(10);
    
                    tr {
                        border-color: var(--color-grey-xlight);
                        &:first-of-type {
                            border-top: 0;
                        }
                    th {
                        color: var(--color-grey-dark);
                    }
                    td, th {
                        font-family: Consolas, 'Courier New', Courier, monospace;
                        font-size: rems(13);
    
                    }
                    td {
                        line-height: 1.2;
                        padding-bottom: 0;
                        height: rems(18);
                        font-weight: 400;

                        &.label {
                            flex: 1 1 30%;
                                span {
                                    padding-left: rems(4);
                                }

                            &.imported {
                                span{
                                    background: var(--color-grey-light);
                                    border-radius: radius(small);
                                    padding: rems(1) rems(4);
                                }

                            }
                        }
                        &.value {
                            flex: 1 1 70%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }

                }
            }
    
        }
    }

    .csv-import__preview--footer {
        display: flex;
        flex-direction: row;
        padding: rems(20) rems(8) rems(10) rems(8);
        .csv-import__preview--footer-counter {
            font-size: rems(12);
            color: var(--color-grey-border);
            flex: 1 1 50%;
        }
    }

}