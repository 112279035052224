.feature__page, .howto__page {
    .feature__video-holder {
        video {
            //border: 1px solid var(--color-grey-light);
            box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
            outline: none;
            margin-bottom: rems(20);
            width: 100%;
        }
    }

    .feature__image-holder {
        img {
            width: 100%;
            box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.25);
            margin-bottom: rems(20);
        }
    }
}
