.profile__infobox{
    border-top: rems(1) solid var(--color-grey-xlight);
    font-size: rems(14);
    list-style: none;
    margin-bottom: rems(20);
    margin-top: rems(20);
    padding: 0;
    width: 100%;
    
    .profile__infobox-actions{
        padding-left: rems(10);
        //min-width: rems(90);
        a{
            color: var(--color-grey);
        }
    }    

    .profile__infobox-details{
        //overflow: hidden;
        //text-overflow: ellipsis;
        //white-space: nowrap;
        flex-basis: 80%;
        > span {
            display: inline-block;
            //min-width: rems(160);
            @include breakpoint(for-large-phone-landscape-up) {
                //min-width: rems(208);
            }            
        }
    }


    li{
        border-bottom: rems(1) solid var(--color-grey-xlight);


        &:last-child{
            margin-bottom: 0;
            //border-bottom: 0;
        }
    }

    .profile__infobox-property {
        color: var(--color-grey);
        padding-bottom: rems(10);
    }
    
    .profile__infobox-item{
        display: flex;
        justify-content: space-between;
        padding-bottom: rems(10);
        padding-top: rems(10);
        width: 100%;

        .loader{
            padding: 0;
        }
        
        &.hidden {
            color: var(--color-grey-border);
        }        

        input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]){
            margin-bottom: 0;
            &:focus { outline: none; }
        }

        &.profile__infobox--editing{
            flex-direction: column;

            .profile__infobox-actions{
                padding-left: 0;                
                input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                    margin-left: 0;
                    margin-bottom: rems(4); 
                    margin-right: rems(4);
                }               
                span {
                    margin-right: 0.25rem; input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                        margin-left: 0;
                    }
                }                 
            }
        }       
    }
    &.profile__infobox-wider {
        .profile__infobox-details{
            > span {
                //min-width: rems(128);
                @include breakpoint(for-large-phone-landscape-up) {
                    //min-width: rems(248);
                }
            } 
        }
        .profile__infobox-item{
            &.profile__infobox--editing{
                .profile__infobox-actions{
                    display: inline-block;
                    flex: none;
                    width: 100%;
                    input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                        margin-left: 0; 
                        margin-bottom: rems(4);
                    }
                    @include breakpoint(for-large-phone-landscape-up) {
                        display: flex;
                        flex: 1 0 auto;
                        textbox {
                            width: 43%;
                            margin-right: rems(8);
                        }
                        input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]) {
                            margin-bottom: 0;
                        }    
                    }
                }
            }       
        }        
    }    
}

@include breakpoint(for-tablet-portrait-up){
    .profile__infobox{
        .profile__infobox-item{
            &.profile__infobox--editing{
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                input:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]){
                    margin-right: rems(10);
                    width: 100%;
                }

                .profile__infobox-details {
                    flex-basis: 85%;
                }

                .profile__infobox-actions{
                    display: flex;
                    align-items: center;
                    flex: 1 0 auto;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    padding-left: 0;
                    //justify-content: space-between;
                    input { 
                        margin-left: rems(10);
                        flex-basis: 60%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 414px) and (max-width: 486px) {
    .profile__infobox{
        .profile__infobox-item{
            &.profile__infobox--editing{
                .profile__infobox-actions{    
                    max-width: fit-content;
                }
            }
        }
    }
}
