.icon {
    &.icon-rolling {
        fill: none;
        stroke: var(--color-black);

        &.icon--darkgreen {
            fill: none;
            stroke: var(--color-green-dark);
        }

        &.icon--darkcyan {
            fill: none;
            stroke: var(--color-cyan-dark);
        }
    
        &.icon--darkorange {
            fill: none;
            stroke: var(--color-orange-dark);
        }
    
        &.icon--darkgrey {
            fill: none;
            stroke: var(--color-grey-border); 
        }
    }


}