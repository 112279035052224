.supplier__intro--holder {

    border: 1px solid var(--color-violet-light);
    border-radius: radius(medium);
    padding: rems(8);
    height: 100%;

    &-image {
        border: 0;
        padding: 0;
        img {
            display: block;
            width: rems(64);
        }
    }

    // &-green {
    //     border-color: var(--color-green-xdark);
    //     color: var(--color-green-xdark);
    // }
    &-orange {
        border-color: var(--color-orange-xdark);
        color: var(--color-orange-xdark);
    }
    &-pink {
        border-color: var(--color-pink-xdark);
        color: var(--color-pink-xdark);
    }

    .supplier_images {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        max-width: 100%;

        img {
            width: 100%;
            max-width: 200px;
            max-height: 200px;
            object-fit: cover;
        }
    }

    .supplier__intro--label {
        font-size: rems(12);
        width: 100%;
        font-weight: 600;
        color: var(--color-grey-border);
        margin-bottom: 0;
        line-height: 1;

    }

    .supplier__intro--wrapper {
        p {
            font-size: rems(18);
            margin-bottom: 0;
        }
        .supplier__intro--category-wrapper {
                
            p {
                margin: 0;
                margin-bottom: rems(2);
                font-size: rems(12);
                color: var(--color-grey-border);
                line-height: 1;
            }

            h4 {
                font-weight: 400;
                font-size: rems(22);
                
            }
        }
        .info-badge {
            &.info-badge--large {
                margin-top: rems(4);
                padding: rems(0) rems(6);
                font-size: rems(13);
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .col.col__lg--10.col__lg--12override {
        width: 100%;
    }
    .col.col__lg--6.col__lg--5override {
        width: 41.6666666667%;
    }
}

@include breakpoint(for-small-desktop-up) {
    .col.col__lg--10.col__lg--12override {
        width: 83.3333333333%;
    }
    .col.col__lg--6.col__lg--5override {
        width: 50%;
    }
    .supplier__intro--holder {
        &-image {
            img {
                width: rems(146);
            }
        }

    }

}
