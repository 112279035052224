.coho__dark {
    .button {
        color: var(--color-grey-xxlight);
        background-color: var(--color-magenta-dark);
        &:hover {
            background-color: var(--color-magenta-xdark);
        }
    }

    .button--ghost {
        background-color: var(--color-grey-border);
        border-color: var(--color-grey);
        color: var(--color-black);
        box-shadow: 0 rems(2) rems(6) rgba(var(--color-grey-light-rgb),0.15);

        .icon {
            fill: var(--color-black);
        }
        &:hover {
            background-color: var(--color-grey);
            border-color: var(--color-grey-dark);
        }
    }

    .button--secondary {
        background-color: var(--color-cyan-xdark);
        border-color: var(--color-cyan-xdark);
        &:hover {
            background-color: var(--color-cyan-xxdark);
            border-color: var(--color-cyan-xdark);
        }
    }

    .button--focus {
        background-color: var(--color-orange-xxdark);
        border-color: var(--color-orange-xdark);
        .icon{
            fill: var(--color-black);
        }
        &:hover{
            .icon{
                fill: var(--color-black);
            }
            background-color: var(--color-orange-xdark);
            border-color: var(--color-orange-dark); 
        }
    }

    .button--payment {
        background-color: var(--color-green-xdark);

        &:hover {
            background-color: var(--color-green-dark);
        }
    }

    .button--cancel {
        color: var(--color-grey-light);
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
    }

    .button--collapse,
    .button--uncollapse {
        background-color: var(--color-teal-dark);
        color: var(--color-white);
        border-color: var(--color-teal);

        .icon {
            fill: var(--color-white);
        }

        &:hover {
            background-color: var(--color-teal);
        }

        &.button--active {
            background-color: var(--color-teal-dark);
            color: white;

            .icon {
                fill: var(--color-white);
            }
        }
    }

    .button--transparent-full{
        background-color: transparent !important;
        color: var(--color-white) !important;

        svg {
            fill: var(--color-white) !important;
        }

        &:hover, &:focus {
            background-color: var(--color-grey-dark) !important;
        }

        &.light-blue-bg{
            color: var(--color-grey-dark) !important;
            svg {
                fill: var(--color-grey-dark) !important;
            }
            background-color: var(--color-primary-dark) !important;
        }

        &.grey-bg{
            color: var(--color-grey-dark) !important;
            svg {
                fill: var(--color-grey-dark) !important;
            }
            background-color: var(--color-white) !important;
        }

        &.light-grey-bg{
            background-color: var(--color-grey-dark) !important;
        }
    }

    // Guide:
    // text > white
    // grey, light > grey
    loader-button {
      .button--loading{
        &.button--transparent-full::before {
          background-color: rgba(var(--color-white-rgb), 0.25);
        }

        &.button--transparent-full::after {
          background-color: rgba(var(--color-white-rgb), 0.75);
        }
      }

      .menu {
        background: var(--color-text);
        border: 1px solid var(--color-grey);
        .menu-options li {
          border-bottom: 1px solid var(--color-grey);
          svg {
              fill: var(--color-white) !important;
          }
        }
      }
    }


    .page__section--alt, .room {
        .button--ghost {
            box-shadow: 0 rems(2) rems(6) rgba(var(--color-grey-rgb),0.3);

            &:hover {
                border-color: var(--color-grey-border);
            }
        }
    }

    .button.button--info-icon {
        background-color: transparent;
    }


    info-button p.info-button--intro {
        color: var(--color-white);

        span {
            color: var(--color-form);
        }
    }

    #organisation-accounting-organisation-panel {
        .form__message.form__message--error {
            .form__message-text {
                .button {
                    background-color: var(--color-grey-xlight);
                    color: var(--color-pink-dark);
                    border-color: var(--color-grey-border);
                }
            }
        }
    }
}
