body{
    color: var(--color-text);
    font-family: $type__family;
    font-size: rems(16);
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: rems(24);
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p, ol, ul, dl, table{
    margin-top: 0;
    margin-bottom: rems(20);
}

a{
    color: var(--color-cyan);
}

a.plain-link {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

h1 + *, h2 + *, h3 + *, h4 + *, h5 + *, h6 + *{
    margin-top: rems(20);
}

h1, h2, h3, h4, h5, h6{
    font-weight: 800;
    margin: 0;
    margin-bottom: 0;
    letter-spacing: -0.1px;

}

strong{
    font-style: normal;
    font-weight: 700;
}



