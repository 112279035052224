.image__size {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        position: absolute;
        height: 100%;
        object-fit: cover;
        max-width: none;
        width: 100%;
    }

    &.image__size--square {
        height: 0;
        padding-bottom: 100%;
    }

    &.image__size--4x3 {
        height: 0;
        padding-bottom: 75%;
    }

    &.image__size--1x1 {
        height: 0;
        padding-bottom: 100%;
    }

    &.image__size--16x9 {
        height: 0;
        padding-bottom: 56.25%;
    }

    &.image__size--constrain {
        img {
            width: unset;
            max-height: rems(48);
            margin-left: rems(10);
        }
    }

}
