.room {
    .room__content-section {
        h3 {
            &.heading--status-badge {
                .status-badge {
                    background-color: white;
                    border-radius: radius(medium);
                    border: 1px solid var(--color-grey);
                    color: var(--color-grey);
                    display: inline-block;
                    font-size: rems(14);
                    font-weight: 700;
                    line-height: rems(17);
                    padding: rems(3) rems(5) rems(2) rems(4);
                    text-align: center;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: default;

                    &.status-badge--confirmed {
                        border-color: var(--color-green-xdark);
                        background-color: var(--color-green-dark);
                        color: white;
                    }

                    &.status-badge--unconfirmed {
                        border-color: var(--color-magenta-xdark);
                        background: var(--color-magenta-dark);
                        color: white;
                    }

                    &.status-badge--on {
                        border-color: var(--color-pink-dark);
                        color: var(--color-pink-dark);
                    }

                    &.status-badge--automatic {
                        border-color: var(--color-green-xdark);
                        color: var(--color-green-xdark);
                    }
                    &.status-badge--in-progress {
                        border-color: var(--color-orange-xdark);
                        background-color: var(--color-orange-dark);
                        color: white;                        
                    }
                    &.status-badge--unconfirmed {
                        border-color: var(--color-pink-xdark);
                        background: var(--color-pink-dark);
                        color: white;                        
                    }
                }
            }
        }
    }
}