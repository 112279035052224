.dashboard__container {
    background-color: var(--color-white);
    box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-blue-xdark-rgb),0.25);
    border-radius: radius(medium);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: rems(10);

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .update {
        position: absolute;
        right: rems(10);
        top: rems(10);
        text-transform: uppercase;
        color: var(--color-grey-dark);
        font-size: rems(12);
        font-weight: 400;
        padding: rems(4);
        line-height: 1;
        text-decoration: none;
        strong {
            padding-left: rems(4);
            font-weight: 800;
        }
        &:hover {
            cursor: pointer;
            outline: 1px dotted var(--color-grey-xdark);
        }
    }
    .update--info {
        padding-top: rems(4);
        text-transform: uppercase;
        color: var(--color-grey-dark);
        font-size: rems(12);
        font-weight: 400;
        @include breakpoint(for-tablet-portrait-up) {
            position: absolute;
            right: rems(100);
            top: rems(8);
        }
    }

    .dashboard__panel {
        border: 1px solid var(--color-grey-light);
        border-radius: radius(medium);
        padding: rems(6);
        //background-color: #fafafa;

        h5 {
            font-weight: 600;
            display: flex;
            align-items: center;
            color: var(--color-grey-dark);

            .icon, svg {
                fill: var(--color-grey-dark);
                width: rems(20);
                height: rems(20);
                margin-right: rems(6);
            }

            & + * {
                margin-top: 0;
            }
        }

        .card__status {
            margin-top: rems(8);
            padding: rems(4) rems(10);
        
            &:focus {
                outline: none;
            }
        
            &--action {
                background-color: var(--color-magenta-xdark);
        
                &:hover {
                    background-color: var(--color-magenta);
                }
            }
        
            &--active {
                background-color: var(--color-green-xxdark);
        
                &:hover {
                    background-color: var(--color-green-xdark);
                }
            }
        
            &--active-2 {
                background-color: var(--color-green-xdark);
        
                &:hover {
                    background-color: var(--color-green-dark);
                }
            }
        
            &--active-3 {
                background-color: var(--color-green-dark);
        
                &:hover {
                    background-color: var(--color-green);
                }
            }
        
            &--progress {
                background-color: var(--color-orange-xdark);
        
                &:hover {
                    background-color: var(--color-orange-dark);
                }
            }
        
            &--inactive {
                background-color: var(--color-grey);
                cursor: default;
        
                &:hover {
                    background-color: var(--color-grey-border);
                }
        
                &:focus {
                    outline: 0;
                }
            }
    
        
            .card__title-wrapper{
                .card__title {
                    line-height: 1.5;
                    font-size: rems(20);
                    font-weight: 700;
                    span {
                        font-weight: 400;
                        font-size: rems(17);
                    }
                    color: var(--color-white);
                    &:hover {
                        color: white;
                    }
                }
                .card__sub-title {
                    color: var(--color-white);
                    font-size: rems(10);
                    font-weight: 400;
                    line-height: 1;
                }
            }
        
            &--filter, &--filter-2 {
                .card__count {
                    font-size: rems(36);
                }
            }
        
            &--filter {
                .card__count {
                    color: var(--color-black-light);
        
                    &:hover {
                        color: var(--color-black);
                    }
                }
        
                .card__title {
                    color: var(--color-black-light);
        
                    &:hover {
                        color: var(--color-black);
                    }
                }
            }
        
            &--filter-2 {
                background-color: var(--color-grey-xlight);
        
                &:hover {
                    background-color: var(--color-grey-light);
                }
        
                .card__count {
                    color: var(--color-black-light);
        
                    &:hover {
                        color: var(--color-black);
                    }
                }
        
                .card__title {
                    color: var(--color-black-light);
        
                    &:hover {
                        color: var(--color-black);
                    }
                }
            }
        
        }   
    }

    .daily-tip {

        display: flex;
        flex-direction: row;
        align-items: center;

        border: 1px solid var(--color-teal);
        border-radius: radius(medium);
        padding: rems(6);
        background-color: var(--color-teal-xlight);

        position: relative;

        .daily-tip__icon {
            margin-right: rems(20);
            .icon-lightbulb, .icon-lightbulb svg {
                width: rems(64);
                height: rems(64);
            }
        }

        .daily-tip__content {
            padding-top: rems(24);
            h5 + * { margin-top: rems(2); }
            p { 
                line-height: 1.3;
                font-size: rems(15);
                font-weight: 400;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .dismiss {
            position: absolute;
            right: rems(10);
            top: rems(6);
            text-transform: uppercase;
            color: var(--color-teal-dark);
            font-size: rems(12);
            font-weight: 400;
            padding: rems(4);
            line-height: 1;
            strong {
                padding-left: rems(4);
                font-weight: 800;
            }
            &:hover {
                cursor: pointer;
                outline: 1px dotted var(--color-grey-xdark);
            }
        }
    }


}

@include breakpoint(for-large-phone-up) {
    .dashboard__container {
        .daily-tip {
    
            .daily-tip__content {
                padding-top: 0;
            }
        }
    }
}

@include breakpoint(for-wide-desktop-up) {
    .dashboard__container {
        .dashboard__panel {
            
            .card__status {            
                .card__title-wrapper{
                    .card__title {
                        font-size: rems(24);
                    }
                    .card__sub-title {
                        font-size: rems(12);

                    }
                }
            }
        }
    }
}