// SEE EG. IN panel.component.html
//USED AS A WRAPPER CLASS, NOTHING RELATED TO THE MODAL COMPONENT


.terms__modal-wrapper{
    position: relative;
    min-height: rems(160);
}

.terms__modal {
    background-color: rgba(var(--color-white-rgb), 0.95);
    @include shadow__low;
    border: rems(1) solid var(--color-grey-light);
    border-radius: radius(medium);
    bottom: rems(-10);
    display: flex;
    flex-direction: column;
    left: rems(0);
    overflow: auto;
    padding: rems(20);
    position: absolute;
    right: rems(0);
    top: rems(-10);
    //min-height: -webkit-fill-available;
}

.terms__modal-content{
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: rems(600);
    text-align: center;
}

.room{
    .terms__modal-content{
        padding-top: rems(50);
        justify-content: flex-start;
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .terms__modal-wrapper {
        min-height: rems(128);
    }
}
