.rent__payments{
    display: flex;
    flex-direction: column;
    width: 100%;
}

rent-due-card{
    display: flex;
    width: 100%
}

.rent__payment{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    margin-bottom: rems(10);
    position: relative;
    width: 100%;
}

.rent__payment-wrapper {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    padding: rems(10);
}

.rent__payment-content{
    display: flex;
    flex-direction: row;
    font-size: rems(14);
    height: 100%; 
}

.rent__payment-notes {
    padding-top: rems(5);
    p {
        font-size: rems(14);
        line-height: 1.3;
        margin-bottom: 0;
    }
}


.rent__payment-details-status, .rent__payment-details-payments, .rent__payment-details-assignments{
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin-bottom: rems(10);

    li{
        padding: 0;
        margin-right: rems(10);
    }
}

.rent__payment-details, .rent__payments-details {
    display: flex;
    flex-grow: 1 0 auto;
    flex-direction: column;
    width: 100%;
}

.rent__payment-edit{
    align-items: flex-start;
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
}

.rent__payment-date{
    font-weight: bold;
}

.rent__payment-icon{
    align-self: flex-start;
    display: flex;
    margin-right: rems(10);
    width: rems(20);

    .icon{
        height: rems(20);
        width: rems(20);


        svg, img{
            height: rems(20);
            width: rems(32);
        }    
    }

}

@include breakpoint(for-tablet-portrait-up) {

    .rent__payment-icon {
        align-self: center;
        height: rems(30);
        width: rems(30);

        .icon {
            height: rems(30);
            width: rems(30);


            svg, img {
                height: rems(30);
                width: rems(30);
            }
        }
    }

    .rent__payment-details, .rent__payments-details {
        flex-direction: row;
        width: calc(100% - 2.5rem);
    }

    .rent__payment-details-status, .rent__payment-details-payments, .rent__payment-details-assignments {
        align-items: center;
        flex-direction: row;
        margin-bottom: 0;
        margin-right: rems(10);
        width: 40%;
    }

    .rent__payment-details-form {
        display: flex;
        flex-direction: row;

        .form__group {
            margin-right: rems(10);
        }
    }

    .rent__payment-details-status {
        width: 30%;
    }

    .rent__payments-details {
        .rent__payment-details-status {
            width: 20%;
        }

        .rent__payment-details-payments {
            width: 12%;
        }

        .rent__payment-details-assignments {
            width: auto;
            flex: 1;
        }
    }

    .rent__payment-notes {
        padding-left: 20%;
    }

    .rent__payment-edit {
        align-items: center;
        display: flex;
        flex: 1 0 auto;
    }
}


// RENT PROFILE

a.rent__payment-details-tenant{
    text-decoration: none;
}

.rent__payment-profile {
    align-items: center;
    background-color: white;
    @include shadow__low;
    border: 1px solid var(--color-grey-light);
    margin-bottom: 0;
    padding: rems(5);
    padding-right: rems(10);

    .profile__image {
        height: rems(32);
        max-width: rems(32);
        width: rems(32);
    }

    .profile__preview-text {
        margin-left: rems(10);

        h6 {
            color: var(--color-text);
            font-size: rems(14);
        }
    }

    &:hover {
        background-color: var(--color-grey-xxlight);
        text-decoration: underline;

        .profile__preview-text {
            margin-left: rems(10);

            h6 {
                font-size: rems(14);
            }
        }
    }
}


.rent__payment{
    &.rent__payment--property{

        .rent__payment-content{
            flex-wrap: wrap;
        }

        .rent__payment-details{
            width: 80%;
        }

        .rent__payment-details-payments{
            .rent__payment-details-property-tenant{
                margin-top: rems(10);
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up){
    .rent__payment{
        &.rent__payment--property{

 
            .rent__payment-details{
                width: 80%;
            }

            .rent__payment-details-payments{
                margin-right: 0;
                width: 70%;

                .rent__payment-details-property-tenant{
                    flex-grow: 1;
                    margin-left: rems(10);
                    margin-right: 0;
                    margin-top: 0;

                    .rent__payment-profile{
                        width: 100%;
                    }
                }
            }

        }
    }
}

.rent__payment-tenants{
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    list-style: none;
    padding: rems(10) rems(0) rems(0) rems(0);
    margin-bottom: 0;
    width: 100%;

    li{
        margin-bottom: rems(10);
    }
}

@include breakpoint(for-large-phone-up){
    .rent__payment-tenants{
        flex-direction: row;
        flex-wrap: wrap;

        li{
            margin-right: rems(10);
        }
    }
}

@include breakpoint (for-tablet-portrait-up) {
    #credit-control-notifications-list {
        label.form__checkbox.form__input--small.form__checkbox {
            margin-bottom: 0;
            align-items: flex-end;
            display: flex;
        }
    }
}


