.button{
    &.button--editor{
        background-color: var(--color-white);
        border: 1px solid var(--color-grey-border);
        border-radius: radius(medium);
        box-shadow: 0 rems(2) rems(6) rgba(0,0,0,0.15);
        cursor: pointer;
        display: inline-block;
        font-family: $type__family;
        font-size: rems(16);
        font-weight: 700;
        height: rems(48);
        line-height: rems(20);
        margin-bottom: rems(10);
        min-width: 1px;
        padding: rems(11);
        position: relative;
        transition: all 0.2s ease-out;
        width: rems(48);


        .icon{
            display: block;
            fill: var(--color-text);
            position: absolute;
            height: rems(24);
            top: rems(12);
            width: rems(24);
        }

        &:hover{
            background-color: var(--color-grey-xlight);

            .icon{
                fill: var(--color-grey);
            }
        }
    }
}