.form__header{
    border-bottom: 1px solid var(--color-grey-light);
    margin-bottom: rems(10);
    padding: rems(20) 0 rems(30) 0;

    h1{
        + p{
            margin-top: rems(10);
        }
    }

    *:last-child{
        margin-bottom: 0;
    }

}