.section__header {
    border-bottom: 1px solid var(--color-grey-light);
    display: flex;
    flex-direction: column;
    padding-bottom: rems(8);
    margin-bottom: rems(20);

    &.section__header--small {
        padding-bottom: rems(10);
    }

    &.section__header--no-border {
        border-bottom: none;
        margin-bottom: 0;
    }
    &.section__header--no-padding {
        padding-bottom: 0;
    }

    &.section__header--slim {
        //@extend .section__header--no-border;
        padding-bottom: rems(10);
    }
}



.section__header-title {
    *:last-child {
        margin-bottom: 0;
    }
}

.section__header-search {
    flex-basis: 30%;
    padding-top: rems(20);
    .coho__search {
        padding-bottom: 0;  
    }
}

.section__header-right {
    width: 100%;
}
.section__header-property-title, .section__header-org-title, .section__header-right-upper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .section__header-image {
        width: 100%;
        max-width: rems(160);
        box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        margin-right: rems(16);
        flex-shrink: 0;

        &--small {
            display: none;
        }
    }

    .section__header-title {
        flex: 1;
        margin-top: rems(8);
    }
}

.section__header-right-upper--right {
    justify-content: flex-end;
}

.section__header--slim {
    .section__header-org-title {
        display: none;
    }
}

.section__header-actions {
    margin-top: rems(8);

    .form__group {
        margin-bottom: 0;
    }
}

.section__header-display--settings {
    .display--settings {
        display: inline-flex;
    }
}

.section__header-actions--group {
    display: flex;
    align-items: center;
    margin-top: rems(20);
    justify-content: space-between;

    .section__header-actions {
        margin-top: 0;
        min-width: rems(140);
        .button__group-item {
            max-height: rems(34);
        }
        .button {
            margin-bottom: 0;
        }
    }
}

.panel__actions {
    border-top: 1px solid var(--color-grey-xlight);
    margin-top: 1.25rem;
    padding-top: 0.625rem;
}
.page__section--alt {
    .panel__actions {
        border-color: var(--color-grey-light);
    }
}


.section__header-org-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .section__header-image {
        width: rems(64);
        height: rems(64);
        box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        margin-right: rems(16);
    }

}

.section__header-welcome {
    h1 { font-weight: 600;}
}

@include breakpoint(for-large-phone-landscape-up) {
    .section__header {
        flex-direction: row;
        .section__header-title {
            width: 100%;
        }
        .section__header-actions--group {
            margin-top: 0;
        }
    }

    .section__header-property-title, .section__header-org-title, .section__header-right-upper {
        flex-direction: row;
        align-items: center;

        .section__header-image {
            width: rems(152);
            max-width: rems(152);

            &--small {
                display: block;
                width: rems(60);
                max-width: rems(60);
                margin-right: 0;
                margin-top: 0;
                margin-left: rems(16);
            }
        }

        .section__header-title {
            margin-top: 0;
        }
    }

    .section__header-actions {
        margin-top: rems(20);
    }

    .section__header-org-title {
        .section__header-image {
            width: rems(64);
            height: rems(64);
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .section__header {
        flex-direction: row;
        align-items: center;
        padding-bottom: rems(20);
    }

    .section__header-title {
        width: 100%;

        span {
            white-space: nowrap;
        }
    }

    .section__header-search {
        flex-basis: 30%;
        padding-top: 0;

        .coho__search {
            #coho__search--results {
                &.visible {
                    min-width: rems(330);
                    right: 0;
                }
            }
        }
    }

    .section__header-actions {
        flex-shrink: 0;
        margin-top: 0;
    }

    .section__header-display--settings {
        padding-left: rems(30);
    }

    .section__header-actions--group {
        display: flex;
        align-items: center;
        margin-top: 0;
        justify-content: space-between;
        min-width: max-content;
    }

    .section__header--slim {
        .section__header-property-title, .section__header-org-title {
            display: flex;

            .section__header-image {
                width: rems(40);
                height: rems(40);
            }

            .section__header-title {
                h1 {
                    font-size: rems(24);
                }
            }
        }
    }
}

@include breakpoint (for-small-desktop-up) {
    .section__header-property-title, .section__header-right-upper {


        .section__header-image {
            width: rems(160);
            max-width: rems(160);
            align-self: flex-start;

            &--small {
                width: rems(60);
                max-width: rems(60);
            }
        }

    }

}


