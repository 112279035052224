
.supplier__table--container {

    border: 1px solid var(--color-violet-light);
    border-radius: radius(medium);
    padding-top: rems(2);
    background-color: var(--color-white);

    &:hover {
        box-shadow: 0 rems(2) rems(10) 0 rgba(var(--color-violet-xdark-rgb),0.15);
    }

    .supplier__table--holder {

        //border: 1px solid var(--color-violet-light);
        border-radius: radius(medium);
        padding: rems(8);
        height: 100%;

        .supplier__table--label {
            font-size: rems(12);
            width: 100%;
            font-weight: 600;
            color: var(--color-grey-border);
            margin-bottom: 0;
            line-height: 1;
        }

        .supplier__table--wrapper {
            p {
                font-size: rems(18);
                margin-bottom: 0;
            }
            .button__group--right {
                justify-content: left;
                .button--nomargin {
                    margin-bottom: rems(8);
                }
            }
        }

    }

    + .supplier__table--container {
        margin-top: rems(8);
    }
}

maintenance-issues-supplier-row + maintenance-issues-supplier-row {
    display: block;
    margin-top: rems(8);
}

@include breakpoint(for-tablet-portrait-up) {

    .supplier__table--container {

        .supplier__table--holder {

            .supplier__table--wrapper {
                .button__group--right {
                    justify-content: right;
                }
            }

        }

    }
}
