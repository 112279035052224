.user__group{
   left: rems(20);
   list-style: none;
   margin-bottom: 0;
   margin-top: rems(-40);
   padding: 0;
   position: absolute;
   vertical-align: top;

   li{
       background-color: white;
       border-radius: $size__radius--sm;
       box-shadow: 0 rems(2) rems(10) rgba(0,0,0,0.25);
       display: inline-block;
       font-weight: 700;
       line-height: rems(40);
       height: rems(40);
       margin-right: rems(5);
       overflow: hidden;
       text-align: center;

   }

    li.user__group-more {
        min-width: rems(40);
        padding-left: rems(8);
        padding-right: rems(8);
    }
}

.card__image{
    + .card__users{
        margin-top: rems(20);
    }
}
