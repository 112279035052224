.coho__dark {
    table {
        tr {
            border-color: var(--color-grey-border) !important;
        }

        .missing_gc_mandate_or_customer, .mandate_id_match_subscription {
            color: var(--color-text);
        }
    }
}
