.main__navigation{
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: rems(30);
    position: absolute;
    width: 100%;
    z-index: 101;

    &:not([class="open"]){
        display: none;
    }

    &.open{
        display: block;
    }

    li{

        a{
            color: white;
            display: block;
            padding: rems(10);
            text-decoration: none;

            &:hover{
                background-color: var(--color-blue-dark);
            }

            &:focus {
                outline: none;
            }

        }
    }

}

.main__menu-bar{
    background-color: var(--color-white);
    border-radius: radius(small);
    display: block;
    margin-top: rems(3);
    position: absolute;
    height: rems(3);
    width: rems(20);
}

.main__menu-icon{
    background-color: transparent;
    border: none;
    display: block;
    height: $size__header--sm;
    left: rems(0);
    padding: 0;
    position: absolute;
    width: rems(48);

    &:focus{
        outline: none;

        .main__menu-bar{
            background-color: var(--color-white);
        }

    }

}

.main__menu-icon{
    .main__menu-bar{
        left: 50%;
        top: 50%;
        transition: all 0.25s ease-out;

        &:nth-child(1){
            transform: translateY(rems(-10)) translateX(rems(-12));
        }

        &:nth-child(2){
            transform: translateY(rems(-2)) translateX(rems(-12));
            width: rems(12);
        }

        &:nth-child(3){
            transform: translateY(rems(6)) translateX(rems(-12));
        }
    }

    &.open{
        .main__menu-bar{
            &:nth-child(1){
                transform: translateY(rems(-2)) translateX(rems(-12)) rotate(45deg);
            }
    
            &:nth-child(2){
                opacity: 0;
                transform: translateY(rems(-2)) translateX(rems(-12)) scale(0.5);
            }
    
            &:nth-child(3){
                transform: translateY(rems(-2)) translateX(rems(-12)) rotate(-45deg);
            }          
        }
    }
}
@include breakpoint(for-large-phone-landscape-up) {
    .main__menu-icon{
        width: rems(64);
        height: $size__header--md;
    }
    .main__menu-icon{
        height: rems(80);
        width: rems(64);
    }
    
    .main__menu-icon{
        .main__menu-bar{
            &:nth-child(2){
                width: rems(16);
            }
        }
    }
}

@include breakpoint(for-large-desktop-up){
    .main__menu-icon{
        display: none;
    }

    .main__navigation{
        display: block;
        height: rems(80);
        width: auto;
        padding-bottom: 0;
        position: relative;

        &:not([class="open"]){
            display: block;
        }

        li{
            a{
                .badge:not(.no_count) {
                    border: none;
                    box-shadow: 0 2px 0px rgba(0,0,0,0.5);
                    position: absolute;
                    right: 0;
                    top: rems(16);
                }
            }
        }
    }
}