.feedback {
    //background-color: var(--color-orange-light);
    border: 1px solid var(--color-rating-gold);
    border-radius: radius(medium);
    //padding: rems(10);
    display: flex;
    flex-direction: column;
    max-width: rems(700);
    position: relative;
    &:hover {
        cursor: pointer;
    }
    
    .feedback--cover {
        display: none;
    }
    &.reduced {
        background-color: transparent;
        flex-direction: row;
        .feedback--cover {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .feedback__message {
            .feedback__message--month {
                display: none;
            }
        }
        p {
            display: none;
        }
        .feedback--icons {
            flex: 1 1 30%;
            .feedback--icons-holder {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 0px;
                padding: 0;
                min-width: 0;
                margin-left: rems(4);
                background: var(--color-white);
                border-radius: rems(12);
                .icon,.icon svg {
                    width: rems(24);
                    height: rems(24);
                }
                &.sad,&.unhappy,&.happy,&.good,&.great {
                    display: none;
                    &.active {
                        display: flex;
                        background-color: transparent;
                    }          
                }
            }
        }
    }
}

.feedback__holder {
    //background-color: var(--color-orange-light);
    border: 1px solid var(--color-rating-gold);
    border-radius: radius(medium);
    //padding: rems(10);
    display: flex;
    flex-direction: column;
    max-width: rems(700);
    position: relative;
    &:hover {
        cursor: pointer;
    }

    .feedback__upper, .feedback__lower {
        display: flex;
        flex-direction: column;
    }

    .feedback__lower {
        h5 + * { margin-top: 0; }
        h5 {
            margin-right: auto;
            font-size: rems(14);
            letter-spacing: -0.5px;
            font-weight: 600;
        }
    }
    
    .feedback--cover {
        display: none;
    }
    &.reduced {
        background-color: transparent;
        flex-direction: row;
        .feedback--cover {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .feedback__message {
            .feedback__message--month {
                display: none;
            }
        }
        .feedback__lower {
            h5 {
                display: none;
            }
        }
        p {
            display: none;
        }
        .feedback--icons {
            flex: 1 1 30%;
            .feedback--icons-holder {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 0px;
                padding: 0;
                min-width: 0;
                margin-left: rems(4);
                background: var(--color-white);
                border-radius: rems(12);
                .icon,.icon svg {
                    width: rems(24);
                    height: rems(24);
                }
                &.sad,&.unhappy,&.happy,&.good,&.great {
                    display: none;
                    &.active {
                        display: flex;
                        background-color: transparent;
                    }          
                }
            }
        }
    }
}

.feedback__message {
    padding: rems(10);
    h4 { 
        font-size: rems(14);
        letter-spacing: -0.5px;
        font-weight: 600;
        .feedback__message--badge {
            border: rems(3) double var(--color-white);
            background-color: var(--color-rating-gold);
            border-radius: radius(medium);
            color: var(--color-white);
            padding: rems(2) rems(6);
            font-weight: 700;
            margin-right: rems(4);
            white-space: nowrap;
            font-size: rems(12);
            text-transform: uppercase;
        }
        .feedback__message--month {
            display: inline;
        }
    }
    p {
        font-size: rems(12);
        color: var(--color-grey);
        line-height: 1.2;
        padding-top: rems(8);
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    h4 + p {
        margin-top: 0;
    }
    flex: 1 1 50%;
}
.feedback--icons {
    flex: 1 1 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: rems(10);
    .feedback--icons-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--color-white);
        border: 1px solid var(--color-rating-gold);
        border-radius: radius(small);
        padding: rems(4);
        min-width: 18.5%;
        &:hover {
            cursor: pointer;
            outline: 2px solid var(--color-rating-gold);
        }
        p {
            color: var(--color-grey);
            margin-bottom: 0;
            margin-top: rems(4);
            text-transform: uppercase;
            font-size: 2vw;
            line-height: 1.3;
        }

        margin-left: rems(5);
        &:first-of-type {
            margin-left: 0;
        }

        &.sad {
            outline-color: var(--color-pink-dark);
            
            &.active {
                background-color: rgba(var(--color-pink-light-rgb),0.75);
                border-color: var(--color-pink-dark);
                outline: 1px solid var(--color-pink-dark);
            }
            
        }
        &.unhappy {
            outline-color: var(--color-pink);
            
            &.active {
                background-color: rgba(var(--color-pink-light-rgb),0.75);
                border-color: var(--color-pink);
                outline: 1px solid var(--color-pink);
            }
            
        }
        &.happy {
            outline-color: #edc32b;
            
            &.active {
                background-color: rgba(var(--color-orange-light-rgb),0.75);
                border-color: #edc32b;
                outline: 1px solid #edc32b;
            }
            
        }
        &.good {
            outline-color: var(--color-green-dark);
            
            &.active {
                background-color: rgba(var(--color-green-light-rgb),0.75);
                border-color: var(--color-green-dark);
                outline: 1px solid var(--color-green-dark);
            }
            
        }
        &.great {
            outline-color: var(--color-green-xxdark);
            
            &.active {
                background-color: rgba(var(--color-green-light-rgb),0.75);
                border-color: var(--color-green-xxdark);
                outline: 1px solid var(--color-green-xxdark);
            }
            
        }
    }
}

.dropdown__menu-item {
    &.feedback__holder {
        border: 0;
        .feedback--icons {
            justify-content: flex-start;
            padding-left: 0;
            padding-right: 0;
            .feedback--icons-holder {
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
}

.rating__stars {
    display: flex;
    padding-top: rems(10);
    .rating__stars--icon {
        margin-right: rems(4);
        &:last-of-type {
            margin-right: 0;
        }
        &:hover {
            cursor: pointer;
        }
        &.hovered-1,&.selected-1 {
            .icon.icon--light-grey {
                fill: var(--color-pink-dark);
            }
        }
        &.hovered-2,&.selected-2 {
            .icon.icon--light-grey {
                fill: var(--color-warning);
            }
        }
        &.hovered-3,&.selected-3 {
            .icon.icon--light-grey {
                fill: var(--color-orange);
            }
        }
        &.hovered-4,&.selected-4 {
            .icon.icon--light-grey {
                fill: var(--color-green);
            }
        }
        &.hovered-5,&.selected-5 {
            .icon.icon--light-grey {
                fill: var(--color-green-dark);
            }
        }
        
    }
}

.card--manager {
    .profile__preview {
        p {
            margin-bottom: 0;
        }
    }
    textarea#comment {
        margin-bottom: 0;
    }
}

.compact__table.compact__table--feedback {
    tr.details {
        td {
            .card__header {
                h5 + p {
                    margin-top: 0;
                }
            }
            .card__content {
                flex-direction: column;
                .rating__stars {
                    padding-top: 0;
                    padding-bottom: rems(20);
                }
                .message {
                    font-size: rems(16);
                }
            }
        }
    }
}

@include breakpoint(for-large-phone-up) {
    .feedback, .feedback__holder {
        .feedback--icons {
            .feedback--icons-holder {
                padding: rems(8);
                margin-left: rems(5);
            }
        }
        &.reduced {
            max-width: rems(214);
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .feedback, .feedback__holder {
        .feedback--icons {
            .feedback--icons-holder {
                padding: rems(8);
                margin-left: rems(8);
                min-width: rems(80);
                p {
                    font-size: rems(11);
                }
            }
        }
        &.reduced {
            max-width: rems(214);
        }
    }
    .feedback__holder {
        .feedback__lower {
            h5 {
                margin-right: 0;
                margin-left: auto;
            }
            .feedback--icons {
                .feedback--icons-holder {
                    padding: rems(8);
                    margin-left: rems(8);
                    min-width: rems(50);
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .feedback {
        flex-direction: row;
        align-items: center;
    }
    .feedback__holder {
        .feedback__upper, .feedback__lower {
            flex-direction: row;
        }
    }
    .feedback--icons-group {
        justify-content: center;
        &:first-of-type {
            justify-content: flex-start;
        }
        &:last-of-type {
            justify-content: flex-end;
        }
    }

}