.coho__dark {

    #quick-access {

        &.hidden.clicked {

            .grid__layout {
                background: var(--color-black);
                border: 1px solid var(--color-grey);
                box-shadow: 0 rems(2) rems(15) rems(2) rgba(var(--color-grey-rgb),0.15);

                &::-webkit-scrollbar-track {
                    background: var(--color-grey-dark);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--color-grey-border);
                    border: rems(3) solid var(--color-grey-dark);
                }

                .quick-access__close {
                    color: var(--color-magenta-dark);

                    .icon {
                        fill: var(--color-magenta-dark);
                    }

                    &:hover {
                        color: var(--color-grey-border);

                        .icon {
                            fill: var(--color-grey-border);
                        }
                    }
                }
            }
        }
    }

    .toggle-bar {
        background-color: var(--color-grey-light);
    }
}
