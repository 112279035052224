viewing-hours, change-viewing-date-form { //JON TODO
    .flex_aligned {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        .bubble {
            background-color: var(--color-grey-xxlight);
            color: var(--color-grey);
            border: 1px solid var(--color-grey-xlight);
            padding: 4px 8px;
            border-radius: radius(medium);
        }
    }
}

.date_and_button { //JON TODO
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  loader-button {
    margin-top: 0;
    button {
      margin-bottom: 0;
    }
  }
}

viewing-view { //JON TODO
  .date_and_date_status {
    display: flex;
    align-items: center;
    gap: 20px;

    ul {
      margin: 0;
      padding: 0;
    }
  }
}
