.tags__container {
    display: flex;
    flex-direction: column;
    padding-bottom: rems(8);
    padding-top: rems(10);
    .tags__list {
        display: flex;
        flex-direction: row;
        margin-left: rems(-4);

        .tags__badge {
            border: 1px solid var(--color-brown);
            background-color: var(--color-brown-light);
            border-radius: radius(small);
            cursor: pointer;
            padding: rems(1) rems(8);
            margin-left: rems(4);
            margin-bottom: rems(4);
            white-space: nowrap;

            .tags__badge--tag {
                line-height: rems(24);
                font-size: rems(14);
                color: var(--color-brown-xdark);
                .button__options {
                    .button__menu {
                        bottom: rems(36);
                        min-width: rems(100);
                        .button__menu-item {
                            padding: rems(4);
                        }
                    }
                }
            }
            &.tags__badge-temp {
                background-color: transparent;
            }
            .tags__badge--tag-temp {
                color: var(--color-grey-border);
            }
            &.tags__badge--tag-removing {
                background-color: var(--color-warning-light);
            }

            .tags__badge--spinner, .tags__badge--remove {
                a {
                    text-decoration: none;
                    color: var(--color-brown-xdark);
                    font-weight: 600;
                    font-size: rems(14);

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
    autocomplete .form__input-group-append.form__input--tiny .form__input-group-button {
        margin-top: rems(6);
    }
    .button--tag {
        margin-left: rems(4);
        margin-bottom: 0;
        &.button.button--icon.button--small {
            min-width: rems(28);
            width: rems(28);
            min-height: rems(28);
            height: rems(28);
            .icon {
                left: rems(5);
                top: rems(5);
                svg {
                    width: rems(16);
                    height: rems(16);
                }
            }
        }
    }
    .tags__form {
        margin-top: rems(10);
        .button--tag {
            margin-left: 0;
        }
        autocomplete {
            input {
                max-width: 125px;
                &:not(:focus){
                    border-color: var(--color-grey-xlight) !important;
                }
            }
        }
    }
}

.tags__entity--brown {
    &.tags__container {
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-brown);
                background-color: var(--color-brown-light);
                .tags__badge--tag {
                    color: var(--color-brown-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-brown-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-brown-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--teal {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-teal);
                background-color: var(--color-teal-light);
                .tags__badge--tag {
                    color: var(--color-teal-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-teal-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-teal-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--cyan {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-cyan);
                background-color: var(--color-cyan-light);
                .tags__badge--tag {
                    color: var(--color-cyan-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-cyan-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-cyan-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--violet {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-violet);
                background-color: var(--color-violet-light);
                .tags__badge--tag {
                    color: var(--color-violet-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-violet-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-violet-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--pumpkin {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-pumpkin);
                background-color: var(--color-pumpkin-light);
                .tags__badge--tag {
                    color: var(--color-pumpkin-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-pumpkin-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-pumpkin-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--red {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-red);
                background-color: var(--color-red-light);
                .tags__badge--tag {
                    color: var(--color-text);
                }
                .tags__badge--tag-temp {
                    color: var(--color-red-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-red-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--green {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-green-xdark);
                background-color: var(--color-green-light);
                .tags__badge--tag {
                    color: var(--color-green-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-green-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-green-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--pink {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-pink);
                background-color: var(--color-pink-light);
                .tags__badge--tag {
                    color: var(--color-pink-xdark);
                }
                .tags__badge--tag-temp {
                    color: var(--color-pink-light);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-pink-xdark);
                    }
                }
            }
        }
    }
}
.tags__entity--grey {
    &.tags__container {
        padding-top: 0;
        .tags__list {
            .tags__badge {
                border: 1px solid var(--color-grey-border);
                background-color: var(--color-grey-xlight);
                .tags__badge--tag {
                    color: var(--color-text);
                }
                .tags__badge--tag-temp {
                    color: var(--color-grey-xlight);
                }
                &.tags__badge-temp {
                    background-color: transparent;
                    .tags__badge--tag-temp {
                        color: var(--color-grey-border);
                    }
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-grey-xdark);
                    }
                }
            }
        }
    }
}

.tags__container {
    &.tags__container--compact {
        .tags__list {
            .tags__badge {
                padding: rems(4) rems(6);
                .tags__badge--tag {
                    font-size: rems(11);

                }
            }
        }
    }
}

@include breakpoint(for-large-phone-landscape-up) {

    .tags__container {
        flex-direction: row;
        align-items: center;
        padding-bottom: rems(20);

        .tags__list {
            flex-wrap: wrap;
        }
        .tags__form {
            margin-top: 0;
            display: flex;
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: rems(208);
            margin-left: rems(4);
            autocomplete {
                input {
                    max-width: 125px;
                    &:not(:focus){
                        border-color: var(--color-grey-xlight) !important;
                    }
                }
            }
        }

        &.tags__container--compact {
            padding-top: rems(2);
            padding-bottom: 0;
            .tags__list {
                .tags__badge {
                    margin-bottom: 0;
                    padding: 0 rems(4) rems(2) rems(4);
                    .tags__badge--tag {
                        line-height: 1;
                    }
                }
            }
        }
    }
}









