@include breakpoint(for-tablet-portrait-up) {
    .conversation__page .conversation__grid-wrapper .conversation__grid .conversation__grid-right .conversation__grid-header {
        .form__message {
            width: max-content;
            margin-left: auto;
            margin-right: auto;
        }

        .context__list {
            .form__message {
                margin-left: 0;
            }
        }
    }
}
