.service__preview{
    display: flex;
    flex-direction: column;
    margin-bottom: rems(20);
    padding-bottom: rems(20);


    + .service__preview{
        border-top: rems(1) solid var(--color-grey-xlight);
        padding-top: rems(20);
    }
}

.service__preview-content, .service__preview-header{
    display: flex;
    flex-wrap: wrap;
}

.service__preview-content{
    flex-direction: column;
}

.service__preview-header{
    align-items: center;
    margin-bottom: rems(20);
    padding-right: 40px;
}

.service__preview-title{
    flex: 1 1 auto;
    padding-left: rems(10);
    width: calc(100% - 5rem);
}

.service__preview-description{
    p:last-child{
        margin-bottom: 0;
    }
}

.service__preview-image{
    // background-color: yellow;
    width: rems(40);

    img{
        height: auto;
        width: 100%;
    }
}

.service__preview-content{
    h4{
        display: flex;
        margin-bottom: rems(5);
        vertical-align: middle;
    }
}

.service__preview-title--selected{
    color: var(--color-teal-dark);

    &::before{
        background-image: url("data:image/svg+xml;utf8, <svg height='20' viewBox='0 0 30 30' width='20' xmlns='http://www.w3.org/2000/svg'><path fill='#{$color__forms_svg}' fill-rule='nonzero' d='m9.41662426 13.5369317c-.77971492-.7823799-2.04604304-.7845404-2.828423-.0048255-.78237997.779715-.78454041 2.0460431-.00482549 2.828423l5.03406323 5.0512696c.7815969.7842683 2.0516517.7842683 2.8332485 0l9.9659368-10c.7797149-.78238.7775544-2.0487081-.0048255-2.82842303-.78238-.77971492-2.0487081-.77755448-2.828423.00482549l-8.5493125 8.57853374z'/></svg>");
        content: "";
        display: inline-block;
        margin-right: rems(5);
        height: rems(20);
        width: rems(20);
    }
}

@include breakpoint(for-tablet-portrait-up){
    .service__preview-title{
        flex: 1 1 auto;
        padding-left: rems(10);
        width: calc(100% - 7.5rem);
    }
    .service__preview-image{
        width: rems(60);

    }
}
