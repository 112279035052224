.button{
    &.button--ghost{
        box-shadow: none;

        &:hover{
            box-shadow: none;
        }

        &:focus{
            box-shadow: none;
        }

        &:disabled, &.disabled{
            box-shadow: none;
    
            &:hover{
                box-shadow: none;
            }
        }
    }
}
