.card--supplier {
    .card__header.card__header--status {
    }
    .card__content {
        expand-text {
            .truncate__text {
                --lh: 1.3rem;
                --max-lines: 2;
                max-height: calc(var(--lh) * var(--max-lines));

                &.open {
                    max-height: none;
                }
            }

            p {
                font-size: rems(14);
                line-height: 1.4;
            }

            a.toggle__trigger {
                color: var(--color-grey);
                font-size: rems(14);
            }
        }
        .supplier--notes-container {
            padding-bottom: rems(10);
        }
        .card__content--right {
            .supplier--specialisms {
                .status-badge {
                    border-color: var(--color-grey-light);
                    background-color: var(--color-grey-xlight);
                    color: var(--color-black);
                    margin: 0 rems(4) rems(4) 0;
                }
            }
        }
    }

    .card__actions {
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .card--supplier {
        .card__content {
            display: flex;
            flex-direction: row;
            padding-right: rems(15);

            .card__content--left {
                flex: 0 1 60%;

                .supplier--notes-container {
                    padding-bottom: 0;
                }
            }

            .card__content--right {
                flex: 0 1 40%;

                .supplier--specialisms {
                    text-align: right;
                }
            }
        }
    }
}
