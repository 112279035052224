.notes {
    .notes__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rems(10) 0 rems(20) 0;

        h4 + * {
            margin-top: 0;
        }

        .button__group {
            width: unset;
        }
    }

    .notes__container {
        max-height: rems(240);
        width: 100%;
        display: flex;
        flex-direction: column;

        // form.add_history_item {
        //     display: flex;
        //     justify-content: space-between;
        //     border: 1px solid color(grey, xlight);
        //     background: var(--color-grey-xxlight);
        //     padding: 5px;
        //     border-radius: radius(medium);
        //     //gap: 10px;

        //     .add_history_item_inputs {
        //         display: flex;
        //         flex-direction: column;
        //         flex: 1;
        //     }
        // }

        .items__container {
            overflow: auto;
            display: flex;
            flex-direction: column;

            .load_more {
                padding: rems(20) 0 0 0;
                &:focus {
                    outline: 0;
                }
            }
        }

        &::-webkit-scrollbar {
            width: rems(12);
        }

        &::-webkit-scrollbar-track {
            background: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-grey-dark);
            border-radius: rems(20);
            border: rems(3) solid white;
        }

        // history-item {
        //     display: block;
        //     padding-bottom: rems(10);

        //     &:last-of-type {
        //         padding-bottom: 0;
        //     }

        //     .note {
        //         .note_content_and_actions {
        //             display: flex;
        //             justify-content: space-between;
        //             align-items: center;
        //             gap: 10px;

        //             .note__content {
        //                 display: flex;
        //                 flex-direction: column;

        //                 .note__header {
        //                     p {
        //                         margin: 0;
        //                     }

        //                     > p {
        //                         line-height: 1.2;
        //                         margin-bottom: rems(3);
        //                     }
        //                 }

        //                 p.action {
        //                     margin-bottom: rems(10);
        //                     font-size: rems(14);
        //                     line-height: 1.2;
        //                     white-space:pre-wrap;

        //                     &:last-of-type {
        //                         margin-bottom: 0;
        //                     }
        //                 }
        //             }

        //             .note__actions {
        //                 display: flex;
        //                 align-items: center;
        //             }
        //         }


        //         &__contextual {
        //             background: var(--color-teal-xlight);
        //             padding: rems(4);
        //             border-radius: radius(small);

        //             .u-text--small {
        //                 font-size: rems(12);
        //             }

        //             .note__content {
        //                 p {
        //                     color: var(--color-teal-xdark);
        //                 }
        //             }

        //             &.highlight {
        //                 background: var(--color-magenta-light);
        //                 .note__content {
        //                     p {
        //                         color: var(--color-magenta-xdark);
        //                     }
        //                 }

        //             }
        //         }
        //     }
        // }
    }

    .notes__container.conversation {
        padding-right: 0;
    }
}

history-items {
    .notes {
        .notes__container {
            .items__container {
                padding: rems(6) 0;
            }
        }
    }
}
history-panel {
    history-items {
        .notes {
            .notes__container {
                .items__container {
                    padding: rems(10);
                }
            }
        }
    }
}

.history__item {
    .history__item--note-container--header {
        display: inline-block;
        font-size: rems(12);
        padding-bottom: rems(6);
        line-height: 1.3;
    }
    span {
        &.category-icon, &.property-icon, &.room-icon, &.tenancy-icon {
            display: none;
        }
    }
}

.compact__table {
    .notes {
        .notes__container {
            &::-webkit-scrollbar-track {
                background: var(--color-grey-xxlight);
            }
            &::-webkit-scrollbar-thumb {
                border: rems(3) solid var(--color-grey-xxlight);
            }
        }

        .notes__container.conversation {
          &::-webkit-scrollbar-track {
            background: none;
          }
          &::-webkit-scrollbar-thumb {
            border: none;
          }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .history__item {
        .history__item--note-container--header {
            display: flex;
            align-items: center;

        }
        span {
            &.category-icon, &.property-icon, &.room-icon, &.tenancy-icon {
                overflow: visible;
                display: inline;
                position: relative;
                width: rems(16);
                height: rems(16);
                padding: 0 rems(8) 0 0;
                
                .icon, svg {
                    width: rems(16);
                    height: rems(16);
                }
                
                .icon {
                    margin-right: 0;
                    margin-top: 0;
                    cursor: pointer;
                    svg {
                        fill: var(--color-cyan-dark);
                    }
                }

                .tooltip {
                    background-color: var(--color-cyan-dark);
                    border-radius: radius(small);
                    box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
                    color: white;
                    display: none;
                    font-size: rems(12);
                    line-height: rems(12);
                    left: rems(20);
                    padding: rems(5);
                    position: absolute;
                    top: rems(-20);
                    white-space: nowrap;
                }
    
                &:hover {
                    .tooltip {
                        display: block;
                        z-index: 100;
    
                        &:hover {
                            display: none;
                        }
                    }
                }
            }
            &.category-icon {
                display: inline-block;
                //width: rems(16);
                margin-left: rems(-2);
                .icon {
                    svg {
                        fill: var(--color-grey-dark);
                    }
                }
                .tooltip {
                    background-color: var(--color-grey-dark);
                }
            }
        }

        .mobile-text {
            display: none;
        }
        &.history__item--log {
            span {
                &.property-icon, &.room-icon, &.tenancy-icon {
                    padding-right: 0;
                }
            }

        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    .notes {
        .notes__header {
            padding: 0;
            //padding-bottom: rems(20);
        }
        .notes__container {
            min-height: rems(320);
            max-height: rems(360);
        }
    }
    maintenance-issue,.compact__table tr.details {
        .section__tabs {
            .tab-panes {
                .tab-pane {
                    .notes__container {
                        min-height: rems(360);
                        max-height: 0;
                    }
                }
            }

        }
    }
}
