body{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site__content{
    flex: 1;
}

.container{
    @if($dev == true){
        background-color: rgba(0,0,0,0.1);
    }

    margin-left: auto;
    margin-right: auto;
    padding-left: rems(20);
    padding-right: rems(20);
   /* max-width: rems(768);*/
    width: 100%;

    &--room {
        padding-left: 0;
        padding-right: 0;
    };

}


hr {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: rems(20);
    margin-top: rems(10);

    &.hr__settings {
        margin-top: rems(5);
        margin-bottom: rems(10);
    }

    &.hr__settings-first {
        border-color: var(--color-black);
    }
}

/*.hidden {
    display: none;
}*/

.hide-for-mobile {
    display: none;
}

.show-for-mobile {
    display: block;
}

.show-for-large {
    display: none;
}

@include breakpoint(for-tablet-portrait-up) {
    .hide-for-mobile {
        display: block;
    }

    .show-for-mobile {
        display: none;
    }
}
@include breakpoint(for-tablet-landscape-up){
    .container{
        max-width: none;
        width: rems(1120);
        &--room {
            padding-left: rems(20);
            padding-right: rems(20);
        }
    }

    .show-for-large {
        display: block;
    }

    .wide-nav {
        .site__header--logged-in {
            .container {
                width: rems(960);
            }
        }
        .container{
            width: rems(960);
        }
    }
}

// @include breakpoint(for-small-desktop-up){
//     .container{
//         max-width: none;
//         width: rems(1200);
//     }
// }
