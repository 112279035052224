availabilities-table {
    .compact__table {
        &.compact__table--narrow {
            tr {

                td, th {
                    span {
                        &.warning-icon, &.profile-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    availabilities-table {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    td, th {
                        .icon, svg {
                            width: rems(16);
                            height: rems(16);
                        }

                        .icon {
                            &.icon-warning {
                                svg {
                                    fill: var(--color-pink-dark);
                                }
                            }

                            &.icon-viewing {
                                svg {
                                    fill: var(--color-grey);
                                }
                            }
                        }

                        span {
                            &.warning-icon, &.profile-icon {
                                display: block;
                                position: relative;

                                .tooltip {
                                    background-color: var(--color-grey);
                                    border-radius: radius(small);
                                    box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
                                    color: white;
                                    display: none;
                                    font-size: rems(12);
                                    line-height: rems(12);
                                    left: rems(20);
                                    padding: rems(5);
                                    position: absolute;
                                    top: rems(-1);
                                    white-space: nowrap;
                                }

                                &:hover {
                                    .tooltip {
                                        display: block;
                                        z-index: 100;

                                        &:hover {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &.warning-icon {
                                margin-right: rems(8);
                                &:hover {
                                    cursor: default;
                                }
                                .tooltip {
                                    background-color: var(--color-pink-dark);
                                }
                            }
                            &.mobile-text {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}