.accordion{
    background-color: white;
    border-radius: radius(medium);
    box-shadow: 0 0 rems(20) rgba(0,0,0,0.15);
    margin-bottom: rems(20);
}

.accordion__title{
    /*border-bottom: 1px solid var(--color-grey-xlight);*/

    .accordion__title-button{
        background-color: white;
        border: none;
        border-top-left-radius: radius(medium);
        border-top-right-radius: radius(medium);
        cursor: pointer;
        display: block;
        font-family: $type__family;
        font-size: rems(16);
        font-weight: 700;
        padding: rems(15) rems(20) rems(15) rems(60);
        position: relative;
        text-align: left;
        width: 100%;

        &:hover{
            color: var(--color-teal);
        }

        &::after{
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z'/> </svg>");
            content: "";
            display: block;
            position: absolute;
            height: rems(20);
            left: rems(20);
            top: rems(18);
            //transform: scale(-1);
            transition: transform 0.2s ease-out;
            width: rems(20);
        }


        &:focus{
            outline: none;
            text-decoration: underline;

            &::after{
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z'/> </svg>");
            }
        }

    }

    &.accordion__title--with-action{
        display: flex;
        border-bottom: rems(1) solid var(--color-grey-xlight);
        margin-bottom: rems(20);

        .accordion__title-button{
            border-top-right-radius: 0;
        }

        .accordion__title-action{
            background-color: transparent;
            border: none;
            border-left: rems(1) solid var(--color-grey-xlight);
            cursor: pointer;
            padding: rems(10) rems(15);

            .icon{
                fill: var(--color-grey);
                svg{
                    height: rems(20);
                    width: rems(20);
                }
            }

            &:last-child{
                border-top-right-radius: radius(medium);
            }

            &:hover{
                background-color: var(--color-grey-xxlight);

                .icon{
                    fill: var(--color-text);
                }
            }

            &:focus{
                //@include shadow__input_active-inset;
                outline: none;
            }

            &.action--green {
                &:hover{
                    background-color: var(--color-green-light);

                    .icon{
                        fill: var(--color-green-xdark);
                    }
                }
            }
            &.action--pink {
                &:hover{
                    background-color: var(--color-magenta-light);

                    .icon{
                        fill: var(--color-magenta-xdark);
                    }
                }
            }
        }
    }


    &.accordion__title--with-status{
        .accordion__title-button{
            display: flex;
            flex: 1 0 auto;
            flex-direction: row;
            justify-content: space-between;

            .icon{
                display: block;
                height: rems(24);
                margin-right: rems(10);
                width: rems(24);

                svg{
                    height: rems(24);
                    width: rems(24);
                }
            }


            .status-badge{
                margin-left: auto;
                text-decoration: none;
            }


            &:hover, &:active, &:focus{
                text-decoration: none;
                + .status-badge{
                    text-decoration: none;
                }
            }


        }
    }


}
.accordion__description {
    display: none;
}

.accordion__content {
    display: block;
    padding: rems(0) rems(20);
}

.accordion--unpadded {
    .accordion__content {
        padding: 0;
    }
}

.accordion {
    &.closed {

        .accordion__title {
            .accordion__title-button {
                border-bottom-left-radius: radius(medium);
                border-bottom-right-radius: radius(medium);

                &::after {
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path d='M9.586,21.586 C8.805,22.367 8.805,23.633 9.586,24.414 C10.367,25.195 11.633,25.195 12.414,24.414 C20.414,16.414 20.414,16.414 20.414,16.414 C21.195,15.633 21.195,14.367 20.414,13.586 C12.414,5.586 12.414,5.586 12.414,5.586 C11.633,4.805 10.367,4.805 9.586,5.586 C8.805,6.367 8.805,7.633 9.586,8.414 C16.172,15.000 16.172,15.000 16.172,15.000 C16.172,15.000 9.586,21.586 9.586,21.586 z'/> </svg>");
                }


                + button {
                    border-bottom-right-radius: radius(medium);
                }
            }

            &.accordion__title--with-action {
                .accordion__title-button {
                    border-bottom-right-radius: 0;
                }
                border-bottom: 0;
                margin-bottom: 0;
            }
        }

        .accordion__content {
            display: none;
        }

        .accordion__description {
            display: block;
        }
    }
}

.accordion.accordion--small.accordion--flat {
    .accordion__title {

        .accordion__title-button {
            padding: rems(6) rems(20) rems(6) rems(24);

            &:after {
                background-size: 100%;
                top: rems(6);
                left: rems(-3) !important;
            }
        }
    }

}

.accordion + .button__group {
    padding-top: rems(20);
}

accordion {
    .send_message_recipients {
        .recipients {
            display: flex;
            gap: 10px;
            img {
                border-radius: 100px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

// Accordion changes for FAQS page
.faqs, .fasttrack {
    .accordion {
        margin-bottom: 0.25rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);

        .accordion__content {
            padding: 0.75rem 1.25rem 1rem 1.25rem;
        }
    }
}

.form.form--inline {
    .accordion.accordion--small.accordion--flat {
        background: transparent;
        .accordion__title {
            background-color: transparent;
            .accordion__title-button {
                background-color: transparent
            }
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    .accordion:not(.accordion--small) {
        .accordion__title {

            .accordion__title-button {
                font-size: rems(20);
            }
        }
    }
}
