.card__knowledge{
    background-color: var(--color-pumpkin-dark);
    color: white;
    &.hidden {
        display: none;
    }
    .card__header {
        border-radius: radius(medium);
        &:hover {
            background-color: var(--color-pumpkin-xdark);
            cursor: pointer;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0;
        .card__icon {
            padding-top: rems(8);
            padding-left: 0;
            max-width: rems(48);
            overflow: visible;
            margin-right: rems(20);
            .icon, .icon,svg {
                fill: var(--color-white);
            }
        }
        .card__title {
        }
    }
    .card__content {
        padding: 0 rems(4);
        &--inner {
            background-color: var(--color-white);
            border-top-left-radius: radius(medium);
            border-top-right-radius: radius(medium);
            padding: rems(16);
            padding-bottom: rems(12);
            color: var(--color-text);
            display: flex;
            flex-direction: column;
            .card__content--left {
                .knowledge__thumbnails {
                    padding-top: rems(20);
                    display: flex;
                    .knowledge__thumbnail--holder {
                        width: rems(112);
                        padding: rems(5);
                        border: rems(1) solid var(--color-pumpkin-light);
                        margin-left: rems(8);
                        &:first-of-type {
                            margin-left: 0;
                        }
                        &:hover {
                            background-color: var(--color-pumpkin-light);
                            border: rems(1) solid var(--color-pumpkin-dark);
                            cursor: pointer;
                        }
                        &.active {
                            border: rems(1) solid var(--color-pumpkin-dark);
                            outline: rems(1) solid var(--color-pumpkin-dark);
                            &:hover {
                                background-color: transparent;
                                cursor: default;
                            }
                        }
                    }
                    img {
                       display: block;
                       width: 100%;
                    }

                }
            }
            .card__content--right {
                padding-top: rems(40);
                .embed-container {
                    position: relative;
                    padding-bottom: 56.25%;
                    @media (min-width:72rems) {
                       padding-bottom: 57.25%;
                    }
                    height: 0;
                    overflow: hidden;
                    max-width: 100%;
                    border-radius: radius(small);
                    @include shadow__high;
                    &.hidden {
                        display: none;
                    }
                    iframe, object, embed {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

            }
        }
    }
    .card__actions {
        padding: 0 rems(4) rems(4) rems(4);
        background-color: var(--color-pumpkin-dark);
        border-bottom-left-radius: radius(medium);
        border-bottom-right-radius: radius(medium);
        &--inner {
            background-color: var(--color-white);
            padding: 0 rems(16) rems(8) rems(16);
            border-bottom-left-radius: radius(medium);
            border-bottom-right-radius: radius(medium);
        }
        .button {
            &.button--transparent {
              &.button--feedback {
                  color: var(--color-pumpkin-dark);
                  background-color: transparent;
                  border: 0;
                  box-shadow: none;
                  &:hover {
                      background-color: transparent;
                      outline: 1px dotted var(--color-pumpkin-dark);
                  }
                  &.button--icon {
                      .icon, .icon svg {
                          fill: var(--color-pumpkin-dark);
                      }
                  }
              }
            }
          }

    }
    &:not(.open) {
        .card__content, .card__actions {
            display: none;
        }
    }
    &.open {
        .card__header {
            &:hover {
                background-color: transparent;
            }
        .card__title {
            h5 {
                font-size: rems(24);
                line-height: rems(24);
            }
        }
        }
    }
}

maintenance-manager-overview,knowledge-article {
    display: block;
    width: 100%;
}

@include breakpoint(for-tablet-landscape-up) {
    .card.card__knowledge {
        .card__content {
            .card__content--inner {
                flex-direction: row;
                justify-content: space-between;
                p {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                .card__content--left {flex-basis: 32% }
                .card__content--right {
                    flex-basis: 64%;
                    padding-top: 0;
                    min-height: rems(360);
                }
            }
        }
    }
}
