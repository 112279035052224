.conversation__page {
    .conversation__grid-wrapper {

        .conversation__grid {
            .conversation__grid-list {
                a:focus {
                    outline: 0;
                }

                &.hideList {
                    display: none;
                }
            }

            .conversation__grid-right {
                &.showMessages {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    min-height: 100%;
                    overflow: hidden;

                    .conversation__grid-header {
                        display: block;
                        text-align: center;

                        .conversation__grid-header-context {
                            display: none;

                            maintenance-view-issue {
                                > p, file-uploader {
                                    display: none;
                                }
                            }

                            &.visible {
                                display: block;
                                overflow-y: scroll;
                                overflow-x: hidden;
                                height: 93%;
                            }
                        }
                    }

                    .conversation__grid-messages {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        overflow: hidden;

                        .conversation__grid-messages-content {
                            flex: 1;
                            width: 100%;
                            overflow-y: scroll;
                            display: flex;
                            padding: rems(10);
                        }

                        .conversation__grid-messages-footer {
                        }
                    }
                }
            }
        }
    }
}
.conversation__page {
    .conversation__grid-wrapper {
        .conversation__grid {
            .conversation__grid-right {
                conversation-details {
                    &.fullheight {
                        display: block;
                        height: 100%;
                        .conversation__grid-header {
                            height: 100%;
                        }
                    }

                    .conversation__grid-header {
                        height: auto;
                    }

                }
            }
        }
    }
}
//this is a custom one to make this whole thing look better
@media only screen and (min-width: 640px) {
    .conversation__page {
        .conversation__grid-wrapper {
            .conversation__grid {
                .conversation__grid-list {
                    &.hideList {
                        display: flex;
                        flex-direction: column;
                        overflow: auto;
                    }
                }

                .conversation__grid-right {
                    .conversation__grid-header {
                        .conversation__grid-header-context {
                            display: none;

                            &.visible {
                                display: block;

                            }
                        }
                    }

                    .conversation__grid-messages {
                        .conversation__grid-messages-content {
                            a:not(.file_document_download):not(.linkified) {
                              //  display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-small-desktop-up) {
    .conversation__page {
        .conversation__grid-wrapper {
            .conversation__grid {
                .conversation__grid-right {
                    &.showMessages {
                        flex-direction: row-reverse;

                        .conversation__grid-header {
                            height: 100%;
                            max-width: rems(384);

                            .conversation__grid-header-context {
                                display: block;
                                min-width: rems(330);
                                overflow-y: scroll;
                                overflow-x: hidden;
                                height: 93%;

                                &.visible {
                                    display: block;
                                }
                            }
                        }
                    }

                    .conversation__grid-header {
                        .conversation__grid-header-context {
                            display: block;

                            &.visible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include breakpoint(for-large-desktop-up) {
    .conversation__page {
        .conversation__grid-wrapper {
            .conversation__grid {
                .conversation__grid-right {
                    &.showMessages {
                        .conversation__grid-header {
                            max-width: rems(480);
                        }
                    }
                }
            }
        }
    }
}
