//THE WHOLE WRAPPER WITH THE BACKGROUND COLOR
.modal__wrapper {
    display: none;
    &.open{
        background-color: rgba(var(--color-blue-dark-rgb), 0.85);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//THE MODAL
.modal {
    //It comes and sits over everything
    z-index: 1000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    transform: scale(1);
    position: relative;
}

//HEADER
.modal__header{
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--color-grey-light);
  padding: 10px;

    h2 {
        flex-grow: 1; /* Allow h2 to fill available space */
        white-space: nowrap; /* Keep the title in a single line */
        overflow: hidden; /* Hide overflow */
        text-overflow: ellipsis; /* Show ellipsis for overflow */
    }

  .button__group {
      display: flex;
      flex-shrink: 0;
      margin-top: 0; //comes included with the +h2, so need to correct it
      width: auto; //so that it doesn't have this weird expand thing
  }

  .toggle_size {
    display: none;
  }
}

//BODY
.modal__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
    height: 100%;

  .form {
    &:not(.form--payment,.form--inline) {
      padding-left: 0;
      padding-right: 0;
    }
  }

    p {
        word-wrap: break-word; /* Break long words */
        overflow-wrap: break-word; /* Ensure text breaks correctly */
        white-space: normal; /* Allow normal wrapping of text */
    }

    .document_viewer {
        iframe {
            min-height: 80vh;
        }
    }
}

//FOOTER
.modal__footer{
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-grey-light);
  padding: 10px;

  .button{
    margin-bottom: 0;
  }
}

//RESPONSIVENESS
@include breakpoint(for-large-phone-up) { // 414px
}

@include breakpoint(for-large-phone-landscape-up) { // 560px
  .modal {
    height: fit-content;
    max-height: 90%;
    width: 90%;
    border-radius: radius(medium);
    box-shadow: 0 0 20px var(--color-blue-dark);
  }
}

@include breakpoint(for-tablet-portrait-up) { // 768px
    .modal {
        max-height: 80%;
        width: 70%;

        &.hasDropdownElement {
          overflow: visible;
        }
    }
    .modal.full-height {
        max-height: 90%;
    }
    .modal.large-width {
      width: 90%;
    }

    .modal__header, .modal__body, .modal__footer {
      padding: 10px 20px;
    }

    .modal__header {
      .toggle_size {
        display: block;
      }
    }

    .modal__body {
      &.hasDropdownElement {
        overflow: visible;
      }

        .document_viewer {
            iframe {
                min-height: 70vh; //needed for chrome
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) { // 1152px
  .modal {
    width: 60%;
  }
  .modal.large-width {
    width: 80%;
  }
}

@include breakpoint(for-small-desktop-up) { // 1280px
  .modal {
    width: 50%;
  }
  .modal.large-width {
    width: 70%;
  }
}

@include breakpoint(for-large-desktop-up) { // 1440px
  .modal {
    width: 40%;
  }
  .modal.large-width {
    width: 60%;
  }
}

@include breakpoint(for-wide-desktop-up) { // 1888px
  .modal {
    width: 30%;
  }
  .modal.large-width {
    width: 50%;
  }
}




