.card__category {

    .card__click-wrapper {
        padding: rems(10);
        &:hover {
            cursor: pointer;
            background-color: var(--color-cyan-light);
            box-shadow: 0 rems(8) rems(16) 0 rgba(var(--color-cyan-xdark-rgb),0.15);
        }
    }

    .card__title-wrapper {
        .card__title {
            line-height: 1.2;
            font-size: rems(16);
            min-height: rems(48);
        }

        .card__sub-title {
            color: var(--color-grey);
            font-size: rems(13);
            font-weight: 400;
            line-height: 1;
            padding-bottom: rems(4);

            span {
                &.card__category--override {
                    background-color: var(--color-teal-xlight);
                    border: 1px solid var(--color-teal);
                    border-radius: rems(4);
                    color: var(--color-grey-dark);
                    margin-bottom: rems(5);
                    margin-right: rems(5);
                    padding: rems(2) rems(5);
                    font-weight: 700;
                }

                &.card__category--override--custom {
                    background-color: var(--color-teal-xlight);
                    border: 1px solid var(--color-teal);
                    border-radius: rems(4);
                    color: var(--color-grey-dark);
                    margin-bottom: rems(5);
                    margin-right: rems(5);
                    padding: rems(2) rems(5);
                    font-weight: 700;
                }

                &.card__category--override--disabled {
                    background-color: var(--color-grey-xxlight);
                    border: 1px solid var(--color-grey);
                    border-radius: rems(4);
                    color: var(--color-grey-dark);
                    margin-bottom: rems(5);
                    margin-right: rems(5);
                    padding: rems(2) rems(5);
                    font-weight: 700;
                }
            }
        }
    }

    .card__actions {
        margin-top: auto;
        padding: rems(20) 0 0 0;
        width: 100%;

        .button__group-item:first-of-type {
            .button.button--cancel {
                padding-left: 0;
                border-left: 0;
                min-width: 0;
            }
        }
    }

    &.card__category--image {
        .card__click-wrapper {
            padding: rems(15) rems(10) rems(10) rems(15);
            height: 100%
        }

        svg-icon {
            [class*="icon-"] {
                width: rems(48);
                height: rems(48);
            }
        }


        .card__title-wrapper {
            .card__title {
                margin: rems(4) 0;
                min-height: 0;
            }
        }
        .card__actions {
            margin-top: 0;
            padding: 0;
            width: 100%;

        }
    }
}

.card__category.disabled {
  color: var(--color-grey-dark);
  background-color: var(--color-grey-xxlight);
}

.row {
    &.row--space {
        .col {
            &.category__chooser {
                margin-bottom: rems(10);
                .card__category {
                    .card__title-wrapper {
                        .card__title {
                            min-height: 0;
                        }
                    }
                }
                &:last-of-type {
                    margin-bottom: rems(20);
                }
            }
        }
    }
}

