.coho__dark {
    .accordion {
        background-color: transparent;
        border-radius: 0;

        &.accordion--flat, &.accordion {
            .accordion__title {
                .accordion__title-button {
                    background-color: transparent;
                    color: var(--color-grey-xlight);

                    &:hover {
                        color: var(--color-teal);
                    }

                    &::after {
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path fill='%23EDEDED' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z'/> </svg>");
                    }

                    &:focus {
                        &::after {
                            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path fill='%23EDEDED' d='m8.41421356 9.58578644c-.78104858-.78104859-2.04737854-.78104859-2.82842712 0-.78104859.78104856-.78104859 2.04737856 0 2.82842716l7.99999996 8c.7810486.7810485 2.0473786.7810485 2.8284272 0l8-8c.7810485-.7810486.7810485-2.0473786 0-2.82842716-.7810486-.78104859-2.0473786-.78104859-2.8284272 0l-6.5857864 6.58578646z'/> </svg>");
                        }
                    }
                }
            }
        }

        &.closed {

            .accordion__title {
                .accordion__title-button {
                    &::after {
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path fill='%23EDEDED' d='M9.586,21.586 C8.805,22.367 8.805,23.633 9.586,24.414 C10.367,25.195 11.633,25.195 12.414,24.414 C20.414,16.414 20.414,16.414 20.414,16.414 C21.195,15.633 21.195,14.367 20.414,13.586 C12.414,5.586 12.414,5.586 12.414,5.586 C11.633,4.805 10.367,4.805 9.586,5.586 C8.805,6.367 8.805,7.633 9.586,8.414 C16.172,15.000 16.172,15.000 16.172,15.000 C16.172,15.000 9.586,21.586 9.586,21.586 z' /> </svg>");
                    }

                    &:focus {
                        &::after {
                            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'> <path fill='%23EDEDED' d='M9.586,21.586 C8.805,22.367 8.805,23.633 9.586,24.414 C10.367,25.195 11.633,25.195 12.414,24.414 C20.414,16.414 20.414,16.414 20.414,16.414 C21.195,15.633 21.195,14.367 20.414,13.586 C12.414,5.586 12.414,5.586 12.414,5.586 C11.633,4.805 10.367,4.805 9.586,5.586 C8.805,6.367 8.805,7.633 9.586,8.414 C16.172,15.000 16.172,15.000 16.172,15.000 C16.172,15.000 9.586,21.586 9.586,21.586 z' /> </svg>");
                        }
                    }
                }
            }
        }

        &.accordion--panel, &.accordion--form {
          border-color: var(--color-grey-border);
        }

        &.accordion--panel {
            .accordion__title {
                .accordion__title-button {
                    span {
                        color: var(--color-grey-light);
                    }
                }
              
                svg {
                  path{
                    fill: var(--color-grey-xlight);
                  }
                }

                &:hover {
                  svg {
                    path{
                      fill: var(--color-teal);
                    }
                  }
                }
            }
            &.closed {
                .accordion__title {
                    .accordion__title-button {
                        &:hover {
                            background-color: var(--color-black-light);
                        }
                    }
                }
            }
        }
    }
}
