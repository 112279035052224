//INFO MODALS GET A CYAN BACKGROUND
.modal__wrapper--info {
    .modal__overlay {
        background-color: rgba(var(--color-cyan-xdark-rgb),0.85);
    }
}


// EG. performance.component.html
// HEADINGS THAT HAVE AN INFO TOOLTIP. THESE OPEN A MODAL WITH THE INFO WRAPPER (SEE ABOVE)
// CSS BELOW CONCERN THE STYLING OF THE HEADING AND NOT THE MODAL ITSELF

.heading--info-button {
    display: flex;
    align-items: center;

    .button {
        margin-bottom: 0;
        margin-left: rems(10);
        margin-top: rems(6);
    }

    p {
        font-weight: 600;
    }

    ul {
        padding-left: rems(16);

        li {
            font-weight: 600;
            font-size: rems(16);
        }
    }

    ul.rating {
        padding-left: 0;
        list-style: none;

        li {
            border-left: 30px solid var(--color-grey-border);
            padding: rems(4) 0 rems(4) rems(10);
            &.rating--1 {border-color: var(--color-green-xxdark);}
            &.rating--2 {border-color: var(--color-green-xdark);}
            &.rating--3 {border-color: var(--color-orange-dark);}
            &.rating--4 {border-color: var(--color-orange-xdark);}
            &.rating--5 {border-color: var(--color-magenta-xdark);}
        }
    }
}

.form__label {
    .modal__wrapper--info {
        p,li {
            font-weight: 600;
        }
    }
}
