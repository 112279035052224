.card--repair {
    .card__header.card__header--status {
        padding: rems(10) rems(10) rems(10) rems(20);

        h4 {
            line-height: 1.3;
            max-width: calc(100% - 72px);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

    }

    .card__header {
        &.card__header--category {
            .card__header--category-wrapper {
                p {
                    margin: 0;
                    margin-bottom: rems(2);
                    font-size: rems(12);
                    color: var(--color-grey-border);
                    line-height: 1;
                }

                h4 {
                    font-weight: 400;
                    font-size: rems(22);
                }

                .button__menu {
                    p {
                        margin-bottom: 0;
                        padding: rems(4) 0 rems(4) rems(10);
                        font-size: rems(14);
                        font-weight: 400;
                        color: var(--color-black);
                        line-height: 1.5rem;
                        &:hover {
                            cursor: pointer;
                            background: var(--color-teal-xlight);
                        }
                    }
                }
            }
        }
    }

    .card__content {
        display: none;
    }

    .card__actions {
        padding: rems(12) rems(20) rems(2) rems(20);

        .hidden {
            display: none;
        }
    }
}

maintenance-issue {
    &.viewing {
        &.col__sm--6 {
            width: 100%;
        }

        .card__content {
            display: flex;
            flex-direction: column;

            &--left {
                padding-bottom: rems(16);
            }

            &--right {
                padding-top: rems(16);
                border-top: 1px solid var(--color-grey-xlight);
            }



        }

        .card__header.card__header--status {
            padding-right: rems(20);

            h4 {
                overflow: visible;
                -webkit-line-clamp: 99;
                max-width: 100%;
            }

            .status-badge {
                display: none;
            }
        }

        .card__header {
            &.card__header--category {
                padding: rems(8) rems(10) rems(4) rems(20);
            }
        }

        .card__actions {
            padding-bottom: rems(12);
        }
    }
}


maintenance-issue,.compact__table {
    .context__list-actions {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;

        .context__list-actions-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: rems(8) 0;

            p {
                margin: 0;
                padding: 0;
                border: 0;
                text-align: left;
                min-width: rems(60);
            }

            ul {
                margin-top: 0;
                display: block;

                li {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
                border: 0;
            }
        }
    }
}

maintenance-categories {
    .category__wrapper {
        p {
            margin: 0;
            margin-bottom: rems(4);
            font-size: rems(14);
            color: var(--color-grey);
            line-height: 1;
            font-weight: 400;
        }
    }
}
.repair-photos{
    display: flex;
    flex-wrap: wrap;
    margin-top: rems(20);
    padding: 0;
    margin-bottom: rems(10);

    li{
        align-items: center;
        border: 1px solid var(--color-grey-xlight);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: rems(10);
        margin-bottom: rems(10);
        padding: rems(5);
        width: rems(90);
        max-height: rems(71);
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
}

maintenance-issue-row {
    .card__header {
        &.card__header--category {
            p.info-badge {
                margin-bottom: 0;
                margin-right: rems(20);
                white-space: nowrap;
            }
        }
    }
}

maintenance-privacy-settings {
    margin-bottom: 10px;
}

.maintenance_statuses {
    display: flex;
    gap: 5px 20px;
    flex-wrap: wrap;

    p {
        margin: 0;
    }
}

maintenance-issue-page {
    .maintenance_archived_banner{
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        background-color: var(--color-grey);
        color: var(--color-white);
        border-radius: radius(medium);
    }
}

.info_row {
    width: 100% !important;
}

@include breakpoint(for-tablet-landscape-up) {
    maintenance-issue {
        &.viewing {
            &.col__lg--4 {
                width: 100%;

                .card__header {
                    h4 {
                        max-width: rems(800);
                    }
                }

                .card__content {
                    flex-direction: row;
                    justify-content: space-between;

                    &--left {
                        flex: 0 1 60%;
                        padding-bottom: 0;
                    }

                    &--right {
                        flex: 0 1 40%;
                        padding: 0 rems(8) 0 rems(16);
                        margin-left: rems(16);
                        border-top: 0;
                        border-left: 1px solid var(--color-grey-xlight);
                    }

                    &--left-half {
                        flex: 0 1 50%;
                    }

                    &--right-half {
                        flex: 0 1 50%;
                        h4 {
                            padding-top: rems(6);
                            padding-bottom: rems(18);
                        }
                    }


                }
            }
        }
    }

    maintenance-issue,.compact__table {
        .context__list-actions {
            flex-direction: row;

            ul {
                li {
                    min-width: fit-content;
                    padding-right: rems(8);
                }
            }

            .context__list-actions-group:last-of-type {
                padding-left: rems(16);
            }
        }
    }
}
