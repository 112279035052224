.accordion__box {
    border: 1px solid var(--color-grey-light);
    border-radius: radius(medium);
    padding: rems(4) rems(8);
    text-align: center;
    h3 {
        font-weight: 600;
        font-size: rems(14);
        margin: 0;
    }
    &--numbers {
        margin: 0;
        padding: 0;
        font-size: rems(24);
        color: #3e812f;
        &-zero {
            color: var(--color-grey-light)
        }
    }
    &--footer { 
        color: var(--color-grey-light);
        font-size: rems(12);
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: 600;
    }
}

#settlements {
    .accordion__box {
        &--numbers {
            color: var(--color-pumpkin-dark);
            &-zero {
                color: var(--color-grey-light)
            }
        }
    }
}