.compact__table {
    &.compact__table--narrow {
        &.compact__table--transactions {

            margin-bottom: rems(10);

            &.compact__table--transactions-border {
                border: 1px solid var(--color-grey-light);
                border-radius: radius(medium);
                padding: rems(6) rems(10);
            }

            tr {
                th {
                    color: var(--color-grey-dark);
                }
                td, th {
                    //font-family: Consolas, 'Courier New', Courier, monospace;
                    //font-size: rems(13);

                }
                td {
                    line-height: 1.2;
                    font-weight: 600;
                    padding-bottom: rems(8);
                    &.actions {
                        font-weight: 800;
                    }
                }

                &.deleted {
                    background-color: var(--color-grey-xxlight);
                    td:not(.actions) {
                        color: var(--color-grey-dark);
                    }
                }

                &.errored {
                  background-color: var(--color-red-light);
                  td.actions a {
                    color: var(--color-green-xdark);
                  }
                }

                &.matched {
                    background-color: var(--color-green-light);
                    td.actions a {
                        color: var(--color-green-xdark);
                    }
                }
                &.open {
                    td {
                        height: auto;
                        &.reference {
                            -webkit-line-clamp: 99;
                        }
                    }
                }
            }
        }

    }
}


.match__holder--wrapper {
    max-height: rems(1000);
    overflow-y: scroll;
    padding-right: rems(20);
    margin-bottom: rems(30);

    &::-webkit-scrollbar {
        width: rems(12);
    }

    &::-webkit-scrollbar-track {
        background: #ededed;
        border-radius: rems(6);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-grey-dark);
        border-radius: rems(20);
        border: rems(3) solid #ededed;
        &:hover {
            background-color: var(--color-grey);
            border-width: rems(2);
        }
    }
}

.match__holder {
    &.match__holder--transaction {
        .compact__table {
            &.compact__table--narrow {
                &.compact__table--transactions {
                    tr {
                        border: 0;
                        padding: 0;
                        td {
                            &.label {
                                flex: 1 1 150px;
                            }
            
                            &.value {
                                flex: none;

                                &.partial {
                                    font-weight: 800;
                                    color: var(--color-grey);
                                    text-decoration: underline dotted rems(1) var(--color-grey-border);
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.match__holder--container {
    display: flex;
    flex-direction: column;

    .button__group {
        width: auto;
        //align-items: unset;
        padding-right: rems(8);
        .button__group-item {
            .button {
                &.button--small {
                    min-width: rems(48);
                    &.button--icon.button--icon-only {
                        min-width: rems(34);
                        min-height: rems(34);
                    }
                }
                &.button--tiny {
                    &.button--icon.button--icon-only {
                        padding-left: rems(16);
                        min-width: rems(16);
                        min-height: rems(24);
                    }
                }
            }
        }
    }
    .match__holder {
        &.match__holder--match {
            width: 100%;
            .compact__table {
                &.compact__table--narrow {
                    margin-bottom: rems(10);
                    tr {
                        border: 0;
                        padding: 0;
                        line-height: 1.2;
                        td {

                            &.label {
                                flex: 1 1 150px;
                            }
            
                            &.value {
                                flex: none;

                                &.partial {
                                    color:var(--color-green-xdark);
                                    font-weight: 800;
                                    text-decoration: underline dotted rems(1) var(--color-green);
                                }
                            }
                        }
                    }
                }
            }
            .section__tabs {
                margin: 0;
                .tab-container {
                    .tabs {
                        border: 0;
                        margin: 0;
                        .tab {
                            font-size: rems(12);
                            padding: 0 rems(8);
                            margin: 0;
                            border-top: rems(1) solid var(--color-grey-light);
                            border-bottom: 0;
                            background-color: var(--color-grey-xxlight);
                            height: rems(24);
                            &.active {
                                background-color: var(--color-white);
                                height: rems(25);
                            }
                            &.highlight {
                                border-color: var(--color-green-dark);
                                background-color: var(--color-green-light);
                            }
                          &.lowlight {
                            border-color: var(--color-pumpkin-dark);
                            background-color: var(--color-pumpkin-light);
                          }
                          &.midlight {
                            background-color: var(--color-cyan-light);
                            border-color: var(--color-cyan);
                          }
                        }
                    }
                }
                .tab-panes {
                    border: 0;
                    margin-top: -1px;
                    
                    .tab-pane {
                        border: 1px solid var(--color-grey-light);
                        border-radius: radius(medium);
                        padding: rems(6) rems(10);
                        min-height: rems(222);
                        &.highlight {
                            border: 1px solid var(--color-green-dark);
                            background-color: var(--color-green-light);
                        }
                        &.lowlight {
                            border: 1px solid var(--color-pumpkin-dark);
                            background-color: var(--color-pumpkin-light);
                            .compact__table {
                                &.compact__table--narrow {
                                    tr {
                                        td {
                                            &.value {
                                                &.partial {
                                                    color:var(--color-pumpkin-xdark);
                                                    text-decoration: underline dotted rems(1) var(--color-pumpkin);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.midlight {
                          background-color: var(--color-cyan-light);
                          border: 1px solid var(--color-cyan);
                        }
                        p {
                            &.helper {
                                font-size: rems(12);
                                color: var(--color-grey-dark);
                                font-weight: 400;
                                font-style: italic;
                                margin-bottom: rems(6);
                                line-height: 1.3;
                            }
                        }
                        .button + p.helper {
                            margin-top: rems(10);
                        }
                    }
                }

            }
        }
    }
}



@include breakpoint(for-tablet-portrait-up) {
    .compact__table {
        &.compact__table--narrow {
            &.compact__table--transactions {
    
                &.compact__table--transactions-border {
                    border: 1px solid var(--color-grey-light);
                    border-radius: radius(medium);
                    padding: rems(6) rems(10);
                }
    
                tr {
                    //th {
                        //color: var(--color-grey-dark);
                    //}
                    td, th {
                        //font-family: Consolas, 'Courier New', Courier, monospace;
                        //font-size: rems(13);
                        padding-bottom: 0;
                        min-height: rems(18);
                        &.actions {
                            padding-right: rems(4);
                        }
                        &:first-of-type {
                            padding-left: rems(4);
                        }
                        &.reference {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                    th {
                        height: rems(26);
                    }
                    td {
                        line-height: 1.2;
                        padding-bottom: 0;
                        min-height: rems(18);
                        font-weight: 600;
                        font-size: rems(14);
                        &.actions {
                            font-weight: 800;
                            a {
                                margin-left: rems(8);
                                &:first-of-type {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    &.details {
                        > td {
                            margin-top: rems(10);
                            .form.form--inline {
                                //margin-left: rems(16);
                                margin-top: rems(6);
                            }
                            height: auto;
                        }
                    }
                }
            }
    
        }
    }
    
    
    
    
    .match__holder {
        &.match__holder--transaction {
            .compact__table {
                &.compact__table--narrow {
                    &.compact__table--transactions {
                        tr {
                            border-top: 0;
                            td {
                                &.label {
                                    flex: 1 1 150px;
                                }
                
                                &.value {
                                    flex: 1 1 100%;
                                    // overflow: hidden;
                                    // text-overflow: ellipsis;
                                    // display: -webkit-box;
                                    // -webkit-line-clamp: 1;
                                    // -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .match__holder--container {
        flex-direction:row;
    
        .button__group {
            padding-right: rems(16);
        }
        .match__holder {
            &.match__holder--match {
                .compact__table {
                    &.compact__table--narrow {
                        margin-bottom: rems(10);
                        border-bottom: 0;
                        tr {
                            border-top: 0;
                            padding: rems(2) 0;
                            td {
                                line-height: 1.2;
                                padding-bottom: 0;
                                height: rems(18);
                                font-weight: 600;
                                font-size: rems(13);
    
                                &.label {
                                    flex: 1 1 150px;
                                }
                
                                &.value {
                                    flex: 1 1 100%;
                                    // overflow: hidden;
                                    // text-overflow: ellipsis;
                                    // display: -webkit-box;
                                    // -webkit-line-clamp: 1;
                                    // -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@include breakpoint(for-tablet-landscape-up) {

}
