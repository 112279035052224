
financial-items, tenancies-recurring-transactions-card, financial-item-repeatings,financial-item-preview {
    .compact__table {
        &.compact__table--narrow {
            tr {

                td, th {
                    span {
                        &.parent-category-icon, &.property-icon, &.room-icon, &.tenancy-icon, &.settlement-icon, &.transaction-icon, &.payment-icon {
                            display: none;
                        }
                    }

                    &.description {
                        .hide-for-mobile {
                            display: none;
                        }
                    }

                    &.amount {
                        &.negative {
                            color: var(--color-warning-dark);
                        }
                    }

                    &.room {
                        background: none;
                        margin-bottom: 0;
                        box-shadow: none;
                        border: 0;
                    }

                    .card__content--left {
                        padding-bottom: 0;
                    }
                }

                &.settled {
                    td, th {
                        color: var(--color-grey-light);
                    }
                }

                &.client-money {
                    background-color: var(--color-green-light);
                }

                &.client-expenses {
                    background-color: var(--color-grey-xlight);
                }

                &.tenant-funds {
                    background-color: var(--color-cyan-light);
                }

                &.rent-direct {
                    display: none;
                }
                &.open {
                    border-top: rems(1) solid var(--color-grey-border);
                    border-left: rems(1) solid var(--color-grey-border);
                    border-right: rems(1) solid var(--color-grey-border);
                    border-top-left-radius: rems(2);
                    border-top-right-radius: rems(2);
                    td:not(.actions) {
                        color: var(--color-grey-border);
                        .icon.icon--grey {
                            fill: var(--color-grey-border);
                        }
                    }
                }
                &.open--child {
                    border-left: rems(1) solid var(--color-grey-border);
                    border-right: rems(1) solid var(--color-grey-border);
                }

            }

            financial-item-group-row {
                financial-item-row {
                    &:last-of-type {
                        tr {
                            border-bottom-left-radius: rems(2);
                            border-bottom-right-radius: rems(2);
                            border-bottom: 1px solid var(--color-grey-border);
                        }

                    }
                }
            }
        }
    }

    .finance__total-holder {
        border: rems(1) solid var(--color-grey-light);
        border-radius: radius(medium);
        position: relative;
        height: rems(42);

        &.client-money {
            background-color: var(--color-green-light);
        }

        &.tenant-funds {
            background-color: var(--color-cyan-light);
        }

        &.client-expenses {
            background-color: var(--color-grey-xlight);
        }

        .finance__total-holder--label {
            font-size: rems(9);
            text-transform: uppercase;
            color: var(--color-grey-border);
            position: absolute;
            top: rems(2);
            left: rems(2);
            margin: 0;
            line-height: 1;
        }

        .finance__total-holder--amount {
            text-align: right;
            font-weight: 700;
            font-size: rems(18);
            margin: 0;
            position: absolute;
            right: rems(4);
            bottom: rems(4);
        }
    }

    .finance__detail--holder {
        width: 100%;

        .finance__detail--item-holder {
            display: flex;
            flex-direction: row;

            p {
                margin-bottom: rems(4);
            }

            .finance__detail--label {
                font-size: rems(14);
                width: 35%;
                font-weight: 700;
            }

            .finance__detail--item {
                width: 65%;
                font-size: rems(14);
            }

            .file_documents_container {
                margin-top: rems(4);
                margin-bottom: 0;

                .file_document_group {
                    border-radius: radius(medium);
                    padding: rems(10);
                }
            }
        }
        &:nth-child(2) {
            padding-left: rems(20);
        }

    }

    .settlement__info-item {
        margin-bottom: rems(10);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .settlement__info-item--label {
        font-size: rems(12);
        width: 100%;
        font-weight: 600;
        color: var(--color-grey-border);
        margin-bottom: 0;
        line-height: 1;
    }

    .status-badge {
        background-color: var(--color-grey-light);
        border-radius: radius(medium);
        border: 1px solid var(--color-grey-border);
        color: var(--color-black);
        display: inline-block;
        font-size: rems(12);
        padding: rems(1) rems(3);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        margin-right: rems(4);

        &.status-badge--overdue {
            border-color: var(--color-pink-xdark);
            background-color: var(--color-pink-dark);
            color: var(--color-white);
            font-weight: 700;
        }

        &.status-badge--due {
            border-color: var(--color-orange-xdark);
            background-color: var(--color-orange-dark);
            color: var(--color-white);
            font-weight: 700;
        }

        &.status-badge--paid {
            border-color: var(--color-green-xdark);
            background-color: var(--color-green-dark);
            color: var(--color-white);
            font-weight: 700;
        }

        &.status-badge--client-expense {
            border-color: var(--color-grey-border);
            background-color: var(--color-grey-xlight);
            color: var(--color-grey-dark);
            font-weight: 700;
        }

        &.status-badge--client-money {
            border-color: var(--color-green);
            background-color: var(--color-green-light);
            color: var(--color-black);
            font-weight: 700;
        }

        &.status-badge--tenant-funds {
            border-color: var(--color-cyan);
            background-color: var(--color-cyan-light);
            color: var(--color-black);
            font-weight: 700;
        }

        &.status-badge--income {
            border-color: var(--color-green-xdark);
            background-color: var(--color-green-dark);
            color: var(--color-white);
            font-weight: 700;
        }

        &.status-badge--expense {
            border-color: #993534;
            background-color: var(--color-warning-dark);
            color: var(--color-white);
            font-weight: 700;
        }
    }
}

financial-item-view p:last-child {
    margin-bottom: 0;
}

@include breakpoint(for-tablet-portrait-up) {
    financial-items, tenancies-recurring-transactions-card, financial-item-repeatings,financial-item-preview {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    padding-left: rems(4);
                    padding-right: rems(4);

                    td, th {
                        line-height: 1.3;

                        .icon, svg {
                            width: rems(16);
                            height: rems(16);
                        }

                        .icon {

                            &.property-ownership-owned {
                                svg {
                                    fill: var(--color-cyan-dark);
                                }
                            }

                            &.property-ownership-master-lease {
                                svg {
                                    fill: var(--color-brown-dark);
                                }
                            }

                            &.property-ownership-managed {
                                svg {
                                    fill: var(--color-magenta-dark);
                                }
                            }

                            &.expense-type-settlement {
                                svg {
                                    fill: var(--color-grey-light);
                                }
                            }
                        }

                        span {
                            &.parent-category-icon, &.property-icon, &.room-icon, &.tenancy-icon, &.settlement-icon, &.transaction-icon, &.payment-icon {
                                display: block;
                            }

                            &.mobile-text {
                                display: none;
                            }
                        }

                        &.description {
                            flex: 1 1 70vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;

                            .hide-for-mobile {
                                display: inline-block;
                            }
                        }

                        &.property, &.room, &.tenancy, &.category, &.transaction, &.settlement, &.payment {
                            overflow: visible;
                            display: table-cell;
                            position: relative;

                            .icon {
                                cursor: pointer;
                            }

                            span {


                                .tooltip {
                                    background-color: var(--color-blue-dark);
                                    border-radius: radius(small);
                                    box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
                                    color: white;
                                    display: none;
                                    font-size: rems(12);
                                    line-height: rems(12);
                                    left: rems(20);
                                    padding: rems(5);
                                    position: absolute;
                                    top: rems(-1);
                                    white-space: nowrap;

                                    &.property-ownership-owned {
                                        background-color: var(--color-cyan-dark);
                                    }

                                    &.property-ownership-master-lease {
                                        background-color: var(--color-brown-dark);
                                    }

                                    &.property-ownership-managed {
                                        background-color: var(--color-magenta-dark);
                                    }
                                }

                                &:hover {
                                    .tooltip {
                                        display: block;
                                        z-index: 100;

                                        &:hover {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        &.category, &.transaction, &.settlement, &.payment {
                            span {
                                .tooltip {
                                    background-color: var(--color-grey);
                                }
                            }
                        }

                        &.transaction, &.settlement, &.payment {
                            span {
                                .tooltip {
                                    left: auto;
                                    right: rems(22);
                                }
                            }
                        }

                        &.amount {
                            flex: 1 1 80px;
                            min-width: rems(80);
                        }

                        &.actions {
                            flex: 0 1 44px;
                            min-width: rems(44);
                        }

                        &.has-icon {
                            flex: 0 0 16px;
                            min-width: rems(20);

                            &.tenancy {
                                min-width: rems(28);
                            }

                            &.payment {
                                min-width: rems(22);
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

        .card__content--left {
            display: flex;
            flex-direction: row;
        }

        .finance__detail--holder {
            width: 50%;

            .finance__detail--item-holder {
                flex-direction: column;

                .finance__detail--label {
                    font-size: rems(12);
                    width: 100%;
                    font-weight: 600;
                    color: var(--color-grey-border);
                    margin-bottom: 0;
                    line-height: 1;
                }

                .finance__detail--item {
                    width: 100%;
                    font-size: rems(16);
                    margin-bottom: rems(10);

                    &.u-text--large {
                        font-size: rems(20);

                        span {
                            font-size: rems(16);
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(for-tablet-landscape-up) {
    financial-items, tenancies-recurring-transactions-card, financial-item-repeating,financial-item-preview,credit-control-organisation-notification-card {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    td, th {

                        &.description {
                            flex: 1 1 800px;
                        }

                        &.has-icon {
/*                            flex: 1 1 16px;
                            min-width: rems(20);*/
                        }
                    }
                }
            }
        }
    }
}




// financial-item-preview .finance__detail--holder {
//   width: 100%;

//   .finance__detail--item-holder {
//     display: flex;
//     flex-direction: row;

//     p {
//       margin-bottom: rems(4);
//     }

//     .finance__detail--label {
//       font-size: rems(14);
//       width: 35%;
//       font-weight: 700;
//     }

//     .finance__detail--item {
//       width: 65%;
//       font-size: rems(14);
//     }

//     .file_documents_container {
//       margin-top: rems(4);
//       margin-bottom: 0;

//       .file_document_group {
//         border-radius: radius(medium);
//         padding: rems(10);
//       }
//     }
//   }
//   &:nth-child(2) {
//     padding-left: rems(20);
//   }

// }

// financial-item-preview .finance__detail--item-holder {
//   flex-direction: row;

//   .finance__detail--label {
//     font-size: rems(12);
//     width: 100%;
//     font-weight: 600;
//     color: var(--color-grey-border);
//     margin-bottom: 0;
//     line-height: 1;
//   }

//   .finance__detail--item {
//     width: 100%;
//     font-size: rems(16);
//     margin-bottom: rems(10);

//     &.u-text--large {
//       font-size: rems(20);

//       span {
//         font-size: rems(16);
//       }
//     }
//   }
// }




// JON: Can we not standardise this?
// credit-control-organisation-notification-card .finance__detail--holder {
//     width: 100%;

//     .finance__detail--item-holder {
//         display: flex;
//         flex-direction: row;

//         p {
//             margin-bottom: rems(4);
//         }

//         .finance__detail--label {
//             font-size: rems(14);
//             width: 35%;
//             font-weight: 700;
//         }

//         .finance__detail--item {
//             width: 65%;
//             font-size: rems(14);
//         }

//         .file_documents_container {
//             margin-top: rems(4);
//             margin-bottom: 0;

//             .file_document_group {
//                 border-radius: radius(medium);
//                 padding: rems(10);
//             }
//         }
//     }
//     &:nth-child(2) {
//         padding-left: rems(20);
//     }

// }

// credit-control-organisation-notification-card .finance__detail--item-holder {
//     flex-direction: row;

//     .finance__detail--label {
//         font-size: rems(12);
//         width: 100%;
//         font-weight: 600;
//         color: var(--color-grey-border);
//         margin-bottom: 0;
//         line-height: 1;
//     }

//     .finance__detail--item {
//         width: 100%;
//         font-size: rems(16);
//         margin-bottom: rems(10);

//         &.u-text--large {
//             font-size: rems(20);

//             span {
//                 font-size: rems(16);
//             }
//         }
//     }
// }
