.u-margin-bottom--none{
    margin-bottom: 0 ;
    &-forced {
        margin-bottom: 0 !important;
    }
}

.u-margin-top--none{
    margin-top: 0;
}

.u-margin-left--none{
    margin-left: 0 !important;
}
.u-margin-left--5 {
    margin-left: rems(5);
    &-forced {
      margin-left: rems(5) !important;
    }
}
.u-margin-left--10 {
    margin-left: rems(10);
}
.u-margin-left--20 {
    margin-left: rems(20);
}

.u-margin-right--none{
    margin-right: 0;
}
.u-margin-right--5 {
    margin-right: rems(5);
}
.u-margin-right--10 {
    margin-right: rems(10);
}
.u-margin-right--20 {
    margin-right: rems(20);
}

.u-margin-bottom--10{
    margin-bottom: rems(10);
    &-forced {
        margin-bottom: rems(10) !important;
    }
}

.u-margin-bottom--5{
    margin-bottom: rems(5);
    &-forced {
        margin-bottom: rems(5) !important;
    }
}

.u-margin-top--5{
    margin-top: rems(5);
}
.u-margin-top--10{
    margin-top: rems(10);
}
.u-margin-top--n10{
    margin-top: rems(-10);
}
.u-margin-top--20{
    margin-top: rems(20);
}
.u-margin-top--30{
    margin-top: rems(30);
}

.u-margin-bottom--20{
    margin-bottom: rems(20);
    &-forced {
        margin-bottom: rems(20) !important;
    }
}
.u-margin-bottom--minus20{
    margin-bottom: rems(-20);
}
.u-margin-bottom--30{
    margin-bottom: rems(30);
}
.u-margin-bottom--40{
    margin-bottom: rems(40);
}

.u-margin-top--20{
    margin-top: rems(20);
    &-forced {
        margin-top: rems(20) !important;
    }
}

.u-margin-top--30{
    margin-top: rems(30);
}
.u-margin-top--40{
    margin-top: rems(30);
}

.u-margin--10{
    margin: 10px;
}

h1, h2, h3, h4, h5, h6{
    &.u-margin-bottom--none{
        + *{
            margin-top: 0;
        }
    }

    &.u-margin-bottom--10{
        + *{
            margin-top: rems(10);
        }
    }

    &.u-margin-bottom--20{
        + *{
            margin-top: rems(20);
        }
    }
}
