ul.options {
    list-style-type: none;
    padding: 0;
    margin-bottom: rems(8);
    margin-top: rems(8);

    li {
      font-size: rems(12);
      background-color: var(--color-form-light);
      border: 1px solid var(--color-form);
      border-radius: radius(medium);
      display: inline-block;
      padding: rems(2) rems(8);
      margin: 0 rems(6) rems(6) 0;
    }
}
