.property--score-wrapper {
    margin-bottom: rems(20);
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--color-grey-light);
    padding: rems(10) 0 rems(20) 0;
    justify-content: space-between;

    .card {
        &__score{
            cursor: pointer;
            border: 1px solid var(--color-pink);
            .card__content {
                padding: rems(8) rems(20);
                p {
                    font-size: rems(14);
                    font-weight: 800;
                    line-height: 1.3;
                }
            }
        }        
    }
}

.property--score {
    position: relative;
    margin-bottom: rems(20);

    property-completion-score {
        display: block;
    }

    .card__image--score-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 300;
        font-size: rems(21);
        color: var(--color-grey-dark);
    }
}

@include breakpoint(for-large-phone-landscape-up) {
    .property--score-wrapper {
        margin-left: 0;
        padding: rems(20) 0 rems(30) 0;

        > .col:last-child {
            padding-left: rems(30);
        }


        .property--score {
            margin-bottom: 0;

            .card__image--score-label {
                font-weight: 600;
                font-size: rems(24);
                top: 50%;
            }
        }
    }
}


.property--score-completed {
    background: var(--color-green-xdark);
    width: rems(160);
    height: rems(160);
    border-radius: rems(160);
    .icon {
        svg {
            width: rems(150);
            height: rems(150);
        }
    }
}
