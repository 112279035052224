.form__section{
    margin-bottom: rems(30);
    &:last-child {
        margin-bottom: 0;
    }
    &.no-margin {
        margin-bottom: 0;
    }
}

.form__section-header{
    margin-bottom: rems(30);

    h2{
        margin-bottom: 0;

        + p{
            margin-top: 0;
        }
    }
}



.form__section-header--border {
    border-top: 1px solid var(--color-grey-light);
    padding-top: rems(30);
    margin-top: rems(30);
}