.tooltip__info{
    display: inline-block;
    height: rems(24);
    overflow: visible;
    margin: 0;
    position: relative;
    vertical-align: middle;
    width: rems(24);

    svg{
        display: block;
        fill: var(--color-cyan);
        height: rems(24);
        margin: 0;
        width: rems(24);
    }

    &-content{
        background-color: var(--color-cyan);
        bottom: rems(36);
        border-radius: radius(medium);
        box-shadow: rems(0) rems(2) rems(10) rgba(0,0,0,0.2);
        color: white;
        display: block;
        font-size: rems(14);
        left: 50%;
        line-height: rems(20);
        margin-bottom: rems(-12);
        max-width: rems(240);
        opacity: 0;
        padding: rems(5) rems(10);
        position: absolute; 
        transform: translateX(-50%);
        visibility: hidden;
        white-space: nowrap;
        word-wrap: none;
        transition: opacity 0.3s ease-out, margin-bottom 0.3s ease-out;


        &::after{
            border-left:  rems(8) solid transparent;
            border-right: rems(8) solid transparent;
            border-top:   rems(8) solid var(--color-cyan);
            bottom: rems(-8);
            content: "";
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            height: 0; 
            width: 0; 

        }

        &.tooltip--right{
            left: rems(-12);
            right: auto;
            transform: none;

            &::after{
                left: rems(16);
                right: auto;
                transform: none;
            }
        }

        &.tooltip--left{
            left: auto;
            right: rems(-12);
            transform: none;

            &::after{
                left: auto;
                right: rems(16);
                transform: none;
            }            
        }

    }

    &:hover{
        cursor: pointer;

        svg{
            fill: var(--color-blue);
        }
    }

    &.open{
        .tooltip__info-content{
            margin-bottom: 0;
            opacity: 1;
            visibility: visible;
        }
    }

}

p{
    .tooltip__info{
        margin-top: rems(-4);
    }
}

#coho-reusable-tooltip {
    position: fixed;
    display: none;
    z-index: 99999;

    &.tooltip {
        background-color: var(--color-grey-light);
        border-radius: radius(small);
        box-shadow: 0 rems(2) rems(5) rgba(0,0,0,0.2);
        color: var(--color-text);
        font-size: rems(12);
        line-height: rems(12);
        padding: rems(10);
        max-width: 50vw;
        h5 { font-size: rems(14); }
        p {
            font-size: rems(12);
            line-height: 1.3;
            margin-bottom: 0;
            span {
                padding-right: rems(4);
                font-weight: 400;
                font-style: italic;
            }
        }
        h5 + p {
            margin-top: rems(4);
        }
    }
}

#coho-tooltip-arrow {
    position: fixed;
    display: none;
    border-left:  rems(8) solid transparent;
    border-right: rems(8) solid transparent;
    border-top:   rems(8) solid var(--color-grey-light);
    bottom: rems(-8);
    content: "";
    z-index: 99999;
    height: 0;
    width: 0;
}



