.profile__source {
    display: flex;
    flex: 1 0 auto;
    border-bottom: 1px solid var(--color-grey-xlight);
    padding-bottom: rems(16);
    margin-bottom: rems(12);
    flex-direction: column;
    position: relative;

    .profile__source-heading {
        display: flex;
        flex: 1 0 auto;
        //flex-direction: column;
    }

    p {
        margin-top: rems(4);
        margin-bottom: 0;

        .source-title {
            display: inline-block;
            min-width: rems(100);
            font-weight: 700;
        }
    }

    .profile__source-text {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .profile__source-warning {
        .form__message {
            margin-bottom: 0;
        }
    }

    .profile__source-content {
        .card__source {
            //min-width: 13rem;
            .card__content {
                p {
                    line-height: 1.1;
                    margin: rems(6) 0;

                    span.source__status {
                        text-transform: uppercase;
                        color: var(--color-grey-border);
                        font-size: rems(12);
                    }
                }
            }

            &.active {
                .card__content {
                    p {
                        span.source__status {
                            color: var(--color-green-xdark);
                        }
                    }
                }
            }

            &.updating, &.uploading {
                .card__content {
                    p {
                        span.source__status {
                            color: var(--color-orange-dark);
                        }
                    }
                }
            }

            &.error {
                .card__content {
                    p {
                        span.source__status {
                            color: var(--color-pink-dark);
                        }
                    }
                }
            }
        }
    }

    .profile__source-actions {
        min-height: rems(32);

        .button {
            margin-top: rems(20);
            margin-bottom: 0;
            border: 0;
        }
    }
}

source-item {
    display: block;
    &:first-of-type {
        border-top: 1px solid var(--color-grey-xlight);
        padding-top: rems(12);
    }
}

.page__section--alt {
    .profile__source {
        border-color: var(--color-grey-light);
    }

    source-item {
        border-color: var(--color-grey-light);
    }
}


@include breakpoint(for-large-phone-landscape-up) {
    .profile__source{
        //flex-direction: row;
        .profile__source-text{
            margin: 0;
        }   
        .profile__source-actions{
            margin: 0 0 0 rems(20);
            .button {
                margin-top: 0;
            }
        }
    }    
}




