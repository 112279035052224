.coho__dark {

    h1, h2, h3, h4, h5, h6 {
        color: var(--color-grey-light);
    }

    p {
        color: var(--color-grey-light);

        &.lede {
            color: var(--color-grey-light);
        }

        &.u-text-color--magenta {
            color: var(--color-magenta);
        }
    }

    .truncate__text::after {
        background-color: transparent;
    }
}
