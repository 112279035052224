@page {
    size: A4;
}
.statement-box {
    font-weight: 400;
    margin: auto;
    padding: rems(20);
    font-size: rems(14);
    line-height: rems(24);
    font-family: "Mulish", "Muli", "Trebuchet MS", -apple-system, BlinkMacSystemFont, sans-serif;
    color: var(--color-black);
    background-color: var(--color-white);
    line-height: 1.4;
    max-width: 800px;
    min-width: 600px;

    table {
        width: 100%;
        line-height: inherit;
        text-align: left;

        tr.top {
            padding-bottom: rems(40);
            td {
                font-size: rems(14);

                &.heading-title {
                    font-size: 18px;
                    color: #333;
                    line-height: 1.1;

                    span {
                        font-size: 14px;
                        color: var(--color-black);
                    }
                }

                &.org-header {
                    text-align: right;
                    img {
                        width: rems(100);
                        padding-bottom: rems(10);
                    }
                    p {
                        font-size: rems(14);
                        margin-bottom: 0;
                    }
                }

                .property-owner {
                    padding-top: rems(50);
                    font-size: rems(16);
                    line-height: 1.4;
                }
            }
        }

        tr:not(.top) {
            td {
                padding: rems(5);
                vertical-align: top;

                &:nth-child(2) {
                    width: auto;
                }

                &:nth-child(1), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    width: 13%;
                }

                &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    text-align: right;
                }
            }

            &.heading {
                td {
                    border-bottom: 1px solid var(--color-grey-light);
                    font-weight: bold;
                    color: var(--color-grey);
                }
            }

            &.property-heading {
                td {
                    padding-top: rems(20);
                    font-weight: 700;
                    border-bottom: 1px solid var(--color-grey);
                    font-size: rems(18);

                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }

          &.category-heading {
            td {
              padding-top: rems(14);
              font-weight: 700;
              //border-bottom: 1px solid var(--color-grey);
              font-size: rems(16);

              &:nth-child(2) {
                text-align: right;
              }
            }
          }

            &.total-income-heading {
                td {
                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }

          &.total-income-category-heading {
            td {
              &:nth-child(2) {
                text-align: right;
              }
            }
          }

            &.details {
                td {
                    padding-bottom: 20px;
                }
            }

            &.item {
                td {
                   // border-bottom: 1px solid var(--color-grey-light);
                    padding: rems(2) rems(5);

                    span {
                        font-size: rems(13);
                        line-height: 1.2;
                    }
                }
            }

            &.footer {
                table {
                    tr {
                        td {
                            font-size: 11px;
                            line-height: 1.3;
                        }
                    }
                }
            }

            &.gap {
                td {
                    height: rems(20);
                }

                &.big-gap {
                    td {
                        height: rems(40);
                    }
                }
            }

            &.subtotal {
                td {
                    &:nth-child(2) {
                        text-align: right;
                        border-top: 1px solid var(--color-grey-light);
                        border-bottom: 1px solid var(--color-grey-light);
                        font-weight: 600;
                    }
                }
            }

            &.total {
                td {
                    &:nth-child(2) {
                        text-align: right;
                        border-top: 1px solid var(--color-black);
                        border-bottom: 1px solid var(--color-black);
                        font-weight: 800;
                        font-size: rems(16);
                    }
                }
            }

            &.notes {
                td {
                    strong {
                        font-weight: 400;
                        color: var(--color-grey);
                        font-size: rems(14);
                    }
                    border: rems(1) solid var(--color-grey-light);
                    border-radius: radius(small);
                    padding: rems(8);
                    font-size: rems(16);
                }
            }
        }
    }

    .footer {
        background-color: var(--color-white);
        color: var(--color-black);
        line-height: 1.4;
        padding: rems(5);
        padding-top: rems(30);
        display: flex;
        align-items: flex-end;

        .footer-left {
            width: 75%;
            .contact {
                padding-top: rems(4);
                font-size: rems(12);
            }
        }

        .footer-right {
            width: 25%;
            text-align: right;
            display: flex;
            justify-content: flex-end;

            img {
                width: rems(100);
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .statement-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .statement-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}


@include breakpoint(for-tablet-landscape-up) {
    .statement-box {
        padding: rems(30);
        table {
            tr {
                &.top {
                    td {
                        &.heading-title {
                            font-size: 24px;
                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

        }
    }
}


@media (min-width: 800px) {
    .statement__wrapper {
        background: var(--color-grey-xlight);
        height: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .statement {
            background: white;
            min-height: 96vh;
            padding: rems(30) rems(30);
            -webkit-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            -moz-box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
            box-shadow: 0px 0px rems(15) 0px rgba(var(--color-text-rgb),0.25);
        }
    }
}

@media print {
    .statement__wrapper {
        background: none;
        padding-top: 0;

        .statement {
            background: none;
            min-height: 0;
            padding: 0 rems(10) 0 rems(10);
            box-shadow: none;
        }
    }
}
