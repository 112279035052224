.partner-profile-holder {
    margin-top: rems(20);
    margin-bottom: rems(20);
    background: var(--color-grey-xxlight);
    border: 1px solid var(--color-grey-xlight);
    padding: rems(8);
    border-radius: rems(4);
    .profile__preview-text {
        h5 + p {
            margin-top: rems(4);
        }
        p { 
            font-size: rems(14);
            line-height: 1.5;
        }
    }
}

@include breakpoint(for-tablet-landscape-up){
    .partner-profile-holder {
        margin-top: 0;
        margin-bottom: 0;   
    }
    .partner-profile-holder + .partner-profile-holder {
        margin-top: rems(10); 
    } 
}