rent-records {
    .compact__table {
        &--narrow {
            rent-record-row {
                tr {
                    td {

                        &.status, &.rent-status {
                            span:not(.label) {
                                background-color: var(--color-grey);
                                border: 1px solid var(--color-grey-dark);
                                border-radius: rems(4);
                                color: var(--color-white);
                                padding: rems(2) rems(5);
                                font-weight: 700;
                                margin-right: rems(4);
                                white-space: nowrap;
                            }


                        }

                        &.actions {
                            a:last-of-type:not(:first-of-type) {
                                margin-left: rems(8);
                            }
                        }
                    }

                    &.payment {
                        color: var(--color-green-xxdark);
                        font-weight: 700;

                        td {
                            span {
                                &.label {
                                    color: var(--color-black);
                                }
                            }
                        }
                    }

                    &.overdue, &.part-assigned {

                        td {
                            &.status, &.rent-status {
                                span:not(.label) {
                                    border-color: var(--color-orange-xdark);
                                    background-color: var(--color-orange-dark);
                                    color: var(--color-white);

                                    &.payment-status-unconfirmed-overdue {
                                        background-color: var(--color-orange-light);
                                        border: 1px solid var(--color-orange);
                                        color: var(--color-orange-xdark);
                                    }

                                }
                            }
                        }
                    }

                    &.paid {

                        td {
                            &.status, &.rent-status  {
                                span:not(.label) {
                                    border-color: var(--color-green-xxdark);
                                    background-color: var(--color-green-xdark);
                                    color: var(--color-white);
                                }
                            }
                        }
                    }
                    &.paid_late {

                        td {
                            &.status, &.rent-status  {
                                span:not(.label) {
                                    border-color: var(--color-green);
                                    background-color: var(--color-green-light);
                                    color: var(--color-green-xxdark);
                                }
                            }
                        }
                    }

                    &.unassigned {

                        td {
                            &.status, &.rent-status  {
                                span:not(.label) {
                                    border-color: var(--color-pink-xdark);
                                    background-color: var(--color-pink-dark);
                                    color: var(--color-white);
                                }
                            }
                        }
                    }

                    &.details {
                        td {
                            .card__content {
                                rent-payment-view {
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                    }

                    &.deleted {
                        background-color: var(--color-teal-xlight);
                        td {
                            &.status, &.rent-status {
                                span:not(.label) {
                                    background-color: var(--color-grey-xlight);
                                    border: 1px solid var(--color-grey-border);
                                    color: var(--color-black);
                                }


                            }
                        }
                    }
                    &.bad-debt {
                        background-color: var(--color-grey-xxlight);
                        td {
                            &.status, &.rent-status {
                                span:not(.label) {
                                    background-color: var(--color-teal-light);
                                    border: 1px solid var(--color-teal);
                                    color: var(--color-teal-xdark);
                                }


                            }
                        }
                    }
                    &.reversal{
                        background-color: var(--color-grey-xxlight);
                        td {
                            &.status, &.rent-status {
                                span:not(.label) {
                                    background-color: var(--color-violet-light);
                                    border: 1px solid var(--color-violet);
                                    color: var(--color-violet-xdark);
                                }


                            }
                        }
                    }
                }
            }
        }
    }
}
rent-due-record-generated-item {
    date-time-picker {
        .date-picker {
            &.form__input--small {
                width: 100%;
                input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]).mat-datepicker {
                    width: 100%;
                }
            }
        }
    }
}
rent-records {
    .compact__table {
        &--narrow {
            tr {
                &.helper {
                    border: none;
                    padding: 0;

                    td {
                        .button.button--small.button--icon {
                            padding-left: rems(24);
                            text-decoration: none;
                            color: var(--color-grey-dark);
                            font-size: rems(13);
                            margin-bottom: 0;

                            .icon {
                                left: 0;
                            }

                            &:focus {
                                box-shadow: none;
                                border: none;
                            }

                            &:hover {
                                color: var(--color-black);
                            }
                        }
                    }
                }
            }
        }
    }
}

rent-collection-group {
    .compact__table {
        &.compact__table--narrow {
            tr {
                &.property {
                    background-color: var(--color-magenta-light);
                }
            }
        }
    }
}

rent-reminder-add-form {
    h3 {
        + form-message {
            display: block;
            margin-top: rems(20);
        }
    }
}

@include breakpoint(for-tablet-portrait-up) {
    rent-records {
        .compact__table {
            &--narrow {
                tr {
                    td, th {
                        &.amount {
                            margin-right: rems(8);
                        }

                        &.type {
                            flex: 1 1 100px;
                        }

                        &.status, &.rent-status  {
                            padding-left: rems(20);

                            span:not(.label) {
                                font-size: rems(12);
                            }
                        }
                    }

                    &.helper {
                        border-top: 1px solid rgba(3, 3, 3, 0.2);
                        padding: 0.125rem 0;
                    }
                }
            }
        }
    }

    rent-due-record-generator {
        .compact__table.compact__table--narrow {

            tr {
                th,td {
                    &.date {
                        flex: 1 1 80%;
                    }
                    &.amount {
                        flex: 1 1 164px;
                    }
                    &.actions {
                        flex: 1 1 150px;
                    }
                }
            }

            rent-due-record-generated-item {

                tr.editing {
                    th,td {
                        &.date {
                            flex: 1 1 25%;
                        }
                        &.amount {
                            flex: 1 1 174px;
                        }
                    }

                }

                date-time-picker {
                    .date-picker {
                        &.form__input--small {
                            width: rems(132);

                            input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=range]):not([type=file]).mat-datepicker {
                                width: rems(132);
                            }
                        }
                    }
                }
            }
        }
    }

    rent-collection-group {
        .compact__table {
            &.compact__table--narrow {
                tr {
                    border-radius: 0;

                    &.property {
                        background-color: transparent;
                        border-top-color: var(--color-black);

                        td.property-room {
                            font-weight: 700;
                        }

                        td.overdue {
                            font-weight: 700;
                        }
                    }

                    &:not(.property) {
                        :first-of-type {
                            border-top: 0;
                        }

                        td {
                            &.property-room {
                                span {
                                    &.tenancy {
                                        margin-left: rems(20);
                                    }
                                }

                                a {
                                    &.link {
                                        margin-left: rems(20);
                                    }
                                }
                            }
                        }
                    }

                    &.property {
                        td {
                            &.property-room {
                                a {
                                    &.link {
                                        margin-left: rems(20);
                                    }
                                }
                            }
                        }
                    }

                    &.property + tr {
                        border-top-color: var(--color-black);
                    }


                    td, th {
                        &.overdue {
                            flex: 1 1 120px;
                        }
                    }
                }
            }
        }
    }

    rent-due-record-card {
        rent-due-record-history {
            display: block;
            height: 100%;

            history-items {
                display: block;
                height: 100%;

                .notes {
                    display: block;
                    height: 100%;
                }
            }
        }
        credit-control-history, credit-contorl-history {
            display: block;
            height: 100%;
            history-items {
                display: block;
                height: 100%;

                .notes {
                    display: block;
                    height: 100%;
                }
                .zero__state {
                    padding: 0;
                }
            }
        }
    }
    .compact__table tr.details {
        rent-due-record-card {
            .section__tabs {
                .tab-panes {
                    .tab-pane {
                        .notes {
                            height: 100%;
                        }
                    }
                }
            }
        }

    }
}

@include breakpoint(for-tablet-landscape-up) {
    rent-due-record-card {
        rent-due-record-history {

            history-items {

                .notes {

                    .notes__container {
                        min-height: 90%;
                        max-height: 0;
                    }
                }
            }
        }
    }
}

.rent__total-holder {
    border: rems(1) solid var(--color-grey-light);
    border-radius: radius(medium);
    position: relative;
    height: rems(42);


    .rent__total-holder--label {
        font-size: rems(9);
        text-transform: uppercase;
        color: var(--color-grey-border);
        position: absolute;
        top: rems(2);
        left: rems(2);
        margin: 0;
        line-height: 1;
    }

    .rent__total-holder--amount {
        text-align: right;
        font-weight: 700;
        font-size: rems(18);
        margin: 0;
        position: absolute;
        right: rems(4);
        bottom: rems(4);
    }

    &.due {
        background-color: var(--color-magenta-light);
    }

    &.overdue {
        background-color: var(--color-warning-light);

        .rent__total-holder--label {
            color: var(--color-grey);
        }
    }

    &.paid {
        background-color: var(--color-green-light);
    }
}

rent-due-record-card {
    .truncate__text {
        --lh: 1.3rem;
        --max-lines: 1;
        max-height: calc(var(--lh) * var(--max-lines));

        &.open {
            max-height: none;
        }
    }

    a.toggle__trigger {
        color: var(--color-grey);
        font-size: rems(14);
    }

    .card {
        &.card--inner {
            .card__header {
                align-items: flex-start;
            }

            .card__content {
                display: block !important;

                .missed-payment__details {
                    border-bottom: rems(1) solid var(--color-grey-light);
                    margin-bottom: rems(10);

                    &:last-child {
                        margin-bottom: 0;
                        border: 0;
                    }

                    &.highlight {
                        padding: rems(4) rems(6);
                        background-color: var(--color-magenta-light);
                        border-bottom: 0;
                        margin-bottom: rems(16);

                        .truncate__text::after {
                            background-color: transparent;
                        }

                        .u-padding-bottom--20 {
                            padding-bottom: rems(8);
                        }
                    }
                }
            }
        }
    }
}

