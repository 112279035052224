.coho__dark {
    .tags__container {
        .tags__list {
            
            .tags__badge {
                border: 1px solid var(--color-brown);
                background-color: var(--color-brown-light);
                .tags__badge--tag {
                    color: var(--color-grey-xlight); 
                }
                &.tags__badge-temp {
                    background-color: transparent;
                }
                .tags__badge--tag-temp {
                    color: var(--color-grey-border);
                }
                &.tags__badge--tag-removing {
                    background-color: var(--color-warning-light);
                }
                .tags__badge--spinner, .tags__badge--remove {
                    a {
                        color: var(--color-brown-xdark);
                    }
                }
            }
        }
    }

    .tags__entity--brown {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-brown-dark);
                    background-color: var(--color-brown-xdark);
                }
            }
        }
    }
    .tags__entity--teal {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-teal);
                    background-color: var(--color-teal-light);
                    .tags__badge--tag {
                        color: var(--color-teal-xdark); 
                    }
                    .tags__badge--tag-temp {
                        color: var(--color-teal-light);
                    }
                    &.tags__badge-temp {
                        background-color: transparent;
                        .tags__badge--tag-temp {
                            color: var(--color-grey-border);
                        }
                    }
                    &.tags__badge--tag-removing {
                        background-color: var(--color-warning-light);
                    }
                    .tags__badge--spinner, .tags__badge--remove {
                        a {
                            color: var(--color-teal-xdark);
                        }
                    }
                }
            }
        }
    }
    .tags__entity--cyan {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-cyan-dark);
                    background-color: var(--color-cyan-xdark);
                }
            }
        }
    }
    .tags__entity--violet {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-violet-dark);
                    background-color: var(--color-violet-xdark);
                }
            }
        }
    }
    .tags__entity--pumpkin {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-pumpkin-dark);
                    background-color: var(--color-pumpkin-xdark);

                }
            }
        }
    }
    .tags__entity--red {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-red-dark);
                    background-color: var(--color-red-xdark);
                }
            }
        }
    }
    .tags__entity--green {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-green-xdark);
                    background-color: var(--color-green-xxdark);
                }
            }
        }
    }
    .tags__entity--pink {
        &.tags__container {
            padding-top: 0;
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-pink-dark);
                    background-color: var(--color-pink-xdark);
                }
            }
        }
    }
    .tags__entity--grey {
        &.tags__container {
            .tags__list {
                .tags__badge {
                    border: 1px solid var(--color-grey);
                    background-color: var(--color-grey-dark);
                }
            }
        }
    }


    .button{
        &.button--tag{
            background-color: var(--color-brown-xdark);
            border: 1px solid var(--color-grey-dark);
            color: var(--color-grey-xlight);
            &:hover{
                background-color: var(--color-brown-dark);
            }
    
        }
        &.button--tag-zero {
            background-color: var(--color-brown-xdark);
            color: var(--color-grey-xlight);
            &.button--icon .icon {
                fill: var(--color-grey-xlight);
            }
        }
    }
    
    .tags__entity--teal {
        .button{
            &.button--tag{
                background-color: var(--color-teal-xdark);
                border-color: var(--color-teal-dark);
            }
            &.button--tag-zero {
                background-color: var(--color-teal-xdark);
            }
        }
    }
    .tags__entity--cyan {
        .button{
            &.button--tag{
                background-color: var(--color-cyan-xdark);
                border-color: var(--color-cyan-dark);
            }
            &.button--tag-zero {
                background-color: var(--color-cyan-xdark);

            }    
        }
    }
    .tags__entity--violet {
        .button{
            &.button--tag{
                background-color: var(--color-violet-xdark);
                border-color: var(--color-violet-dark);
            }
            &.button--tag-zero {
                background-color: var(--color-violet-xdark);
            }    
        }
    }
    .tags__entity--pumpkin {
        .button{
            &.button--tag{
                background-color: var(--color-pumpkin-xdark);
                border-color: var(--color-pumpkin-dark);
            }
            &.button--tag-zero {
                background-color: var(--color-pumpkin-xdark);
            }    
        }
    }
    .tags__entity--red {
        .button{
            &.button--tag{
                background-color: var(--color-red-xdark);
                border-color: var(--color-red-dark);
            }
            &.button--tag-zero {
                background-color: var(--color-red-xdark);
            }    
        }
    }
    .tags__entity--green {
        .button{
            &.button--tag{
                background-color: var(--color-green-xxdark);
                border-color: var(--color-green-xdark);
            }
            &.button--tag-zero {
                background-color: var(--color-green-xxdark);
            }    
        }
    }
    .tags__entity--pink {
        .button{
            &.button--tag{
                background-color: var(--color-pink-xdark);
                border-color: var(--color-pink-dark);
            }
            &.button--tag-zero {
                background-color: var(--color-pink-xdark);
            }    
        }
    }
    .tags__entity--grey {
        .button{
            &.button--tag{
                background-color: var(--color-grey-dark);
                border-color: var(--color-grey);
            }
            &.button--tag-zero {
                background-color: var(--color-grey-dark);
            }    
        }
    }
}











