.button{
    &.button--feedback{
        background-color: var(--color-pumpkin-dark);
        border-color: var(--color-pumpkin-xdark);
        color: var(--color-white);

        &:hover{
            background-color: var(--color-pumpkin-xdark);
            border-color: var(--color-pumpkin-xdark); 
            color: var(--color-white);
        }
        
        &:focus{
            border: 1px solid var(--color-pumpkin);
            box-shadow: 0 0 0 rems(3) var(--color-pumpkin-xdark);
            outline: none;
        }
        &:disabled, &.disabled{
            background-color: var(--color-grey-border);
            border-color: var(--color-grey-border);
            color: white;
    
            &:hover{
                background-color: var(--color-grey-border);
                border-color: var(--color-grey-border);
                cursor: default;
            }
    
        }
    }
}