// This solution uses transforms and a caret mixin.
// What will yours be? Fork this pen and share
// your solution in this comments.

// This pen's solution is on line 91.


/// DEMO
/// ==========================================================
//$bg:darken(#8CA8D8, 10%);
//html,body{height:100%;}body{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;background:$bg;}


/// REQUIRED
/// ==========================================================

$gutter: 20px;
$caret-unit: 35px;
$bubble-bg: var(--color-magenta-dark);
$bubble-color: white;
$cite-color: $color__text;


/// Opposite Direction
/// ==========================================================
/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}


/// Unit Removal
@function strip-unit($num) {
    @return math.div($num,($num * 0 + 1));
}


/// Triangle Mixin
/// ==========================================================

@mixin caret($point, $border-width, $color) {
  $opposite: opposite-direction($point);
  border: $border-width solid transparent;
  border-#{$opposite}: $border-width solid $color;
  border-#{$point}: 0;
  height: 0;
  width: 0;
}


/// Component
/// ==========================================================
.testimonials {
    blockquote {
        filter: drop-shadow(-1px -1px 2px rgba(black, .25)) drop-shadow(1px 2px 2px rgba(black, .25));
        margin: rems(4);
        margin-bottom: rems(16);
        margin-bottom: ($gutter * 2);
        padding: 1.5rem 2rem;
        position: relative;
        font-size: rems(20);
        @include breakpoint(for-tablet-portrait-up){
            font-size: rems(28);
        }
        line-height: 1.3;
        background: $bubble-bg;
        color: $bubble-color;
        border-radius: $size__radius;
        &::before {
            @include caret(bottom, math.div($caret-unit,2), $bubble-bg);
            border-top-width: $caret-unit;
            content: '';
            display: block;
            position: absolute;
            left: 3rem;
            bottom: -$caret-unit;
            transform-origin: center;
            transform: rotate(90deg) skew(-(strip-unit($caret-unit))+deg) translateY(math.div($caret-unit,1.5));
        }
    }
    div:nth-child(2) {
        blockquote {
            background: var(--color-green-xdark);
            &::before {
                border-top-color: var(--color-green-xdark);
            }                
        }
    }
    div:nth-child(3) {
        blockquote {
            background: var(--color-pink-dark);
            &::before {
                border-top-color: var(--color-pink-dark);
            }                
        }
    }
    div:nth-child(4) {
        blockquote {
            background: var(--color-teal-xdark);
            &::before {
                border-top-color: var(--color-teal-xdark);
            }                
        }
    }

    cite {
        position: absolute;
        bottom: 0.25rem;
        left: 5rem;
        font-size: rems(14);
        font-style: normal;
        font-weight: 300;
        //letter-spacing: 0.5px;
        color: $cite-color;
        line-height: 1.2;
    }
}

.testimonials__small {
    blockquote {
        font-size: rems(18);

        @include breakpoint(for-tablet-portrait-up) {
            font-size: rems(20);
        }

        background: var(--color-teal-dark);

        &::before {
            border-top-color: var(--color-teal-dark);
        }
    }
}




@include breakpoint(for-large-phone-landscape-up) {
    .testimonials {
        cite {
            bottom: 0.5rem;
            font-size: 1rem;
        }
    }
}
